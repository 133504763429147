export const SKIP_INTERCEPTOR = 'X-Skip-Interceptor';
export const DEFAULT_HOME_PAGE_URL = '/dashboard/home';
export const DEFAULT_LOGIN_PAGE_URL = '/pages/authentication/login';
export const DEFAULT_LOGIN_URL = '/login';
export const DEFAULT_LOGOUT_URL = '/logout';
export const DEFAULT_SESSION_REFRESH_URL = '/session_refresh';
export const DEFAULT_SESSION_VALIDATE_URL = '/users/session';
export const EVENTS = {
  LOGIN_SUCCESS: 'login:success',
  LOGIN_FAILED: 'login:failed',
  LOGOUT_SUCCESS: 'logout:success',
  SESSION_VALIDATING: 'session:validating',
  SESSION_VALID: 'session:valid',
  SESSION_INVALID: 'session:invalid',
  AVATAR_UPDATED: 'avatar:updated',
};
export const MAP = {
  OPENSTREETMAP: {
    TILE_LAYER: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
    ATTRIBUTION: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>',
    MAX_ZOOM: 18
  },
  BOUNDS: [
    [12.202833383417302, 142.3935277816377],
    [-14.970492799940834, 93.22885908533497]
  ],
  BOUNDS_A: [
    [30.634973284466774, 144.74412653145592],
    [-34.05043992462802, 91.2298063563335]
  ]
};
