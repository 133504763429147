export const environment = {
  production: false,
  hmr: false,
  name: 'dev',
  appName: 'JPMD Dashboard',
  baseApiUrl: 'http://localhost:3925',
  baseApiPrefix: 'api/v1',
  imageApiPrefix: 'images',
  title: 'Dashboard'
};
