import { Injectable } from '@angular/core';
import { Router, RoutesRecognized } from '@angular/router';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, filter, pairwise } from 'rxjs/operators';

import { TranslateService } from '@ngx-translate/core';
import swal from 'sweetalert2';

import { EventsService } from '@core/services/events.service';
import { NotifyService } from '@core/services/notify.service';

import {
  SKIP_INTERCEPTOR,
  DEFAULT_LOGIN_PAGE_URL,
  DEFAULT_LOGIN_URL,
  DEFAULT_HOME_PAGE_URL,
  EVENTS
} from 'app/app.constant';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  activeUrl: string = '';

  constructor(
    private events: EventsService,
    private translate: TranslateService,
    private notifyService: NotifyService,
    private router: Router) {
    // set active url
    this.router.events.pipe(
      filter((e: any) => e instanceof RoutesRecognized),
      pairwise()
    ).subscribe((e: any) => {
      if (e && e.length > 1) {
        this.activeUrl = e[e.length - 1].url || '';
      }
    });
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.headers.has(SKIP_INTERCEPTOR)) {
      const headers = request.headers.delete(SKIP_INTERCEPTOR);
      return next.handle(request.clone({ headers }));
    }
    return next.handle(request).pipe(catchError((error) => this.errorHandler(error)));
  }

  // Customize the default error handler here if needed
  private errorHandler(err: HttpErrorResponse): Observable<HttpEvent<any>> {
    let message = (err.error && err.error.message) || err.statusText || err.message || '';
    const status = (err.status || err['statusCode'] || 0);

    swal.close();
    if (status === 401) {
      // 401 = unauthorized
      if (['Expired token', 'Your token has expired, please re-login to get a new one'].includes(message)) {
        setTimeout(() => {
          this.events.publish(EVENTS.SESSION_INVALID, this.activeUrl);
        }, 300);
      } else {
        message = `${status} ${this.translate.instant(message)}`;
        if (!err.url.endsWith(DEFAULT_LOGIN_URL)) {
          message += `, ${this.translate.instant('signing out...')}`;
          this.notifyService.error(message);
        }
        setTimeout(() => {
          if (!err.url.endsWith(DEFAULT_LOGIN_URL)) {
            // go to login page without logout
            this.router.navigate([DEFAULT_LOGIN_PAGE_URL], { replaceUrl: true });
          }
        }, 1000);
      }
      if (!err.url.endsWith(DEFAULT_LOGIN_URL)) {
        return of();
      }
    } else if (status === 403) {
      // 403 = forbidden
      this.notifyService.error(`${status} ${this.translate.instant(message)}, ${this.translate.instant('redirecting...')}`);
      setTimeout(() => {
        // back to home
        this.router.navigate([DEFAULT_HOME_PAGE_URL], { replaceUrl: true });
      }, 1000);
      return of();
    } else if (status === 404) {
      // 404 = not found
      swal.fire({
        icon: 'error',
        title: this.translate.instant('Ooops..'),
        text: this.translate.instant('Data tidak ditemukan !!'),
        footer: `err: (${status}) ${this.translate.instant(message)}`
      });
    } else if (status === 0) {
      // 0 = connection refused
      swal.fire({
        icon: 'error',
        title: this.translate.instant('Error..'),
        text: this.translate.instant('Server tidak dapat diakses !!'),
        footer: `err: (${status}) ${this.translate.instant(message)}`
      });
    }

    throw err;
  }
}
