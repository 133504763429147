import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';

import { ApiService } from '@core/services/api.service';
import { EventsService } from '@core/services/events.service';
import { User } from 'app/auth/models';

import {
  EVENTS,
  DEFAULT_LOGIN_URL,
  DEFAULT_LOGOUT_URL,
  DEFAULT_SESSION_REFRESH_URL,
  DEFAULT_SESSION_VALIDATE_URL
} from '../../app.constant';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  public currentUser: Observable<User>;

  private currentUserSubject: BehaviorSubject<User>;

  constructor(
    private apiService: ApiService,
    private events: EventsService) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  // getter: currentUserValue
  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  login(password: string): Observable<any> {
    const loginData = {
      hash: btoa(password)
    };
    return this.apiService
      .post(DEFAULT_LOGIN_URL, loginData)
      .pipe(
        map(user => {
          // login successful if there's a jwt token in the response
          if (user && user.token) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('currentUser', JSON.stringify(user));

            // notify
            this.currentUserSubject.next(user);
          }

          return user;
        })
      );
  }

  logout(): Observable<boolean> {
    return this.apiService
      .post(DEFAULT_LOGOUT_URL, {})
      .pipe(
        map(() => {
          // delayed publish event, ensure everything sets correctly
          this.clearData();
          setTimeout(() => {
            this.events.publish(EVENTS.LOGOUT_SUCCESS, new Date());
          }, 300);
          return true;
        }),
        catchError(() => {
          this.clearData();
          this.events.publish(EVENTS.LOGOUT_SUCCESS, new Date());
          return of(false);
        })
      );
  }

  validateSession(): Observable<any> {
    return this.apiService
      .get(DEFAULT_SESSION_VALIDATE_URL)
      .pipe(
        map(user => {
          this.clearData();
          // validate successful if there's a jwt token in the response
          if (user && user.token) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('currentUser', JSON.stringify(user));

            // notify
            this.currentUserSubject.next(user);

            setTimeout(() => {
              this.events.publish(EVENTS.SESSION_VALID, user);
            }, 500);
          } else {
            setTimeout(() => {
              this.events.publish(EVENTS.SESSION_INVALID);
            }, 500);
          }

          return user;
        }),
        catchError((err) => {
          return of(false);
        })
      );
  }

  refreshSession(): Observable<any> {
    const sessionData = {
      refresh_token: this.currentUserValue.refresh_token
    };
    return this.apiService
      .post<any, { refresh_token: string }>(DEFAULT_SESSION_REFRESH_URL, sessionData)
      .pipe(
        map((user) => {
          // refresh token successful if there's a jwt token in the response
          if (user && user.token) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('currentUser', JSON.stringify(user));

            // notify
            this.currentUserSubject.next(user);

            // delayed publish event, ensure everything sets correctly
            setTimeout(() => {
              this.events.publish(EVENTS.SESSION_VALID, (user));
            }, 500);
            return true;
          }

          return of(false);
        }),
        catchError((err: HttpErrorResponse) => {
          return of(false);
        })
      );
  }

  private clearData() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    // notify
    this.currentUserSubject.next(null);
  }
}
