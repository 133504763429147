export const locale = {
  lang: 'id',
  data: {
    'Page Not Found': 'Halaman tidak ditemukan',
    'The requested URL was not found on this server': 'URL yang diminta tidak ditemukan di server ini',
    'Back to home': 'Kembali ke halaman utama',
    'Whoops! Password is incorrect.': 'Kode akses tidak sesuai',
    'Login page': 'Halaman login',
    'Please log in using JPMD Account': 'Silahkan masuk menggunakan akun JPMD'
  }
};
