import { InMemoryDbService } from 'angular-in-memory-web-api';

import { HomeFakeData } from '@fake-db/home.data';

export class FakeDbService implements InMemoryDbService {
  createDb(): any {
    return {
      // Total Home
      // 'countpejabat': HomeFakeData.total,

      // Map GUBERNUR Home
      'home-map-GUBERNUR': HomeFakeData.mapGUBERNUR,

      // Map WALIKOTA Home
      'home-map-WALIKOTA': HomeFakeData.mapWALIKOTA,

      // Map BUPATI Home
      'home-map-BUPATI': HomeFakeData.mapBUPATI
    };
  }
}
