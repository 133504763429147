import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiService } from '@core/services/api.service';

import {
  IHomeResultModel,
  IHomeRegionResultModel,
  ICandidateRegionResultModel,
  ICandidateResultModel,
  ICandidateContext
} from '../../../domain/model';

import { HomeRepository } from '../../../domain/repository/home.repository';

@Injectable({
  providedIn: 'root',
})
export class HomeApiRepository extends HomeRepository {
  constructor(
    private apiService: ApiService) {
    super();
  }

  getAllFavoriteCandidate(type: string): Observable<ICandidateRegionResultModel> {
    const url = `/candidates/favorite/${type}`;
    return this.apiService.get<ICandidateRegionResultModel>(url);
  }

  getCandidateRegion(id: string, payload: ICandidateContext): Observable<ICandidateRegionResultModel> {
    const url = `/candidates/region/${id}/${payload.year}`;
    return this.apiService.get<ICandidateRegionResultModel>(url);
  }

  getHome(): Observable<IHomeResultModel> {
    const url = `/home`;
    return this.apiService.get<IHomeResultModel>(url);
  }

  getHomeRegion(id: string): Observable<IHomeRegionResultModel> {
    const url = `/home/region/${id}`;
    return this.apiService.get<IHomeRegionResultModel>(url);
  }

  getOtherCandidate(id: string, payload: ICandidateContext): Observable<ICandidateRegionResultModel> {
    const url = `/candidates/other/${id}/${payload.year}/${payload.search}`;
    return this.apiService.get<ICandidateRegionResultModel>(url);
  }

  getSearchCandidate(keywords: string): Observable<ICandidateRegionResultModel> {
    const url = `/candidates/search/${keywords}`;
    return this.apiService.get<ICandidateRegionResultModel>(url);
  }

  updateCandidateFavorite(id: string, payload: ICandidateContext): Observable<ICandidateResultModel> {
    const url = `/candidates/favorite/${id}`;
    return this.apiService.put<string, ICandidateContext>(url, payload);
  }

  updateCandidateSelected(id: string, payload: ICandidateContext): Observable<ICandidateResultModel> {
    const url = `/candidates/selected/${id}`;
    return this.apiService.put<string, ICandidateContext>(url, payload);
  }

  updateCandidateUnselected(id: string): Observable<IHomeResultModel> {
    const url = `/candidates/unselected/${id}`;
    return this.apiService.put<string, ICandidateContext>(url, {});
  }
}
