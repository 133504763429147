import { Observable } from 'rxjs';

import {
  IHomeResultModel,
  IHomeRegionResultModel,
  ICandidateRegionResultModel,
  ICandidateResultModel,
  ICandidateContext
} from '../../domain/model';

export abstract class HomeRepository {
  abstract getAllFavoriteCandidate(type: string): Observable<ICandidateRegionResultModel>;
  abstract getCandidateRegion(id: string, payload: ICandidateContext): Observable<ICandidateRegionResultModel>;
  abstract getHome(): Observable<IHomeResultModel>;
  abstract getHomeRegion(id: string): Observable<IHomeRegionResultModel>;
  abstract getOtherCandidate(id: string, payload: ICandidateContext): Observable<ICandidateRegionResultModel>;
  abstract getSearchCandidate(keywords: string): Observable<ICandidateRegionResultModel>;
  abstract updateCandidateFavorite(id: string, payload: ICandidateContext): Observable<ICandidateResultModel>;
  abstract updateCandidateSelected(id: string, payload: ICandidateContext): Observable<ICandidateResultModel>;
  abstract updateCandidateUnselected(id: string): Observable<IHomeResultModel>;
}
