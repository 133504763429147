import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ElementRef,
  Renderer2
} from '@angular/core';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import * as Waves from 'node-waves';
import swal from 'sweetalert2';

import { environment } from 'environments/environment';
import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';
import { CoreConfigService } from '@core/services/config.service';
import { CoreLoadingScreenService } from '@core/services/loading-screen.service';
import { CoreTranslationService } from '@core/services/translation.service';
import { AuthenticationService } from 'app/auth/service';
import { EventsService } from '@core/services/events.service';
import { NotifyService } from '@core/services/notify.service';

import { locale as menuIndonesia } from 'app/menu/i18n/id';
import { locale as menuEnglish } from 'app/menu/i18n/en';

import {
  EVENTS,
  DEFAULT_HOME_PAGE_URL,
  DEFAULT_LOGIN_PAGE_URL
} from './app.constant';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  coreConfig: any;
  defaultLanguage: 'id'; // This language will be used as a fallback when a translation isn't found in the current language
  appLanguage: 'id'; // Set application default language i.e fr

  // Private
  private _unsubscribeAll: Subject<any>;

  constructor(
    @Inject(DOCUMENT) private document: any,
    private _title: Title,
    private _renderer: Renderer2,
    private _elementRef: ElementRef,
    public _coreConfigService: CoreConfigService,
    private _coreSidebarService: CoreSidebarService,
    private _coreLoadingScreenService: CoreLoadingScreenService,
    private _coreTranslationService: CoreTranslationService,
    private _authenticationService: AuthenticationService,
    private _events: EventsService,
    private _notifyService: NotifyService,
    private _router: Router,
    private _translateService: TranslateService) {
    // Add languages to the translation service
    this._translateService.addLangs(['id', 'en']);

    // This language will be used as a fallback when a translation isn't found in the current language
    this._translateService.setDefaultLang('id');

    // Set the translations for the menu
    this._coreTranslationService.translate(menuIndonesia, menuEnglish);

    // Set the private defaults
    this._unsubscribeAll = new Subject();

    this.eventSubscriptions();
  }

  ngOnInit(): void {
    // Init wave effect (Ripple effect)
    Waves.init();

    // Subscribe to config changes
    this._coreConfigService.config.pipe(
      takeUntil(this._unsubscribeAll)
    ).subscribe((config: any) => {
      this.coreConfig = config;

      // Set application default language.

      // Change application language? Read the ngxTranslate Fix

      // ? Use app-config.ts file to set default language
      const appLanguage = this.coreConfig.app.appLanguage || 'en';
      this._translateService.use(appLanguage);

      // ? OR
      // ? User the current browser lang if available, if undefined use 'en'
      // const browserLang = this._translateService.getBrowserLang();
      // this._translateService.use(browserLang.match(/en|fr|de|pt/) ? browserLang : 'en');

      /**
       * ! Fix : ngxTranslate
       * ----------------------------------------------------------------------------------------------------
       */

      /**
       *
       * Using different language than the default ('en') one i.e French?
       * In this case, you may find the issue where application is not properly translated when your app is initialized.
       *
       * It's due to ngxTranslate module and below is a fix for that.
       * Eventually we will move to the multi language implementation over to the Angular's core language service.
       *
       **/

      // Set the default language to 'en' and then back to 'fr'.

      setTimeout(() => {
        this._translateService.setDefaultLang('en');
        this._translateService.setDefaultLang(appLanguage);
      });

      /**
       * !Fix: ngxTranslate
       * ----------------------------------------------------------------------------------------------------
       */

      // Layout
      //--------

      // Remove default classes first
      this._elementRef.nativeElement.classList.remove(
        'vertical-layout',
        'vertical-menu-modern',
        'horizontal-layout',
        'horizontal-menu'
      );
      // Add class based on config options
      if (this.coreConfig.layout.type === 'vertical') {
        this._elementRef.nativeElement.classList.add('vertical-layout', 'vertical-menu-modern');
      } else if (this.coreConfig.layout.type === 'horizontal') {
        this._elementRef.nativeElement.classList.add('horizontal-layout', 'horizontal-menu');
      }

      // Navbar
      //--------

      // Remove default classes first
      this._elementRef.nativeElement.classList.remove(
        'navbar-floating',
        'navbar-static',
        'navbar-sticky',
        'navbar-hidden'
      );

      // Add class based on config options
      if (this.coreConfig.layout.navbar.type === 'navbar-static-top') {
        this._elementRef.nativeElement.classList.add('navbar-static');
      } else if (this.coreConfig.layout.navbar.type === 'fixed-top') {
        this._elementRef.nativeElement.classList.add('navbar-sticky');
      } else if (this.coreConfig.layout.navbar.type === 'floating-nav') {
        this._elementRef.nativeElement.classList.add('navbar-floating');
      } else {
        this._elementRef.nativeElement.classList.add('navbar-hidden');
      }

      // Footer
      //--------

      // Remove default classes first
      this._elementRef.nativeElement.classList.remove('footer-fixed', 'footer-static', 'footer-hidden');

      // Add class based on config options
      if (this.coreConfig.layout.footer.type === 'footer-sticky') {
        this._elementRef.nativeElement.classList.add('footer-fixed');
      } else if (this.coreConfig.layout.footer.type === 'footer-static') {
        this._elementRef.nativeElement.classList.add('footer-static');
      } else {
        this._elementRef.nativeElement.classList.add('footer-hidden');
      }

      // Blank layout
      if (
        this.coreConfig.layout.menu.hidden &&
        this.coreConfig.layout.navbar.hidden &&
        this.coreConfig.layout.footer.hidden
      ) {
        this._elementRef.nativeElement.classList.add('blank-page');
        // ! Fix: Transition issue while coming from blank page
        this._renderer.setAttribute(
          this._elementRef.nativeElement.getElementsByClassName('app-content')[0],
          'style',
          'transition:none'
        );
      } else {
        this._elementRef.nativeElement.classList.remove('blank-page');
        // ! Fix: Transition issue while coming from blank page
        setTimeout(() => {
          this._renderer.setAttribute(
            this._elementRef.nativeElement.getElementsByClassName('app-content')[0],
            'style',
            'transition:300ms ease all'
          );
        }, 0);
        // If navbar hidden
        if (this.coreConfig.layout.navbar.hidden) {
          this._elementRef.nativeElement.classList.add('navbar-hidden');
        }
        // Menu (Vertical menu hidden)
        if (this.coreConfig.layout.menu.hidden) {
          this._renderer.setAttribute(this._elementRef.nativeElement, 'data-col', '1-column');
        } else {
          this._renderer.removeAttribute(this._elementRef.nativeElement, 'data-col');
        }
        // Footer
        if (this.coreConfig.layout.footer.hidden) {
          this._elementRef.nativeElement.classList.add('footer-hidden');
        }
      }

      // Skin Class (Adding to body as it requires highest priority)
      if (this.coreConfig.layout.skin !== '' && this.coreConfig.layout.skin !== undefined) {
        this.document.body.classList.remove('default-layout', 'bordered-layout', 'dark-layout', 'semi-dark-layout');
        this.document.body.classList.add(this.coreConfig.layout.skin + '-layout');
      }
    });

    // Set the application page title
    this._title.setTitle(this.coreConfig.app.appTitle);

    if (this._authenticationService.currentUserValue &&
      this._authenticationService.currentUserValue.token) {
      // validate sessions
      this._authenticationService.validateSession().subscribe();
    }
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  toggleSidebar(key: any): void {
    this._coreSidebarService.getSidebarRegistry(key).toggleOpen();
  }

  eventSubscriptions() {
    this._events.subscribe(EVENTS.LOGIN_SUCCESS, () => {
      this.onLoginSuccess();
    });

    this._events.subscribe(EVENTS.LOGOUT_SUCCESS, () => {
      this.onLogoutSuccess();
    });

    this._events.subscribe(EVENTS.SESSION_VALID, (user) => {
      if (user.token) {
        this.onValidSession();
      }
    });

    this._events.subscribe(EVENTS.SESSION_INVALID, (url: string) => {
      this.onInvalidSession(url);
    });
  }

  onLoginSuccess() {
    if (!environment.production) {
      console.log('[Event] LOGIN_SUCCESS');
    }
  }

  onLogoutSuccess() {
    if (!environment.production) {
      console.log('[Event] LOGOUT_SUCCESS');
    }
    swal.close();
    this._notifyService.success(this._translateService.instant('Signing out...'));
    setTimeout(() => {
      // back to login
      this._router.navigate([DEFAULT_LOGIN_PAGE_URL], { replaceUrl: true });
    }, 500);
  }

  onValidSession() {
    if (!environment.production) {
      console.log('[Event] SESSION_VALID');
    }
  }

  onInvalidSession(url: string = '') {
    if (!environment.production) {
      console.log('[Event] SESSION_INVALID');
    }
    if (this._authenticationService.currentUserValue &&
      this._authenticationService.currentUserValue.refresh_token) {
      // do token refresher
      this._notifyService.info(`${this._translateService.instant('Session has expired')}, ${this._translateService.instant('refreshing token...')}`);
      // go to login page before checking
      swal.close();
      this._router.navigate([DEFAULT_LOGIN_PAGE_URL], { replaceUrl: true });
      this._authenticationService.refreshSession()
        .subscribe(isValid => {
          if (isValid) {
            setTimeout(() => {
              this._notifyService.success(`${this._translateService.instant('Welcome back')}`);
              // go to url
              if (url) {
                if (url.toLowerCase() === DEFAULT_LOGIN_PAGE_URL.toLowerCase()) {
                  url = DEFAULT_HOME_PAGE_URL;
                }
              } else {
                url = DEFAULT_HOME_PAGE_URL;
              }
              this._router.navigate([url], { replaceUrl: true });
            }, 2000);
          } else {
            setTimeout(() => {
              this._notifyService.error(`${this._translateService.instant('Invalid token !!')}`);
              // sign out automatically
              this._events.publish(EVENTS.LOGOUT_SUCCESS);
            }, 1000);
          }
        }, err => {
          if (!environment.production) {
            console.log('error: ', err);
          }
          setTimeout(() => {
            this._notifyService.error(`${this._translateService.instant('Unknown error !!')}`);
            // sign out automatically
            this._events.publish(EVENTS.LOGOUT_SUCCESS);
          }, 1000);
        });
    } else {
      this._authenticationService.logout().subscribe();
    }
  }
}
