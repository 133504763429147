import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

import { AuthenticationService } from 'app/auth/service';

import {
  SKIP_INTERCEPTOR,
  DEFAULT_LOGIN_PAGE_URL,
  DEFAULT_SESSION_REFRESH_URL
} from 'app/app.constant';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(
    private _authenticationService: AuthenticationService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const setHeaders = {};
    const publicUrls = [DEFAULT_LOGIN_PAGE_URL, DEFAULT_SESSION_REFRESH_URL];

    if (publicUrls.find((o) => request.url.endsWith(o))) {
      setHeaders[SKIP_INTERCEPTOR] = '';
    }

    const currentUser = this._authenticationService.currentUserValue;

    if (currentUser) {
      const token = currentUser && currentUser.token || null;
      if (token) {
        setHeaders['Authorization'] = `Bearer ${token}`;
      }
    }
    if (setHeaders[SKIP_INTERCEPTOR] !== undefined ||
      setHeaders['Authorization'] !== undefined) {
      request = request.clone({ setHeaders: setHeaders });
    }

    return next.handle(request);
  }
}
