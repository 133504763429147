export class HomeFakeData {
  public static total = {
    GUBERNUR: 34,
    WALIKOTA: 93,
    BUPATI: 415
  };

  public static mapGUBERNUR = {
    "type": "FeatureCollection",
    "features": [{
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [97.113005, 2.113689],
              [97.104261, 2.110974],
              [97.098966, 2.113267],
              [97.094571, 2.108742],
              [97.094038, 2.101395],
              [97.098622, 2.093202],
              [97.093422, 2.087594],
              [97.08651, 2.089639],
              [97.078692, 2.086232],
              [97.077491, 2.076348],
              [97.085411, 2.060576],
              [97.091728, 2.053044],
              [97.091931, 2.047397],
              [97.096496, 2.042309],
              [97.097797, 2.032759],
              [97.112372, 2.016149],
              [97.114026, 2.004251],
              [97.126644, 2.007757],
              [97.128345, 2.012816],
              [97.121535, 2.025306],
              [97.118481, 2.034669],
              [97.125385, 2.045669],
              [97.135632, 2.045116],
              [97.153662, 2.0554],
              [97.153, 2.062363],
              [97.162686, 2.071394],
              [97.154088, 2.079963],
              [97.153591, 2.086227],
              [97.147993, 2.093851],
              [97.142549, 2.096337],
              [97.129534, 2.107722],
              [97.113005, 2.113689]
            ]
          ],
          [
            [
              [96.66509270000006, 2.12017830000002],
              [96.64844920000007, 2.122979100000066],
              [96.640023, 2.11894940000002],
              [96.62776880000007, 2.121003200000075],
              [96.61457530000007, 2.112750600000027],
              [96.61052920000009, 2.103740100000039],
              [96.61194450000005, 2.082567400000073],
              [96.61528980000008, 2.074640100000067],
              [96.62276440000005, 2.066250900000057],
              [96.63570730000004, 2.058349400000054],
              [96.648415, 2.056343200000072],
              [96.65966030000004, 2.066952900000047],
              [96.669332, 2.072093300000063],
              [96.67559490000008, 2.072792700000036],
              [96.68685650000003, 2.085749],
              [96.68697950000006, 2.091043400000046],
              [96.68133260000008, 2.097446600000069],
              [96.67678240000004, 2.111683200000073],
              [96.66509270000006, 2.12017830000002]
            ]
          ],
          [
            [
              [96.64818550000007, 2.169428100000061],
              [96.63463340000004, 2.186394],
              [96.62507880000004, 2.187862800000062],
              [96.61225010000004, 2.184730300000069],
              [96.60834790000007, 2.181973],
              [96.609565, 2.174758900000029],
              [96.61745070000006, 2.168327800000043],
              [96.62456210000005, 2.158422800000039],
              [96.63321520000005, 2.15085270000003],
              [96.64325050000008, 2.148641],
              [96.65160640000005, 2.149342800000056],
              [96.65788740000005, 2.155759700000033],
              [96.66195990000006, 2.166579200000058],
              [96.64818550000007, 2.169428100000061]
            ]
          ],
          [
            [
              [97.441511, 2.183255],
              [97.428297, 2.180377],
              [97.432442, 2.170319],
              [97.430324, 2.163717],
              [97.439363, 2.158854],
              [97.440735, 2.171476],
              [97.43833, 2.178177],
              [97.441511, 2.183255]
            ]
          ],
          [
            [
              [97.168557, 2.235694],
              [97.163004, 2.231211],
              [97.161786, 2.223563],
              [97.152108, 2.224952],
              [97.145506, 2.221973],
              [97.134065, 2.225994],
              [97.120886, 2.226111],
              [97.113991, 2.218857],
              [97.116428, 2.208043],
              [97.124187, 2.199993],
              [97.129335, 2.197997],
              [97.136134, 2.201911],
              [97.144053, 2.202219],
              [97.153784, 2.197991],
              [97.162541, 2.190186],
              [97.168618, 2.188691],
              [97.174814, 2.178036],
              [97.182283, 2.178012],
              [97.196116, 2.184146],
              [97.20886, 2.182055],
              [97.213586, 2.178672],
              [97.214364, 2.16991],
              [97.218462, 2.157728],
              [97.231981, 2.158766],
              [97.231094, 2.150985],
              [97.242023, 2.14412],
              [97.250692, 2.143932],
              [97.253532, 2.138066],
              [97.26144, 2.134841],
              [97.262212, 2.123783],
              [97.259493, 2.120216],
              [97.26646, 2.105812],
              [97.274934, 2.105651],
              [97.286954, 2.096712],
              [97.296501, 2.081443],
              [97.305259, 2.074982],
              [97.306421, 2.063066],
              [97.317216, 2.056325],
              [97.329795, 2.054747],
              [97.33384, 2.060309],
              [97.341088, 2.055843],
              [97.33856, 2.045729],
              [97.333419, 2.045139],
              [97.327951, 2.039475],
              [97.337364, 2.033787],
              [97.343715, 2.03876],
              [97.34627310000008, 2.056820400000049],
              [97.34361920000003, 2.070657600000061],
              [97.34771140000004, 2.078032800000074],
              [97.358005, 2.084268],
              [97.355789, 2.092101],
              [97.358765, 2.097621],
              [97.356323, 2.102469],
              [97.347045, 2.104394],
              [97.348993, 2.112085],
              [97.338408, 2.110826],
              [97.335432, 2.121088],
              [97.343298, 2.125639],
              [97.336252, 2.131234],
              [97.343391, 2.140091],
              [97.340628, 2.150489],
              [97.334064, 2.152233],
              [97.33379, 2.162377],
              [97.327062, 2.165202],
              [97.330669, 2.171685],
              [97.318872, 2.177237],
              [97.311717, 2.17559],
              [97.304188, 2.182186],
              [97.304345, 2.188692],
              [97.291897, 2.200777],
              [97.300596, 2.206952],
              [97.301946, 2.219366],
              [97.300355, 2.227396],
              [97.296128, 2.230414],
              [97.290872, 2.226499],
              [97.274982, 2.224363],
              [97.270851, 2.232008],
              [97.253744, 2.233588],
              [97.244478, 2.23254],
              [97.236474, 2.234938],
              [97.228793, 2.229263],
              [97.225043, 2.220231],
              [97.229827, 2.213531],
              [97.219577, 2.203853],
              [97.214605, 2.208948],
              [97.207634, 2.204529],
              [97.204341, 2.211463],
              [97.19310680000007, 2.206577200000027],
              [97.18966, 2.213596],
              [97.18979, 2.225563],
              [97.181523, 2.221826],
              [97.179018, 2.233478],
              [97.168557, 2.235694]
            ]
          ],
          [
            [
              [97.22459970000006, 2.236675800000057],
              [97.21768170000007, 2.243623800000023],
              [97.21402350000005, 2.242193],
              [97.22165370000005, 2.225478900000041],
              [97.22633570000005, 2.229138500000033],
              [97.22459970000006, 2.236675800000057]
            ]
          ],
          [
            [
              [97.431557, 2.246816],
              [97.42653410000008, 2.249604],
              [97.422684, 2.244685],
              [97.428936, 2.23969],
              [97.431557, 2.246816]
            ]
          ],
          [
            [
              [97.28148730000004, 2.274898700000051],
              [97.28164890000005, 2.279371500000025],
              [97.26446440000007, 2.287027800000033],
              [97.25970650000005, 2.283314200000063],
              [97.27481220000004, 2.274918700000057],
              [97.28148730000004, 2.274898700000051]
            ]
          ],
          [
            [
              [97.31480970000007, 2.292997100000036],
              [97.31588190000008, 2.307924600000035],
              [97.30846040000006, 2.309694600000057],
              [97.30645790000005, 2.300646800000038],
              [97.31480970000007, 2.292997100000036]
            ]
          ],
          [
            [
              [97.40542530000005, 2.313440600000035],
              [97.40242530000006, 2.309727],
              [97.40840130000004, 2.295165800000063],
              [97.41292190000007, 2.29654510000006],
              [97.40542530000005, 2.313440600000035]
            ]
          ],
          [
            [
              [97.22459250000009, 2.318283],
              [97.22872610000007, 2.320087200000046],
              [97.22733460000006, 2.327462600000047],
              [97.21866770000008, 2.321545],
              [97.22459250000009, 2.318283]
            ]
          ],
          [
            [
              [96.19906170000007, 2.358565200000044],
              [96.19441050000006, 2.358965400000045],
              [96.19131750000008, 2.351392700000076],
              [96.19714820000007, 2.348278100000073],
              [96.20593210000004, 2.35497040000007],
              [96.19906170000007, 2.358565200000044]
            ]
          ],
          [
            [
              [97.38118440000005, 2.362567600000034],
              [97.37834860000004, 2.353544300000067],
              [97.38022780000006, 2.339382100000023],
              [97.390165, 2.34497390000007],
              [97.40598040000003, 2.328314300000045],
              [97.41055810000006, 2.314952500000061],
              [97.42051470000007, 2.303548],
              [97.41864310000005, 2.318219700000043],
              [97.41196980000007, 2.337785600000075],
              [97.40793280000008, 2.344864400000063],
              [97.40122140000005, 2.348812],
              [97.38876420000008, 2.360559300000034],
              [97.38118440000005, 2.362567600000034]
            ]
          ],
          [
            [
              [96.25895810000009, 2.359705100000042],
              [96.25554450000004, 2.374087200000076],
              [96.25011350000005, 2.366460600000039],
              [96.23863530000006, 2.360625700000071],
              [96.22557440000008, 2.36089370000002],
              [96.22071150000005, 2.35664840000004],
              [96.22537290000008, 2.350311400000066],
              [96.23534630000006, 2.344519300000059],
              [96.24900270000006, 2.34548540000003],
              [96.25500070000004, 2.349341500000037],
              [96.25895810000009, 2.359705100000042]
            ]
          ],
          [
            [
              [96.49388930000003, 2.435887700000023],
              [96.48927370000007, 2.437708600000065],
              [96.48683040000009, 2.428003400000023],
              [96.49740090000006, 2.416410600000063],
              [96.50970670000004, 2.41508170000003],
              [96.51703890000005, 2.425912800000049],
              [96.50704620000005, 2.432743300000027],
              [96.49388930000003, 2.435887700000023]
            ]
          ],
          [
            [
              [96.38168390000004, 2.524613300000055],
              [96.37801230000008, 2.527360600000065],
              [96.37237610000005, 2.522136200000034],
              [96.36693210000004, 2.526109],
              [96.36302510000007, 2.520210100000043],
              [96.37386810000004, 2.51357820000004],
              [96.37734980000005, 2.521510600000056],
              [96.38168390000004, 2.524613300000055]
            ]
          ],
          [
            [
              [95.93551760000008, 2.542446900000073],
              [95.93216720000004, 2.544860800000038],
              [95.92616150000003, 2.538657],
              [95.91880940000004, 2.536082800000031],
              [95.91656140000003, 2.524909600000058],
              [95.93583110000009, 2.522937100000036],
              [95.93823640000005, 2.524153800000022],
              [95.95763730000004, 2.523392300000069],
              [95.95842320000008, 2.530660300000022],
              [95.94958530000008, 2.534003300000052],
              [95.94368530000008, 2.539640700000064],
              [95.93551760000008, 2.542446900000073]
            ]
          ],
          [
            [
              [96.37722960000008, 2.655198700000028],
              [96.36705690000008, 2.658507600000064],
              [96.36540850000006, 2.66386760000006],
              [96.35585780000008, 2.660448400000064],
              [96.35397030000007, 2.654125],
              [96.36088860000007, 2.653768100000036],
              [96.38224330000008, 2.643776900000034],
              [96.395593, 2.648744],
              [96.38702430000006, 2.655417100000022],
              [96.37722960000008, 2.655198700000028]
            ]
          ],
          [
            [
              [95.74517120000007, 2.712655],
              [95.73779930000006, 2.71390770000005],
              [95.71463350000005, 2.713177700000074],
              [95.72211970000006, 2.703506200000049],
              [95.73120640000008, 2.701760100000058],
              [95.73986840000003, 2.702901900000029],
              [95.74517120000007, 2.712655]
            ]
          ],
          [
            [
              [95.80514330000005, 2.929832],
              [95.79513570000006, 2.928498400000024],
              [95.78994030000007, 2.930021300000021],
              [95.78402040000003, 2.925577300000043],
              [95.78082140000004, 2.909631300000058],
              [95.77489960000008, 2.905886400000043],
              [95.76773960000008, 2.897524700000076],
              [95.769069, 2.88634490000004],
              [95.76370940000004, 2.88050480000004],
              [95.76748670000006, 2.872221100000047],
              [95.76765120000005, 2.863181500000053],
              [95.763357, 2.857531],
              [95.76582890000003, 2.851946200000043],
              [95.77887080000005, 2.845086400000071],
              [95.79061870000004, 2.836917800000037],
              [95.79068240000004, 2.830627100000072],
              [95.78168770000008, 2.832],
              [95.77817870000007, 2.827237100000048],
              [95.76127190000005, 2.824160900000038],
              [95.75375, 2.829312200000061],
              [95.74517310000004, 2.830546100000049],
              [95.739573, 2.827920800000072],
              [95.73298170000004, 2.831769700000052],
              [95.718541, 2.825391600000046],
              [95.71884440000008, 2.816352900000027],
              [95.71571470000004, 2.80870230000005],
              [95.71025730000008, 2.804866],
              [95.70615690000005, 2.796327400000052],
              [95.70718730000004, 2.79241630000007],
              [95.70262010000005, 2.785088],
              [95.695174, 2.779941600000029],
              [95.692136, 2.772617600000046],
              [95.69990020000006, 2.763320400000055],
              [95.69792770000004, 2.756092600000045],
              [95.70123680000006, 2.749112600000046],
              [95.71268510000004, 2.748072600000057],
              [95.720856, 2.759371500000043],
              [95.72618280000006, 2.760131800000067],
              [95.73426590000008, 2.752791900000034],
              [95.73145380000005, 2.749309800000049],
              [95.72181830000005, 2.746373800000072],
              [95.72373760000005, 2.739249900000061],
              [95.74195850000007, 2.73557210000007],
              [95.74947850000007, 2.730699900000047],
              [95.74922350000008, 2.722125300000073],
              [95.762327, 2.715219800000057],
              [95.76502120000004, 2.70889040000003],
              [95.75456840000004, 2.699869800000045],
              [95.76000670000008, 2.69286180000006],
              [95.78195770000008, 2.682027],
              [95.790628, 2.668737200000066],
              [95.79382980000008, 2.655383100000051],
              [95.78874660000008, 2.649945200000047],
              [95.78820340000004, 2.639915700000074],
              [95.79571520000007, 2.630412],
              [95.80352180000006, 2.629429500000072],
              [95.80421170000005, 2.640255800000034],
              [95.80921550000005, 2.638103800000067],
              [95.81263870000004, 2.644458100000065],
              [95.82111410000005, 2.64639070000004],
              [95.82758140000004, 2.642848900000047],
              [95.831672, 2.630019],
              [95.83696010000006, 2.623039900000038],
              [95.84052070000007, 2.624094100000036],
              [95.842003, 2.634848100000056],
              [95.85221370000005, 2.638432700000067],
              [95.86162720000004, 2.637491300000022],
              [95.87328320000006, 2.627687500000036],
              [95.88616260000003, 2.62237140000002],
              [95.88982380000004, 2.617965100000049],
              [95.88424050000003, 2.610541300000023],
              [95.884778, 2.604012600000033],
              [95.89641640000008, 2.598069600000031],
              [95.90204440000008, 2.601094800000055],
              [95.91752860000008, 2.59868350000005],
              [95.91826370000007, 2.592047600000058],
              [95.92657910000008, 2.582898400000033],
              [95.94366160000004, 2.572535],
              [95.954232, 2.570005800000047],
              [95.95847620000006, 2.576821700000039],
              [95.96957070000008, 2.58602650000006],
              [95.97987390000009, 2.58778680000006],
              [95.98823930000003, 2.585058500000059],
              [95.99360970000004, 2.57995180000006],
              [95.99229190000005, 2.570679700000028],
              [95.99632190000005, 2.565839200000028],
              [96.00500420000009, 2.588460200000043],
              [96.009874, 2.590714500000047],
              [96.01691420000009, 2.585552100000029],
              [96.04296670000008, 2.580754900000045],
              [96.05748460000007, 2.563687200000061],
              [96.06142640000007, 2.568428600000061],
              [96.07537560000009, 2.569583300000033],
              [96.08438760000007, 2.563861],
              [96.08667180000003, 2.554174300000057],
              [96.09501870000008, 2.551677600000062],
              [96.11910330000006, 2.539493900000025],
              [96.12671590000008, 2.526531100000057],
              [96.12840410000007, 2.520190200000059],
              [96.13928150000004, 2.513478700000064],
              [96.14686640000008, 2.517645],
              [96.16055220000004, 2.511921600000051],
              [96.16371870000006, 2.508550700000058],
              [96.16397720000003, 2.499550200000044],
              [96.16938730000004, 2.490921700000058],
              [96.17665540000007, 2.489544100000046],
              [96.18135980000005, 2.484612600000048],
              [96.18660370000003, 2.488472900000033],
              [96.19371110000009, 2.48303530000004],
              [96.20029310000007, 2.471461900000065],
              [96.20660360000005, 2.470513],
              [96.21728460000008, 2.464257600000053],
              [96.22667010000004, 2.448973600000045],
              [96.22888790000007, 2.441508500000054],
              [96.24219260000007, 2.441558],
              [96.25230160000007, 2.435203300000069],
              [96.27251140000004, 2.429141300000026],
              [96.28125050000006, 2.429349200000047],
              [96.30937190000009, 2.422244500000033],
              [96.31534780000004, 2.416831700000046],
              [96.31847430000005, 2.405988400000069],
              [96.31453720000007, 2.403488700000025],
              [96.31341570000006, 2.385544],
              [96.31965820000005, 2.383264900000029],
              [96.33306560000005, 2.39104640000005],
              [96.33549160000007, 2.387032500000032],
              [96.33396110000007, 2.371906800000033],
              [96.32149480000004, 2.362188600000025],
              [96.314053, 2.359486300000071],
              [96.31813810000006, 2.353677900000037],
              [96.32639480000006, 2.34960030000002],
              [96.33400210000008, 2.350497800000028],
              [96.34465070000005, 2.341242800000032],
              [96.35348760000005, 2.339031300000045],
              [96.36048, 2.340788900000064],
              [96.36517570000007, 2.33681450000006],
              [96.372747, 2.340061500000047],
              [96.37304160000008, 2.34638],
              [96.38734420000009, 2.348980500000039],
              [96.394471, 2.340171700000042],
              [96.40706160000008, 2.332076400000062],
              [96.436266, 2.327744900000027],
              [96.44426230000005, 2.329017900000053],
              [96.45439690000006, 2.333511200000032],
              [96.46412750000007, 2.342627100000072],
              [96.46998250000007, 2.340019300000051],
              [96.48190340000008, 2.345036300000061],
              [96.48566290000008, 2.353595300000052],
              [96.49870160000006, 2.363226],
              [96.49675460000003, 2.373975500000029],
              [96.49241360000008, 2.375906600000064],
              [96.48993470000005, 2.389960200000075],
              [96.47787380000005, 2.396724600000027],
              [96.486918, 2.410446900000068],
              [96.47566560000007, 2.43026640000005],
              [96.46863840000003, 2.426497400000073],
              [96.46596790000007, 2.436554300000068],
              [96.47127680000006, 2.449216300000046],
              [96.46732780000008, 2.460384100000056],
              [96.45548470000006, 2.467368200000067],
              [96.45375920000004, 2.476134400000035],
              [96.44626380000005, 2.477794500000073],
              [96.43493880000005, 2.488657200000034],
              [96.42765960000008, 2.491771700000072],
              [96.42523390000008, 2.477357],
              [96.41389770000006, 2.490511300000037],
              [96.40904440000008, 2.511168300000065],
              [96.40593290000004, 2.498685],
              [96.40156840000009, 2.491129300000068],
              [96.40720070000003, 2.46061670000006],
              [96.40245260000006, 2.455485900000042],
              [96.39269580000007, 2.462092900000073],
              [96.38871150000006, 2.468630500000074],
              [96.38192190000007, 2.471783100000039],
              [96.38455090000008, 2.477735400000029],
              [96.37693350000006, 2.483378500000072],
              [96.37088240000008, 2.493389500000035],
              [96.36661970000006, 2.49657620000005],
              [96.36360170000006, 2.504876],
              [96.36621230000009, 2.514089],
              [96.36195530000003, 2.520601900000031],
              [96.35322890000003, 2.517671300000075],
              [96.34899870000004, 2.522058300000026],
              [96.34269680000006, 2.522398400000043],
              [96.344253, 2.539683600000046],
              [96.34863770000004, 2.544203400000072],
              [96.33911670000003, 2.552189300000066],
              [96.33299490000007, 2.550217100000054],
              [96.32405010000008, 2.558857200000034],
              [96.31297090000004, 2.561751500000071],
              [96.30965470000007, 2.558587300000056],
              [96.29408130000007, 2.563147400000048],
              [96.28039970000003, 2.573844200000053],
              [96.27313460000005, 2.573042600000065],
              [96.258291, 2.58381490000005],
              [96.25293210000007, 2.585669800000062],
              [96.247218, 2.599121700000069],
              [96.239934, 2.607629200000076],
              [96.24112420000006, 2.615419400000064],
              [96.23371810000003, 2.622129100000052],
              [96.22075660000007, 2.621924200000024],
              [96.21920210000008, 2.633529600000031],
              [96.21212690000004, 2.636833700000068],
              [96.19537120000007, 2.649162],
              [96.18492680000008, 2.653082],
              [96.18619770000004, 2.65932360000005],
              [96.18015160000004, 2.667954800000075],
              [96.171314, 2.666753500000027],
              [96.18386770000006, 2.651141100000075],
              [96.18163170000008, 2.638747600000045],
              [96.18468430000007, 2.631783100000064],
              [96.19291030000005, 2.620235100000059],
              [96.18635130000007, 2.620621400000061],
              [96.18210860000005, 2.615643100000057],
              [96.16726130000006, 2.636731800000064],
              [96.16018770000005, 2.639367],
              [96.14804170000008, 2.639829500000076],
              [96.141645, 2.648481500000059],
              [96.12697920000005, 2.648113200000068],
              [96.12646250000006, 2.641606200000069],
              [96.11973960000006, 2.636310200000025],
              [96.10543970000003, 2.640755],
              [96.09992260000007, 2.650589700000069],
              [96.10574280000009, 2.653343800000073],
              [96.10000210000004, 2.679243100000065],
              [96.11360530000007, 2.692285200000072],
              [96.11844690000004, 2.687328300000047],
              [96.12951380000004, 2.682475100000033],
              [96.13462970000006, 2.683378400000038],
              [96.14014620000006, 2.689233300000069],
              [96.15165470000005, 2.688944],
              [96.14495840000006, 2.70064780000007],
              [96.14772050000005, 2.703818100000035],
              [96.14674770000005, 2.713013600000068],
              [96.13212530000004, 2.702644900000053],
              [96.12190320000008, 2.706118500000059],
              [96.11586320000004, 2.711896900000056],
              [96.11189570000005, 2.721868700000073],
              [96.11291630000005, 2.730047500000069],
              [96.12591230000004, 2.723754100000065],
              [96.11874430000006, 2.737366200000054],
              [96.10546820000008, 2.741325600000039],
              [96.09032290000005, 2.750061200000061],
              [96.09071390000008, 2.739456100000041],
              [96.08879770000004, 2.734938500000055],
              [96.08190130000008, 2.735956],
              [96.08148090000003, 2.744532],
              [96.08678340000006, 2.749702300000024],
              [96.08352120000006, 2.758597200000054],
              [96.07865850000007, 2.756767300000035],
              [96.07034010000007, 2.759424600000045],
              [96.05560920000005, 2.761417900000026],
              [96.038832, 2.760350600000038],
              [96.02674120000006, 2.757477],
              [96.00845730000003, 2.76596040000004],
              [96.00566830000008, 2.769122700000025],
              [96.00522670000004, 2.778816200000051],
              [95.99732870000008, 2.786020400000041],
              [95.99116580000003, 2.785258800000065],
              [95.98077270000005, 2.78933360000002],
              [95.97476410000007, 2.800736900000061],
              [95.972323, 2.812708600000065],
              [95.96013470000008, 2.811884500000076],
              [95.95506160000008, 2.81988750000005],
              [95.94398220000005, 2.820417600000042],
              [95.92601760000008, 2.830623200000048],
              [95.91480830000006, 2.845273700000064],
              [95.91226040000004, 2.844754200000068],
              [95.90914640000005, 2.830531400000041],
              [95.89910680000008, 2.823653700000023],
              [95.893715, 2.829371500000036],
              [95.88601330000006, 2.832286700000054],
              [95.88537890000003, 2.844168900000057],
              [95.87383150000005, 2.846560900000043],
              [95.86707640000009, 2.859311600000069],
              [95.87841550000007, 2.865278400000022],
              [95.88150060000004, 2.872912700000029],
              [95.89130350000005, 2.864457600000037],
              [95.89470250000005, 2.858734600000048],
              [95.90351540000006, 2.856381800000065],
              [95.90659320000003, 2.849399400000038],
              [95.91155170000008, 2.849785600000075],
              [95.91323830000005, 2.860416100000066],
              [95.91219950000004, 2.867543800000021],
              [95.91627360000007, 2.86932580000007],
              [95.910408, 2.878724],
              [95.90336110000004, 2.89636790000003],
              [95.89134070000006, 2.901927200000046],
              [95.88160310000006, 2.90329840000004],
              [95.87457950000004, 2.91218],
              [95.86910390000008, 2.914401700000042],
              [95.86165360000007, 2.909953600000051],
              [95.85399430000007, 2.914079700000059],
              [95.832262, 2.91192140000004],
              [95.83141970000008, 2.898171700000034],
              [95.82221940000005, 2.906440700000076],
              [95.81654340000006, 2.914067100000068],
              [95.80412730000006, 2.912167700000055],
              [95.80514330000005, 2.929832]
            ]
          ],
          [
            [
              [95.40181650000005, 2.966938800000037],
              [95.40643130000007, 2.975887900000032],
              [95.40211540000007, 2.977652300000045],
              [95.39674850000006, 2.987523700000054],
              [95.38908370000007, 2.986640200000068],
              [95.39494730000007, 2.978037500000028],
              [95.39731440000008, 2.96759510000004],
              [95.40181650000005, 2.966938800000037]
            ]
          ],
          [
            [
              [98.04713530000004, 4.545522],
              [98.04007150000007, 4.54045050000002],
              [98.04170350000004, 4.536544200000037],
              [98.04960620000008, 4.538178600000037],
              [98.04713530000004, 4.545522]
            ]
          ],
          [
            [
              [97.96387460000005, 4.703420700000038],
              [97.96071450000005, 4.702524],
              [97.96679270000004, 4.689927300000022],
              [97.97165230000007, 4.67489340000003],
              [97.97527960000008, 4.674593500000071],
              [97.96387460000005, 4.703420700000038]
            ]
          ],
          [
            [
              [95.377307, 4.877136900000039],
              [95.37223160000008, 4.878294700000026],
              [95.36627010000007, 4.872380700000065],
              [95.37441750000005, 4.861526400000059],
              [95.38382490000004, 4.858641400000067],
              [95.39021010000005, 4.868233700000076],
              [95.39063990000005, 4.873343400000067],
              [95.38513030000007, 4.878058900000042],
              [95.377307, 4.877136900000039]
            ]
          ],
          [
            [
              [95.17371190000006, 5.555127500000026],
              [95.17530530000005, 5.560722],
              [95.16786780000007, 5.562013400000069],
              [95.156634, 5.553318300000058],
              [95.16770380000008, 5.550354800000036],
              [95.17371190000006, 5.555127500000026]
            ]
          ],
          [
            [
              [95.07873150000006, 5.644355200000064],
              [95.06523340000007, 5.63755],
              [95.05972830000007, 5.637545500000044],
              [95.05251250000003, 5.630198900000039],
              [95.05129710000006, 5.622849900000062],
              [95.06047190000004, 5.622232200000042],
              [95.07655560000006, 5.634238400000072],
              [95.085739, 5.638521200000071],
              [95.07873150000006, 5.644355200000064]
            ]
          ],
          [
            [
              [95.43560110000004, 5.656503200000031],
              [95.429572, 5.655777600000022],
              [95.417103, 5.65004330000005],
              [95.41069540000007, 5.644958400000064],
              [95.38964770000007, 5.622111500000074],
              [95.38864450000005, 5.618066300000066],
              [95.37782520000007, 5.610527],
              [95.36059360000007, 5.606363200000033],
              [95.34417110000004, 5.60382050000004],
              [95.32984650000009, 5.593993],
              [95.32144910000005, 5.586731100000065],
              [95.310533, 5.579963900000052],
              [95.31141480000008, 5.568995100000052],
              [95.30987570000008, 5.561943500000041],
              [95.29159910000004, 5.563635800000043],
              [95.28460790000008, 5.559469200000024],
              [95.27762820000004, 5.550513500000022],
              [95.2563, 5.547916500000042],
              [95.25367390000008, 5.54543],
              [95.24351580000007, 5.545960300000047],
              [95.23609090000008, 5.550432100000023],
              [95.23477530000008, 5.562652800000023],
              [95.23854620000009, 5.571226],
              [95.22720820000006, 5.575676200000032],
              [95.22635510000003, 5.570567100000062],
              [95.21697130000007, 5.566319400000054],
              [95.216445, 5.557825300000047],
              [95.20960210000004, 5.544014900000036],
              [95.19383630000004, 5.527317100000062],
              [95.20926410000004, 5.508395],
              [95.20961, 5.503723],
              [95.21794330000006, 5.498172100000033],
              [95.22648070000008, 5.498746900000071],
              [95.22996010000008, 5.490241800000035],
              [95.22673340000006, 5.485340500000063],
              [95.23295420000005, 5.481556600000033],
              [95.235961, 5.473180200000058],
              [95.24017190000006, 5.46909480000005],
              [95.24358610000007, 5.459498200000041],
              [95.24256620000006, 5.445611600000063],
              [95.23805290000007, 5.437328900000068],
              [95.23227560000004, 5.433195100000034],
              [95.23907280000003, 5.428690500000073],
              [95.24762640000006, 5.414139700000021],
              [95.25080740000004, 5.402061100000026],
              [95.25237660000005, 5.384090700000058],
              [95.252031, 5.374069400000053],
              [95.249561, 5.366891600000031],
              [95.24461360000004, 5.365642800000046],
              [95.24046350000003, 5.349485900000047],
              [95.241717, 5.340087900000071],
              [95.23410480000007, 5.344844700000067],
              [95.23435360000008, 5.354795400000057],
              [95.22822380000008, 5.351469300000076],
              [95.22577690000008, 5.341365900000028],
              [95.23264430000006, 5.331484400000022],
              [95.23907210000004, 5.326108600000055],
              [95.23623280000004, 5.318871800000068],
              [95.23040470000007, 5.314388800000074],
              [95.21734790000005, 5.310848300000032],
              [95.21529520000007, 5.30662030000002],
              [95.21995970000006, 5.290723100000037],
              [95.21480740000004, 5.275777400000038],
              [95.21967710000007, 5.270868],
              [95.22307010000009, 5.277757700000052],
              [95.23133060000004, 5.284916700000053],
              [95.23925460000004, 5.282311800000059],
              [95.24668720000005, 5.272438300000033],
              [95.24783990000003, 5.265923900000075],
              [95.23964020000005, 5.261748700000055],
              [95.23982590000008, 5.256374500000049],
              [95.24966290000003, 5.247500800000068],
              [95.25264120000008, 5.240237400000069],
              [95.25408230000005, 5.221264100000042],
              [95.26079520000008, 5.213588],
              [95.27088170000007, 5.197244300000023],
              [95.273321, 5.199614200000042],
              [95.28335710000005, 5.196906500000068],
              [95.29115260000003, 5.190066600000023],
              [95.30762110000006, 5.16839710000005],
              [95.30867590000008, 5.162148800000068],
              [95.30719470000008, 5.147169100000042],
              [95.30410690000008, 5.13648790000002],
              [95.30974690000005, 5.131448300000045],
              [95.30575020000003, 5.123799200000065],
              [95.29576980000007, 5.118874800000071],
              [95.28656230000007, 5.119285200000036],
              [95.28140690000004, 5.11501990000005],
              [95.29140410000008, 5.109538600000064],
              [95.29826550000007, 5.115768400000036],
              [95.31171090000004, 5.102829300000053],
              [95.31575180000004, 5.093591700000047],
              [95.32011970000008, 5.092905],
              [95.31955830000004, 5.08459890000006],
              [95.31601160000008, 5.080345300000033],
              [95.33028230000008, 5.059172700000033],
              [95.33240760000007, 5.050631200000055],
              [95.33965580000006, 5.051475800000048],
              [95.34963710000005, 5.047149700000034],
              [95.35494730000005, 5.040104],
              [95.36239880000005, 5.038821900000073],
              [95.36659510000004, 5.032236200000057],
              [95.36856610000007, 5.022163700000021],
              [95.36576880000007, 5.012474800000064],
              [95.35543350000006, 5.006564],
              [95.35800310000008, 4.998429700000031],
              [95.36456360000005, 5.000226500000053],
              [95.36903770000004, 4.995688],
              [95.37120020000003, 4.985983900000065],
              [95.37492160000005, 4.981545600000061],
              [95.37407670000005, 4.975821],
              [95.38020150000006, 4.971921200000054],
              [95.38077030000005, 4.961694600000044],
              [95.37397350000003, 4.952508200000068],
              [95.36883980000005, 4.949043800000027],
              [95.37145750000008, 4.938813900000071],
              [95.37593590000006, 4.932669200000021],
              [95.37915940000005, 4.920189200000038],
              [95.38653120000004, 4.920376100000055],
              [95.38978420000007, 4.914847900000041],
              [95.39402680000006, 4.900934800000073],
              [95.40239410000004, 4.89416760000006],
              [95.39476770000005, 4.879561300000034],
              [95.39694450000007, 4.871686300000022],
              [95.40454940000006, 4.865934],
              [95.41105390000007, 4.857756],
              [95.416779, 4.855697300000031],
              [95.42672910000005, 4.834207200000037],
              [95.42485910000005, 4.82705720000007],
              [95.41316320000004, 4.820545800000048],
              [95.423595, 4.817657200000042],
              [95.42745460000003, 4.809470600000054],
              [95.43686570000006, 4.807402100000047],
              [95.44485490000005, 4.808403300000066],
              [95.45178310000006, 4.79939070000006],
              [95.45399990000004, 4.789777300000026],
              [95.45274580000006, 4.782830500000046],
              [95.46071940000007, 4.779538700000046],
              [95.47297130000004, 4.770307200000047],
              [95.48725480000007, 4.756572600000027],
              [95.49771180000005, 4.743473100000074],
              [95.49945420000006, 4.737071200000059],
              [95.506098, 4.730061300000045],
              [95.51321750000005, 4.719015500000069],
              [95.51032820000006, 4.706431700000053],
              [95.52274920000008, 4.697039500000074],
              [95.53289970000009, 4.683621500000072],
              [95.53324350000008, 4.674065700000028],
              [95.54061150000007, 4.67385280000002],
              [95.54038350000008, 4.664998500000024],
              [95.543599, 4.659280200000069],
              [95.55489460000007, 4.658058600000061],
              [95.561973, 4.669445900000028],
              [95.56804760000006, 4.668046200000049],
              [95.57224330000008, 4.662324300000023],
              [95.58008780000006, 4.662295500000027],
              [95.58439040000007, 4.659032900000057],
              [95.58419340000006, 4.648034300000063],
              [95.57248280000005, 4.644256],
              [95.57386050000008, 4.636870900000076],
              [95.56552490000007, 4.635392],
              [95.58128830000004, 4.62732440000002],
              [95.58241470000007, 4.634866100000067],
              [95.58750840000005, 4.635718100000076],
              [95.60708030000006, 4.625256300000046],
              [95.62216580000006, 4.612946500000021],
              [95.64221980000008, 4.592281700000058],
              [95.67411010000006, 4.568916800000068],
              [95.68246410000006, 4.557836200000054],
              [95.69251190000006, 4.550745400000039],
              [95.707095, 4.537577100000021],
              [95.71457730000009, 4.528720400000054],
              [95.72917180000007, 4.518398800000057],
              [95.75228170000008, 4.496682700000065],
              [95.76285640000003, 4.492417800000055],
              [95.78004380000004, 4.472604400000023],
              [95.78594050000004, 4.467116300000043],
              [95.87684060000004, 4.367429600000037],
              [95.90289010000004, 4.340654],
              [95.91696170000006, 4.323932300000024],
              [95.94041970000006, 4.298171700000069],
              [95.95841440000004, 4.275685600000031],
              [95.97681890000007, 4.255127400000049],
              [95.98622470000004, 4.247529500000041],
              [96.00264650000008, 4.228451300000074],
              [96.01309760000004, 4.218056400000023],
              [96.02219380000008, 4.205295600000056],
              [96.02222160000008, 4.197949700000038],
              [96.02740770000008, 4.194993100000033],
              [96.03308650000008, 4.199083500000029],
              [96.03727150000003, 4.206746500000065],
              [96.04640130000007, 4.208821900000032],
              [96.05536640000008, 4.20521640000004],
              [96.069143, 4.195390900000064],
              [96.08641250000005, 4.178305500000022],
              [96.10029090000006, 4.161334900000043],
              [96.12277750000004, 4.138371],
              [96.12686780000007, 4.13051020000006],
              [96.13444730000003, 4.140669800000069],
              [96.14241450000009, 4.142254300000047],
              [96.15230090000006, 4.139532300000042],
              [96.19193240000004, 4.107215900000028],
              [96.18943280000008, 4.105447500000025],
              [96.21733690000008, 4.077656800000057],
              [96.23538280000008, 4.057229200000052],
              [96.24056360000009, 4.055536800000027],
              [96.24699640000006, 4.041288200000054],
              [96.26556480000005, 4.021069700000055],
              [96.28396740000005, 3.998806400000035],
              [96.29590720000004, 3.982733500000052],
              [96.35261780000008, 3.897730200000069],
              [96.35858510000008, 3.887960100000043],
              [96.38523320000007, 3.849256100000048],
              [96.38794450000006, 3.848515400000053],
              [96.42169860000007, 3.814663100000075],
              [96.44949570000006, 3.78826730000003],
              [96.47815190000006, 3.764177500000073],
              [96.49666670000005, 3.74949730000003],
              [96.51590170000009, 3.736973900000066],
              [96.53653540000005, 3.729821900000047],
              [96.55729340000005, 3.729930600000046],
              [96.57940690000004, 3.733149800000035],
              [96.59632540000007, 3.733080400000063],
              [96.61817550000006, 3.734628800000053],
              [96.61962490000008, 3.73712880000005],
              [96.63613620000007, 3.744134700000075],
              [96.66891780000003, 3.747311300000035],
              [96.69779460000007, 3.743099],
              [96.71803930000004, 3.746958],
              [96.72444440000004, 3.747109300000034],
              [96.73749240000006, 3.743547200000023],
              [96.744007, 3.744050800000025],
              [96.75180420000004, 3.739887600000031],
              [96.77076930000004, 3.739437900000041],
              [96.77786370000007, 3.737461300000064],
              [96.79345630000006, 3.724535700000047],
              [96.80496570000008, 3.725829600000054],
              [96.81038820000003, 3.722690600000021],
              [96.81188430000003, 3.717199400000027],
              [96.81912770000008, 3.716441900000063],
              [96.83451280000008, 3.707022900000027],
              [96.84393370000004, 3.704966300000024],
              [96.85662490000004, 3.699567400000035],
              [96.86564880000003, 3.693104],
              [96.87931270000007, 3.67745130000003],
              [96.89031290000008, 3.654954100000054],
              [96.89597050000003, 3.638244500000042],
              [96.89670080000008, 3.62380840000003],
              [96.89509550000008, 3.620335600000033],
              [96.90488710000005, 3.60096],
              [96.93431250000003, 3.578224300000045],
              [96.94049160000009, 3.574740200000065],
              [96.94027260000007, 3.569574100000068],
              [96.970635, 3.558034],
              [96.98093910000006, 3.556493200000034],
              [96.99307990000005, 3.551072400000066],
              [96.99974480000009, 3.545269400000052],
              [97.00461420000005, 3.53089140000003],
              [97.01699550000006, 3.520530500000064],
              [97.01794630000006, 3.508050400000059],
              [97.02880760000005, 3.49638740000006],
              [97.04567760000003, 3.474697],
              [97.05199880000004, 3.461551400000076],
              [97.05762440000007, 3.456054100000074],
              [97.05796450000008, 3.445651],
              [97.06266940000006, 3.443423400000029],
              [97.06046350000008, 3.435908],
              [97.06202680000007, 3.430157700000052],
              [97.07082490000005, 3.427009],
              [97.06949930000007, 3.418887300000051],
              [97.07533660000007, 3.413331800000037],
              [97.08461780000005, 3.400478200000066],
              [97.084328, 3.395037900000034],
              [97.09285730000005, 3.387024100000076],
              [97.105988, 3.367881700000055],
              [97.10946090000004, 3.360044400000049],
              [97.11857170000007, 3.356231300000047],
              [97.12252640000008, 3.348245],
              [97.121384, 3.332281500000022],
              [97.12668030000003, 3.326142700000048],
              [97.12842950000004, 3.317734],
              [97.14020350000004, 3.312743100000034],
              [97.14559340000005, 3.305104500000027],
              [97.15030430000007, 3.29152380000005],
              [97.15773250000007, 3.283746300000075],
              [97.15821630000005, 3.27298380000002],
              [97.16510320000003, 3.270055700000057],
              [97.16698220000006, 3.261160100000041],
              [97.17686970000005, 3.251616800000022],
              [97.18152080000004, 3.255958200000066],
              [97.19438310000004, 3.259019800000033],
              [97.19553440000004, 3.252987300000029],
              [97.20170020000006, 3.249064400000066],
              [97.21244280000008, 3.249718800000039],
              [97.22173080000005, 3.243395100000043],
              [97.22048580000006, 3.237931100000026],
              [97.22356190000005, 3.232162800000026],
              [97.23209170000007, 3.232478800000024],
              [97.23626110000004, 3.235978],
              [97.24461940000003, 3.236005],
              [97.257573, 3.22063490000005],
              [97.26399270000007, 3.210454],
              [97.27575440000004, 3.185999300000049],
              [97.28144920000005, 3.168829900000048],
              [97.28352030000008, 3.157985800000063],
              [97.28764670000004, 3.148079100000075],
              [97.29021540000008, 3.134638400000028],
              [97.29751740000006, 3.114337500000033],
              [97.30074580000007, 3.116328900000042],
              [97.30908210000007, 3.109772700000065],
              [97.30235720000007, 3.102855300000044],
              [97.30860350000006, 3.082407300000057],
              [97.31426730000004, 3.082643200000064],
              [97.31738270000005, 3.074392400000022],
              [97.311052, 3.073768100000052],
              [97.31955850000008, 3.058901400000025],
              [97.31899450000009, 3.054813500000023],
              [97.33690820000004, 3.032355300000063],
              [97.36967660000005, 2.988607600000023],
              [97.39295780000003, 2.963075400000037],
              [97.42512790000006, 2.934293400000058],
              [97.44731960000007, 2.923158800000067],
              [97.45689590000006, 2.923384],
              [97.48665710000006, 2.920244900000057],
              [97.49885750000004, 2.912716600000067],
              [97.50536250000005, 2.902541],
              [97.513092, 2.902741800000058],
              [97.52209570000008, 2.889461900000072],
              [97.53445860000005, 2.882996900000023],
              [97.54287060000007, 2.88241910000005],
              [97.549195, 2.888066300000048],
              [97.55581940000008, 2.888943300000051],
              [97.56302440000007, 2.886202100000048],
              [97.57584050000008, 2.888244900000075],
              [97.58218070000004, 2.886371],
              [97.59248360000004, 2.877218200000073],
              [97.59883320000006, 2.867816600000026],
              [97.61122050000006, 2.841336900000044],
              [97.61498980000005, 2.829282200000023],
              [97.617774, 2.811939],
              [97.62414510000008, 2.787026700000069],
              [97.634054, 2.752202900000043],
              [97.647913, 2.678298500000039],
              [97.65006140000008, 2.662090100000057],
              [97.651139, 2.638735300000064],
              [97.65449180000007, 2.589355],
              [97.65513760000005, 2.545883300000071],
              [97.65673190000007, 2.519981700000073],
              [97.65532050000007, 2.472841200000062],
              [97.65729910000005, 2.451151700000025],
              [97.65915590000009, 2.417555400000026],
              [97.66110530000003, 2.401928300000066],
              [97.66391370000008, 2.394431500000053],
              [97.69389190000004, 2.36555050000004],
              [97.70591950000005, 2.351563800000065],
              [97.71410670000006, 2.340092600000048],
              [97.72037190000003, 2.328542200000072],
              [97.73654, 2.311449200000027],
              [97.73886880000003, 2.300177500000075],
              [97.74975580000006, 2.279729],
              [97.75657540000003, 2.270161800000039],
              [97.771274, 2.254624100000058],
              [97.77540660000005, 2.236803100000031],
              [97.782797, 2.230710500000043],
              [97.79491550000006, 2.239092600000049],
              [97.79627080000006, 2.24807080000005],
              [97.79466240000005, 2.25463910000002],
              [97.80073240000007, 2.26681480000002],
              [97.81480520000008, 2.267747],
              [97.83082620000005, 2.26402070000006],
              [97.84236080000005, 2.256818],
              [97.86087870000006, 2.248802],
              [97.86730580000005, 2.248152300000072],
              [97.86810360000004, 2.255802900000049],
              [97.86095950000004, 2.25980880000003],
              [97.86212230000007, 2.26432120000004],
              [97.86955730000005, 2.270548700000063],
              [97.88550690000005, 2.276052400000026],
              [97.89283440000008, 2.272282],
              [97.88842, 2.255834200000038],
              [97.868315, 2.243727300000046],
              [97.88256460000008, 2.241646400000036],
              [97.89708380000008, 2.25061850000003],
              [97.90127760000007, 2.26014280000004],
              [97.91356930000006, 2.270335100000068],
              [97.92393, 2.271721700000057],
              [97.93832080000004, 2.267967900000031],
              [97.94893890000009, 2.269891500000028],
              [97.97437380000008, 2.259832600000038],
              [97.98404880000004, 2.254738600000053],
              [98.01287760000008, 2.236519600000065],
              [98.03126090000006, 2.228644100000054],
              [98.06092880000006, 2.212091500000042],
              [98.08458250000007, 2.200759700000049],
              [98.098362, 2.190969400000029],
              [98.11159450000008, 2.183144],
              [98.12651980000004, 2.167957900000033],
              [98.13049890000008, 2.165970100000038],
              [98.17880190000005, 2.239912],
              [98.17437090000004, 2.250599],
              [98.18205240000003, 2.261253],
              [98.18800620000007, 2.295759],
              [98.19681790000004, 2.310719],
              [98.18484590000008, 2.329155300000025],
              [98.16295550000007, 2.382526],
              [98.14739810000003, 2.423653],
              [98.13677750000005, 2.430929900000024],
              [98.09440520000004, 2.485103],
              [98.08567360000006, 2.50352330000004],
              [98.08111380000008, 2.522604],
              [98.08394080000005, 2.530452],
              [98.08483770000004, 2.546768500000042],
              [98.08312620000004, 2.560344800000053],
              [98.078462, 2.575173900000038],
              [98.07868420000005, 2.581563500000073],
              [98.083449, 2.59213630000005],
              [98.08497670000008, 2.60275],
              [98.10043620000005, 2.601534100000038],
              [98.10960910000006, 2.604057],
              [98.11260430000004, 2.61337930000002],
              [98.10862470000006, 2.623759300000074],
              [98.10317210000005, 2.654482],
              [98.10445190000007, 2.661238],
              [98.10388680000005, 2.681603],
              [98.10063790000004, 2.689232],
              [98.085556, 2.706296],
              [98.07624850000008, 2.723808],
              [98.07131140000007, 2.736206],
              [98.07612610000007, 2.749845900000025],
              [98.06955850000008, 2.763973400000054],
              [98.08049630000005, 2.769545],
              [98.09317410000006, 2.773875],
              [98.10847770000004, 2.776121],
              [98.116918, 2.784982],
              [98.10505560000007, 2.790332],
              [98.09797360000005, 2.818634],
              [98.09856060000004, 2.822817],
              [98.06265870000004, 2.81694],
              [98.049008, 2.823002],
              [98.04189430000008, 2.837025],
              [98.03955270000006, 2.847295],
              [98.03192560000008, 2.84919],
              [98.02775980000007, 2.85589],
              [98.00256150000007, 2.874749],
              [97.99742370000007, 2.87428],
              [97.99240170000007, 2.882203],
              [97.970522, 2.889885],
              [97.94869290000008, 2.895330500000057],
              [97.96376160000005, 2.961892500000033],
              [97.96455660000004, 2.973231200000043],
              [97.95939360000006, 2.981324900000061],
              [97.94959130000007, 2.987669700000026],
              [97.93513880000006, 2.989519],
              [97.94694180000005, 3.005719300000067],
              [97.94609820000005, 3.01044840000003],
              [97.93874250000005, 3.025838700000065],
              [97.93912370000004, 3.037001],
              [97.94434980000005, 3.04423],
              [97.94236280000007, 3.047970700000064],
              [97.93030280000005, 3.054254],
              [97.93202110000004, 3.069423],
              [97.93840190000009, 3.074863],
              [97.94963380000007, 3.07306920000002],
              [97.94949840000004, 3.065688400000056],
              [97.96108040000007, 3.065873100000033],
              [97.97452580000004, 3.072806400000047],
              [97.97683270000005, 3.078997800000025],
              [97.98248460000008, 3.081742900000052],
              [97.97936410000005, 3.097179700000027],
              [97.981749, 3.110903800000074],
              [97.98091110000007, 3.118860600000062],
              [97.96931450000005, 3.142752],
              [97.96380920000007, 3.144934100000057],
              [97.93849640000008, 3.163513600000044],
              [97.94296460000004, 3.175129500000025],
              [97.93975240000003, 3.193633800000043],
              [97.943537, 3.20126920000007],
              [97.93783330000008, 3.204136],
              [97.930577, 3.212915],
              [97.92689530000007, 3.223063],
              [97.91707530000008, 3.23071],
              [97.89854330000009, 3.234039600000074],
              [97.88891430000007, 3.240386],
              [97.88181860000003, 3.238108900000043],
              [97.870368, 3.24961890000003],
              [97.87531760000007, 3.255367300000046],
              [97.89669060000006, 3.257816500000047],
              [97.90156660000008, 3.26229630000006],
              [97.91295410000004, 3.26425050000006],
              [97.93658450000004, 3.265390700000069],
              [97.94109750000007, 3.271513600000048],
              [97.94563340000008, 3.271168100000068],
              [97.95451970000005, 3.283507],
              [97.966112, 3.287339700000075],
              [97.969748, 3.277656900000068],
              [97.98133350000006, 3.281233],
              [97.99562750000007, 3.283156400000053],
              [98.002701, 3.29131760000007],
              [98.00984510000006, 3.293457400000023],
              [98.01697730000006, 3.302540200000067],
              [98.02075090000005, 3.302636],
              [98.02018280000004, 3.315992400000027],
              [98.02880640000006, 3.331094900000039],
              [98.023306, 3.34185],
              [98.0143, 3.349385],
              [98.010663, 3.35924],
              [98.001949, 3.369756],
              [97.9787, 3.371677],
              [97.970668, 3.378021],
              [97.948226, 3.390666],
              [97.94698940000006, 3.393553800000063],
              [97.93053960000003, 3.389718900000048],
              [97.90990270000003, 3.389560200000062],
              [97.90584910000007, 3.399221900000043],
              [97.89828670000009, 3.408178700000065],
              [97.89764890000004, 3.415894500000036],
              [97.91111880000005, 3.417055800000071],
              [97.921066, 3.422083300000054],
              [97.92526410000005, 3.43041340000002],
              [97.93366890000004, 3.441085],
              [97.93631030000006, 3.450358],
              [97.94144730000005, 3.455834],
              [97.94976650000007, 3.472817],
              [97.95860280000005, 3.48061],
              [97.94604560000005, 3.489412],
              [97.92865160000008, 3.493215],
              [97.92049530000008, 3.500003700000036],
              [97.92345440000008, 3.513332],
              [97.912527, 3.533914],
              [97.91234890000004, 3.543658],
              [97.90441110000006, 3.55694],
              [97.89783320000004, 3.560312],
              [97.892415, 3.559086],
              [97.87559480000004, 3.56516270000003],
              [97.86825860000005, 3.571032],
              [97.86704190000006, 3.584839],
              [97.86364330000004, 3.594627],
              [97.86426210000008, 3.60079],
              [97.87054320000004, 3.606984],
              [97.87194330000005, 3.612075],
              [97.85355140000007, 3.626923],
              [97.85328290000007, 3.641871],
              [97.84659780000004, 3.653196],
              [97.84440250000006, 3.661197],
              [97.83839630000006, 3.668519],
              [97.82931330000008, 3.672177],
              [97.822521, 3.681288],
              [97.81912590000007, 3.690598],
              [97.80463880000008, 3.710372],
              [97.80027320000005, 3.722666],
              [97.81521050000003, 3.736741],
              [97.81773820000006, 3.742496],
              [97.83696990000004, 3.763022],
              [97.83988890000006, 3.767805],
              [97.84894570000006, 3.774263],
              [97.85615830000006, 3.775282],
              [97.86098240000007, 3.784309],
              [97.87284290000008, 3.789393],
              [97.88243760000006, 3.79627],
              [97.88517790000009, 3.800694],
              [97.89632170000004, 3.80793],
              [97.90209820000007, 3.818153],
              [97.90925120000009, 3.818812],
              [97.909665, 3.824431],
              [97.91562180000005, 3.832502],
              [97.91555980000004, 3.836387],
              [97.92151660000007, 3.843502],
              [97.91465610000006, 3.853242],
              [97.91518830000007, 3.860594],
              [97.90886590000008, 3.868541],
              [97.90939680000008, 3.878642],
              [97.91670680000004, 3.886280500000055],
              [97.93177140000006, 3.884121400000026],
              [97.93646450000006, 3.888968300000045],
              [97.93585470000005, 3.894897600000036],
              [97.94525660000005, 3.895712600000024],
              [97.94947850000005, 3.889749900000027],
              [97.95728740000004, 3.902026600000056],
              [97.95482660000005, 3.912676200000021],
              [97.95758050000006, 3.928904400000022],
              [97.97029830000008, 3.937114600000029],
              [97.98008420000008, 3.941223900000068],
              [97.99000880000006, 3.941800100000023],
              [97.99525550000004, 3.945093800000052],
              [98.00282690000006, 3.959033500000032],
              [98.00388180000004, 3.963869500000044],
              [98.01595790000005, 3.963720100000046],
              [98.02264480000008, 3.96692390000004],
              [98.02442860000008, 3.97659550000003],
              [98.03135330000003, 3.985945400000048],
              [98.02930490000006, 3.993154200000049],
              [98.03097020000007, 4.001997],
              [98.02581550000008, 4.01379090000006],
              [98.03233340000008, 4.022321],
              [98.04033170000008, 4.021101300000055],
              [98.04620360000007, 4.023976300000072],
              [98.03707940000004, 4.045094100000028],
              [98.03839990000006, 4.049573700000053],
              [98.033811, 4.057192900000075],
              [98.03970350000009, 4.068334400000026],
              [98.04481230000005, 4.07356],
              [98.04552260000008, 4.097022400000071],
              [98.04739130000007, 4.120712600000047],
              [98.05649580000005, 4.135563700000034],
              [98.06426890000006, 4.137738],
              [98.07189610000006, 4.142682600000057],
              [98.06862630000006, 4.159246800000062],
              [98.06972910000007, 4.171498100000065],
              [98.06341210000005, 4.179611600000044],
              [98.061377, 4.188903900000071],
              [98.07515890000008, 4.189914900000076],
              [98.07811420000007, 4.193808700000034],
              [98.08548080000008, 4.193289500000049],
              [98.09280170000005, 4.207067],
              [98.09004020000003, 4.212902400000075],
              [98.08917910000008, 4.226485900000057],
              [98.07736270000004, 4.228529500000036],
              [98.07398420000004, 4.231067],
              [98.06772090000004, 4.245897500000069],
              [98.07692640000005, 4.253173600000025],
              [98.079255, 4.25933340000006],
              [98.08875490000008, 4.258567500000026],
              [98.10856940000008, 4.251760500000046],
              [98.12229730000007, 4.256115900000054],
              [98.14194440000006, 4.259285],
              [98.15543510000003, 4.270071600000051],
              [98.15687470000006, 4.276608800000076],
              [98.15409010000008, 4.287256200000058],
              [98.16216940000004, 4.285813],
              [98.16530110000008, 4.288479100000075],
              [98.17874550000005, 4.29024830000003],
              [98.18841710000004, 4.293907200000035],
              [98.19325690000005, 4.299408],
              [98.19904590000004, 4.300860500000056],
              [98.21111020000006, 4.293248100000028],
              [98.21437180000004, 4.287078800000074],
              [98.22208710000007, 4.287812900000063],
              [98.22883430000007, 4.292298],
              [98.23065050000008, 4.28455370000006],
              [98.23836770000008, 4.283351100000061],
              [98.239326, 4.289645700000051],
              [98.244988, 4.294371700000056],
              [98.252208, 4.289165900000057],
              [98.25585480000007, 4.291915800000027],
              [98.27158240000006, 4.32349720000002],
              [98.27976540000009, 4.329975700000034],
              [98.27812430000006, 4.347249600000055],
              [98.28234230000004, 4.34858],
              [98.28150390000008, 4.364604700000029],
              [98.27748750000006, 4.380409300000053],
              [98.27986970000006, 4.387069200000042],
              [98.27350030000008, 4.390950600000053],
              [98.27437390000006, 4.396807500000023],
              [98.27985270000005, 4.397704400000066],
              [98.28172360000008, 4.407357400000024],
              [98.28698960000008, 4.41549],
              [98.27589630000006, 4.424546700000064],
              [98.26120260000005, 4.434149700000034],
              [98.25484760000006, 4.442935900000066],
              [98.24868530000003, 4.444444],
              [98.24292380000009, 4.438849100000027],
              [98.23297270000006, 4.446278300000074],
              [98.23641290000006, 4.45798080000003],
              [98.23147480000006, 4.464287100000035],
              [98.21433650000006, 4.475171800000055],
              [98.19877680000008, 4.481201],
              [98.18841960000003, 4.479524100000049],
              [98.18645020000008, 4.484507400000041],
              [98.17842030000008, 4.489141700000062],
              [98.177872, 4.494699300000036],
              [98.16935860000007, 4.502745100000027],
              [98.154156, 4.510371500000076],
              [98.14481260000008, 4.511943300000041],
              [98.12051180000003, 4.523022300000036],
              [98.11049490000005, 4.521267200000068],
              [98.10347480000007, 4.523446100000058],
              [98.087094, 4.522395300000028],
              [98.08964570000006, 4.511211],
              [98.08268460000005, 4.506788400000062],
              [98.07958720000005, 4.510014500000068],
              [98.08014760000003, 4.527816200000075],
              [98.08568970000005, 4.533311],
              [98.08123130000007, 4.540437700000041],
              [98.07164860000006, 4.547528500000055],
              [98.06338390000008, 4.546488300000021],
              [98.05720980000007, 4.537879600000053],
              [98.03965660000006, 4.528175300000044],
              [98.03576880000008, 4.53341690000002],
              [98.03914550000007, 4.545429400000046],
              [98.02917080000003, 4.543169],
              [98.02492310000008, 4.53706],
              [98.01961380000006, 4.538341600000024],
              [98.00149480000005, 4.530645500000048],
              [97.98961, 4.531713700000068],
              [97.983548, 4.548190300000044],
              [97.97367980000007, 4.56296530000003],
              [97.984089, 4.568107700000041],
              [97.98868540000007, 4.580596800000023],
              [98.00519790000004, 4.60054770000005],
              [98.01026630000007, 4.604566500000033],
              [98.01797830000004, 4.604649],
              [98.01627220000006, 4.613916300000028],
              [98.01206270000006, 4.618971500000043],
              [98.00237290000007, 4.625241700000061],
              [97.99341850000008, 4.625424100000032],
              [97.99280550000003, 4.63191740000002],
              [97.995614, 4.639371800000049],
              [97.98685460000007, 4.641850200000022],
              [97.97588640000004, 4.652671700000042],
              [97.97116120000004, 4.662575500000059],
              [97.96366940000007, 4.665445700000021],
              [97.96189840000005, 4.675516200000061],
              [97.96658690000004, 4.685345100000063],
              [97.95910810000004, 4.703476500000022],
              [97.962752, 4.714731500000028],
              [97.96040260000007, 4.728801100000055],
              [97.955246, 4.736405900000022],
              [97.95782450000007, 4.744659100000035],
              [97.94402040000006, 4.771990100000039],
              [97.944859, 4.776477800000066],
              [97.94142310000007, 4.788792600000022],
              [97.93022260000004, 4.814867],
              [97.91853840000005, 4.848351700000023],
              [97.90628190000007, 4.878057500000068],
              [97.90420760000006, 4.890170700000056],
              [97.89317860000006, 4.888498200000072],
              [97.88091760000003, 4.889380200000062],
              [97.86626650000005, 4.893508],
              [97.84212010000005, 4.907640300000025],
              [97.83857310000008, 4.911855100000025],
              [97.81501280000003, 4.928066],
              [97.79374950000005, 4.945319900000072],
              [97.77598770000009, 4.963616600000023],
              [97.73582150000004, 4.997673700000064],
              [97.728303, 5.005098900000064],
              [97.69007940000006, 5.037179700000024],
              [97.67152170000008, 5.054931500000066],
              [97.66403620000006, 5.060367400000075],
              [97.64604490000005, 5.078718600000059],
              [97.64943340000008, 5.081832100000042],
              [97.65661590000008, 5.081091400000048],
              [97.65317070000003, 5.100070300000027],
              [97.653319, 5.112865600000021],
              [97.64278640000003, 5.126609600000052],
              [97.63436350000006, 5.128185200000075],
              [97.63165870000006, 5.132642700000076],
              [97.625, 5.129582800000037],
              [97.60962490000009, 5.133418100000029],
              [97.590835, 5.144238600000051],
              [97.59241080000004, 5.147529600000041],
              [97.57026470000005, 5.174555400000031],
              [97.56819890000008, 5.183033],
              [97.56069650000006, 5.192282400000067],
              [97.54672020000004, 5.204461],
              [97.54455370000005, 5.209050200000036],
              [97.55372340000008, 5.213009900000031],
              [97.51828680000006, 5.239018],
              [97.49775620000008, 5.249685600000021],
              [97.49151130000007, 5.248725500000035],
              [97.480393, 5.242951500000061],
              [97.441736, 5.227616600000033],
              [97.41932810000009, 5.220829900000069],
              [97.39345050000009, 5.211603500000024],
              [97.370216, 5.201800200000037],
              [97.351076, 5.194740600000046],
              [97.33502260000006, 5.190110600000025],
              [97.32568980000008, 5.188843],
              [97.31062370000006, 5.184093500000074],
              [97.28273710000008, 5.16970630000003],
              [97.27055790000009, 5.166747600000065],
              [97.26784890000005, 5.168679800000064],
              [97.25109270000007, 5.158399],
              [97.23932280000008, 5.153516400000058],
              [97.22773820000003, 5.153030300000069],
              [97.21119060000007, 5.144908800000053],
              [97.18994130000004, 5.141256300000066],
              [97.17995830000007, 5.141728],
              [97.16291630000006, 5.145616100000041],
              [97.14177360000008, 5.157169400000043],
              [97.13958670000005, 5.16913930000004],
              [97.15015210000007, 5.166723],
              [97.15381530000008, 5.173865100000057],
              [97.15033160000007, 5.189314800000034],
              [97.14112350000005, 5.200303500000075],
              [97.12061260000007, 5.21059850000006],
              [97.11704660000004, 5.216595300000051],
              [97.10868460000006, 5.22038660000004],
              [97.107498, 5.214823300000035],
              [97.10026550000003, 5.214920200000051],
              [97.09630390000007, 5.218317400000046],
              [97.10114480000004, 5.225534],
              [97.08754550000003, 5.228607500000066],
              [97.07504130000007, 5.233010300000046],
              [97.06475540000008, 5.234671],
              [97.04713010000006, 5.240683],
              [97.038199, 5.240857400000039],
              [97.02905470000007, 5.244279200000051],
              [97.033178, 5.250592100000063],
              [96.99384380000004, 5.260570400000063],
              [96.97961680000009, 5.262581100000034],
              [96.95765010000008, 5.261008200000049],
              [96.91442410000008, 5.250638200000026],
              [96.906753, 5.250935],
              [96.89032930000008, 5.247431400000039],
              [96.87679360000004, 5.250257500000032],
              [96.86886450000009, 5.265530700000056],
              [96.85841820000007, 5.27524690000007],
              [96.84841330000006, 5.276973500000054],
              [96.83166210000007, 5.275792100000047],
              [96.81425260000003, 5.27239830000002],
              [96.79847640000008, 5.266872],
              [96.780622, 5.258785700000033],
              [96.74832680000009, 5.251090600000055],
              [96.73304930000006, 5.24939130000007],
              [96.72149, 5.246010400000046],
              [96.65499650000004, 5.218016300000045],
              [96.63231710000008, 5.212283100000036],
              [96.61111810000006, 5.210196500000052],
              [96.58019290000004, 5.210499],
              [96.55240020000008, 5.201447400000063],
              [96.52805190000004, 5.198265400000025],
              [96.50919490000007, 5.19989570000007],
              [96.490893, 5.206089700000064],
              [96.48137810000009, 5.21204350000005],
              [96.47632260000006, 5.226905600000066],
              [96.46715490000008, 5.236451500000044],
              [96.45950940000006, 5.235005],
              [96.43060510000004, 5.21920810000006],
              [96.40573750000004, 5.211181900000042],
              [96.38976070000007, 5.210272800000041],
              [96.370535, 5.217790300000047],
              [96.36625910000004, 5.217831900000022],
              [96.35011170000007, 5.224830700000041],
              [96.31980570000007, 5.232357600000057],
              [96.31097390000008, 5.237630300000035],
              [96.29103090000007, 5.246188200000063],
              [96.258618, 5.263371600000028],
              [96.23794980000008, 5.262850400000048],
              [96.20954610000007, 5.258709700000054],
              [96.19526810000008, 5.259030900000027],
              [96.167649, 5.266020400000059],
              [96.15329550000007, 5.272570300000041],
              [96.14512820000004, 5.277874600000075],
              [96.12033570000006, 5.281348500000036],
              [96.10387020000007, 5.287721300000044],
              [96.07911120000006, 5.292403],
              [96.06776250000007, 5.301978700000063],
              [96.05462130000006, 5.321450800000036],
              [96.04966370000005, 5.326591],
              [96.03951590000008, 5.331747700000051],
              [96.01563590000006, 5.348177700000065],
              [95.98091020000004, 5.373911200000066],
              [95.96436470000003, 5.38866790000003],
              [95.95317480000006, 5.394727900000021],
              [95.93887620000004, 5.406562],
              [95.93517120000007, 5.405825500000049],
              [95.92960950000008, 5.413811500000065],
              [95.915211, 5.427527400000031],
              [95.90735770000003, 5.430856900000038],
              [95.90875770000008, 5.435070500000052],
              [95.89669860000004, 5.451554500000043],
              [95.89028210000004, 5.462913600000036],
              [95.89182940000006, 5.465676900000062],
              [95.89329290000006, 5.48413],
              [95.88852940000004, 5.502080200000023],
              [95.87257330000006, 5.512210800000048],
              [95.85807120000004, 5.517247600000076],
              [95.84505110000003, 5.524190100000055],
              [95.82795960000004, 5.535088500000029],
              [95.81310970000004, 5.53724040000003],
              [95.79010420000009, 5.545683600000075],
              [95.76416750000004, 5.559701400000051],
              [95.750074, 5.565848300000027],
              [95.74144740000008, 5.571359500000028],
              [95.72942690000008, 5.575883300000044],
              [95.70208780000007, 5.580058800000074],
              [95.69124340000008, 5.58265],
              [95.66673920000005, 5.593102100000067],
              [95.64203210000005, 5.607250500000021],
              [95.62944820000007, 5.616318200000023],
              [95.62187580000005, 5.617763800000034],
              [95.61013830000007, 5.627703300000064],
              [95.59158070000007, 5.622808400000054],
              [95.58536190000007, 5.617858200000057],
              [95.56658150000004, 5.616415500000073],
              [95.56007260000007, 5.613740800000073],
              [95.54280990000007, 5.614954200000057],
              [95.53954250000004, 5.610195500000032],
              [95.52934680000004, 5.612829],
              [95.53033960000005, 5.599228900000071],
              [95.52319930000004, 5.594831200000044],
              [95.512441, 5.595071100000041],
              [95.49913340000006, 5.602480300000025],
              [95.48426580000006, 5.61756680000002],
              [95.47588350000007, 5.630362600000069],
              [95.46214910000003, 5.646583700000065],
              [95.455949, 5.651195700000073],
              [95.43560110000004, 5.656503200000031]
            ]
          ],
          [
            [
              [95.14810590000008, 5.66151270000006],
              [95.13794730000006, 5.65824310000005],
              [95.14415140000006, 5.648015100000066],
              [95.145128, 5.635079400000052],
              [95.12527780000005, 5.622631],
              [95.11894790000008, 5.615204200000051],
              [95.12762860000004, 5.594464400000049],
              [95.13352320000007, 5.598384700000054],
              [95.13735170000007, 5.60700520000006],
              [95.14517880000005, 5.604757100000029],
              [95.14591810000007, 5.593258900000023],
              [95.15725530000003, 5.595352100000071],
              [95.16770910000008, 5.605998],
              [95.18588860000006, 5.608858300000065],
              [95.18809130000005, 5.61115760000007],
              [95.18057470000008, 5.62019],
              [95.17096970000006, 5.625061100000039],
              [95.17064670000008, 5.631431400000054],
              [95.16155680000008, 5.637682300000051],
              [95.15729950000008, 5.643665200000044],
              [95.15890070000006, 5.653150200000027],
              [95.15489430000008, 5.660387400000047],
              [95.14810590000008, 5.66151270000006]
            ]
          ],
          [
            [
              [95.05953310000007, 5.749818600000026],
              [95.03732320000006, 5.750590700000032],
              [95.02694960000008, 5.749783100000059],
              [95.03119850000007, 5.744294100000047],
              [95.01647770000005, 5.733008500000039],
              [95.01117450000004, 5.723930200000041],
              [95.01803220000005, 5.719579800000076],
              [95.04510280000005, 5.737238600000069],
              [95.04881130000007, 5.734785400000021],
              [95.05220070000007, 5.720847800000058],
              [95.058573, 5.720669800000053],
              [95.06048820000007, 5.713348600000074],
              [95.05478610000006, 5.70997710000006],
              [95.04696930000006, 5.710778900000037],
              [95.04140150000006, 5.708001],
              [95.04105940000005, 5.697868800000037],
              [95.05057420000009, 5.697015200000067],
              [95.05926080000006, 5.686001100000055],
              [95.05887370000005, 5.679093600000044],
              [95.04918110000006, 5.675346600000069],
              [95.05108230000008, 5.671512500000063],
              [95.066796, 5.671010500000023],
              [95.07017870000004, 5.666157500000054],
              [95.06516320000009, 5.658242200000075],
              [95.05926210000007, 5.655617100000029],
              [95.05553970000005, 5.648016100000063],
              [95.07726280000009, 5.656536600000038],
              [95.07785310000008, 5.666114200000038],
              [95.088236, 5.669068400000072],
              [95.11119450000007, 5.669090900000072],
              [95.11743970000003, 5.672396300000059],
              [95.14162470000008, 5.667009200000052],
              [95.13239980000009, 5.686189900000045],
              [95.11890690000007, 5.689479700000049],
              [95.11325870000007, 5.695049900000072],
              [95.11157260000004, 5.704616100000067],
              [95.10431780000005, 5.713187400000038],
              [95.09692940000008, 5.714094100000068],
              [95.08518030000005, 5.723906800000066],
              [95.07963870000003, 5.720168500000057],
              [95.07196310000006, 5.724002400000074],
              [95.07286250000004, 5.729862600000047],
              [95.06346010000004, 5.738587700000039],
              [95.05953310000007, 5.749818600000026]
            ]
          ],
          [
            [
              [95.22141640000007, 5.905997900000045],
              [95.216799, 5.907218500000056],
              [95.216421, 5.890274100000056],
              [95.22203650000006, 5.873056800000029],
              [95.23676540000008, 5.85929040000002],
              [95.24072370000005, 5.850461900000028],
              [95.24797870000003, 5.851186100000064],
              [95.25281340000004, 5.848320100000024],
              [95.25899180000005, 5.839955800000041],
              [95.259867, 5.830457300000035],
              [95.265391, 5.808100200000069],
              [95.27191090000008, 5.798845800000038],
              [95.27738790000006, 5.78753480000006],
              [95.28483480000006, 5.780494300000043],
              [95.29932980000007, 5.780542600000047],
              [95.31148140000005, 5.777335600000072],
              [95.31759110000007, 5.773840800000073],
              [95.33056760000005, 5.77294020000005],
              [95.34195630000005, 5.786315400000035],
              [95.34776280000005, 5.804650500000037],
              [95.343981, 5.816464800000062],
              [95.34437520000006, 5.825807],
              [95.35157980000008, 5.828026500000021],
              [95.36631840000007, 5.811737700000037],
              [95.370658, 5.804041],
              [95.37632530000008, 5.810783400000048],
              [95.377224, 5.82854320000007],
              [95.37262240000007, 5.843615100000022],
              [95.37306960000006, 5.848501400000032],
              [95.36324570000005, 5.853968400000042],
              [95.35399410000008, 5.865869500000031],
              [95.35304090000005, 5.877481200000034],
              [95.34830070000004, 5.886600100000067],
              [95.34289610000008, 5.88919960000004],
              [95.33339060000009, 5.897890700000062],
              [95.32122830000009, 5.900535700000034],
              [95.310261, 5.893962],
              [95.314, 5.885746100000063],
              [95.32169190000008, 5.889900300000022],
              [95.323897, 5.882241400000055],
              [95.31442750000008, 5.867997800000069],
              [95.30139650000007, 5.872437600000069],
              [95.304139, 5.84109090000004],
              [95.29856420000004, 5.838986400000067],
              [95.28603140000007, 5.841570300000058],
              [95.28447290000008, 5.848477100000025],
              [95.27847160000005, 5.847441300000071],
              [95.26750690000006, 5.85780120000004],
              [95.25205840000007, 5.88176610000005],
              [95.24388220000009, 5.882495400000039],
              [95.23956290000007, 5.89176290000006],
              [95.23429380000005, 5.897691100000031],
              [95.22141640000007, 5.905997900000045]
            ]
          ]
        ]
      },
      "properties": {
        "nama": "Provinsi Aceh",
        "nid": 1,
        "type": 1
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [105.549767, -6.995661],
              [105.54168750000008, -6.996104399999979],
              [105.52916, -7.00093],
              [105.52161450000006, -7.001578],
              [105.51985220000006, -7.007339599999966],
              [105.52488760000006, -7.013464599999963],
              [105.53568310000009, -7.01535],
              [105.56297350000006, -7.016568399999926],
              [105.56810810000007, -7.014251],
              [105.56972550000006, -7.007352099999935],
              [105.56087540000004, -7.000815099999954],
              [105.549767, -6.995661]
            ]
          ],
          [
            [
              [105.81460610000005, -6.950282499999958],
              [105.80111730000004, -6.953026199999954],
              [105.78765910000004, -6.959918399999935],
              [105.77247660000006, -6.963359299999979],
              [105.76575510000004, -6.97091],
              [105.77224010000003, -6.973933099999954],
              [105.81257670000008, -6.958734],
              [105.82007640000006, -6.954337099999975],
              [105.81460610000005, -6.950282499999958]
            ]
          ],
          [
            [
              [105.24840670000003, -6.725922499999967],
              [105.24781780000006, -6.748479],
              [105.25788640000007, -6.751043099999947],
              [105.26639610000007, -6.742080699999974],
              [105.26779230000005, -6.737521099999981],
              [105.26343640000005, -6.729259199999944],
              [105.24840670000003, -6.725922499999967]
            ]
          ],
          [
            [
              [105.26564840000003, -6.522833799999944],
              [105.25425780000006, -6.523445099999947],
              [105.25123650000006, -6.529466599999978],
              [105.238793, -6.533188799999948],
              [105.21997130000005, -6.529750799999931],
              [105.21341760000007, -6.535138599999925],
              [105.21469940000009, -6.544111699999974],
              [105.20864160000008, -6.545288],
              [105.19839540000004, -6.543556199999955],
              [105.18404450000008, -6.538773899999967],
              [105.18014580000005, -6.544544599999938],
              [105.16317810000004, -6.553731299999981],
              [105.16336120000005, -6.556195199999934],
              [105.15125330000006, -6.567645899999945],
              [105.14104520000006, -6.572037599999931],
              [105.12114010000005, -6.591040899999939],
              [105.11048950000009, -6.604401899999971],
              [105.11017670000007, -6.61048059999996],
              [105.11719580000005, -6.613107499999956],
              [105.11654730000004, -6.617437699999925],
              [105.12830410000004, -6.623641799999973],
              [105.13140930000009, -6.605759499999976],
              [105.13591060000005, -6.60017],
              [105.14993350000009, -6.591513499999962],
              [105.15682280000004, -6.578162099999929],
              [105.16434540000006, -6.573245899999961],
              [105.17211970000005, -6.576118399999928],
              [105.18090880000005, -6.583016799999939],
              [105.17413390000007, -6.599203499999931],
              [105.18212950000009, -6.606527699999958],
              [105.18565430000007, -6.613095699999974],
              [105.19133060000007, -6.612136299999975],
              [105.19494690000005, -6.620505199999968],
              [105.19058290000004, -6.640637799999979],
              [105.18191590000004, -6.650860699999953],
              [105.17169250000006, -6.655895599999951],
              [105.17024290000006, -6.663344299999949],
              [105.17935240000008, -6.671937299999968],
              [105.18254150000007, -6.68019],
              [105.19156710000004, -6.66625629999993],
              [105.19812830000006, -6.663436299999944],
              [105.20436920000009, -6.656817299999943],
              [105.20486510000006, -6.647621099999981],
              [105.21052610000004, -6.646362699999941],
              [105.218865, -6.63767],
              [105.22914940000004, -6.643041599999947],
              [105.24299680000007, -6.635757399999932],
              [105.25465450000007, -6.608055599999943],
              [105.26070460000005, -6.597861299999977],
              [105.25773680000003, -6.582671599999969],
              [105.25317440000003, -6.580117199999961],
              [105.25119070000005, -6.573271299999931],
              [105.25434170000005, -6.566195499999935],
              [105.25273950000008, -6.561572],
              [105.25343380000004, -6.548186799999939],
              [105.25840820000008, -6.540129199999967],
              [105.26516020000008, -6.536538599999972],
              [105.26784570000007, -6.528072799999961],
              [105.26564840000003, -6.522833799999944]
            ]
          ],
          [
            [
              [105.85638560000007, -5.937729499999932],
              [105.82984190000008, -5.951088099999936],
              [105.83670840000008, -5.956247099999928],
              [105.84678090000006, -5.957015199999944],
              [105.85045570000005, -5.964086899999927],
              [105.85110410000004, -5.979180199999973],
              [105.86158460000007, -5.973699499999952],
              [105.86610120000006, -5.966389599999957],
              [105.860619, -5.955254099999934],
              [105.85976340000008, -5.940067499999941],
              [105.85638560000007, -5.937729499999932]
            ]
          ],
          [
            [
              [106.16814450000004, -5.93237709999994],
              [106.16050750000005, -5.921534799999961],
              [106.15063510000004, -5.919453899999951],
              [106.14089230000008, -5.920822899999962],
              [106.13911470000005, -5.928018299999962],
              [106.14157140000003, -5.939476299999967],
              [106.15598330000006, -5.944540299999971],
              [106.16814450000004, -5.93237709999994]
            ]
          ],
          [
            [
              [106.72465820000008, -6.089184499999931],
              [106.71262, -6.06897],
              [106.70953, -6.05557],
              [106.71241430000003, -6.040306199999975],
              [106.70509750000008, -6.035059499999932],
              [106.70081220000009, -6.027838],
              [106.68921460000007, -6.021889099999953],
              [106.67797, -6.01325],
              [106.66487660000007, -6.02193479999994],
              [106.65772920000006, -6.018760099999952],
              [106.64859720000004, -6.005256699999961],
              [106.64306490000007, -6.001402],
              [106.63299980000005, -6.006505899999979],
              [106.62935920000007, -6.012752],
              [106.62391020000007, -6.014476799999954],
              [106.61681, -6.02085],
              [106.60652810000005, -6.025405899999953],
              [106.58677880000005, -6.032061299999953],
              [106.56944220000008, -6.031048699999928],
              [106.55847, -6.02525],
              [106.54936610000004, -6.023484],
              [106.53524, -6.010473],
              [106.52933510000008, -6.017604399999925],
              [106.521404, -6.033270199999947],
              [106.51234630000005, -6.033176699999956],
              [106.50860570000003, -6.037788899999953],
              [106.489633, -6.046078399999942],
              [106.47335, -6.04465],
              [106.45789, -6.03623],
              [106.44213380000008, -6.03088],
              [106.43049, -6.03205],
              [106.41204910000005, -6.023879099999931],
              [106.40406830000006, -6.018709299999955],
              [106.39558250000005, -6.001152599999955],
              [106.39072520000008, -5.987517],
              [106.38908660000004, -5.977217099999962],
              [106.36301520000006, -5.969320299999936],
              [106.35999080000005, -5.962221499999941],
              [106.352995, -5.961576599999944],
              [106.34107730000005, -5.977195399999971],
              [106.32601, -5.98363],
              [106.31460550000008, -5.97640309999997],
              [106.30424880000004, -5.967961399999979],
              [106.29228020000005, -5.969153699999936],
              [106.276471, -5.966232699999978],
              [106.25562510000009, -5.960137],
              [106.24399580000005, -5.966876699999943],
              [106.24491790000008, -5.982451599999933],
              [106.24201640000007, -5.991933199999949],
              [106.22515470000008, -6.005251199999975],
              [106.224422, -6.012429499999939],
              [106.21079, -6.01875],
              [106.19585, -6.01802],
              [106.19019, -6.02356],
              [106.18162350000006, -6.026395599999944],
              [106.15590150000008, -6.024446199999943],
              [106.14351890000006, -6.01649169999996],
              [106.12151820000008, -6.010089599999958],
              [106.11207550000006, -6.00146],
              [106.09818830000006, -5.981994699999973],
              [106.11091080000006, -5.982045099999937],
              [106.11011710000008, -5.974883299999931],
              [106.10377770000008, -5.959090499999945],
              [106.11008980000008, -5.954546499999935],
              [106.10629, -5.9493],
              [106.114126, -5.930083899999943],
              [106.10836250000006, -5.927619399999969],
              [106.10105390000007, -5.918109899999934],
              [106.10155240000006, -5.913258699999972],
              [106.09179880000005, -5.909017899999981],
              [106.08807, -5.89985],
              [106.08190640000004, -5.890806399999974],
              [106.07071460000009, -5.881724399999939],
              [106.05382360000004, -5.883934099999976],
              [106.04169720000004, -5.878397699999937],
              [106.03588610000008, -5.884547699999928],
              [106.02302650000007, -5.889481499999931],
              [106.016488, -5.897889599999928],
              [106.01454, -5.90462],
              [106.00155, -5.91287],
              [105.99337230000003, -5.929026299999975],
              [105.99990080000003, -5.935276099999953],
              [106.00256, -5.94778],
              [105.99813020000005, -5.954790399999979],
              [105.99590840000008, -5.964516899999978],
              [105.99630250000007, -5.974206199999969],
              [105.98966930000006, -5.980726899999979],
              [105.97865390000004, -5.997041299999978],
              [105.96846860000005, -6.004779099999951],
              [105.96241010000006, -6.01217],
              [105.94207, -6.02124],
              [105.92534, -6.04139],
              [105.92056, -6.05208],
              [105.914055, -6.053198899999927],
              [105.90113, -6.06049],
              [105.88226710000004, -6.072892599999932],
              [105.88337, -6.08216],
              [105.88109790000004, -6.090889899999979],
              [105.88149010000006, -6.09924419999993],
              [105.87860710000007, -6.111671199999932],
              [105.87334, -6.1131],
              [105.86515, -6.12363],
              [105.86085, -6.13961],
              [105.85580010000007, -6.143031],
              [105.85341, -6.16376],
              [105.84321, -6.17981],
              [105.84133610000004, -6.192624499999965],
              [105.83580730000006, -6.199916899999948],
              [105.83248, -6.20927],
              [105.83052, -6.23256],
              [105.82508930000006, -6.239187499999957],
              [105.83023660000003, -6.268737099999953],
              [105.82580830000006, -6.277128299999958],
              [105.82616, -6.28734],
              [105.83075290000005, -6.294254299999977],
              [105.84038, -6.2968],
              [105.83969, -6.31367],
              [105.82813, -6.31879],
              [105.82926, -6.32698],
              [105.82345310000005, -6.345114699999954],
              [105.82426630000003, -6.351338799999951],
              [105.81952510000008, -6.36059419999998],
              [105.81910540000007, -6.368714799999964],
              [105.82300940000005, -6.37561809999994],
              [105.81865120000003, -6.380780699999946],
              [105.82459, -6.3856],
              [105.82468990000007, -6.397039099999972],
              [105.82665090000006, -6.405285699999979],
              [105.82519, -6.42508],
              [105.82208, -6.43791],
              [105.8081, -6.47246],
              [105.79703020000005, -6.489633599999934],
              [105.78226830000006, -6.507227],
              [105.77151770000006, -6.516273099999978],
              [105.75233660000004, -6.52170209999997],
              [105.74522290000004, -6.519531799999925],
              [105.73670950000007, -6.521331399999951],
              [105.72511510000004, -6.53109859999995],
              [105.71470390000007, -6.532697699999972],
              [105.70846440000008, -6.527477599999941],
              [105.68672090000007, -6.521915699999965],
              [105.68445, -6.51543],
              [105.67563150000007, -6.511153],
              [105.67272, -6.50524],
              [105.67777490000009, -6.492660799999953],
              [105.67575710000006, -6.485778699999969],
              [105.67908010000008, -6.479259799999966],
              [105.676521, -6.475253799999962],
              [105.66588190000004, -6.471502899999962],
              [105.66717830000005, -6.479784699999925],
              [105.65625, -6.47834679999994],
              [105.647962, -6.485796199999925],
              [105.64954, -6.49232],
              [105.6411, -6.49957],
              [105.63386720000005, -6.515893299999959],
              [105.630243, -6.518296],
              [105.62245080000008, -6.53648979999997],
              [105.62316940000005, -6.547340799999972],
              [105.61624840000007, -6.559015099999954],
              [105.61744820000007, -6.576071799999966],
              [105.62615150000005, -6.585099199999945],
              [105.62449250000009, -6.592624199999932],
              [105.61824330000007, -6.602491899999961],
              [105.61817590000004, -6.616271199999971],
              [105.60573250000004, -6.637193],
              [105.59673, -6.64873],
              [105.57984160000007, -6.657672399999967],
              [105.574221, -6.663331099999937],
              [105.572215, -6.680047],
              [105.56146790000008, -6.686590699999954],
              [105.55454530000009, -6.697078099999942],
              [105.54207950000006, -6.704461399999957],
              [105.52029240000007, -6.724050299999931],
              [105.507265, -6.729430199999967],
              [105.50679290000005, -6.737779299999943],
              [105.51191320000004, -6.743273299999942],
              [105.51203280000004, -6.757244599999979],
              [105.50327120000009, -6.765529399999934],
              [105.50471, -6.77703],
              [105.50028990000004, -6.785228299999972],
              [105.49733080000004, -6.795688],
              [105.48966220000005, -6.802924099999927],
              [105.47952610000004, -6.807527499999935],
              [105.48202470000007, -6.81518449999993],
              [105.46940550000005, -6.82732249999998],
              [105.46141330000006, -6.828023599999938],
              [105.44763880000005, -6.825597599999981],
              [105.440013, -6.818233499999963],
              [105.43548870000006, -6.806565099999943],
              [105.436839, -6.79109219999998],
              [105.43435610000006, -6.784910499999967],
              [105.43604580000004, -6.776951],
              [105.42513260000004, -6.773466699999972],
              [105.42051530000003, -6.767012],
              [105.41545810000008, -6.765802],
              [105.40872750000005, -6.751947199999961],
              [105.39595710000003, -6.744326799999953],
              [105.39201390000005, -6.74398739999998],
              [105.38749020000006, -6.734923699999968],
              [105.39509650000008, -6.724941099999967],
              [105.40161640000008, -6.727028799999971],
              [105.40065770000007, -6.71641539999996],
              [105.39457430000004, -6.70853349999993],
              [105.39206290000004, -6.695078599999931],
              [105.39261450000004, -6.687443799999926],
              [105.387748, -6.678470199999936],
              [105.37915150000003, -6.67458629999993],
              [105.37473880000005, -6.658472499999959],
              [105.37517410000004, -6.654249299999947],
              [105.36891160000005, -6.646804299999928],
              [105.36296360000006, -6.65495],
              [105.33975, -6.66144],
              [105.32725750000009, -6.668092699999931],
              [105.324224, -6.677752299999952],
              [105.32877, -6.682281399999965],
              [105.32555, -6.69844],
              [105.31964, -6.70926],
              [105.29829, -6.71886],
              [105.28175, -6.73397],
              [105.26950470000008, -6.746928899999943],
              [105.26508880000006, -6.759224199999949],
              [105.25853660000007, -6.762352799999974],
              [105.23393990000005, -6.757659299999943],
              [105.22187030000003, -6.747897199999954],
              [105.21323470000004, -6.746201099999951],
              [105.210563, -6.752504899999963],
              [105.213965, -6.762335],
              [105.22236320000007, -6.767659499999979],
              [105.22924840000007, -6.77805079999996],
              [105.23272980000007, -6.779849499999955],
              [105.23253020000004, -6.789566199999967],
              [105.23700630000008, -6.792318299999977],
              [105.23648590000005, -6.80200259999998],
              [105.23952770000005, -6.810422099999926],
              [105.23619090000005, -6.815345499999978],
              [105.24419450000005, -6.824697499999957],
              [105.240204, -6.83058],
              [105.24129330000005, -6.83942019999995],
              [105.25055710000004, -6.836595],
              [105.26407270000004, -6.845834499999967],
              [105.27230790000004, -6.841165599999954],
              [105.27656430000008, -6.833214299999952],
              [105.28977210000005, -6.820377899999926],
              [105.29479, -6.80536],
              [105.30224, -6.8028],
              [105.32656, -6.80279],
              [105.36055880000004, -6.810363399999972],
              [105.38919, -6.8209],
              [105.40673, -6.82962],
              [105.41242, -6.83664],
              [105.40926, -6.84651],
              [105.41168, -6.85228],
              [105.41747, -6.85416],
              [105.42226, -6.84965],
              [105.42017, -6.84116],
              [105.42552, -6.83748],
              [105.43678, -6.83565],
              [105.44646, -6.83878],
              [105.47269, -6.85912],
              [105.47604, -6.86415],
              [105.48839, -6.86693],
              [105.4959, -6.86108],
              [105.51510920000004, -6.863885699999969],
              [105.53765110000006, -6.871960299999955],
              [105.54790340000005, -6.867909899999972],
              [105.56, -6.85838],
              [105.58234, -6.85355],
              [105.59994, -6.85087],
              [105.60326, -6.84637],
              [105.61913, -6.8432],
              [105.64897490000004, -6.842868299999964],
              [105.66144530000008, -6.843963899999949],
              [105.67255, -6.83701],
              [105.70837, -6.83631],
              [105.733218, -6.839769099999955],
              [105.77126420000008, -6.848164499999939],
              [105.77607970000008, -6.850130899999954],
              [105.79513410000004, -6.842922399999964],
              [105.82283780000006, -6.837421599999971],
              [105.84208, -6.83446],
              [105.86971440000008, -6.832869199999948],
              [105.87870770000006, -6.836850799999979],
              [105.88603240000003, -6.845755799999949],
              [105.89686190000003, -6.839846799999975],
              [105.89352680000007, -6.828984499999933],
              [105.90556, -6.8198],
              [105.92924, -6.81421],
              [105.96082, -6.81202],
              [105.99296, -6.81404],
              [106.02634880000005, -6.821080799999947],
              [106.04460030000007, -6.826435499999945],
              [106.05792140000005, -6.832364299999938],
              [106.07507410000005, -6.851808199999937],
              [106.08544180000007, -6.858791499999938],
              [106.09599150000008, -6.876443199999926],
              [106.10131220000005, -6.87752409999996],
              [106.11481190000006, -6.886612699999944],
              [106.13566, -6.89366],
              [106.14002740000006, -6.899027699999976],
              [106.14947950000004, -6.903579799999932],
              [106.153032, -6.902543899999955],
              [106.16480910000007, -6.908879899999931],
              [106.18609090000007, -6.909986699999934],
              [106.20065630000005, -6.913855799999965],
              [106.21536230000004, -6.920367699999929],
              [106.23829670000003, -6.932268599999929],
              [106.24594650000006, -6.939857399999937],
              [106.24709130000008, -6.949082799999928],
              [106.23948860000007, -6.954902299999958],
              [106.24581050000006, -6.961126399999955],
              [106.25461180000008, -6.960311799999943],
              [106.26535570000004, -6.968466699999965],
              [106.26488920000008, -6.972969199999966],
              [106.28025290000005, -6.979734199999939],
              [106.28278520000003, -6.974367399999949],
              [106.301378, -6.978391099999953],
              [106.308706, -6.985719899999935],
              [106.30654890000005, -6.995340399999975],
              [106.33142810000004, -6.991749099999936],
              [106.33517270000004, -6.986985199999936],
              [106.343583, -6.990168199999971],
              [106.34383750000006, -6.997503599999959],
              [106.35157340000006, -6.994578399999966],
              [106.35803530000004, -6.998124799999971],
              [106.36152080000005, -6.992124299999944],
              [106.377729, -6.995184],
              [106.38135730000005, -6.989283499999942],
              [106.39371860000006, -6.985097099999962],
              [106.39604980000007, -6.979715299999953],
              [106.39294860000007, -6.968129],
              [106.39571410000008, -6.954141399999969],
              [106.38964160000006, -6.941997499999957],
              [106.391083, -6.932365199999936],
              [106.39807150000007, -6.928857599999958],
              [106.40010090000004, -6.924261899999976],
              [106.39703390000005, -6.908618799999942],
              [106.39287590000004, -6.90063269999996],
              [106.40242790000008, -6.895624899999973],
              [106.40625790000007, -6.886378599999944],
              [106.41934230000004, -6.880546899999956],
              [106.41918970000006, -6.874290299999927],
              [106.43125180000004, -6.861655499999927],
              [106.43117550000005, -6.846004799999946],
              [106.43531060000004, -6.838906099999974],
              [106.43605070000007, -6.820207],
              [106.447037, -6.818476499999974],
              [106.45291160000005, -6.813145199999951],
              [106.45558190000008, -6.804760299999941],
              [106.47911860000005, -6.795192099999952],
              [106.49649070000004, -6.791544299999941],
              [106.51663230000008, -6.77653209999994],
              [106.52629110000004, -6.767873699999939],
              [106.52021050000008, -6.761620899999969],
              [106.50000020000004, -6.752548599999955],
              [106.47462490000004, -6.752168499999925],
              [106.46601130000005, -6.750111],
              [106.463196, -6.736881599999947],
              [106.45383480000004, -6.730781399999955],
              [106.45046260000004, -6.726231],
              [106.45139340000009, -6.71353229999994],
              [106.44590790000007, -6.702960799999971],
              [106.43990350000007, -6.698986399999967],
              [106.43284630000005, -6.690430499999934],
              [106.42879510000006, -6.675584599999979],
              [106.43149590000007, -6.652736499999946],
              [106.43174770000007, -6.636523599999975],
              [106.42752860000007, -6.609221299999945],
              [106.43037440000006, -6.589945199999931],
              [106.42436240000006, -6.56896009999997],
              [106.41632870000007, -6.559753299999954],
              [106.42289760000006, -6.551936499999954],
              [106.41405510000004, -6.542091699999958],
              [106.40795930000007, -6.532879199999968],
              [106.40470150000004, -6.512881599999957],
              [106.40598320000004, -6.496398799999952],
              [106.41259030000003, -6.483306699999957],
              [106.41179680000005, -6.467626899999971],
              [106.406975, -6.453891599999963],
              [106.42278320000008, -6.449464699999965],
              [106.43928550000004, -6.450198099999966],
              [106.44176760000005, -6.44095429999993],
              [106.45034050000004, -6.432906499999945],
              [106.45410940000005, -6.418483099999946],
              [106.44998960000004, -6.412774899999931],
              [106.43930840000007, -6.404442699999947],
              [106.44122340000007, -6.398612899999932],
              [106.435631, -6.389570599999956],
              [106.431557, -6.376890099999969],
              [106.42742180000005, -6.376501899999937],
              [106.43060330000009, -6.357067],
              [106.428406, -6.352570899999932],
              [106.43622610000006, -6.335392399999932],
              [106.44268820000008, -6.325179899999966],
              [106.46501950000004, -6.308263699999941],
              [106.47624990000008, -6.309058599999958],
              [106.47809630000006, -6.315757699999949],
              [106.48403960000007, -6.324607299999968],
              [106.482033, -6.343996899999979],
              [106.47644830000007, -6.344098],
              [106.47627280000006, -6.350747499999954],
              [106.48828150000008, -6.354322799999977],
              [106.49969510000005, -6.355725199999938],
              [106.50225090000004, -6.349936399999933],
              [106.51230650000008, -6.355268399999943],
              [106.51910420000007, -6.355163],
              [106.52210260000004, -6.350148599999955],
              [106.52943440000007, -6.345820799999956],
              [106.52928950000006, -6.337882399999955],
              [106.52410910000003, -6.333591399999932],
              [106.51640340000006, -6.332494199999928],
              [106.51351950000009, -6.325368799999978],
              [106.523575, -6.32505649999996],
              [106.54229, -6.334005299999944],
              [106.54859950000008, -6.333127399999967],
              [106.55863210000007, -6.337936799999966],
              [106.57740050000007, -6.34001109999997],
              [106.58725, -6.347353899999973],
              [106.58800530000008, -6.355953199999931],
              [106.59357480000006, -6.362521099999981],
              [106.62870050000004, -6.36087079999993],
              [106.65326710000005, -6.362263199999973],
              [106.65858480000009, -6.358821399999954],
              [106.66983050000005, -6.358408],
              [106.71138020000006, -6.360186099999964],
              [106.75943010000009, -6.360786899999937],
              [106.76967640000004, -6.356535899999926],
              [106.77325460000009, -6.347215699999936],
              [106.77335380000005, -6.340148499999941],
              [106.77896140000007, -6.324610699999937],
              [106.77989980000007, -6.31654409999993],
              [106.77684040000008, -6.315314299999955],
              [106.77485680000007, -6.304646499999933],
              [106.76591060000004, -6.288837099999967],
              [106.76010290000005, -6.286402599999974],
              [106.76221250000003, -6.277862899999946],
              [106.75158710000005, -6.274566199999981],
              [106.75424010000006, -6.254116],
              [106.74667940000006, -6.247205199999939],
              [106.74861160000006, -6.240953899999965],
              [106.74391940000004, -6.236242299999958],
              [106.74430180000007, -6.229173199999934],
              [106.73643510000005, -6.223883199999932],
              [106.71823040000004, -6.224172299999964],
              [106.72016930000007, -6.209604299999967],
              [106.72439580000008, -6.207603099999972],
              [106.72405090000007, -6.191571899999929],
              [106.71009090000007, -6.181756],
              [106.69351220000004, -6.173670299999969],
              [106.68944570000008, -6.173135799999955],
              [106.68746210000006, -6.147720799999945],
              [106.68621080000008, -6.14306449999998],
              [106.69126150000005, -6.137696299999959],
              [106.68708820000006, -6.128545299999928],
              [106.68907190000004, -6.11447759999993],
              [106.687258, -6.097111499999926],
              [106.69422990000004, -6.09558119999997],
              [106.71137910000004, -6.09595],
              [106.72465820000008, -6.089184499999931]
            ]
          ],
          [
            [
              [106.271021, -5.807418299999938],
              [106.25478980000008, -5.811108],
              [106.28223430000008, -5.81652269999995],
              [106.29191030000004, -5.815598699999953],
              [106.29163580000005, -5.808928199999968],
              [106.271021, -5.807418299999938]
            ]
          ]
        ]
      },
      "properties": {
        "nama": "Provinsi Banten",
        "nid": 16,
        "type": 1
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [106.77989980000007, -6.31654409999993],
              [106.80892960000006, -6.314516099999935],
              [106.80339070000008, -6.329963699999951],
              [106.79911820000007, -6.333960099999956],
              [106.80001720000007, -6.340271399999949],
              [106.79259510000009, -6.348018199999956],
              [106.792473, -6.359365],
              [106.79468790000004, -6.364917899999966],
              [106.80931110000006, -6.364154799999937],
              [106.81591970000005, -6.356030799999928],
              [106.82734180000006, -6.356854599999963],
              [106.83913440000003, -6.351900099999966],
              [106.83802810000009, -6.341218],
              [106.85478230000007, -6.344559699999934],
              [106.86221330000006, -6.357338],
              [106.87011740000008, -6.352953],
              [106.88269820000005, -6.369637099999977],
              [106.88870260000004, -6.368408699999975],
              [106.89575980000006, -6.370730899999955],
              [106.90197010000009, -6.368856],
              [106.90560170000003, -6.361237599999981],
              [106.914345, -6.36554579999995],
              [106.91774770000006, -6.350202599999932],
              [106.91723660000008, -6.343993],
              [106.92094440000005, -6.330972299999928],
              [106.91921250000007, -6.325126699999942],
              [106.922806, -6.314239599999951],
              [106.921811, -6.30345049999994],
              [106.91922010000008, -6.299148199999934],
              [106.91188830000004, -6.298308],
              [106.90849320000007, -6.282791199999963],
              [106.90982070000007, -6.272276499999975],
              [106.904503, -6.269785],
              [106.90435040000006, -6.262069299999951],
              [106.90992750000004, -6.257999499999926],
              [106.91572590000004, -6.258374299999957],
              [106.92211170000007, -6.25388339999995],
              [106.94388760000004, -6.252337799999964],
              [106.94355810000008, -6.238643499999966],
              [106.94862380000006, -6.222211],
              [106.955766, -6.219511199999943],
              [106.95904560000008, -6.212894099999971],
              [106.966202, -6.206918299999927],
              [106.97176380000008, -6.180959299999927],
              [106.97096270000009, -6.162380299999938],
              [106.97216980000007, -6.144468599999925],
              [106.97071030000006, -6.139853799999969],
              [106.96965810000006, -6.120590299999947],
              [106.97039180000007, -6.10457839999998],
              [106.96604450000007, -6.093733099999952],
              [106.95904660000008, -6.093828899999949],
              [106.94033920000004, -6.098151899999948],
              [106.93838340000008, -6.096011799999928],
              [106.91876580000007, -6.099112399999967],
              [106.88415, -6.09644],
              [106.87867570000009, -6.102348799999959],
              [106.87282930000003, -6.104380899999967],
              [106.85384, -6.11837],
              [106.84351520000007, -6.12149],
              [106.83067840000007, -6.121015199999931],
              [106.82756050000006, -6.116326099999981],
              [106.8172, -6.1149],
              [106.81647250000009, -6.119910299999958],
              [106.80957, -6.12023],
              [106.80440340000007, -6.101947099999961],
              [106.80055, -6.10842],
              [106.79604730000005, -6.10888719999997],
              [106.79713160000006, -6.09509509999998],
              [106.79090920000004, -6.093941499999971],
              [106.79034380000007, -6.108044],
              [106.78249, -6.10859],
              [106.76889910000006, -6.102579],
              [106.75509450000004, -6.103459599999951],
              [106.75000620000009, -6.099438099999929],
              [106.74499620000006, -6.10193709999993],
              [106.73296270000009, -6.098155099999929],
              [106.72465820000008, -6.089184499999931],
              [106.71137910000004, -6.09595],
              [106.69422990000004, -6.09558119999997],
              [106.687258, -6.097111499999926],
              [106.68907190000004, -6.11447759999993],
              [106.68708820000006, -6.128545299999928],
              [106.69126150000005, -6.137696299999959],
              [106.68621080000008, -6.14306449999998],
              [106.68746210000006, -6.147720799999945],
              [106.68944570000008, -6.173135799999955],
              [106.69351220000004, -6.173670299999969],
              [106.71009090000007, -6.181756],
              [106.72405090000007, -6.191571899999929],
              [106.72439580000008, -6.207603099999972],
              [106.72016930000007, -6.209604299999967],
              [106.71823040000004, -6.224172299999964],
              [106.73643510000005, -6.223883199999932],
              [106.74430180000007, -6.229173199999934],
              [106.74391940000004, -6.236242299999958],
              [106.74861160000006, -6.240953899999965],
              [106.74667940000006, -6.247205199999939],
              [106.75424010000006, -6.254116],
              [106.75158710000005, -6.274566199999981],
              [106.76221250000003, -6.277862899999946],
              [106.76010290000005, -6.286402599999974],
              [106.76591060000004, -6.288837099999967],
              [106.77485680000007, -6.304646499999933],
              [106.77684040000008, -6.315314299999955],
              [106.77989980000007, -6.31654409999993]
            ]
          ],
          [
            [
              [106.62987530000004, -5.853813299999956],
              [106.61504330000008, -5.856408099999953],
              [106.61569420000006, -5.860548399999971],
              [106.62987530000004, -5.853813299999956]
            ]
          ]
        ]
      },
      "properties": {
        "nama": "Provinsi DKI Jakarta",
        "nid": 11,
        "type": 1
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [121.52008770000009, 0.490160400000036],
              [121.51571710000007, 0.485483600000066],
              [121.52243980000003, 0.480700600000034],
              [121.52600890000008, 0.483967],
              [121.52008770000009, 0.490160400000036]
            ]
          ],
          [
            [
              [122.52992150000011, 0.500800200000072],
              [122.52130280000006, 0.496518300000048],
              [122.525882, 0.48996870000002],
              [122.5347643, 0.492204500000071],
              [122.53427120000003, 0.499366500000065],
              [122.52992150000011, 0.500800200000072]
            ]
          ],
          [
            [
              [121.6940608000001, 0.512638300000049],
              [121.68327890000012, 0.509674],
              [121.67991920000009, 0.501682200000062],
              [121.68089140000006, 0.496813300000042],
              [121.687969, 0.487904900000046],
              [121.69955420000008, 0.484122800000023],
              [121.70708980000006, 0.484613700000068],
              [121.70947820000004, 0.489215400000035],
              [121.70695560000001, 0.499260800000059],
              [121.70223350000003, 0.506922800000041],
              [121.6940608000001, 0.512638300000049]
            ]
          ],
          [
            [
              [122.8577729000001, 0.899573400000065],
              [122.86324990000003, 0.891601],
              [122.868815, 0.893666200000041],
              [122.8777411000001, 0.892636300000049],
              [122.88221740000006, 0.887600900000052],
              [122.88360780000005, 0.880256400000064],
              [122.87840140000003, 0.869915200000037],
              [122.88122610000005, 0.860286200000075],
              [122.8948501000001, 0.853970500000059],
              [122.89784250000002, 0.866449500000044],
              [122.88942830000008, 0.874335700000074],
              [122.89334340000005, 0.886659200000054],
              [122.88717170000007, 0.889828900000055],
              [122.88340090000008, 0.896614600000021],
              [122.8771316000001, 0.897574700000064],
              [122.86978310000006, 0.893891600000075],
              [122.86517960000003, 0.89892640000005],
              [122.8577729000001, 0.899573400000065]
            ]
          ],
          [
            [
              [122.77159110000002, 0.913531100000057],
              [122.75870290000012, 0.910696400000063],
              [122.75624970000001, 0.905545300000028],
              [122.7679657000001, 0.896458500000051],
              [122.77191190000008, 0.889429],
              [122.78442260000008, 0.881529],
              [122.79033820000006, 0.873974700000076],
              [122.80695520000006, 0.874923400000057],
              [122.80838670000003, 0.888061700000037],
              [122.79870330000006, 0.896635],
              [122.78832360000001, 0.903185600000029],
              [122.7760985000001, 0.900688400000035],
              [122.77540740000006, 0.911542600000075],
              [122.77159110000002, 0.913531100000057]
            ]
          ],
          [
            [
              [122.33585820000008, 1.040515200000073],
              [122.32883650000008, 1.036847],
              [122.33043, 1.021216],
              [122.32008530000007, 1.020629800000052],
              [122.315775, 1.00672],
              [122.3041885, 1.00507360000006],
              [122.2926136000001, 1.007010600000058],
              [122.2878263, 1.004322800000068],
              [122.27971020000007, 1.005463900000052],
              [122.2617302000001, 1.012405400000034],
              [122.25198280000006, 1.001580600000068],
              [122.2471766000001, 1.007678500000054],
              [122.23821310000005, 1.006933900000035],
              [122.2370886000001, 0.993618800000036],
              [122.2271181000001, 0.993229800000051],
              [122.22023420000005, 1.00168240000005],
              [122.22296110000002, 1.011097600000028],
              [122.2280909000001, 1.01632440000003],
              [122.22154290000003, 1.025283400000035],
              [122.21266820000005, 1.01929610000002],
              [122.19913430000008, 1.027836],
              [122.19313550000004, 1.038097],
              [122.17552820000003, 1.03627380000006],
              [122.17529660000002, 1.030174400000021],
              [122.17031290000011, 1.027833900000076],
              [122.16062750000003, 1.029870900000049],
              [122.15631960000007, 1.025601700000038],
              [122.14047190000008, 1.018465500000048],
              [122.13629030000004, 1.019944100000032],
              [122.12550980000003, 1.018218700000034],
              [122.1215625000001, 1.012065300000074],
              [122.11312240000007, 1.013075400000048],
              [122.09269560000007, 0.999813300000028],
              [122.08436060000008, 0.998579],
              [122.07675090000009, 1.006381600000054],
              [122.05952830000001, 0.996834200000023],
              [122.0566133000001, 0.996973500000024],
              [122.04489250000006, 0.989108600000066],
              [122.04231830000003, 0.989477500000021],
              [122.02512790000003, 0.960260500000061],
              [122.01003980000007, 0.952020400000038],
              [121.99910320000004, 0.942506900000069],
              [121.99448430000007, 0.942505600000061],
              [121.98368870000002, 0.927548200000047],
              [121.98650160000011, 0.919063900000026],
              [121.98515470000007, 0.910456100000033],
              [121.9757843000001, 0.90423050000004],
              [121.96493710000004, 0.907305200000053],
              [121.9546616, 0.907719600000064],
              [121.93816390000006, 0.927796500000056],
              [121.93203970000002, 0.929568300000028],
              [121.922232, 0.927426500000024],
              [121.91825990000007, 0.933034200000066],
              [121.91597380000007, 0.941797300000076],
              [121.90209470000002, 0.963876500000026],
              [121.8939974000001, 0.968829500000027],
              [121.8914277, 0.973462400000074],
              [121.88322630000005, 0.979354300000068],
              [121.86762250000004, 0.986152900000036],
              [121.86289840000006, 0.990793900000028],
              [121.85070240000005, 0.992406800000026],
              [121.84669010000005, 0.985767500000065],
              [121.84887160000005, 0.980238900000074],
              [121.83738340000002, 0.973842300000058],
              [121.82937410000011, 0.960186100000044],
              [121.82117430000005, 0.96164390000007],
              [121.81190650000008, 0.950236500000074],
              [121.7957262000001, 0.948772100000042],
              [121.7855657, 0.939730600000075],
              [121.76168610000002, 0.93222570000006],
              [121.74488510000003, 0.931446700000038],
              [121.73537080000006, 0.92966320000005],
              [121.72873130000005, 0.924826400000029],
              [121.70521360000009, 0.913847300000043],
              [121.70152650000011, 0.907050800000036],
              [121.6846488000001, 0.900542300000041],
              [121.67336080000007, 0.903459200000043],
              [121.659033, 0.89206530000007],
              [121.65456350000011, 0.891227700000059],
              [121.6457888000001, 0.895172100000025],
              [121.63518950000002, 0.890986600000076],
              [121.62328660000003, 0.88214720000002],
              [121.61038170000006, 0.882240900000056],
              [121.60390350000011, 0.873348600000043],
              [121.58790150000004, 0.873244500000055],
              [121.57275520000007, 0.870763700000055],
              [121.558103, 0.877815600000019],
              [121.5477552000001, 0.878472500000044],
              [121.53694810000002, 0.875907200000029],
              [121.5346128000001, 0.868014100000039],
              [121.53570710000008, 0.860122300000057],
              [121.53302510000003, 0.853704100000073],
              [121.51021690000005, 0.84784250000007],
              [121.49368780000009, 0.839508200000068],
              [121.48255410000002, 0.839661400000068],
              [121.47658920000003, 0.837621400000046],
              [121.46608830000002, 0.847408300000041],
              [121.46230650000007, 0.845039300000053],
              [121.45375750000005, 0.847582100000068],
              [121.43716020000011, 0.847026500000027],
              [121.43318660000011, 0.84440660000007],
              [121.41121160000012, 0.844930100000056],
              [121.40130810000005, 0.843443600000057],
              [121.39442670000005, 0.85104780000006],
              [121.38429930000007, 0.854542800000047],
              [121.37497, 0.853210900000022],
              [121.36626020000006, 0.849282],
              [121.35896140000011, 0.853415],
              [121.34702880000009, 0.848271500000067],
              [121.35190420000004, 0.840686100000028],
              [121.35318710000001, 0.83305480000007],
              [121.3504871, 0.821345600000029],
              [121.36142990000008, 0.814949500000068],
              [121.36450180000008, 0.80680730000006],
              [121.35334090000003, 0.795310800000038],
              [121.34545870000011, 0.79319780000003],
              [121.33711540000002, 0.794988400000022],
              [121.32530280000003, 0.791519600000072],
              [121.32006640000009, 0.785537800000043],
              [121.31491580000011, 0.785137],
              [121.303248, 0.795529],
              [121.29270870000005, 0.794933400000048],
              [121.28918550000003, 0.79818750000004],
              [121.27015260000007, 0.795794200000046],
              [121.245851, 0.801192400000048],
              [121.23754890000009, 0.801185500000031],
              [121.2336216000001, 0.796984700000053],
              [121.21526830000005, 0.791471700000045],
              [121.21696730000008, 0.78294580000005],
              [121.21356910000009, 0.773352700000032],
              [121.20921160000012, 0.768356800000049],
              [121.20091410000009, 0.766099700000041],
              [121.19261260000007, 0.766883300000075],
              [121.18643070000007, 0.757249400000035],
              [121.18206650000002, 0.743211600000052],
              [121.18741580000005, 0.72212090000005],
              [121.18056610000008, 0.713750600000026],
              [121.17862130000003, 0.700680100000056],
              [121.17153460000009, 0.683528100000046],
              [121.16124520000005, 0.678653300000065],
              [121.1664005, 0.668671400000051],
              [121.17325460000006, 0.66539350000005],
              [121.17918490000011, 0.655097900000044],
              [121.19435930000009, 0.645357100000069],
              [121.21640660000003, 0.638765],
              [121.2210467000001, 0.639114],
              [121.22799210000005, 0.64539220000006],
              [121.23182830000007, 0.641484500000047],
              [121.23135820000005, 0.633924800000045],
              [121.22753160000002, 0.62916910000007],
              [121.23510870000007, 0.608197100000041],
              [121.23939210000003, 0.602213500000062],
              [121.24831660000007, 0.601461200000074],
              [121.26180440000007, 0.589909900000066],
              [121.271601, 0.586629],
              [121.27450710000005, 0.583033200000045],
              [121.29493420000006, 0.580105200000048],
              [121.30038390000004, 0.575294800000052],
              [121.302703, 0.56827480000004],
              [121.30763150000007, 0.56857640000004],
              [121.31232860000011, 0.574320700000044],
              [121.31877760000009, 0.576769800000022],
              [121.3371409, 0.574420700000076],
              [121.35023810000007, 0.555633800000066],
              [121.3440213, 0.546973200000025],
              [121.34766600000012, 0.53766],
              [121.34210360000009, 0.526557400000058],
              [121.33537880000006, 0.526418900000067],
              [121.3331581000001, 0.521678100000031],
              [121.33369370000003, 0.511689700000034],
              [121.34371370000008, 0.501037800000063],
              [121.34672160000002, 0.494628600000055],
              [121.35158720000004, 0.492570300000068],
              [121.34686280000005, 0.485235700000032],
              [121.34243170000002, 0.48349],
              [121.34049020000009, 0.474287600000025],
              [121.3503257000001, 0.475751100000025],
              [121.36079020000011, 0.471608900000035],
              [121.3716104, 0.476149100000043],
              [121.39867790000005, 0.478761600000041],
              [121.40059510000003, 0.484668900000031],
              [121.40876380000009, 0.47260760000006],
              [121.41195710000011, 0.475486700000033],
              [121.4133256, 0.486286100000029],
              [121.42357920000006, 0.481657600000062],
              [121.43182170000011, 0.490322900000024],
              [121.43734670000003, 0.491640700000062],
              [121.43081360000008, 0.475738200000023],
              [121.43350340000006, 0.471239700000069],
              [121.4430569000001, 0.464700700000037],
              [121.44972970000003, 0.466952100000071],
              [121.4562433000001, 0.473414500000047],
              [121.46583730000009, 0.476224700000046],
              [121.47077460000003, 0.481426],
              [121.48507990000007, 0.484286],
              [121.49338640000008, 0.479008400000055],
              [121.50171260000002, 0.488035800000034],
              [121.507989, 0.486919700000044],
              [121.51371230000007, 0.49439620000004],
              [121.520108, 0.498711600000036],
              [121.51540780000005, 0.512897400000043],
              [121.5192545000001, 0.51887540000007],
              [121.51311250000003, 0.52504760000005],
              [121.514966, 0.532506500000068],
              [121.52159540000002, 0.532737700000041],
              [121.52054890000011, 0.541448100000025],
              [121.52607880000005, 0.550918400000057],
              [121.53470730000004, 0.54617810000002],
              [121.54318180000007, 0.545086],
              [121.55562710000004, 0.537458900000047],
              [121.5697828000001, 0.534927300000049],
              [121.59935810000002, 0.537944],
              [121.5972686, 0.53203730000007],
              [121.60266390000004, 0.527861100000052],
              [121.60739890000002, 0.534744400000022],
              [121.60319850000008, 0.537831500000038],
              [121.60174080000002, 0.545653600000037],
              [121.610373, 0.544781500000056],
              [121.61982350000005, 0.547902200000067],
              [121.61780190000002, 0.523348300000066],
              [121.62502050000012, 0.523645],
              [121.6233989000001, 0.535211200000049],
              [121.63215080000009, 0.533207],
              [121.6371610000001, 0.524573800000042],
              [121.6428241000001, 0.528106100000059],
              [121.6316948000001, 0.540700300000026],
              [121.640981, 0.541528400000061],
              [121.64135680000004, 0.53663830000005],
              [121.6600165000001, 0.531925900000033],
              [121.6661319000001, 0.52894770000006],
              [121.66660890000003, 0.523109200000022],
              [121.6717000000001, 0.520245100000068],
              [121.6839331000001, 0.519261400000062],
              [121.689054, 0.514113900000041],
              [121.6944085, 0.520161900000062],
              [121.7020404000001, 0.522295700000029],
              [121.7078570000001, 0.518059500000049],
              [121.71102030000009, 0.521852500000023],
              [121.72156590000009, 0.521974800000066],
              [121.7149889000001, 0.511368],
              [121.71961880000003, 0.50559190000007],
              [121.731296, 0.498505800000032],
              [121.74527910000006, 0.475441400000022],
              [121.75665760000004, 0.46299010000007],
              [121.76154030000009, 0.452675800000065],
              [121.76727130000006, 0.449189],
              [121.77014720000011, 0.443373400000041],
              [121.79138620000003, 0.430161100000021],
              [121.80145870000001, 0.416403100000025],
              [121.8064098000001, 0.412673300000051],
              [121.81436910000002, 0.421744500000045],
              [121.83406080000009, 0.423640100000057],
              [121.84579010000004, 0.430987100000038],
              [121.85601090000011, 0.435129400000051],
              [121.85888970000008, 0.432790900000043],
              [121.86803370000007, 0.441222500000038],
              [121.8847866000001, 0.438063200000045],
              [121.89548800000011, 0.443081200000051],
              [121.90237, 0.444172200000025],
              [121.91491750000012, 0.442718300000024],
              [121.91766280000002, 0.446725900000047],
              [121.93138620000002, 0.447227300000065],
              [121.93797840000002, 0.444698800000026],
              [121.9416864000001, 0.447704400000021],
              [121.9617376000001, 0.451917600000058],
              [121.9708260000001, 0.458962400000075],
              [121.9749594000001, 0.459555700000067],
              [121.9793711000001, 0.452400500000067],
              [121.98760410000011, 0.461300300000062],
              [122.00018470000009, 0.459437800000046],
              [122.00454120000006, 0.464178100000026],
              [122.01636740000004, 0.468048600000031],
              [122.02900150000005, 0.467901900000072],
              [122.04893350000009, 0.477505800000074],
              [122.06702220000011, 0.47969],
              [122.08145040000011, 0.476942500000064],
              [122.09285010000008, 0.484909200000061],
              [122.10375330000011, 0.487885500000061],
              [122.11040890000004, 0.485520100000031],
              [122.11730610000006, 0.489090200000021],
              [122.12008960000003, 0.495295300000066],
              [122.143361, 0.48439],
              [122.1478886000001, 0.480524800000069],
              [122.14787070000011, 0.470779500000049],
              [122.16569470000002, 0.470909900000038],
              [122.19188190000011, 0.479701500000033],
              [122.20208150000008, 0.474038800000073],
              [122.21849940000004, 0.483796900000073],
              [122.22912580000002, 0.481182900000022],
              [122.23589360000005, 0.48508750000002],
              [122.24293820000003, 0.482385300000033],
              [122.25616270000012, 0.485743700000057],
              [122.2611839000001, 0.479203700000028],
              [122.26853620000009, 0.474204900000075],
              [122.275115, 0.473514600000044],
              [122.29040510000004, 0.477983600000073],
              [122.29389760000004, 0.484883900000057],
              [122.30057430000011, 0.484293200000025],
              [122.30350130000011, 0.489697],
              [122.3176992000001, 0.494897300000048],
              [122.31873840000003, 0.502139500000055],
              [122.32712560000004, 0.49287460000005],
              [122.32905260000007, 0.503744400000073],
              [122.33312310000008, 0.505331100000035],
              [122.34203460000003, 0.493329600000038],
              [122.34702850000008, 0.496783100000073],
              [122.34870160000003, 0.504776700000036],
              [122.35593470000003, 0.510488400000042],
              [122.36545390000003, 0.502575500000034],
              [122.37424930000009, 0.513118700000064],
              [122.38154730000008, 0.516905800000075],
              [122.3863033, 0.514106300000037],
              [122.3926775000001, 0.518550600000026],
              [122.40000290000012, 0.519963],
              [122.40634690000002, 0.517039500000067],
              [122.4053808000001, 0.509502800000064],
              [122.41742890000012, 0.511444200000028],
              [122.421079, 0.50466670000003],
              [122.42850670000007, 0.50462],
              [122.43575120000003, 0.499823900000024],
              [122.44276850000006, 0.514195900000061],
              [122.448405, 0.51571180000002],
              [122.452174, 0.508012],
              [122.4694316, 0.505915200000061],
              [122.48149490000003, 0.501484500000061],
              [122.4925085000001, 0.501031500000067],
              [122.5064738000001, 0.507417],
              [122.51072720000002, 0.503203],
              [122.5195079, 0.501878400000066],
              [122.5411779000001, 0.505598400000054],
              [122.54346260000011, 0.50292410000003],
              [122.54034070000012, 0.494631],
              [122.54950720000011, 0.490853500000071],
              [122.55788240000004, 0.500671200000056],
              [122.55153860000007, 0.51316410000004],
              [122.56682260000002, 0.513764800000047],
              [122.56874770000002, 0.508325800000023],
              [122.56065650000005, 0.502794700000038],
              [122.56940980000002, 0.496338600000058],
              [122.58287640000003, 0.493975500000033],
              [122.59191380000004, 0.498090700000034],
              [122.61043130000007, 0.488039],
              [122.61744130000011, 0.49147540000007],
              [122.62108420000004, 0.487632400000052],
              [122.63113550000003, 0.487302700000043],
              [122.639067, 0.482580700000028],
              [122.642243, 0.476791800000058],
              [122.653522, 0.486155200000042],
              [122.65535950000003, 0.494080200000042],
              [122.6503249000001, 0.502370600000063],
              [122.64707770000007, 0.514991],
              [122.659772, 0.515297700000076],
              [122.67296040000008, 0.512132300000076],
              [122.6761203000001, 0.507568100000071],
              [122.68487280000011, 0.504374900000073],
              [122.69087, 0.490189300000054],
              [122.69273730000009, 0.473180300000024],
              [122.69984840000006, 0.480835100000036],
              [122.70653850000008, 0.478854800000022],
              [122.71017480000012, 0.481638300000043],
              [122.7260351000001, 0.482680900000048],
              [122.73756570000012, 0.486328700000058],
              [122.742536, 0.480680600000028],
              [122.74993540000003, 0.48472460000005],
              [122.77054240000007, 0.483400800000027],
              [122.7751846000001, 0.488349500000027],
              [122.78540360000011, 0.494336100000055],
              [122.80412950000004, 0.494627100000059],
              [122.815664, 0.488881100000071],
              [122.82738510000001, 0.495719300000076],
              [122.84691840000005, 0.492107200000021],
              [122.88252150000005, 0.488645100000042],
              [122.8883813000001, 0.48575180000006],
              [122.89977310000006, 0.492100700000037],
              [122.90723960000003, 0.492261700000029],
              [122.91201450000005, 0.487369300000069],
              [122.92015690000005, 0.49175850000006],
              [122.92663630000004, 0.486336100000074],
              [122.94011730000011, 0.489340900000059],
              [122.95772270000009, 0.491006800000036],
              [122.9617502000001, 0.49324480000007],
              [122.98895030000006, 0.48480840000002],
              [122.99988410000003, 0.495322100000067],
              [123.00905730000011, 0.490111100000036],
              [123.01832490000004, 0.496681900000056],
              [123.02350360000003, 0.493628100000024],
              [123.03517450000004, 0.497783200000072],
              [123.04969170000004, 0.495961],
              [123.05137430000002, 0.503702900000064],
              [123.05874790000007, 0.507009800000048],
              [123.06298350000009, 0.505312300000071],
              [123.07882630000006, 0.488557600000036],
              [123.08563250000009, 0.486474],
              [123.08690540000009, 0.478261500000031],
              [123.10001880000004, 0.475433],
              [123.10648640000011, 0.466939800000034],
              [123.1168752000001, 0.461106600000051],
              [123.12568110000007, 0.448666200000048],
              [123.12666030000003, 0.441765900000064],
              [123.13266020000003, 0.438842400000055],
              [123.13305030000004, 0.433647800000074],
              [123.14058350000005, 0.422830600000054],
              [123.14587890000007, 0.423089700000048],
              [123.14516530000003, 0.415051700000049],
              [123.1514843000001, 0.414215600000034],
              [123.15235220000011, 0.408062700000073],
              [123.16175270000008, 0.399963700000058],
              [123.176689, 0.399061300000028],
              [123.19432250000011, 0.393921900000066],
              [123.2072075000001, 0.378118500000028],
              [123.21669220000001, 0.372030600000073],
              [123.216267, 0.364842900000042],
              [123.22205620000011, 0.360641300000054],
              [123.22774330000004, 0.34897170000005],
              [123.246419, 0.341794300000061],
              [123.25842640000008, 0.331275600000026],
              [123.26639770000008, 0.32713780000006],
              [123.26756520000004, 0.322302300000047],
              [123.27848330000006, 0.323517300000049],
              [123.27874530000008, 0.319296500000064],
              [123.29201680000006, 0.315717200000051],
              [123.29583180000009, 0.320463200000063],
              [123.30065840000009, 0.317222900000047],
              [123.314206, 0.313381800000059],
              [123.32759840000006, 0.312958700000024],
              [123.34540450000009, 0.30627080000005],
              [123.354071, 0.309471700000074],
              [123.35934820000011, 0.314493600000048],
              [123.37116440000011, 0.312099500000045],
              [123.38352920000011, 0.312731700000029],
              [123.39986940000006, 0.315413900000067],
              [123.41703660000007, 0.311930200000063],
              [123.43844160000003, 0.319015200000024],
              [123.44380010000009, 0.327496800000063],
              [123.45916690000001, 0.337626400000033],
              [123.469392, 0.322739300000023],
              [123.47702270000002, 0.31697390000005],
              [123.48229070000002, 0.321854400000063],
              [123.48102380000012, 0.336863200000039],
              [123.4836567000001, 0.339707800000042],
              [123.48004950000006, 0.352754800000071],
              [123.48473020000006, 0.356089800000063],
              [123.49418870000011, 0.357364500000074],
              [123.49156470000003, 0.365650900000048],
              [123.49595290000002, 0.369574400000033],
              [123.49790380000002, 0.382130600000039],
              [123.51102480000009, 0.396343600000023],
              [123.530017, 0.408479300000067],
              [123.53482340000005, 0.418431],
              [123.541707, 0.428019500000062],
              [123.5498970000001, 0.432995800000072],
              [123.5474028000001, 0.442905100000075],
              [123.53940670000009, 0.445070700000031],
              [123.52591710000002, 0.453608200000076],
              [123.52475960000004, 0.457053900000062],
              [123.52299850000009, 0.490445100000045],
              [123.54627060000007, 0.497732300000052],
              [123.5522946000001, 0.51071410000003],
              [123.54732260000003, 0.523015900000075],
              [123.53163470000004, 0.533519900000044],
              [123.5275775, 0.544855700000028],
              [123.53353230000005, 0.565324900000064],
              [123.53337270000009, 0.576874500000031],
              [123.520704, 0.584323],
              [123.50911220000012, 0.584424600000034],
              [123.503403, 0.591437900000074],
              [123.48778740000012, 0.601424100000031],
              [123.49219340000002, 0.619130600000062],
              [123.486165, 0.626511900000025],
              [123.4768825000001, 0.62969570000007],
              [123.4625191, 0.632171300000039],
              [123.44130960000007, 0.639328800000044],
              [123.42819120000001, 0.641352],
              [123.42335710000009, 0.639072800000065],
              [123.3991152000001, 0.640883200000076],
              [123.38312110000004, 0.640074800000036],
              [123.37670470000012, 0.645193800000072],
              [123.36351350000007, 0.651884700000039],
              [123.35944830000005, 0.651365200000043],
              [123.35453030000008, 0.657261900000037],
              [123.35426510000002, 0.663096100000075],
              [123.34253500000011, 0.675601300000039],
              [123.33896960000004, 0.683542800000055],
              [123.33024290000003, 0.687242500000025],
              [123.322393, 0.698537100000067],
              [123.3024319000001, 0.698029],
              [123.28755120000005, 0.695923700000037],
              [123.282204, 0.699107],
              [123.273648, 0.698161200000072],
              [123.25204860000008, 0.717571400000054],
              [123.247145, 0.716973900000028],
              [123.249138, 0.728337700000054],
              [123.24621910000008, 0.733157600000027],
              [123.24765350000007, 0.74437910000006],
              [123.25330480000002, 0.756993600000044],
              [123.26855570000009, 0.768115900000055],
              [123.2698243000001, 0.777222900000027],
              [123.27416640000001, 0.789428300000054],
              [123.267491, 0.796034200000065],
              [123.269562, 0.80365560000007],
              [123.26049440000008, 0.809687800000063],
              [123.25000000000011, 0.819392400000027],
              [123.24274370000012, 0.831137300000023],
              [123.23296130000006, 0.833119900000042],
              [123.226157, 0.842824300000075],
              [123.21986040000002, 0.847213200000056],
              [123.21089370000004, 0.849591100000055],
              [123.20607390000009, 0.856844300000034],
              [123.19856620000007, 0.856339500000047],
              [123.19191280000007, 0.862245300000041],
              [123.184907, 0.860684100000071],
              [123.17750200000012, 0.862413],
              [123.17572230000007, 0.868360700000039],
              [123.16697030000012, 0.869724400000052],
              [123.16569250000009, 0.873077200000068],
              [123.15173640000012, 0.871036200000049],
              [123.15048150000007, 0.876731300000074],
              [123.14259890000005, 0.876508700000045],
              [123.13074460000007, 0.883888700000057],
              [123.12672850000001, 0.890823900000044],
              [123.1209917000001, 0.889895700000068],
              [123.11985, 0.903691500000036],
              [123.11537740000006, 0.905850300000054],
              [123.11334670000008, 0.914989900000023],
              [123.11412030000008, 0.926687400000048],
              [123.09278490000008, 0.928782500000068],
              [123.09369450000008, 0.922649100000058],
              [123.09853440000006, 0.92087250000003],
              [123.09613430000002, 0.913376500000027],
              [123.0852493000001, 0.911316900000031],
              [123.08023760000003, 0.915156800000034],
              [123.08549620000008, 0.923257700000022],
              [123.07754180000006, 0.923233500000038],
              [123.0739721000001, 0.91662290000005],
              [123.058935, 0.918930900000021],
              [123.05233140000007, 0.932994],
              [123.04697110000006, 0.939600800000051],
              [123.04170740000006, 0.938627900000029],
              [123.03531970000006, 0.931395800000075],
              [123.01997740000002, 0.93306640000003],
              [123.0098703000001, 0.94479380000007],
              [122.99733120000008, 0.94478],
              [122.99888540000006, 0.953467700000033],
              [122.99467060000006, 0.961981800000046],
              [122.98616240000001, 0.967902700000025],
              [122.97613110000009, 0.965563300000042],
              [122.96858680000003, 0.966728200000034],
              [122.95518650000008, 0.963253900000041],
              [122.94810670000004, 0.964922],
              [122.9389288000001, 0.958366200000057],
              [122.94296020000002, 0.951015100000063],
              [122.9403784000001, 0.944062700000075],
              [122.94727530000011, 0.938501700000074],
              [122.95586970000011, 0.935754300000042],
              [122.96758110000007, 0.937584400000048],
              [122.9620877000001, 0.92686580000003],
              [122.9506070000001, 0.916795800000045],
              [122.94617770000002, 0.910753300000067],
              [122.95126160000007, 0.894733300000041],
              [122.9407758000001, 0.892058400000053],
              [122.93561660000012, 0.888430700000072],
              [122.92367060000004, 0.875012],
              [122.92191170000001, 0.861368500000026],
              [122.91367880000007, 0.855457100000024],
              [122.8982042, 0.851231900000073],
              [122.89491650000002, 0.843586900000048],
              [122.88961, 0.842718],
              [122.88017090000005, 0.830814700000076],
              [122.87183330000005, 0.826710500000047],
              [122.86105870000006, 0.827584600000023],
              [122.85838060000003, 0.821072],
              [122.86059750000004, 0.813883200000021],
              [122.85299440000006, 0.809155800000042],
              [122.8491527000001, 0.80059330000006],
              [122.84411280000006, 0.803036],
              [122.83912740000005, 0.82230480000004],
              [122.83180930000003, 0.818365],
              [122.82515690000002, 0.823025900000061],
              [122.82598880000012, 0.833839700000055],
              [122.81652860000008, 0.831664600000067],
              [122.80847210000002, 0.835080500000061],
              [122.79664530000002, 0.834563600000024],
              [122.79230840000002, 0.850255900000036],
              [122.80094430000008, 0.851779200000067],
              [122.79478270000004, 0.85981],
              [122.788035, 0.860303400000021],
              [122.78329610000003, 0.870526200000029],
              [122.77190930000006, 0.879246200000068],
              [122.76640910000003, 0.874100800000065],
              [122.76852830000007, 0.867392500000051],
              [122.76632020000011, 0.861834200000033],
              [122.75222340000005, 0.869021300000043],
              [122.74799050000001, 0.874810600000046],
              [122.73935630000005, 0.880627100000027],
              [122.73209370000006, 0.870691900000054],
              [122.72754970000005, 0.873626600000023],
              [122.72039460000008, 0.866623700000048],
              [122.71354730000007, 0.867182300000024],
              [122.71354910000002, 0.876585500000033],
              [122.72018770000011, 0.886271300000033],
              [122.71319460000007, 0.89160830000003],
              [122.700989, 0.888498100000049],
              [122.69060410000009, 0.896754100000067],
              [122.68229770000005, 0.899740800000075],
              [122.67884640000011, 0.912235700000053],
              [122.6743054000001, 0.914054400000055],
              [122.67074790000004, 0.923370200000022],
              [122.66398860000004, 0.934523400000046],
              [122.65877380000006, 0.933248500000047],
              [122.6550026000001, 0.94430310000007],
              [122.64958220000005, 0.94881330000004],
              [122.63903770000002, 0.941918600000065],
              [122.6337019, 0.950460700000065],
              [122.62304560000007, 0.953779600000075],
              [122.61859160000006, 0.961066900000048],
              [122.61450290000005, 0.954476400000033],
              [122.61432880000007, 0.945889100000045],
              [122.60927170000002, 0.944421900000066],
              [122.60211570000001, 0.951856500000019],
              [122.58657290000008, 0.952303100000051],
              [122.574744, 0.958712600000069],
              [122.5641687000001, 0.958882700000061],
              [122.55119610000008, 0.966103300000043],
              [122.5516311, 0.970049600000038],
              [122.54265320000002, 0.971785500000067],
              [122.53905740000005, 0.969735],
              [122.5172765000001, 0.979118800000037],
              [122.51195450000012, 0.979910200000063],
              [122.50330540000004, 0.985835800000075],
              [122.49355090000006, 0.987467600000059],
              [122.48720910000009, 0.991711],
              [122.4823857, 0.999775500000055],
              [122.47726950000003, 0.998902100000066],
              [122.47037120000005, 1.009393300000056],
              [122.470531, 1.014720700000055],
              [122.46166280000011, 1.01781120000004],
              [122.46427910000011, 1.022115700000029],
              [122.45195620000004, 1.031789300000071],
              [122.44017610000003, 1.02278560000002],
              [122.44444520000002, 1.018335300000047],
              [122.44179350000002, 1.007922600000029],
              [122.444584, 1.001660400000048],
              [122.43463650000001, 0.99368590000006],
              [122.42789950000008, 0.99415220000003],
              [122.41598980000003, 0.99136580000004],
              [122.41076890000011, 0.998660200000074],
              [122.40266560000009, 1.003627100000074],
              [122.39579820000006, 1.012469],
              [122.39245890000007, 1.012898200000052],
              [122.38943690000008, 1.003153600000076],
              [122.382646, 1.003457100000048],
              [122.37391280000008, 0.994212400000038],
              [122.36212410000007, 0.997005300000069],
              [122.359768, 1.010095700000022],
              [122.34996860000001, 1.014327700000024],
              [122.34556840000005, 1.021494],
              [122.33602770000005, 1.031196400000056],
              [122.33585820000008, 1.040515200000073]
            ]
          ]
        ]
      },
      "properties": {
        "nama": "Provinsi Gorontalo",
        "nid": 28,
        "type": 1
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [108.44408510000005, -3.327808699999935],
              [108.43802190000008, -3.330478599999935],
              [108.44265870000004, -3.339782599999978],
              [108.44696990000006, -3.332224],
              [108.44408510000005, -3.327808699999935]
            ]
          ],
          [
            [
              [108.39533320000004, -3.291806099999974],
              [108.39150860000007, -3.285126299999945],
              [108.38012160000005, -3.299844099999973],
              [108.38671530000005, -3.304646],
              [108.39283610000007, -3.300943899999936],
              [108.39533320000004, -3.291806099999974]
            ]
          ],
          [
            [
              [108.38762480000008, -3.242615599999965],
              [108.38365790000006, -3.239006499999959],
              [108.37415780000003, -3.245627399999933],
              [108.37342990000008, -3.249728399999981],
              [108.37783260000003, -3.258683899999937],
              [108.38202540000009, -3.262043799999958],
              [108.38899750000007, -3.258791099999939],
              [108.39275370000007, -3.253102199999944],
              [108.38762480000008, -3.242615599999965]
            ]
          ],
          [
            [
              [108.20168320000005, -3.219565099999954],
              [108.19380970000009, -3.219596899999942],
              [108.18741620000009, -3.225667],
              [108.19263480000006, -3.229489799999953],
              [108.19797530000005, -3.227976099999978],
              [108.20168320000005, -3.219565099999954]
            ]
          ],
          [
            [
              [108.286785, -3.207991899999968],
              [108.27577020000007, -3.212540599999954],
              [108.27358110000006, -3.22100039999998],
              [108.28123240000008, -3.224657699999966],
              [108.28691240000006, -3.218899099999931],
              [108.286785, -3.207991899999968]
            ]
          ],
          [
            [
              [108.20617590000006, -3.198879899999952],
              [108.19720470000004, -3.202432899999963],
              [108.18614250000007, -3.211182599999972],
              [108.18932170000005, -3.213901],
              [108.20021410000004, -3.207899699999928],
              [108.20617590000006, -3.198879899999952]
            ]
          ],
          [
            [
              [107.52344110000007, -3.192353299999979],
              [107.51720380000006, -3.197798599999942],
              [107.51957990000005, -3.237796499999945],
              [107.51651070000008, -3.245518799999957],
              [107.51769880000006, -3.253142199999957],
              [107.52453010000005, -3.252746199999933],
              [107.52561920000005, -3.245914899999946],
              [107.53056940000005, -3.238984499999958],
              [107.53621270000008, -3.239875599999948],
              [107.542648, -3.233539299999961],
              [107.54423210000004, -3.221658699999978],
              [107.54829130000007, -3.215025399999945],
              [107.55145940000006, -3.201560699999959],
              [107.53700470000007, -3.200273699999968],
              [107.52680720000006, -3.192650299999968],
              [107.52344110000007, -3.192353299999979]
            ]
          ],
          [
            [
              [108.19094390000004, -3.166320199999973],
              [108.18548890000005, -3.16668],
              [108.17853850000006, -3.175059399999952],
              [108.18336790000006, -3.179379599999947],
              [108.19531550000005, -3.169798499999956],
              [108.19094390000004, -3.166320199999973]
            ]
          ],
          [
            [
              [107.85116330000005, -3.103585499999951],
              [107.84973260000004, -3.098149199999966],
              [107.83986140000007, -3.103871599999934],
              [107.85116330000005, -3.123185],
              [107.85788720000005, -3.12461559999997],
              [107.85645650000004, -3.11174],
              [107.86275130000007, -3.107162],
              [107.85602740000007, -3.10101039999995],
              [107.85116330000005, -3.103585499999951]
            ]
          ],
          [
            [
              [108.25454380000008, -3.04530619999997],
              [108.24319890000004, -3.049414099999979],
              [108.24130690000004, -3.055614899999966],
              [108.23482190000004, -3.064417799999944],
              [108.238423, -3.074015399999951],
              [108.24520550000005, -3.077519399999971],
              [108.25347580000005, -3.067911399999957],
              [108.25537550000007, -3.058684099999937],
              [108.26630840000007, -3.058421299999964],
              [108.27283150000005, -3.054294299999981],
              [108.268498, -3.049121799999966],
              [108.25454380000008, -3.04530619999997]
            ]
          ],
          [
            [
              [107.49945050000008, -3.025717099999952],
              [107.49429620000006, -3.030750199999943],
              [107.48732270000005, -3.031174599999929],
              [107.48738330000003, -3.045849299999929],
              [107.49375040000007, -3.052398299999936],
              [107.50351330000007, -3.041180099999963],
              [107.50430160000008, -3.035540599999933],
              [107.49945050000008, -3.025717099999952]
            ]
          ],
          [
            [
              [107.50848140000005, -3.022018199999934],
              [107.510014, -3.017701799999941],
              [107.50625030000003, -3.002621499999975],
              [107.49965380000003, -2.999335799999926],
              [107.49842340000004, -3.005910899999947],
              [107.50352770000006, -3.017388599999947],
              [107.50848140000005, -3.022018199999934]
            ]
          ],
          [
            [
              [107.23466810000008, -2.986717299999953],
              [107.22825220000004, -2.987545199999943],
              [107.22908010000003, -2.99665159999995],
              [107.24067010000005, -2.99044269999996],
              [107.23466810000008, -2.986717299999953]
            ]
          ],
          [
            [
              [106.69893580000007, -2.978352599999937],
              [106.69425530000007, -2.987762399999951],
              [106.69763990000007, -2.993885499999976],
              [106.70916560000006, -2.993073699999968],
              [106.71335180000005, -2.982005299999969],
              [106.70898670000008, -2.979241399999978],
              [106.69893580000007, -2.978352599999937]
            ]
          ],
          [
            [
              [107.50793010000007, -2.986142599999937],
              [107.50369780000005, -2.972409299999924],
              [107.49946550000004, -2.971113699999933],
              [107.50283410000009, -2.997543899999926],
              [107.50939840000007, -2.997198399999945],
              [107.50793010000007, -2.986142599999937]
            ]
          ],
          [
            [
              [107.53212610000008, -2.968016199999965],
              [107.51992540000003, -2.970552899999973],
              [107.51605980000005, -2.974297699999966],
              [107.51932140000008, -2.987344099999973],
              [107.51883820000006, -2.996162399999946],
              [107.52584450000006, -3.00534319999997],
              [107.53357570000009, -2.986136099999953],
              [107.53140130000008, -2.97840489999993],
              [107.53212610000008, -2.968016199999965]
            ]
          ],
          [
            [
              [107.51509340000007, -2.962097],
              [107.516543, -2.970432099999925],
              [107.52995170000008, -2.966566599999965],
              [107.52234130000005, -2.962097],
              [107.51509340000007, -2.962097]
            ]
          ],
          [
            [
              [106.67370210000007, -2.958612099999925],
              [106.66651910000007, -2.959852699999942],
              [106.67141830000008, -2.96777179999998],
              [106.67797140000005, -2.968091399999935],
              [106.67899210000007, -2.962325499999963],
              [106.67370210000007, -2.958612099999925]
            ]
          ],
          [
            [
              [107.51656740000004, -2.934146099999964],
              [107.51328520000004, -2.938032899999939],
              [107.51751750000005, -2.947361199999932],
              [107.52580930000005, -2.951679799999965],
              [107.53038710000004, -2.947015699999952],
              [107.527364, -2.939242099999944],
              [107.51656740000004, -2.934146099999964]
            ]
          ],
          [
            [
              [107.46900490000007, -2.925426899999934],
              [107.46455110000005, -2.923141899999962],
              [107.43581450000005, -2.934528099999966],
              [107.43225140000004, -2.938943199999926],
              [107.42535770000006, -2.95497679999994],
              [107.42837860000009, -2.960089],
              [107.42795250000006, -2.970584499999973],
              [107.43360690000009, -2.974070099999949],
              [107.43229020000007, -2.979337199999975],
              [107.43647290000007, -2.984139499999969],
              [107.44476080000004, -2.984759199999928],
              [107.456147, -2.978872399999943],
              [107.46455110000005, -2.967215099999976],
              [107.46431880000006, -2.956680899999981],
              [107.46207250000003, -2.953582599999947],
              [107.47175470000008, -2.945217199999945],
              [107.46900490000007, -2.925426899999934]
            ]
          ],
          [
            [
              [107.48771090000008, -2.930267899999933],
              [107.48871780000007, -2.921360299999947],
              [107.48469, -2.920895599999938],
              [107.479268, -2.932901499999957],
              [107.48368310000006, -2.944287699999961],
              [107.48771090000008, -2.930267899999933]
            ]
          ],
          [
            [
              [106.80439720000004, -2.886238399999968],
              [106.793639, -2.893171099999961],
              [106.78238260000006, -2.908188],
              [106.77441430000005, -2.907586099999946],
              [106.76170950000005, -2.918069899999978],
              [106.74121450000007, -2.925215899999955],
              [106.73598260000006, -2.92392289999998],
              [106.72999070000009, -2.927389199999936],
              [106.73161250000004, -2.937231299999951],
              [106.72923940000004, -2.944496599999979],
              [106.72361890000008, -2.947455399999967],
              [106.72395390000008, -2.953863699999943],
              [106.71162280000004, -2.960640099999978],
              [106.70884970000009, -2.967424299999948],
              [106.717444, -2.969895599999973],
              [106.73972470000007, -2.982493899999952],
              [106.75771130000004, -2.989225899999951],
              [106.77511360000005, -2.998619199999951],
              [106.78505060000003, -3.008136],
              [106.79216970000004, -3.01893819999998],
              [106.81645220000007, -3.024134699999934],
              [106.82168430000007, -3.029295499999932],
              [106.829941, -3.028617599999961],
              [106.84846970000007, -3.030006399999934],
              [106.856389, -3.038969899999927],
              [106.86598090000007, -3.031829199999947],
              [106.87070220000004, -3.032719099999952],
              [106.89171340000007, -3.028838199999939],
              [106.88075240000006, -3.021473599999979],
              [106.88033560000008, -3.00679839999998],
              [106.90681680000006, -2.964692199999945],
              [106.91045050000008, -2.944064199999957],
              [106.89374860000004, -2.930382099999974],
              [106.87652360000004, -2.930369799999937],
              [106.86779770000004, -2.926204699999971],
              [106.85883710000007, -2.924770899999942],
              [106.84681120000005, -2.91885069999995],
              [106.83789080000008, -2.912368099999981],
              [106.83277910000004, -2.906140799999946],
              [106.833191, -2.900274299999978],
              [106.82021350000008, -2.895503599999927],
              [106.80867790000008, -2.887213],
              [106.80439720000004, -2.886238399999968]
            ]
          ],
          [
            [
              [106.76882990000007, -2.883454799999924],
              [106.76024680000006, -2.886227299999973],
              [106.76093350000008, -2.895459399999936],
              [106.76940970000004, -2.896047299999964],
              [106.77654320000005, -2.888925799999924],
              [106.77702390000007, -2.884609399999931],
              [106.76882990000007, -2.883454799999924]
            ]
          ],
          [
            [
              [106.73647830000004, -2.867422899999951],
              [106.73343420000003, -2.871856299999934],
              [106.73514320000004, -2.879467299999931],
              [106.73103090000006, -2.884249799999964],
              [106.72089910000005, -2.888115299999924],
              [106.71822880000008, -2.893300899999929],
              [106.72134160000007, -2.902315499999929],
              [106.73475410000009, -2.904221599999971],
              [106.74409250000008, -2.892604199999937],
              [106.74879220000008, -2.88158859999993],
              [106.74518350000005, -2.876354499999934],
              [106.74011760000008, -2.876130699999976],
              [106.73647830000004, -2.867422899999951]
            ]
          ],
          [
            [
              [107.50630060000009, -2.843283299999939],
              [107.50157570000005, -2.842973499999971],
              [107.49855490000004, -2.849634799999933],
              [107.48306340000005, -2.863422199999945],
              [107.49080920000006, -2.87659],
              [107.49429480000003, -2.87589289999994],
              [107.49692830000004, -2.862028],
              [107.50235030000005, -2.856915799999967],
              [107.50630060000009, -2.843283299999939]
            ]
          ],
          [
            [
              [106.84392570000006, -2.843991099999926],
              [106.83650230000006, -2.842962499999942],
              [106.82894920000007, -2.850198099999943],
              [106.842972, -2.857815099999925],
              [106.85432450000008, -2.858034399999951],
              [106.85261560000004, -2.84320409999998],
              [106.84392570000006, -2.843991099999926]
            ]
          ],
          [
            [
              [106.79663110000007, -2.837751699999956],
              [106.78767420000008, -2.833049599999924],
              [106.78137990000005, -2.843196399999954],
              [106.79663110000007, -2.837751699999956]
            ]
          ],
          [
            [
              [107.48984580000007, -2.820414099999937],
              [107.47810620000007, -2.824892099999943],
              [107.48113190000004, -2.837963],
              [107.48669910000007, -2.844256399999949],
              [107.49347660000006, -2.828038799999945],
              [107.49880180000008, -2.821987399999955],
              [107.48984580000007, -2.820414099999937]
            ]
          ],
          [
            [
              [107.46434450000004, -2.821596199999931],
              [107.45810060000008, -2.82008449999995],
              [107.45560310000008, -2.82685429999998],
              [107.44684510000008, -2.828415199999938],
              [107.43780790000005, -2.825654799999938],
              [107.42043990000008, -2.827412899999956],
              [107.41201060000009, -2.834462],
              [107.39993350000009, -2.837666099999979],
              [107.38815220000004, -2.849480299999925],
              [107.38069240000004, -2.847607099999948],
              [107.37798120000008, -2.855050499999948],
              [107.37095290000008, -2.864282],
              [107.37798730000009, -2.876001499999973],
              [107.37654620000006, -2.882384799999954],
              [107.36891180000003, -2.884705799999949],
              [107.36474440000006, -2.880712],
              [107.348364, -2.877354899999943],
              [107.33869790000006, -2.880712],
              [107.34668550000004, -2.887657799999943],
              [107.35507820000004, -2.888120799999967],
              [107.35739350000006, -2.898655199999951],
              [107.36503380000005, -2.907800399999928],
              [107.38323740000004, -2.916337799999951],
              [107.39319290000009, -2.931502699999953],
              [107.39736040000008, -2.932891799999936],
              [107.40578210000007, -2.941660799999966],
              [107.41365390000004, -2.944497],
              [107.42083120000007, -2.936509399999977],
              [107.42268340000004, -2.931184299999927],
              [107.41029680000008, -2.919492399999967],
              [107.41724250000004, -2.918508399999951],
              [107.41718470000006, -2.925801399999955],
              [107.42933340000008, -2.929806799999938],
              [107.43562460000004, -2.920663199999979],
              [107.43929770000005, -2.922148],
              [107.44666340000003, -2.917126799999949],
              [107.45502560000006, -2.915876399999945],
              [107.46864350000004, -2.908119899999974],
              [107.47198440000005, -2.89626039999996],
              [107.47632180000005, -2.890125599999976],
              [107.47309810000007, -2.879711899999961],
              [107.47532540000009, -2.874593],
              [107.47724010000007, -2.859392599999978],
              [107.48579770000003, -2.851851],
              [107.47962370000005, -2.846888399999955],
              [107.47564930000004, -2.83861909999996],
              [107.47208370000004, -2.825589],
              [107.46434450000004, -2.821596199999931]
            ]
          ],
          [
            [
              [107.05799120000006, -2.820906099999945],
              [107.05256880000007, -2.820906099999945],
              [107.04771580000005, -2.828086499999927],
              [107.047394, -2.835316399999954],
              [107.03793160000004, -2.845148799999947],
              [107.03857330000005, -2.850687],
              [107.03030970000003, -2.854084299999954],
              [107.02861170000006, -2.867140699999936],
              [107.02077670000006, -2.874374899999964],
              [107.02201510000003, -2.883301],
              [107.03092870000006, -2.885034099999928],
              [107.03813380000008, -2.896324599999957],
              [107.052544, -2.908283599999947],
              [107.05732270000004, -2.909397799999965],
              [107.07633820000007, -2.907318],
              [107.08569750000004, -2.911131],
              [107.08552410000004, -2.897958799999969],
              [107.09466730000008, -2.874571599999967],
              [107.09008, -2.863196],
              [107.09059990000009, -2.857055499999944],
              [107.08236730000004, -2.848377199999959],
              [107.07087870000004, -2.839525499999979],
              [107.06926930000009, -2.843870899999956],
              [107.05932820000004, -2.839748399999962],
              [107.06201470000008, -2.826724699999943],
              [107.05799120000006, -2.820906099999945]
            ]
          ],
          [
            [
              [107.416835, -2.791084799999965],
              [107.40392740000004, -2.797123099999965],
              [107.38722520000005, -2.797483199999931],
              [107.38876370000008, -2.80503559999994],
              [107.39914470000008, -2.805205799999953],
              [107.402137, -2.809502799999962],
              [107.39849230000004, -2.817827499999964],
              [107.39171350000004, -2.816040599999951],
              [107.37947470000006, -2.816593699999942],
              [107.37119260000009, -2.823117299999979],
              [107.38126160000007, -2.826435799999956],
              [107.39091930000006, -2.832689899999934],
              [107.40069050000005, -2.834008799999935],
              [107.41281580000003, -2.827556099999924],
              [107.43252830000006, -2.819983099999945],
              [107.43550650000009, -2.816012199999932],
              [107.43001950000007, -2.805100299999935],
              [107.42470130000004, -2.804657199999951],
              [107.42182070000007, -2.795405799999969],
              [107.416835, -2.791084799999965]
            ]
          ],
          [
            [
              [108.41704620000007, -2.682535199999961],
              [108.41212560000008, -2.686643599999968],
              [108.41849470000005, -2.693023],
              [108.42134240000007, -2.686883599999931],
              [108.41704620000007, -2.682535199999961]
            ]
          ],
          [
            [
              [108.02667330000008, -2.651429099999973],
              [108.02130580000005, -2.653478199999938],
              [108.01924070000007, -2.665645099999949],
              [108.02892780000008, -2.654795499999977],
              [108.02667330000008, -2.651429099999973]
            ]
          ],
          [
            [
              [108.72970930000008, -2.581880499999954],
              [108.72595880000006, -2.58850149999995],
              [108.73316040000009, -2.598265399999946],
              [108.742262, -2.594516],
              [108.73236, -2.582033399999943],
              [108.72970930000008, -2.581880499999954]
            ]
          ],
          [
            [
              [108.56503180000004, -2.56494129999993],
              [108.56520060000008, -2.580131499999936],
              [108.57020460000007, -2.580592299999978],
              [108.57075670000006, -2.570452399999965],
              [108.56503180000004, -2.56494129999993]
            ]
          ],
          [
            [
              [107.86208980000004, -2.539876899999967],
              [107.85519590000007, -2.54291829999994],
              [107.85737560000007, -2.547987299999932],
              [107.86437080000007, -2.548849],
              [107.87040290000004, -2.544286899999975],
              [107.87060570000006, -2.539319299999931],
              [107.86208980000004, -2.539876899999967]
            ]
          ],
          [
            [
              [107.81840280000006, -2.529171199999951],
              [107.81259090000009, -2.535118199999943],
              [107.79610130000009, -2.545660699999928],
              [107.78393690000007, -2.548837],
              [107.76389940000007, -2.557183099999975],
              [107.74977510000008, -2.557521],
              [107.74034770000009, -2.555797799999937],
              [107.73291390000009, -2.556811499999981],
              [107.72867970000004, -2.55126139999993],
              [107.72015430000005, -2.547722499999963],
              [107.713157, -2.550055],
              [107.70455120000008, -2.558902099999955],
              [107.69284880000004, -2.560953],
              [107.68786220000004, -2.564089699999954],
              [107.67620010000007, -2.563124599999981],
              [107.66737310000008, -2.555865899999958],
              [107.65841690000008, -2.561874399999965],
              [107.64371410000007, -2.58811419999995],
              [107.63631330000004, -2.59295659999998],
              [107.62839480000008, -2.594723099999953],
              [107.62778570000006, -2.605139],
              [107.63296320000006, -2.605809],
              [107.64507830000008, -2.618297899999959],
              [107.64546840000008, -2.62785539999993],
              [107.64000690000006, -2.634487199999967],
              [107.64244510000009, -2.63985109999993],
              [107.63639850000004, -2.64794569999998],
              [107.63103460000008, -2.648823399999969],
              [107.62838040000008, -2.665111099999933],
              [107.63079950000008, -2.672201599999937],
              [107.62554420000004, -2.68388],
              [107.61786980000005, -2.694390699999929],
              [107.61319840000004, -2.706486199999972],
              [107.62160170000004, -2.706216499999925],
              [107.62881590000006, -2.71682149999998],
              [107.62926910000004, -2.723312199999953],
              [107.62575620000007, -2.744449699999961],
              [107.63228240000007, -2.744100499999945],
              [107.62572770000008, -2.75458029999993],
              [107.610222, -2.764737499999967],
              [107.61442790000007, -2.769002499999942],
              [107.60186180000005, -2.779843599999936],
              [107.59604890000008, -2.788124499999981],
              [107.58105950000004, -2.796585799999946],
              [107.59231280000006, -2.801630399999965],
              [107.598832, -2.81466869999997],
              [107.59964690000004, -2.825960799999962],
              [107.59747380000005, -2.834885899999961],
              [107.59611570000004, -2.853473199999939],
              [107.59766780000007, -2.858285],
              [107.58882040000009, -2.870469599999979],
              [107.57593190000006, -2.883831699999973],
              [107.56570260000007, -2.890248799999938],
              [107.54870680000005, -2.898095399999931],
              [107.53698450000007, -2.913820499999929],
              [107.52859780000006, -2.921],
              [107.53272760000004, -2.934342499999957],
              [107.53831880000007, -2.932436399999972],
              [107.54721380000007, -2.934469599999943],
              [107.55067640000004, -2.928401899999926],
              [107.559635, -2.924843899999928],
              [107.56668740000003, -2.926305199999945],
              [107.57450230000006, -2.918553899999949],
              [107.58736830000004, -2.911978],
              [107.61500320000005, -2.912829199999976],
              [107.60865090000004, -2.928412599999945],
              [107.60022840000005, -2.932046799999966],
              [107.59477240000007, -2.949908599999958],
              [107.59795510000004, -2.955624399999977],
              [107.59116760000006, -2.962184499999978],
              [107.59045310000005, -2.968809599999929],
              [107.58074280000005, -2.984008399999937],
              [107.56846690000003, -2.983618699999965],
              [107.56073760000004, -2.996609099999944],
              [107.56479710000008, -3.004338399999938],
              [107.56512180000004, -3.014730699999973],
              [107.56804470000009, -3.018562899999949],
              [107.56879160000005, -3.030514099999948],
              [107.58012570000005, -3.050324399999965],
              [107.58497390000008, -3.070794],
              [107.58247890000007, -3.085677299999929],
              [107.59232880000008, -3.095049899999935],
              [107.59317490000007, -3.10333769999994],
              [107.59859890000007, -3.107373099999961],
              [107.60597540000003, -3.10607129999994],
              [107.61090040000005, -3.117049399999928],
              [107.62675170000006, -3.128867699999944],
              [107.62693410000008, -3.139125199999967],
              [107.62978340000006, -3.144595899999956],
              [107.62700250000006, -3.151662199999976],
              [107.60681980000004, -3.173087],
              [107.60482610000008, -3.18090669999998],
              [107.58555980000006, -3.20364479999995],
              [107.59232460000004, -3.210499],
              [107.59594540000006, -3.217919399999971],
              [107.59569210000006, -3.223939199999961],
              [107.607985, -3.23213439999995],
              [107.60786580000007, -3.238541599999962],
              [107.61573320000008, -3.237677399999939],
              [107.62361560000005, -3.233952299999942],
              [107.63610210000007, -3.238690599999927],
              [107.64514670000005, -3.232402599999944],
              [107.65603890000006, -3.228409299999953],
              [107.66028550000004, -3.220214099999964],
              [107.66894270000006, -3.210558599999956],
              [107.68271720000007, -3.203787499999976],
              [107.68871240000004, -3.204005599999959],
              [107.69726920000005, -3.197356299999967],
              [107.70866, -3.192560199999946],
              [107.72942520000004, -3.190543599999955],
              [107.74040730000007, -3.191633699999954],
              [107.74362290000005, -3.195012799999972],
              [107.74991780000005, -3.192287699999952],
              [107.75689410000007, -3.194140699999934],
              [107.76648640000008, -3.191797199999939],
              [107.77542460000006, -3.182422899999949],
              [107.779485, -3.183512899999926],
              [107.78760720000008, -3.174915],
              [107.796166, -3.177004199999942],
              [107.81274450000006, -3.165884399999925],
              [107.82834590000004, -3.167434399999934],
              [107.835422, -3.158269099999927],
              [107.83279370000008, -3.149642899999947],
              [107.83356880000008, -3.138253599999928],
              [107.83956670000003, -3.137242699999945],
              [107.83666880000004, -3.12935779999998],
              [107.84111670000004, -3.123764199999925],
              [107.84893420000009, -3.122012],
              [107.84566570000004, -3.11399229999995],
              [107.83279370000008, -3.110487899999953],
              [107.82942410000004, -3.102266],
              [107.82251640000004, -3.093033299999945],
              [107.81651850000009, -3.090405],
              [107.81608860000006, -3.084566099999961],
              [107.82264390000006, -3.081660499999941],
              [107.83851840000005, -3.068975099999932],
              [107.84443590000006, -3.061037899999974],
              [107.85989370000004, -3.05854359999995],
              [107.86682420000005, -3.074298299999953],
              [107.87247490000004, -3.073928599999931],
              [107.87894490000008, -3.081734],
              [107.88931170000006, -3.081956899999966],
              [107.88910410000005, -3.08974539999997],
              [107.89904720000004, -3.09712449999995],
              [107.89531140000008, -3.105320699999936],
              [107.88893670000004, -3.104751599999929],
              [107.88268980000004, -3.110088399999938],
              [107.88663710000009, -3.117936399999962],
              [107.89421320000008, -3.118172399999935],
              [107.903969, -3.11524739999993],
              [107.91819990000005, -3.123141199999964],
              [107.92350370000008, -3.131714399999964],
              [107.93374450000005, -3.132375799999977],
              [107.93024740000004, -3.141640399999972],
              [107.93227930000006, -3.149946499999942],
              [107.94035580000008, -3.151093099999969],
              [107.95121830000005, -3.158934299999942],
              [107.95935750000007, -3.161938799999973],
              [107.96326290000007, -3.17279149999996],
              [107.97047160000005, -3.180912899999953],
              [107.97348570000008, -3.194137399999931],
              [107.96924350000006, -3.202511899999934],
              [107.967052, -3.215267],
              [107.966983, -3.246820299999968],
              [107.97562060000007, -3.254997899999978],
              [107.97459840000005, -3.260909699999957],
              [107.983219, -3.271932399999969],
              [107.99133490000008, -3.268201699999963],
              [107.99038480000007, -3.260985199999936],
              [108.00752590000008, -3.246881599999938],
              [108.01439160000007, -3.243534299999965],
              [108.03002410000005, -3.24470139999994],
              [108.03638970000009, -3.243184099999951],
              [108.04163290000008, -3.245689],
              [108.04754960000008, -3.240481599999953],
              [108.06047820000003, -3.239350399999978],
              [108.069645, -3.245105399999943],
              [108.08037390000004, -3.246506],
              [108.08339960000006, -3.24303139999995],
              [108.08076, -3.234690699999931],
              [108.08407740000007, -3.22321009999996],
              [108.08713460000007, -3.22003529999995],
              [108.08673290000007, -3.207865],
              [108.07243620000008, -3.197350699999959],
              [108.06921240000008, -3.187992699999938],
              [108.07706110000004, -3.178399],
              [108.07946520000007, -3.170591799999954],
              [108.08605340000008, -3.167461299999957],
              [108.09974380000006, -3.16648],
              [108.10811590000009, -3.168962499999964],
              [108.11274690000005, -3.173571199999969],
              [108.13117160000007, -3.174798],
              [108.14805210000009, -3.170803399999954],
              [108.15247640000007, -3.172136599999931],
              [108.15751060000008, -3.166080499999964],
              [108.17089960000004, -3.160446],
              [108.18540670000004, -3.142778],
              [108.19151860000005, -3.14295519999996],
              [108.20415310000004, -3.146959099999947],
              [108.208738, -3.145264099999963],
              [108.20532450000007, -3.134762799999976],
              [108.20068350000008, -3.130462299999976],
              [108.19072, -3.128517299999942],
              [108.18564180000004, -3.131025299999976],
              [108.17989180000006, -3.120140599999957],
              [108.18424280000005, -3.108616799999936],
              [108.19271870000006, -3.101024799999948],
              [108.19302360000006, -3.096864699999969],
              [108.20001720000005, -3.08222619999998],
              [108.20908160000005, -3.069606399999941],
              [108.210524, -3.063844399999937],
              [108.21729530000005, -3.054121499999951],
              [108.21593530000007, -3.035715799999934],
              [108.21245040000008, -3.03365279999997],
              [108.20794250000006, -3.016934],
              [108.21005230000009, -3.007950199999925],
              [108.22114210000007, -2.985541699999942],
              [108.22968440000005, -2.978496499999949],
              [108.24718060000004, -2.955867],
              [108.24406430000005, -2.939841099999967],
              [108.24949270000008, -2.919594399999937],
              [108.26378810000006, -2.898110699999961],
              [108.27300090000006, -2.890181699999971],
              [108.29018120000006, -2.88139289999998],
              [108.29346780000009, -2.875545699999975],
              [108.29666240000006, -2.862710299999947],
              [108.29809220000004, -2.848990499999957],
              [108.29017540000007, -2.822258],
              [108.28438860000006, -2.812885599999959],
              [108.282294, -2.804507299999955],
              [108.27214070000008, -2.779833899999971],
              [108.266242, -2.770567],
              [108.26731250000006, -2.758923599999946],
              [108.27079160000005, -2.751823299999955],
              [108.26145470000006, -2.743374],
              [108.25087530000008, -2.742273399999931],
              [108.23982850000004, -2.725696399999947],
              [108.223401, -2.714041],
              [108.21875890000007, -2.703015899999968],
              [108.20830920000009, -2.701482299999952],
              [108.20177690000008, -2.689731399999971],
              [108.192116, -2.690877299999954],
              [108.19181450000008, -2.679574699999932],
              [108.18419840000007, -2.679182599999933],
              [108.175464, -2.670289499999967],
              [108.17024850000007, -2.676104599999974],
              [108.16669460000008, -2.686452399999951],
              [108.16122210000009, -2.682922799999972],
              [108.16247630000004, -2.676468599999964],
              [108.14954960000006, -2.672402699999964],
              [108.15502240000006, -2.654176099999972],
              [108.15014820000005, -2.649918699999944],
              [108.14203160000005, -2.650333299999943],
              [108.13781570000003, -2.64722809999995],
              [108.13878380000006, -2.638957799999957],
              [108.12121690000004, -2.628938799999958],
              [108.11344160000004, -2.631740499999978],
              [108.11084950000009, -2.621058099999971],
              [108.10477960000009, -2.61491639999997],
              [108.09709450000008, -2.613618299999928],
              [108.093624, -2.60773439999997],
              [108.08724130000007, -2.604586],
              [108.07781660000006, -2.606853199999932],
              [108.06881860000004, -2.605519499999957],
              [108.06808640000008, -2.601531299999976],
              [108.05783120000007, -2.606208599999945],
              [108.04315140000006, -2.598507699999971],
              [108.04114470000007, -2.602057],
              [108.04423620000006, -2.610199199999954],
              [108.03788690000005, -2.609924899999953],
              [108.03744870000008, -2.617945299999974],
              [108.040048, -2.636503599999969],
              [108.037836, -2.643327399999976],
              [108.03343350000006, -2.643771499999957],
              [108.02956160000008, -2.656890899999951],
              [108.01886110000004, -2.666965399999981],
              [108.01952010000008, -2.655285099999958],
              [108.02550340000005, -2.649459099999945],
              [108.02110620000008, -2.630806499999949],
              [108.01243840000006, -2.619536499999924],
              [108.00289720000006, -2.618897],
              [108.01038510000006, -2.605397299999936],
              [108.013167, -2.595793799999967],
              [108.00828330000007, -2.585145899999929],
              [108.00359590000005, -2.580269599999951],
              [107.99039, -2.572883399999967],
              [107.98649490000008, -2.575322],
              [107.97781870000006, -2.572341499999936],
              [107.95791450000007, -2.577857299999948],
              [107.95195660000007, -2.569900799999971],
              [107.94333760000006, -2.571892499999933],
              [107.93800090000008, -2.566124799999955],
              [107.92941430000008, -2.565051399999959],
              [107.92335760000009, -2.560834799999952],
              [107.92036760000008, -2.571261399999969],
              [107.914771, -2.571031399999924],
              [107.90802440000004, -2.575478099999941],
              [107.90288770000006, -2.574711399999956],
              [107.89644770000007, -2.567044799999962],
              [107.88368280000009, -2.567543099999966],
              [107.86873290000005, -2.559799799999951],
              [107.838488, -2.556081499999948],
              [107.82813430000004, -2.549985899999967],
              [107.82678270000008, -2.539713699999936],
              [107.832527, -2.537078099999974],
              [107.83340560000005, -2.531739199999947],
              [107.81840280000006, -2.529171199999951]
            ]
          ],
          [
            [
              [108.54586340000009, -2.493546599999945],
              [108.54270430000008, -2.493555599999979],
              [108.53572250000008, -2.501805799999943],
              [108.53688550000004, -2.513308299999949],
              [108.54836530000006, -2.508476099999939],
              [108.54586340000009, -2.493546599999945]
            ]
          ],
          [
            [
              [105.78970660000005, -2.400302499999952],
              [105.78535930000004, -2.398466099999951],
              [105.78815980000007, -2.41706019999998],
              [105.80103820000005, -2.416540299999951],
              [105.802553, -2.406008799999938],
              [105.78970660000005, -2.400302499999952]
            ]
          ],
          [
            [
              [106.27506840000007, -2.149359899999979],
              [106.26965480000007, -2.149848499999962],
              [106.26656250000008, -2.156688699999961],
              [106.27185740000004, -2.159296499999925],
              [106.278891, -2.152624199999934],
              [106.27506840000007, -2.149359899999979]
            ]
          ],
          [
            [
              [105.81694240000007, -1.804919799999936],
              [105.81317070000006, -1.795817899999975],
              [105.80525330000006, -1.800323599999956],
              [105.80931750000008, -1.806545899999946],
              [105.81694240000007, -1.804919799999936]
            ]
          ],
          [
            [
              [105.91511430000008, -1.501916899999969],
              [105.91106150000007, -1.506367299999965],
              [105.89980330000009, -1.505587899999966],
              [105.88844850000004, -1.501912],
              [105.88318540000006, -1.502218199999959],
              [105.88783230000007, -1.511705799999959],
              [105.87985310000005, -1.51955049999998],
              [105.87185580000005, -1.521793899999977],
              [105.85344280000004, -1.52335359999995],
              [105.84549170000008, -1.522364599999946],
              [105.826031, -1.528869399999962],
              [105.81466730000005, -1.531451499999946],
              [105.793323, -1.53253049999995],
              [105.75695580000007, -1.532287599999961],
              [105.73810370000007, -1.530766599999936],
              [105.72281460000005, -1.528367099999969],
              [105.70452190000009, -1.528443899999957],
              [105.70257770000006, -1.530758899999967],
              [105.71950260000006, -1.542599499999938],
              [105.722163, -1.550098099999957],
              [105.72043640000004, -1.56161],
              [105.72270760000004, -1.563511399999925],
              [105.71952690000006, -1.579119599999956],
              [105.73182650000007, -1.598598699999968],
              [105.73964090000004, -1.608669499999962],
              [105.74174950000008, -1.620567],
              [105.73588980000005, -1.624672699999962],
              [105.73311040000004, -1.63162479999994],
              [105.73835460000004, -1.635484099999928],
              [105.74462230000006, -1.634054499999934],
              [105.75322910000006, -1.63732959999993],
              [105.75946490000007, -1.647748899999954],
              [105.76149910000004, -1.66063209999993],
              [105.75738360000008, -1.676137299999937],
              [105.75346040000005, -1.681603],
              [105.74221320000004, -1.683430899999962],
              [105.73999320000007, -1.686499099999935],
              [105.74656440000007, -1.703229799999974],
              [105.75278650000007, -1.715772499999957],
              [105.75423010000009, -1.727581099999952],
              [105.764262, -1.736370699999952],
              [105.77071070000005, -1.737606399999947],
              [105.77806950000007, -1.748133199999927],
              [105.78696920000004, -1.754570799999954],
              [105.79216870000005, -1.762344499999926],
              [105.79524060000006, -1.780308499999933],
              [105.79963890000005, -1.784060599999975],
              [105.815177, -1.789961399999925],
              [105.82614880000006, -1.80329219999993],
              [105.82496410000005, -1.806941199999926],
              [105.80944520000008, -1.814187899999979],
              [105.80134840000005, -1.808158499999934],
              [105.78812430000005, -1.808264899999926],
              [105.77985940000008, -1.79848149999998],
              [105.78251890000007, -1.786146],
              [105.77904940000008, -1.773545],
              [105.77070750000007, -1.767021599999964],
              [105.756585, -1.774882599999955],
              [105.745682, -1.777754799999968],
              [105.745236, -1.781550899999957],
              [105.718176, -1.784124799999972],
              [105.69437640000007, -1.781399299999975],
              [105.68355090000006, -1.769041899999934],
              [105.67236280000009, -1.764226199999939],
              [105.66222360000006, -1.748233399999947],
              [105.65568740000003, -1.746998099999928],
              [105.65463210000007, -1.740175499999964],
              [105.65794130000006, -1.736426799999947],
              [105.65434750000009, -1.725919699999963],
              [105.65382460000006, -1.716987],
              [105.66131740000009, -1.71402919999997],
              [105.67023240000009, -1.699641499999927],
              [105.68131520000009, -1.692151599999931],
              [105.69918570000004, -1.675144],
              [105.70232640000006, -1.667863399999931],
              [105.71281990000006, -1.660023299999978],
              [105.71682480000004, -1.654725299999939],
              [105.71763290000007, -1.645364599999937],
              [105.69757320000008, -1.65301119999998],
              [105.67547770000004, -1.654422499999953],
              [105.66297860000009, -1.653573],
              [105.65241940000004, -1.650690599999962],
              [105.64914850000008, -1.646296099999972],
              [105.63990730000006, -1.641715099999942],
              [105.63684720000003, -1.633142499999963],
              [105.62817560000008, -1.625706199999968],
              [105.62884080000003, -1.622190399999965],
              [105.62270530000006, -1.610049299999957],
              [105.62242010000006, -1.591244299999971],
              [105.61345070000004, -1.578702599999929],
              [105.61107980000008, -1.563318699999968],
              [105.60230060000004, -1.554174399999965],
              [105.59927020000003, -1.545377499999972],
              [105.60545870000004, -1.532333599999959],
              [105.59971880000006, -1.527284799999961],
              [105.59240420000003, -1.532232699999952],
              [105.58146370000009, -1.534646099999975],
              [105.568317, -1.532476899999949],
              [105.56502350000005, -1.541786899999977],
              [105.558939, -1.551599499999952],
              [105.55140450000005, -1.558584699999926],
              [105.53656150000006, -1.564738299999931],
              [105.51710150000008, -1.566092599999934],
              [105.50625140000005, -1.564387899999929],
              [105.49951330000005, -1.560669],
              [105.49284030000007, -1.564232899999979],
              [105.48371710000004, -1.564082],
              [105.46944180000008, -1.56096059999993],
              [105.45771140000005, -1.566906299999971],
              [105.45748960000009, -1.572427499999947],
              [105.43537040000007, -1.595538599999941],
              [105.42990330000003, -1.597833299999934],
              [105.41959570000006, -1.607066399999951],
              [105.39787130000008, -1.609186499999964],
              [105.393847, -1.61162029999997],
              [105.39250170000008, -1.626113499999974],
              [105.38923310000007, -1.635517399999969],
              [105.38269340000005, -1.642100899999946],
              [105.37170180000004, -1.645164099999931],
              [105.36532540000007, -1.650940299999945],
              [105.35442890000007, -1.651979599999947],
              [105.34811750000006, -1.646901399999933],
              [105.34383920000005, -1.648517],
              [105.34659130000006, -1.656478799999945],
              [105.34041410000003, -1.661385399999972],
              [105.34368620000004, -1.670788099999925],
              [105.35980590000008, -1.674076399999933],
              [105.36190580000005, -1.680961199999956],
              [105.35677110000006, -1.686066099999948],
              [105.35174210000008, -1.685401099999979],
              [105.34498490000004, -1.693329099999971],
              [105.33007760000004, -1.700332699999933],
              [105.33768330000004, -1.705369299999973],
              [105.34257830000007, -1.701524199999938],
              [105.35498640000009, -1.713446799999929],
              [105.37352540000006, -1.719481099999939],
              [105.38260470000006, -1.730456899999979],
              [105.38942370000007, -1.735553799999934],
              [105.38718330000006, -1.744330499999933],
              [105.39000390000007, -1.751670899999965],
              [105.39812440000009, -1.762950599999954],
              [105.39724750000005, -1.772285799999963],
              [105.38846390000003, -1.79675519999995],
              [105.36871240000005, -1.82538779999993],
              [105.36146320000006, -1.832645199999945],
              [105.34407420000008, -1.845433599999978],
              [105.33865780000008, -1.851159799999948],
              [105.31827680000004, -1.864385599999935],
              [105.29671930000006, -1.87300879999998],
              [105.28583360000005, -1.88285529999996],
              [105.26548830000007, -1.895801799999958],
              [105.24408530000005, -1.900291399999958],
              [105.23039490000008, -1.909627099999966],
              [105.21340090000007, -1.91612989999993],
              [105.19132470000005, -1.918701899999974],
              [105.18141410000004, -1.924302399999931],
              [105.16373480000004, -1.92908209999996],
              [105.159623, -1.932859],
              [105.15551170000003, -1.943228699999963],
              [105.14912360000005, -1.949691399999949],
              [105.12501870000006, -1.959174499999961],
              [105.12501480000009, -1.965606099999945],
              [105.13291330000004, -1.975438199999928],
              [105.12739070000003, -1.988520599999958],
              [105.12656670000007, -1.997253599999965],
              [105.119146, -2.005650299999957],
              [105.10882120000008, -2.022364899999957],
              [105.11097890000008, -2.028147099999956],
              [105.12091390000006, -2.037482],
              [105.12160320000004, -2.047161299999971],
              [105.12498290000008, -2.057379499999968],
              [105.12466560000007, -2.070847199999946],
              [105.13156680000009, -2.085560899999962],
              [105.14677450000005, -2.073945699999967],
              [105.15559430000008, -2.070942799999955],
              [105.171037, -2.070712299999968],
              [105.17504980000007, -2.074643099999946],
              [105.19955820000007, -2.072555899999941],
              [105.21671170000008, -2.066752199999939],
              [105.22868640000007, -2.066194499999938],
              [105.24766330000006, -2.075644299999965],
              [105.25761320000004, -2.085836699999959],
              [105.25871940000007, -2.091026199999931],
              [105.26959230000006, -2.110300399999971],
              [105.27626720000006, -2.118748299999936],
              [105.288205, -2.139210899999966],
              [105.29000410000003, -2.149185099999954],
              [105.29337910000004, -2.15279529999998],
              [105.30103240000005, -2.15388779999995],
              [105.30534120000004, -2.151069599999971],
              [105.32044460000009, -2.132161399999973],
              [105.33067690000007, -2.127090399999929],
              [105.34200240000007, -2.128669899999977],
              [105.351287, -2.12049589999998],
              [105.36200490000004, -2.120377299999973],
              [105.38373680000007, -2.129948899999931],
              [105.39556630000004, -2.127520899999979],
              [105.41153740000004, -2.119306199999926],
              [105.41886710000006, -2.11822879999994],
              [105.43037960000004, -2.123890399999937],
              [105.43521090000007, -2.132665499999973],
              [105.44009550000004, -2.133916],
              [105.44675630000006, -2.124896899999953],
              [105.47001990000007, -2.103619199999969],
              [105.48528770000007, -2.094703899999956],
              [105.50070850000009, -2.087401599999964],
              [105.51277080000006, -2.083403399999952],
              [105.51995580000005, -2.088217499999928],
              [105.526183, -2.088882599999977],
              [105.537141, -2.083304399999975],
              [105.56255430000004, -2.090453199999956],
              [105.57202310000008, -2.093983199999968],
              [105.57975810000005, -2.099283899999932],
              [105.58817850000008, -2.11172739999995],
              [105.590291, -2.123661199999958],
              [105.61303690000005, -2.119825399999968],
              [105.63475710000006, -2.119903299999976],
              [105.64888850000006, -2.11666],
              [105.65550660000008, -2.118104399999936],
              [105.66617380000008, -2.127984799999979],
              [105.66946310000009, -2.123598],
              [105.67681570000008, -2.125192],
              [105.68028810000004, -2.129679499999952],
              [105.69137980000005, -2.126245499999925],
              [105.70018060000007, -2.127675499999953],
              [105.70358810000005, -2.125491699999941],
              [105.71895720000003, -2.125020499999948],
              [105.73239890000008, -2.128041399999972],
              [105.74323410000005, -2.13368689999993],
              [105.75126170000004, -2.140948199999968],
              [105.75982630000004, -2.145533399999977],
              [105.75896860000006, -2.150943199999972],
              [105.77123190000003, -2.158077499999933],
              [105.78091180000007, -2.160452599999928],
              [105.78988770000007, -2.172988699999962],
              [105.79304720000005, -2.180686699999967],
              [105.79409680000003, -2.195082099999979],
              [105.78639260000006, -2.210671499999933],
              [105.78751570000009, -2.217344699999956],
              [105.79286760000008, -2.228057199999967],
              [105.78595010000004, -2.23644939999997],
              [105.78836530000007, -2.253792799999928],
              [105.79605180000004, -2.263252299999976],
              [105.79875780000003, -2.270046599999944],
              [105.80368830000003, -2.272686599999929],
              [105.81017390000005, -2.281894899999941],
              [105.81195840000004, -2.296139699999969],
              [105.82294710000008, -2.303835299999946],
              [105.82555190000005, -2.308389399999953],
              [105.82456310000003, -2.329104199999961],
              [105.82061470000008, -2.337915799999962],
              [105.81334640000006, -2.343247099999928],
              [105.80382590000005, -2.368107799999962],
              [105.81842970000008, -2.381041599999946],
              [105.82673140000009, -2.393396499999938],
              [105.83319330000006, -2.411005399999965],
              [105.83826470000008, -2.415173699999968],
              [105.85070910000007, -2.419337399999961],
              [105.86845590000007, -2.42844139999994],
              [105.88084930000008, -2.427702],
              [105.89783950000009, -2.435897599999976],
              [105.91283190000007, -2.444429299999967],
              [105.92699620000008, -2.459325799999931],
              [105.93456220000007, -2.48041],
              [105.93685270000009, -2.500316099999964],
              [105.93655920000003, -2.510287299999959],
              [105.93344420000005, -2.521167399999968],
              [105.93819210000004, -2.529437799999926],
              [105.92733640000006, -2.536029],
              [105.91630140000007, -2.547089899999946],
              [105.91251210000007, -2.563040599999965],
              [105.90378530000004, -2.581974199999934],
              [105.89958120000006, -2.587834199999975],
              [105.89250670000007, -2.588387],
              [105.891654, -2.595517799999925],
              [105.89900030000007, -2.605206599999974],
              [105.89581440000006, -2.618737099999976],
              [105.88742230000008, -2.638399699999979],
              [105.88693210000008, -2.644635299999948],
              [105.89177460000008, -2.646901699999944],
              [105.89803250000006, -2.660532899999964],
              [105.90283070000004, -2.667070699999954],
              [105.90296520000004, -2.683012799999972],
              [105.90728350000006, -2.697662399999956],
              [105.91594160000005, -2.709965499999953],
              [105.91989580000006, -2.724292599999956],
              [105.91700040000006, -2.726466499999958],
              [105.91894010000004, -2.742751899999973],
              [105.92853280000008, -2.754013599999951],
              [105.93282670000008, -2.76256049999995],
              [105.948272, -2.775000199999965],
              [105.95311320000008, -2.782425399999966],
              [105.95596180000007, -2.79194549999994],
              [105.95004920000008, -2.812550599999952],
              [105.95016770000007, -2.819813099999976],
              [105.96913820000009, -2.814804399999957],
              [105.98082320000009, -2.81619859999995],
              [105.995898, -2.822107699999947],
              [106.018794, -2.82645],
              [106.03130190000007, -2.832152599999972],
              [106.05820810000006, -2.830163199999959],
              [106.07256740000008, -2.833999799999958],
              [106.08906090000005, -2.835850199999925],
              [106.10090370000006, -2.839637399999958],
              [106.12078960000008, -2.849395299999969],
              [106.13422380000009, -2.860214199999973],
              [106.14417240000006, -2.874742499999968],
              [106.15487450000006, -2.874302099999966],
              [106.17691320000006, -2.870767799999953],
              [106.195658, -2.875745399999971],
              [106.19773670000006, -2.880044599999962],
              [106.21137430000005, -2.882622899999944],
              [106.22737440000009, -2.89268919999995],
              [106.23336360000008, -2.900171099999966],
              [106.24362730000007, -2.898857299999975],
              [106.26744230000008, -2.899628299999961],
              [106.27846470000009, -2.902126399999929],
              [106.28806230000004, -2.907344599999931],
              [106.29615480000007, -2.916220499999952],
              [106.31274080000009, -2.925596],
              [106.32058670000004, -2.932060199999967],
              [106.32758130000008, -2.943212099999926],
              [106.33089970000009, -2.952303899999947],
              [106.32999920000009, -2.964023299999951],
              [106.337623, -2.967045599999949],
              [106.34636930000005, -2.96728],
              [106.34991290000005, -2.970542899999941],
              [106.36917890000007, -2.968118899999979],
              [106.38478420000007, -2.972424199999978],
              [106.38649280000004, -2.966342499999939],
              [106.39958150000007, -2.965651599999944],
              [106.41399020000006, -2.967304699999943],
              [106.42995320000006, -2.974755799999969],
              [106.43754680000006, -2.986211699999956],
              [106.44048830000008, -2.998182199999974],
              [106.44355620000005, -3.00216869999997],
              [106.44215990000004, -3.011881499999959],
              [106.45474280000008, -3.017204599999957],
              [106.45873360000007, -3.021460599999955],
              [106.46757860000008, -3.024846899999943],
              [106.47966190000005, -3.034839199999965],
              [106.48640980000005, -3.043092099999967],
              [106.49612560000008, -3.059272899999939],
              [106.498561, -3.068732899999929],
              [106.49480460000007, -3.077732199999957],
              [106.495736, -3.084375199999954],
              [106.49072750000005, -3.087743],
              [106.50199350000008, -3.104359799999941],
              [106.51373760000007, -3.113399199999947],
              [106.53116860000006, -3.104208699999958],
              [106.54038380000009, -3.100717599999939],
              [106.547255, -3.108162499999935],
              [106.55412010000003, -3.106830199999933],
              [106.55004920000005, -3.096751499999925],
              [106.56275540000007, -3.085056799999961],
              [106.56973770000008, -3.081466899999953],
              [106.58480020000007, -3.076964199999964],
              [106.59525950000005, -3.075693899999976],
              [106.60210790000008, -3.079961899999944],
              [106.60765920000006, -3.088375199999973],
              [106.61639320000006, -3.086734499999977],
              [106.624091, -3.082540199999926],
              [106.63940020000007, -3.081541],
              [106.65339730000005, -3.087072499999977],
              [106.65802790000004, -3.096837799999946],
              [106.66805720000008, -3.09907069999997],
              [106.67645820000007, -3.091817],
              [106.68535260000004, -3.089029],
              [106.68962090000008, -3.092618899999934],
              [106.70673980000004, -3.093583299999977],
              [106.71979520000008, -3.082577199999946],
              [106.727419, -3.081442299999935],
              [106.73717690000007, -3.086204],
              [106.73637510000003, -3.077309599999978],
              [106.73237810000006, -3.072782299999972],
              [106.74293790000007, -3.056671199999926],
              [106.73637510000003, -3.050762099999929],
              [106.72902270000009, -3.031937099999936],
              [106.73598650000008, -3.021247799999969],
              [106.74365960000006, -3.014092799999958],
              [106.74076060000004, -3.005926199999976],
              [106.70699970000004, -3.005285299999969],
              [106.69440730000008, -3.003070399999956],
              [106.68094850000006, -2.989710299999956],
              [106.67542190000006, -2.975221399999953],
              [106.66753910000006, -2.978021799999965],
              [106.65401860000009, -2.975462],
              [106.63876490000007, -2.974425799999949],
              [106.62795840000007, -2.969170499999962],
              [106.62868620000006, -2.963545199999942],
              [106.62082810000004, -2.960707899999932],
              [106.61787970000006, -2.965173599999957],
              [106.60692520000003, -2.962015599999972],
              [106.59958520000004, -2.955835099999945],
              [106.59210940000008, -2.93909489999993],
              [106.59062910000006, -2.919924499999979],
              [106.59431760000007, -2.893043899999952],
              [106.59959750000007, -2.877167199999974],
              [106.60412490000004, -2.858218799999975],
              [106.61779050000007, -2.834005599999955],
              [106.62319970000004, -2.815890099999933],
              [106.63152770000005, -2.792421599999955],
              [106.64137260000007, -2.767603599999973],
              [106.64657570000008, -2.758498499999973],
              [106.64587920000008, -2.754677099999981],
              [106.65240630000005, -2.742706],
              [106.65870060000009, -2.722996399999943],
              [106.67066870000008, -2.703094199999953],
              [106.67408950000004, -2.693624599999964],
              [106.68406440000007, -2.67924389999996],
              [106.68965970000005, -2.668291499999953],
              [106.69854160000006, -2.661959099999933],
              [106.699362, -2.656593499999929],
              [106.70774380000006, -2.643355799999938],
              [106.71374510000004, -2.637952399999961],
              [106.72746770000003, -2.629763499999967],
              [106.73352570000009, -2.62121529999996],
              [106.74681230000004, -2.608371799999929],
              [106.76115550000009, -2.598927699999933],
              [106.76482780000003, -2.598483699999974],
              [106.77311970000005, -2.607694299999935],
              [106.78473710000009, -2.593749499999944],
              [106.78833360000004, -2.587151199999937],
              [106.80356610000007, -2.580124099999978],
              [106.81815040000004, -2.578537399999959],
              [106.82431020000007, -2.588171],
              [106.83758450000005, -2.58741],
              [106.84314820000009, -2.581385699999942],
              [106.84239690000004, -2.57182679999994],
              [106.75505990000005, -2.556776199999945],
              [106.71438490000008, -2.547171],
              [106.69834410000004, -2.542345],
              [106.66604530000006, -2.537733499999945],
              [106.64361330000008, -2.535370899999975],
              [106.58344580000005, -2.524005599999953],
              [106.56544620000005, -2.520246199999974],
              [106.54249450000003, -2.513293],
              [106.498425, -2.504161599999975],
              [106.47923560000004, -2.496883699999955],
              [106.46267140000003, -2.483521699999926],
              [106.44533930000006, -2.472842799999967],
              [106.436475, -2.47750659999997],
              [106.422114, -2.480398799999932],
              [106.40501480000006, -2.477346599999976],
              [106.39360760000005, -2.478005199999927],
              [106.376829, -2.474208899999951],
              [106.35586790000008, -2.465633],
              [106.341173, -2.456759399999953],
              [106.32734270000009, -2.444555499999979],
              [106.30672220000008, -2.422047099999929],
              [106.30164550000006, -2.422666099999958],
              [106.29141460000005, -2.410070099999928],
              [106.27950740000006, -2.391657399999929],
              [106.25909360000009, -2.35429629999993],
              [106.23961150000008, -2.329697099999976],
              [106.23145910000005, -2.308013599999924],
              [106.22615590000004, -2.29986839999998],
              [106.22556960000009, -2.278022199999953],
              [106.22254410000005, -2.269201399999929],
              [106.22819540000006, -2.260183499999926],
              [106.22378030000004, -2.252362099999971],
              [106.21435170000007, -2.242893199999969],
              [106.21181240000004, -2.233019299999967],
              [106.20617720000007, -2.222423099999958],
              [106.20557080000003, -2.202802799999972],
              [106.19842590000007, -2.185242899999935],
              [106.19198370000004, -2.179073199999948],
              [106.18850680000008, -2.169794599999932],
              [106.19271480000003, -2.163335199999949],
              [106.18465490000006, -2.161332399999935],
              [106.17599740000009, -2.14214759999993],
              [106.17767940000005, -2.132969199999934],
              [106.17659590000005, -2.12479929999995],
              [106.16759280000008, -2.104463899999928],
              [106.163499, -2.098684299999945],
              [106.16537870000008, -2.09316339999998],
              [106.15820050000008, -2.088870099999951],
              [106.16354480000007, -2.067901799999959],
              [106.15893470000009, -2.044025],
              [106.15826670000007, -2.034783899999979],
              [106.15476820000003, -2.026046099999974],
              [106.15542740000006, -2.020590799999979],
              [106.15008490000008, -1.994211699999937],
              [106.15588430000008, -1.96640739999998],
              [106.15565230000004, -1.959323199999972],
              [106.162206, -1.953422699999976],
              [106.16415690000008, -1.932241499999975],
              [106.16687240000005, -1.920153199999959],
              [106.17291780000005, -1.919265899999971],
              [106.17395410000006, -1.913607099999979],
              [106.18405910000007, -1.907484],
              [106.18580630000008, -1.895643199999938],
              [106.17588670000004, -1.887342699999977],
              [106.17223710000007, -1.873837599999945],
              [106.15048750000005, -1.868128399999932],
              [106.13480550000008, -1.855103499999927],
              [106.12180290000003, -1.832609599999955],
              [106.11890330000006, -1.821720099999936],
              [106.11906140000008, -1.81459],
              [106.12822130000006, -1.80431429999993],
              [106.12565150000006, -1.801241599999969],
              [106.11414330000008, -1.798337699999934],
              [106.102534, -1.789448499999935],
              [106.09713390000007, -1.78186379999994],
              [106.08733590000008, -1.775925499999971],
              [106.09051720000008, -1.762686599999938],
              [106.09389450000003, -1.757418799999925],
              [106.08173720000008, -1.755921399999977],
              [106.07330060000004, -1.747983199999965],
              [106.06406050000004, -1.729424],
              [106.06170230000004, -1.717000199999973],
              [106.04133810000008, -1.700208199999963],
              [106.03431080000007, -1.691009699999938],
              [106.02395990000008, -1.666642699999954],
              [106.020959, -1.652519899999959],
              [106.02232830000008, -1.635841099999936],
              [106.02778950000004, -1.620119199999976],
              [106.03287240000009, -1.614362699999958],
              [106.03910890000009, -1.616110699999979],
              [106.04407950000007, -1.613460499999974],
              [106.05304250000006, -1.619720499999971],
              [106.05156480000005, -1.606274699999972],
              [106.04799870000005, -1.600658099999976],
              [106.03196060000005, -1.593109],
              [106.028343, -1.587648799999954],
              [106.02224170000005, -1.587016],
              [106.01799230000006, -1.581576799999937],
              [106.01056380000006, -1.577984199999946],
              [106.00538040000004, -1.568855499999927],
              [105.987999, -1.562260899999956],
              [105.98618390000007, -1.557205099999976],
              [105.97363510000008, -1.550794099999962],
              [105.96589430000006, -1.55734259999997],
              [105.95716430000004, -1.556658499999969],
              [105.95214090000007, -1.559717199999966],
              [105.93170460000005, -1.552045299999975],
              [105.91133060000004, -1.538598299999933],
              [105.90945750000009, -1.533465499999977],
              [105.91256310000006, -1.522913199999948],
              [105.92032230000007, -1.521853699999951],
              [105.92100070000004, -1.509147499999926],
              [105.91511430000008, -1.501916899999969]
            ]
          ]
        ]
      },
      "properties": {
        "nama": "Provinsi Kepulauan Bangka Belitung",
        "nid": 9,
        "type": 1
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [133.22140500000012, -4.111786],
              [133.213593, -4.101344],
              [133.206879, -4.098111],
              [133.19912460000012, -4.102356699999973],
              [133.203537, -4.115819],
              [133.19661, -4.133492],
              [133.19136, -4.152736],
              [133.204422, -4.158045],
              [133.207275, -4.170226],
              [133.203919, -4.178256],
              [133.206711, -4.18197],
              [133.220276, -4.185729],
              [133.227524, -4.192077],
              [133.228805, -4.20718],
              [133.25679, -4.21269],
              [133.261109, -4.216591],
              [133.2693180000001, -4.236137],
              [133.278747, -4.244868],
              [133.273209, -4.254203],
              [133.27931200000012, -4.256681],
              [133.288147, -4.251755],
              [133.295807, -4.253153],
              [133.30592400000012, -4.250774],
              [133.312653, -4.254698],
              [133.327927, -4.25427],
              [133.3374020000001, -4.242422],
              [133.346878, -4.235313],
              [133.34924300000011, -4.216358],
              [133.3421330000001, -4.20451],
              [133.327301, -4.19995],
              [133.311386, -4.198126],
              [133.288284, -4.188227],
              [133.2785490000001, -4.179552],
              [133.273026, -4.170801],
              [133.270431, -4.15966],
              [133.261582, -4.160439],
              [133.255295, -4.152027],
              [133.248413, -4.146662],
              [133.247421, -4.140028],
              [133.240021, -4.142203],
              [133.233185, -4.139776],
              [133.231445, -4.13544],
              [133.232727, -4.111895],
              [133.22140500000012, -4.111786]
            ]
          ],
          [
            [
              [134.262192, -4.036889],
              [134.259872, -4.016762],
              [134.256104, -4.011966],
              [134.240738, -4.000227],
              [134.230179, -3.996691],
              [134.226654, -4.010205],
              [134.231125, -4.013799],
              [134.231064, -4.026669],
              [134.2353210000001, -4.034054],
              [134.244064, -4.037909],
              [134.257034, -4.039782],
              [134.262192, -4.036889]
            ]
          ],
          [
            [
              [134.3907620000001, -3.984579],
              [134.382599, -3.985132],
              [134.380783, -4.007339],
              [134.383347, -4.01339],
              [134.3797, -4.019397],
              [134.36972, -4.01967],
              [134.367737, -4.031435],
              [134.374023, -4.029871],
              [134.3824770000001, -4.032862],
              [134.393341, -4.02886],
              [134.411759, -4.028359],
              [134.419327, -4.024161],
              [134.432358, -4.025173],
              [134.441299, -4.019836],
              [134.44107, -4.014663],
              [134.428162, -4.011526],
              [134.4265140000001, -4.004561],
              [134.413712, -4.002747],
              [134.410599, -3.994256],
              [134.392883, -3.990486],
              [134.3907620000001, -3.984579]
            ]
          ],
          [
            [
              [134.6959230000001, -3.935589],
              [134.680771, -3.934116],
              [134.6754, -3.936912],
              [134.6758420000001, -3.944031],
              [134.68425, -3.947947],
              [134.687378, -3.960167],
              [134.702759, -3.956076],
              [134.709778, -3.951828],
              [134.73162800000011, -3.949036],
              [134.73158300000011, -3.944848],
              [134.724213, -3.940362],
              [134.709412, -3.935506],
              [134.6959230000001, -3.935589]
            ]
          ],
          [
            [
              [134.100861, -3.914264],
              [134.091873, -3.914301],
              [134.080551, -3.918635],
              [134.071579, -3.917406],
              [134.067459, -3.920437],
              [134.055054, -3.917254],
              [134.051849, -3.923152],
              [134.060822, -3.928506],
              [134.06515500000012, -3.939985],
              [134.074005, -3.943503],
              [134.0737610000001, -3.959559],
              [134.078659, -3.971498],
              [134.0855100000001, -3.981321],
              [134.099442, -3.988701],
              [134.10791, -3.98671],
              [134.1133420000001, -3.992119],
              [134.126587, -3.99645],
              [134.133743, -3.993998],
              [134.136474, -3.998131],
              [134.150833, -3.999487],
              [134.154968, -4.003374],
              [134.168808, -4.005541],
              [134.167343, -4.014551],
              [134.1765140000001, -4.014904],
              [134.186493, -4.021123],
              [134.196792, -4.022058],
              [134.195267, -4.028023],
              [134.202484, -4.029741],
              [134.205688, -4.022754],
              [134.200455, -4.015879],
              [134.1875920000001, -4.007686],
              [134.18132, -4.007986],
              [134.1748960000001, -3.993812],
              [134.169586, -3.99317],
              [134.166229, -3.979738],
              [134.162293, -3.979214],
              [134.150696, -3.968976],
              [134.1473390000001, -3.959279],
              [134.13967900000011, -3.955128],
              [134.122543, -3.939435],
              [134.1213530000001, -3.935302],
              [134.106567, -3.923272],
              [134.099533, -3.920515],
              [134.100861, -3.914264]
            ]
          ],
          [
            [
              [134.037521, -3.856424],
              [134.037369, -3.849658],
              [134.023697, -3.858569],
              [134.014374, -3.857114],
              [134.014252, -3.866178],
              [134.023453, -3.869234],
              [134.032821, -3.866737],
              [134.037521, -3.856424]
            ]
          ],
          [
            [
              [134.032089, -3.816615],
              [134.027115, -3.81259],
              [134.022705, -3.818199],
              [134.012131, -3.822072],
              [134.002472, -3.82153],
              [134.001312, -3.828694],
              [134.005661, -3.83347],
              [134.000702, -3.841729],
              [134.002258, -3.85347],
              [134.008896, -3.849589],
              [134.01638800000012, -3.849208],
              [134.032852, -3.844656],
              [134.043488, -3.843479],
              [134.048355, -3.835979],
              [134.051117, -3.827214],
              [134.036896, -3.816225],
              [134.032089, -3.816615]
            ]
          ],
          [
            [
              [133.888992, -3.730652],
              [133.889572, -3.725378],
              [133.883576, -3.722267],
              [133.874542, -3.721499],
              [133.869843, -3.73164],
              [133.872558, -3.747877],
              [133.881165, -3.773475],
              [133.886871, -3.783461],
              [133.88679500000012, -3.79986],
              [133.894898, -3.814848],
              [133.8964840000001, -3.82839],
              [133.90161100000012, -3.840212],
              [133.908112, -3.849003],
              [133.916229, -3.855615],
              [133.922607, -3.867735],
              [133.926269, -3.870322],
              [133.931992, -3.858182],
              [133.929321, -3.846188],
              [133.922974, -3.847609],
              [133.926712, -3.836609],
              [133.921631, -3.822426],
              [133.913483, -3.808071],
              [133.90834, -3.805647],
              [133.903671, -3.787403],
              [133.90431200000012, -3.7788],
              [133.901245, -3.760908],
              [133.90155, -3.746915],
              [133.896591, -3.73474],
              [133.888992, -3.730652]
            ]
          ],
          [
            [
              [132.766159, -3.539457],
              [132.768723, -3.527625],
              [132.769394, -3.512971],
              [132.765213, -3.509977],
              [132.764343, -3.501131],
              [132.755478, -3.503945],
              [132.75274600000012, -3.519459],
              [132.752762, -3.531354],
              [132.75810300000012, -3.538535],
              [132.766159, -3.539457]
            ]
          ],
          [
            [
              [132.766785, -3.484287],
              [132.7685550000001, -3.47952],
              [132.761337, -3.468368],
              [132.76184100000012, -3.459177],
              [132.754974, -3.460499],
              [132.749527, -3.477209],
              [132.757187, -3.485953],
              [132.762451, -3.488829],
              [132.766785, -3.484287]
            ]
          ],
          [
            [
              [133.6171200000001, -3.423586199999932],
              [133.610764, -3.431281],
              [133.601518, -3.434472],
              [133.6041567000001, -3.439045199999953],
              [133.610809, -3.44029],
              [133.613495, -3.44873],
              [133.62490800000012, -3.456679],
              [133.628815, -3.44814],
              [133.628586, -3.442857],
              [133.63385, -3.436045],
              [133.63069410000003, -3.427179099999933],
              [133.6171200000001, -3.423586199999932]
            ]
          ],
          [
            [
              [132.651123, -3.404073],
              [132.645462, -3.40752],
              [132.633972, -3.40924],
              [132.629577, -3.420041],
              [132.6323850000001, -3.427802],
              [132.638794, -3.435273],
              [132.651169, -3.440165],
              [132.6739500000001, -3.452754],
              [132.683288, -3.456832],
              [132.681122, -3.465227],
              [132.677338, -3.467236],
              [132.677521, -3.479475],
              [132.680847, -3.48511],
              [132.68006900000012, -3.495566],
              [132.689056, -3.50017],
              [132.69725, -3.51011],
              [132.69725, -3.51523],
              [132.70372, -3.517699],
              [132.714813, -3.516096],
              [132.722885, -3.512305],
              [132.727387, -3.500807],
              [132.72525, -3.485177],
              [132.715164, -3.468398],
              [132.711044, -3.466789],
              [132.707489, -3.459145],
              [132.694321, -3.454597],
              [132.688477, -3.448618],
              [132.687103, -3.439772],
              [132.690018, -3.43609],
              [132.68461600000012, -3.426204],
              [132.666061, -3.41034],
              [132.651123, -3.404073]
            ]
          ],
          [
            [
              [132.8127290000001, -3.31642],
              [132.814148, -3.30912],
              [132.809113, -3.303259],
              [132.804886, -3.307167],
              [132.805756, -3.316185],
              [132.8127290000001, -3.31642]
            ]
          ],
          [
            [
              [132.449509, -3.102947],
              [132.440643, -3.102181],
              [132.4541620000001, -3.121056],
              [132.4596100000001, -3.126682],
              [132.465271, -3.127152],
              [132.478668, -3.136802],
              [132.489258, -3.13595],
              [132.493729, -3.139514],
              [132.5020750000001, -3.138997],
              [132.519012, -3.141302],
              [132.5404810000001, -3.154289],
              [132.544373, -3.150327],
              [132.546219, -3.141949],
              [132.534119, -3.137971],
              [132.518768, -3.125779],
              [132.500061, -3.119739],
              [132.49588, -3.11509],
              [132.489181, -3.115426],
              [132.477737, -3.112378],
              [132.471954, -3.106744],
              [132.459763, -3.10594],
              [132.449509, -3.102947]
            ]
          ],
          [
            [
              [133.774063, -3.054899],
              [133.767303, -3.063375],
              [133.76741, -3.07973],
              [133.7713470000001, -3.081403],
              [133.778305, -3.099547],
              [133.7827, -3.103862],
              [133.798874, -3.102123],
              [133.799972, -3.093005],
              [133.797699, -3.086284],
              [133.789352, -3.075249],
              [133.782974, -3.05573],
              [133.774063, -3.054899]
            ]
          ],
          [
            [
              [132.184281, -2.976229],
              [132.178436, -2.969283],
              [132.17178300000012, -2.972052],
              [132.185165, -2.983538],
              [132.194611, -2.98019],
              [132.199005, -2.983111],
              [132.210404, -2.981319],
              [132.219544, -2.986248],
              [132.22409, -2.981471],
              [132.241775, -2.983514],
              [132.24626200000012, -2.981206],
              [132.259338, -2.985502],
              [132.287048, -2.98823],
              [132.292587, -2.991151],
              [132.301483, -2.989703],
              [132.313461, -2.994062],
              [132.312347, -2.985432],
              [132.302536, -2.982566],
              [132.298843, -2.986139],
              [132.290893, -2.980052],
              [132.2830960000001, -2.982369],
              [132.278244, -2.980479],
              [132.267822, -2.981638],
              [132.26767, -2.975252],
              [132.2546840000001, -2.977515],
              [132.25148, -2.972233],
              [132.228104, -2.974442],
              [132.222412, -2.973538],
              [132.198029, -2.978344],
              [132.190781, -2.975414],
              [132.184281, -2.976229]
            ]
          ],
          [
            [
              [132.075806, -2.702403],
              [132.084686, -2.694461],
              [132.08566570000005, -2.687494499999957],
              [132.0827180000001, -2.682586],
              [132.0738070000001, -2.682586],
              [132.059967, -2.68754],
              [132.054047, -2.694461],
              [132.05601500000012, -2.702365],
              [132.065918, -2.705333],
              [132.075806, -2.702403]
            ]
          ],
          [
            [
              [132.561035, -2.642934],
              [132.545471, -2.643696],
              [132.539566, -2.649802],
              [132.552246, -2.655138],
              [132.565308, -2.656267],
              [132.565658, -2.643956],
              [132.561035, -2.642934]
            ]
          ],
          [
            [
              [132.4826660000001, -2.633545],
              [132.47554, -2.632116],
              [132.467316, -2.626898],
              [132.458511, -2.635194],
              [132.463776, -2.639499],
              [132.469406, -2.652379],
              [132.453491, -2.648483],
              [132.459641, -2.662747],
              [132.46376, -2.668545],
              [132.47374, -2.669457],
              [132.481415, -2.66231],
              [132.4865420000001, -2.66041],
              [132.49913, -2.660047],
              [132.506424, -2.656636],
              [132.511521, -2.650186],
              [132.5102230000001, -2.639376],
              [132.49556, -2.634683],
              [132.4826660000001, -2.633545]
            ]
          ],
          [
            [
              [133.617309, -2.543844],
              [133.618958, -2.537069],
              [133.6082, -2.536717],
              [133.599213, -2.541187],
              [133.58577, -2.554739],
              [133.583603, -2.564563],
              [133.588516, -2.564282],
              [133.609802, -2.547744],
              [133.617309, -2.543844]
            ]
          ],
          [
            [
              [134.38329270000008, -2.531227499999943],
              [134.3783112000001, -2.523241799999937],
              [134.37207, -2.526227],
              [134.370529, -2.531972],
              [134.372981, -2.541685],
              [134.3702134, -2.550941699999953],
              [134.37472530000002, -2.553509],
              [134.37954700000012, -2.567647],
              [134.385116, -2.577126],
              [134.3926090000001, -2.575578],
              [134.396088, -2.56134],
              [134.3940745000001, -2.542552099999966],
              [134.38999880000006, -2.531978599999945],
              [134.38329270000008, -2.531227499999943]
            ]
          ],
          [
            [
              [133.62592640000003, -2.493334799999957],
              [133.6194399000001, -2.493947299999945],
              [133.61402930000008, -2.505932],
              [133.61945130000004, -2.511949399999935],
              [133.6312829000001, -2.518829399999959],
              [133.63670420000005, -2.524479199999973],
              [133.6402892000001, -2.521044599999925],
              [133.6398, -2.514331399999946],
              [133.63216220000004, -2.493340499999931],
              [133.62592640000003, -2.493334799999957]
            ]
          ],
          [
            [
              [133.690619, -2.543497399999978],
              [133.69496870000012, -2.538664399999959],
              [133.69496870000012, -2.529481799999928],
              [133.6911023, -2.52271559999997],
              [133.682403, -2.500483899999949],
              [133.67080380000004, -2.492267799999979],
              [133.66017130000012, -2.492267799999979],
              [133.65292180000006, -2.498067399999968],
              [133.65243850000002, -2.509666599999946],
              [133.66017130000012, -2.523682199999939],
              [133.67998650000004, -2.542530799999952],
              [133.690619, -2.543497399999978]
            ]
          ],
          [
            [
              [133.4399687, -2.485549599999956],
              [133.430283, -2.483316599999966],
              [133.42688120000003, -2.489594599999975],
              [133.4311977000001, -2.501841699999943],
              [133.4482819000001, -2.497959799999933],
              [133.4487547000001, -2.487493699999959],
              [133.4399687, -2.485549599999956]
            ]
          ],
          [
            [
              [133.33972140000003, -2.423153699999943],
              [133.33124120000002, -2.425235599999951],
              [133.32166140000004, -2.439800599999955],
              [133.32521520000012, -2.446755699999926],
              [133.33368140000005, -2.453937799999949],
              [133.34272640000006, -2.464221899999927],
              [133.343901, -2.474750899999947],
              [133.362068, -2.485594099999958],
              [133.3732192000001, -2.496285199999932],
              [133.37842050000006, -2.486325199999953],
              [133.37736790000008, -2.476085199999943],
              [133.37016830000005, -2.456421],
              [133.3685968000001, -2.448832],
              [133.35316000000012, -2.434179799999924],
              [133.3520003000001, -2.431302799999969],
              [133.33972140000003, -2.423153699999943]
            ]
          ],
          [
            [
              [133.48792590000005, -2.401625199999955],
              [133.4815191, -2.402657199999965],
              [133.47721760000002, -2.399328199999957],
              [133.46696710000003, -2.398615099999972],
              [133.45897480000008, -2.408240099999944],
              [133.45888290000005, -2.41284509999997],
              [133.47129960000007, -2.429994299999976],
              [133.48154910000005, -2.439192399999968],
              [133.48167100000012, -2.446085399999959],
              [133.4939968000001, -2.468155599999932],
              [133.50177510000003, -2.46981969999996],
              [133.51117180000006, -2.476955799999928],
              [133.51608310000006, -2.47551679999998],
              [133.53206950000003, -2.485809],
              [133.5386592000001, -2.498852099999965],
              [133.55153270000005, -2.505698199999927],
              [133.56442210000012, -2.509614399999975],
              [133.57008110000004, -2.508980399999928],
              [133.58055940000008, -2.496448399999963],
              [133.59439450000002, -2.485847399999955],
              [133.58722510000007, -2.462456299999928],
              [133.57885110000007, -2.447287099999926],
              [133.57398580000006, -2.443497099999945],
              [133.56728920000012, -2.446655],
              [133.5551015000001, -2.443598899999927],
              [133.53894810000008, -2.433025799999939],
              [133.53127580000012, -2.435315699999933],
              [133.519302, -2.423132599999974],
              [133.505101, -2.413699399999928],
              [133.49233440000012, -2.407603299999948],
              [133.48792590000005, -2.401625199999955]
            ]
          ],
          [
            [
              [134.5056300000001, -2.345696],
              [134.500931, -2.352355],
              [134.510208, -2.354778],
              [134.52755700000012, -2.352026],
              [134.518387, -2.344781],
              [134.5056300000001, -2.345696]
            ]
          ],
          [
            [
              [134.526672, -2.312849],
              [134.531693, -2.306245],
              [134.525635, -2.301651],
              [134.518387, -2.300912],
              [134.520996, -2.308599],
              [134.526672, -2.312849]
            ]
          ],
          [
            [
              [134.471741, -2.328747],
              [134.476593, -2.316446],
              [134.490967, -2.30742],
              [134.491501, -2.301337],
              [134.48233, -2.298634],
              [134.466034, -2.310653],
              [134.461838, -2.317903],
              [134.462677, -2.325814],
              [134.471741, -2.328747]
            ]
          ],
          [
            [
              [134.572779, -2.352411599999925],
              [134.575928, -2.342061],
              [134.57283, -2.334128],
              [134.576828, -2.322468],
              [134.5762476000001, -2.317303299999935],
              [134.569321, -2.311207],
              [134.573257, -2.294897],
              [134.570557, -2.284839],
              [134.562836, -2.283628],
              [134.564499, -2.295468],
              [134.560104, -2.306206],
              [134.5629728, -2.318327],
              [134.5599976000001, -2.32585279999995],
              [134.551193, -2.333425],
              [134.55423, -2.337568],
              [134.564926, -2.338716],
              [134.5686647000001, -2.347055],
              [134.565399, -2.353134],
              [134.559677, -2.353307],
              [134.562256, -2.363818],
              [134.557968, -2.371227],
              [134.536728, -2.36714],
              [134.5294040000001, -2.369837],
              [134.523681, -2.366671],
              [134.511368, -2.367125],
              [134.504318, -2.362006],
              [134.50260900000012, -2.368727],
              [134.5040427, -2.376833599999941],
              [134.5108031000001, -2.38366979999995],
              [134.507843, -2.3959],
              [134.51471, -2.40452],
              [134.524506, -2.403152299999931],
              [134.533737, -2.415164],
              [134.546387, -2.41321819999996],
              [134.558349, -2.415632],
              [134.560303, -2.419422],
              [134.54588300000012, -2.421712],
              [134.542267, -2.426714],
              [134.534881, -2.426706],
              [134.533798, -2.433084],
              [134.541306, -2.434349],
              [134.5461120000001, -2.438148],
              [134.552017, -2.437288],
              [134.56420900000012, -2.443555],
              [134.5682680000001, -2.435341],
              [134.57708700000012, -2.431098],
              [134.576218, -2.41961],
              [134.567337, -2.416789],
              [134.56080640000005, -2.411036],
              [134.561157, -2.404659],
              [134.57341, -2.405363],
              [134.5744780000001, -2.399212],
              [134.5875400000001, -2.400712],
              [134.588455, -2.393765],
              [134.58627300000012, -2.384457],
              [134.5782018000001, -2.38376059999996],
              [134.578415, -2.377846],
              [134.572113, -2.363256],
              [134.572779, -2.352411599999925]
            ]
          ],
          [
            [
              [130.25416630000007, -2.226849],
              [130.24546830000008, -2.228304],
              [130.24632330000009, -2.232691],
              [130.25949130000004, -2.232327],
              [130.2665713, -2.236647],
              [130.2699593000001, -2.229089],
              [130.25416630000007, -2.226849]
            ]
          ],
          [
            [
              [133.632858, -2.234227],
              [133.62323, -2.2236],
              [133.615036, -2.22095],
              [133.609955, -2.233126],
              [133.614029, -2.238933],
              [133.632858, -2.234227]
            ]
          ],
          [
            [
              [133.763291, -2.245447],
              [133.771469, -2.224541],
              [133.772095, -2.218164],
              [133.762589, -2.215054],
              [133.732132, -2.230091],
              [133.710907, -2.246963],
              [133.700546, -2.252763],
              [133.6904760000001, -2.254709],
              [133.68641700000012, -2.263041],
              [133.691223, -2.266893],
              [133.707306, -2.259546],
              [133.718536, -2.255873],
              [133.733231, -2.246464],
              [133.74852, -2.246986],
              [133.7575680000001, -2.251128],
              [133.763291, -2.245447]
            ]
          ],
          [
            [
              [130.40339730000005, -2.21031],
              [130.39817830000004, -2.217129],
              [130.40516730000002, -2.220584],
              [130.4143223000001, -2.219475],
              [130.4250803000001, -2.222116],
              [130.43171730000006, -2.220952],
              [130.45036330000005, -2.227162],
              [130.46748430000002, -2.227774],
              [130.46467630000006, -2.22339],
              [130.45364430000006, -2.218613],
              [130.43916430000002, -2.215688],
              [130.4286353000001, -2.211892],
              [130.41856430000007, -2.211444],
              [130.4108893, -2.213648],
              [130.40339730000005, -2.21031]
            ]
          ],
          [
            [
              [134.478348, -2.241295],
              [134.487183, -2.224268],
              [134.495132, -2.2139],
              [134.4854580000001, -2.203513],
              [134.477722, -2.212618],
              [134.477676, -2.225467],
              [134.4734800000001, -2.232831],
              [134.472046, -2.242017],
              [134.478348, -2.241295]
            ]
          ],
          [
            [
              [130.23494030000006, -2.18346],
              [130.21594330000005, -2.184583],
              [130.21936030000006, -2.190466],
              [130.22639530000004, -2.193113],
              [130.2368173000001, -2.192464],
              [130.24020430000007, -2.188129],
              [130.24557530000004, -2.192856],
              [130.23640530000011, -2.199104],
              [130.22937030000003, -2.200325],
              [130.22302330000002, -2.197447],
              [130.2187203000001, -2.199589],
              [130.2472543, -2.21537],
              [130.2514503000001, -2.212477],
              [130.26048330000003, -2.214544],
              [130.2654573000001, -2.212747],
              [130.26341330000002, -2.201203],
              [130.2661753000001, -2.199179],
              [130.2898103000001, -2.201514],
              [130.2946783000001, -2.197813],
              [130.29365530000007, -2.192618],
              [130.28323430000012, -2.190265],
              [130.27372830000002, -2.191837],
              [130.26498430000004, -2.187923],
              [130.25749230000008, -2.188452],
              [130.2401433000001, -2.185878],
              [130.23494030000006, -2.18346]
            ]
          ],
          [
            [
              [133.759979, -2.183059],
              [133.746704, -2.180184],
              [133.73707600000012, -2.175979],
              [133.731873, -2.178793],
              [133.729309, -2.196604],
              [133.723419, -2.206709],
              [133.71112, -2.216814],
              [133.686615, -2.224497],
              [133.686218, -2.231734],
              [133.692123, -2.234148],
              [133.699402, -2.241103],
              [133.707932, -2.240243],
              [133.7270360000001, -2.223479],
              [133.752502, -2.207013],
              [133.760803, -2.206614],
              [133.778702, -2.182786],
              [133.777161, -2.179339],
              [133.759979, -2.183059]
            ]
          ],
          [
            [
              [133.874588, -2.160721],
              [133.8558660000001, -2.161402],
              [133.852783, -2.167029],
              [133.864182, -2.177602],
              [133.875626, -2.162793],
              [133.874588, -2.160721]
            ]
          ],
          [
            [
              [133.811188, -2.19929],
              [133.836487, -2.195859],
              [133.852966, -2.191325],
              [133.856796, -2.184839],
              [133.850082, -2.168576],
              [133.850082, -2.160652],
              [133.8698270000001, -2.155087],
              [133.872513, -2.150952],
              [133.850128, -2.145654],
              [133.838852, -2.146858],
              [133.827286, -2.153798],
              [133.808639, -2.16982],
              [133.794556, -2.180152],
              [133.7895360000001, -2.19003],
              [133.790115, -2.195828],
              [133.80047600000012, -2.199111],
              [133.811188, -2.19929]
            ]
          ],
          [
            [
              [130.4515993000001, -2.127051],
              [130.44218530000012, -2.121366199999954],
              [130.4402013, -2.124411],
              [130.4448853, -2.132661],
              [130.45329330000004, -2.136575],
              [130.47189430000003, -2.14013],
              [130.47616630000005, -2.134517],
              [130.4622353000001, -2.129519],
              [130.45834430000002, -2.131372],
              [130.4515993000001, -2.127051]
            ]
          ],
          [
            [
              [130.39274250000005, -2.115112699999941],
              [130.37203480000005, -2.117186799999956],
              [130.36336390000008, -2.116901299999938],
              [130.344709, -2.122439399999962],
              [130.33792130000006, -2.129727799999955],
              [130.3427375000001, -2.135954899999945],
              [130.35054810000008, -2.13558949999998],
              [130.36274780000008, -2.131825399999968],
              [130.38632770000004, -2.12921549999993],
              [130.39669170000002, -2.122474],
              [130.39274250000005, -2.115112699999941]
            ]
          ],
          [
            [
              [130.29708670000002, -2.137055499999974],
              [130.301037, -2.130331099999978],
              [130.30867790000002, -2.125684099999944],
              [130.317512, -2.115757599999938],
              [130.3120182, -2.110518399999933],
              [130.3041763000001, -2.112368799999956],
              [130.2930586000001, -2.119133099999942],
              [130.288123, -2.125700699999925],
              [130.28940720000003, -2.134557299999926],
              [130.29708670000002, -2.137055499999974]
            ]
          ],
          [
            [
              [133.8989560000001, -2.134712],
              [133.9120630000001, -2.132938],
              [133.928024, -2.118698],
              [133.924301, -2.111751],
              [133.914505, -2.107836],
              [133.895904, -2.11133],
              [133.891098, -2.114197],
              [133.889786, -2.123162],
              [133.892944, -2.133736],
              [133.8989560000001, -2.134712]
            ]
          ],
          [
            [
              [133.882645, -2.140621],
              [133.886246, -2.133845],
              [133.886291, -2.113339],
              [133.876434, -2.109884],
              [133.8668060000001, -2.10207],
              [133.860062, -2.100859],
              [133.854858, -2.108892],
              [133.851547, -2.124975],
              [133.8523560000001, -2.130493],
              [133.867233, -2.135096],
              [133.879959, -2.141707],
              [133.882645, -2.140621]
            ]
          ],
          [
            [
              [133.9331820000001, -2.114166],
              [133.936264, -2.108195],
              [133.9359740000001, -2.096472],
              [133.925674, -2.102606],
              [133.9331820000001, -2.114166]
            ]
          ],
          [
            [
              [130.3962266000001, -2.079071299999953],
              [130.39416660000006, -2.083577299999945],
              [130.39965960000006, -2.08813129999993],
              [130.4061593, -2.080948899999953],
              [130.3962266000001, -2.079071299999953]
            ]
          ],
          [
            [
              [134.72876, -2.083645],
              [134.72869900000012, -2.074165],
              [134.723038, -2.073532],
              [134.72287, -2.082669],
              [134.72876, -2.083645]
            ]
          ],
          [
            [
              [130.4291843000001, -2.070577499999956],
              [130.42150930000003, -2.06653],
              [130.4133763000001, -2.072776],
              [130.4089213000001, -2.07053],
              [130.406814, -2.08160369999996],
              [130.4162453, -2.079825],
              [130.4291843000001, -2.070577499999956]
            ]
          ],
          [
            [
              [130.4599303000001, -2.06209],
              [130.4489903000001, -2.067532],
              [130.44378730000005, -2.06546],
              [130.4378213000001, -2.071819],
              [130.44635030000006, -2.073367],
              [130.45573430000002, -2.072199],
              [130.46553130000007, -2.066817],
              [130.4599303000001, -2.06209]
            ]
          ],
          [
            [
              [130.3628093000001, -2.058353],
              [130.35743730000002, -2.051317],
              [130.35421830000007, -2.05669],
              [130.3628093000001, -2.058353]
            ]
          ],
          [
            [
              [134.74076800000012, -2.052572],
              [134.7454530000001, -2.04879],
              [134.7465360000001, -2.041544],
              [134.7446440000001, -2.030174],
              [134.7364960000001, -2.017421],
              [134.7252810000001, -2.026024],
              [134.729706, -2.03516],
              [134.731888, -2.046422],
              [134.74076800000012, -2.052572]
            ]
          ],
          [
            [
              [134.1590880000001, -2.024678],
              [134.158112, -2.015949],
              [134.154099, -2.006985],
              [134.149643, -2.018474],
              [134.147995, -2.036791],
              [134.151047, -2.049781],
              [134.157974, -2.050069],
              [134.155319, -2.031969],
              [134.1590880000001, -2.024678]
            ]
          ],
          [
            [
              [134.4129180000001, -2.042523],
              [134.4073029000001, -2.035739399999954],
              [134.407455, -2.022126],
              [134.40516660000003, -2.014889499999924],
              [134.404663, -2.000091],
              [134.389328, -1.993172],
              [134.373489, -1.992243],
              [134.361801, -1.998142],
              [134.355927, -2.010038],
              [134.344147, -2.028293],
              [134.342377, -2.038578],
              [134.338425, -2.046331],
              [134.3261414000001, -2.062524099999962],
              [134.3189850000001, -2.069409],
              [134.31463660000009, -2.083312099999944],
              [134.319565, -2.08692949999994],
              [134.32775830000003, -2.088196],
              [134.3224335000001, -2.094917099999975],
              [134.317337, -2.089002],
              [134.31099, -2.093127],
              [134.31723020000004, -2.09847259999998],
              [134.33361820000005, -2.106241],
              [134.336426, -2.113649],
              [134.33403, -2.123075],
              [134.334549, -2.134671],
              [134.340744, -2.14558889999995],
              [134.34005700000012, -2.151396],
              [134.346832, -2.165932],
              [134.35112, -2.166972],
              [134.35374450000006, -2.158178799999973],
              [134.36119070000007, -2.154911899999945],
              [134.370804, -2.140266],
              [134.372787, -2.130506],
              [134.3811333000001, -2.118493599999965],
              [134.37981490000004, -2.105670599999939],
              [134.389038, -2.103222],
              [134.391723, -2.096166],
              [134.38621520000004, -2.092250299999932],
              [134.384201, -2.082028899999955],
              [134.3917540000001, -2.076745],
              [134.401199, -2.07641],
              [134.40509, -2.072845],
              [134.3970806000001, -2.066061499999932],
              [134.407028, -2.044007],
              [134.4129180000001, -2.042523]
            ]
          ],
          [
            [
              [130.56167630000004, -1.987681],
              [130.54921030000003, -1.988789],
              [130.5486913000001, -1.995917],
              [130.5625153000001, -1.997631],
              [130.57356330000005, -2.002716],
              [130.57882730000006, -2.00176],
              [130.58837930000004, -2.005345],
              [130.5965123000001, -2.002955],
              [130.6000673000001, -1.998378],
              [130.59223930000007, -1.994392],
              [130.58360330000005, -1.997868],
              [130.58041430000003, -1.992397],
              [130.56961130000002, -1.993169],
              [130.56167630000004, -1.987681]
            ]
          ],
          [
            [
              [130.45929030000002, -1.968232],
              [130.45387330000005, -1.96154],
              [130.44424530000003, -1.966636],
              [130.44400030000008, -1.974117],
              [130.4483193000001, -1.981895],
              [130.4534003000001, -1.984444],
              [130.46237230000008, -1.983211],
              [130.45929030000002, -1.968232]
            ]
          ],
          [
            [
              [130.4590763000001, -1.896328],
              [130.4510193000001, -1.896656],
              [130.4512793, -1.903332],
              [130.46293630000002, -1.904287],
              [130.4660493, -1.898135],
              [130.4590763000001, -1.896328]
            ]
          ],
          [
            [
              [129.6456763000001, -1.785689],
              [129.64462330000003, -1.77469],
              [129.6400913000001, -1.765004],
              [129.63493430000005, -1.767638],
              [129.63057030000004, -1.786735],
              [129.6240543, -1.789134],
              [129.62812830000007, -1.797844],
              [129.6327063000001, -1.799923],
              [129.63580330000002, -1.812712],
              [129.6409913000001, -1.817514],
              [129.6442723, -1.827589],
              [129.64987230000008, -1.829107],
              [129.65446530000008, -1.824574],
              [129.65286330000004, -1.807876],
              [129.6543733000001, -1.800512],
              [129.6456763000001, -1.785689]
            ]
          ],
          [
            [
              [129.6244663000001, -1.782865],
              [129.6275333000001, -1.774126],
              [129.61979730000007, -1.76322],
              [129.61016930000005, -1.766534],
              [129.60946730000012, -1.771301],
              [129.6208653000001, -1.784178],
              [129.6244663000001, -1.782865]
            ]
          ],
          [
            [
              [129.69334430000004, -1.775352],
              [129.69418330000008, -1.764415],
              [129.68870630000004, -1.747999],
              [129.68415930000003, -1.755816],
              [129.68997230000002, -1.77529],
              [129.69334430000004, -1.775352]
            ]
          ],
          [
            [
              [134.23471730000006, -1.74241619999998],
              [134.2299650000001, -1.737641],
              [134.2216797000001, -1.73413249999993],
              [134.22102360000008, -1.739963899999964],
              [134.2023160000001, -1.73816],
              [134.2025000000001, -1.754424],
              [134.1950836000001, -1.76344359999996],
              [134.1818085000001, -1.786140699999976],
              [134.18003850000002, -1.795973499999945],
              [134.173607, -1.804923199999962],
              [134.15899600000012, -1.819476599999973],
              [134.150253, -1.823955],
              [134.1481470000001, -1.82913],
              [134.146637, -1.852350099999967],
              [134.14961240000002, -1.859477799999979],
              [134.14985660000002, -1.867808799999978],
              [134.14689640000006, -1.880165599999941],
              [134.14737, -1.887927],
              [134.142578, -1.896204],
              [134.1474908, -1.901141899999971],
              [134.13681050000002, -1.902980499999956],
              [134.1379700000001, -1.913618],
              [134.150482, -1.907013],
              [134.15751650000004, -1.91988379999998],
              [134.1594238, -1.93471859999994],
              [134.1640625000001, -1.942126499999972],
              [134.16499380000005, -1.949832699999945],
              [134.17195130000005, -1.94443229999996],
              [134.169479, -1.933741],
              [134.1747130000001, -1.915866],
              [134.18191530000001, -1.910754],
              [134.18508910000003, -1.900287699999978],
              [134.19104, -1.90443],
              [134.19422910000003, -1.897998199999961],
              [134.19963070000006, -1.87644169999993],
              [134.21072390000006, -1.837127899999928],
              [134.21482850000007, -1.829547199999979],
              [134.20680240000002, -1.809882799999968],
              [134.20343050000008, -1.811556799999948],
              [134.201569, -1.824194],
              [134.19672730000002, -1.826723499999957],
              [134.198914, -1.807297],
              [134.19519, -1.801264],
              [134.195236, -1.793448],
              [134.2043152000001, -1.783451599999978],
              [134.208603, -1.783451],
              [134.2090154000001, -1.799425],
              [134.214951, -1.796666],
              [134.221512, -1.789203],
              [134.22682190000012, -1.777071799999931],
              [134.23098760000005, -1.776266199999952],
              [134.233993, -1.763512],
              [134.238266, -1.754601],
              [134.2384949000001, -1.744659799999965],
              [134.23471730000006, -1.74241619999998]
            ]
          ],
          [
            [
              [130.26403590000007, -1.700521],
              [130.2601783, -1.694777],
              [130.25048930000003, -1.698661],
              [130.25572230000012, -1.70605],
              [130.26403590000007, -1.700521]
            ]
          ],
          [
            [
              [129.77160730000003, -1.701612],
              [129.76300130000004, -1.696233],
              [129.7653663000001, -1.707005],
              [129.77499430000012, -1.715034],
              [129.78002930000002, -1.712753],
              [129.7782443000001, -1.701003],
              [129.77160730000003, -1.701612]
            ]
          ],
          [
            [
              [130.26403590000007, -1.700521],
              [130.26348930000006, -1.709005],
              [130.2665713, -1.712296],
              [130.2540593000001, -1.723544],
              [130.2457743000001, -1.722543],
              [130.24224930000003, -1.718501],
              [130.2355503000001, -1.718766],
              [130.2330783000001, -1.723018],
              [130.2245643000001, -1.719249],
              [130.21032730000002, -1.719552],
              [130.2007453000001, -1.713658],
              [130.1911933, -1.71253],
              [130.17381330000012, -1.715133],
              [130.16145330000006, -1.714232],
              [130.1556253000001, -1.715654],
              [130.13520830000004, -1.713002],
              [130.12754930000006, -1.714705],
              [130.12117030000002, -1.707606],
              [130.10943630000008, -1.70999],
              [130.11236530000008, -1.716828],
              [130.10345530000006, -1.717798],
              [130.0937203000001, -1.724043],
              [130.07856830000003, -1.721642],
              [130.07045030000006, -1.721789],
              [130.05902130000004, -1.718067],
              [130.0489513000001, -1.72063],
              [130.0429233000001, -1.726258],
              [130.0267033, -1.735129],
              [130.00611930000002, -1.736276],
              [129.98800730000005, -1.747336],
              [129.97921830000007, -1.759225],
              [129.9730843000001, -1.761968],
              [129.9617313000001, -1.757159],
              [129.96028130000002, -1.762904],
              [129.9470983000001, -1.769485],
              [129.93916330000002, -1.767452],
              [129.9162453, -1.776822],
              [129.9001773000001, -1.77614],
              [129.8934183, -1.780385],
              [129.88737530000003, -1.793024],
              [129.88003630000003, -1.799277],
              [129.86874430000012, -1.803451],
              [129.85791030000007, -1.813378],
              [129.84004230000005, -1.820286],
              [129.81910730000004, -1.82184],
              [129.81015030000003, -1.832462],
              [129.8059853000001, -1.826747],
              [129.80067530000008, -1.826731],
              [129.78323430000012, -1.836361],
              [129.7737433000001, -1.838698],
              [129.7652743000001, -1.836946],
              [129.75016830000004, -1.841103],
              [129.73831230000008, -1.846589],
              [129.73251430000005, -1.851864],
              [129.72378630000003, -1.864043],
              [129.7233133000001, -1.86928],
              [129.71786530000008, -1.871969],
              [129.72415230000001, -1.889949],
              [129.73977730000001, -1.900419],
              [129.75584430000004, -1.90225],
              [129.76330630000007, -1.91056],
              [129.77807630000007, -1.920162],
              [129.77903830000002, -1.924485],
              [129.78610330000004, -1.930199],
              [129.79383930000006, -1.92723],
              [129.80537530000004, -1.930084],
              [129.8078163, -1.934064],
              [129.8285833000001, -1.94938],
              [129.83662430000004, -1.957464],
              [129.85095230000002, -1.962117],
              [129.85179130000006, -1.967065],
              [129.86224430000004, -1.970038],
              [129.87698430000012, -1.976582],
              [129.90258830000005, -1.98011],
              [129.9090123000001, -1.988764],
              [129.9167943000001, -1.988273],
              [129.91838130000008, -1.99323],
              [129.9287723000001, -1.996021],
              [129.94154430000003, -1.993122],
              [129.94465730000002, -1.998829],
              [129.9426433000001, -2.009277],
              [129.9552923000001, -2.011107],
              [129.9602973000001, -2.01595],
              [129.96495130000005, -2.014501],
              [129.97676130000002, -2.006113],
              [129.97882130000005, -2.002473],
              [129.98854130000007, -2.011178],
              [130.00061030000006, -2.016589],
              [130.00312830000007, -2.012833],
              [130.01796030000003, -2.00854],
              [130.0096443000001, -2.024024],
              [130.01782230000003, -2.023551],
              [130.02853430000005, -2.017244],
              [130.0368353, -2.027212],
              [130.0412453, -2.025936],
              [130.04286230000002, -2.018486],
              [130.04831030000003, -2.014726],
              [130.0482333000001, -2.025927],
              [130.0545353000001, -2.02332],
              [130.06259230000012, -2.026889],
              [130.0699313, -2.021279],
              [130.07679830000006, -2.019191],
              [130.0877233000001, -2.022698],
              [130.0877693000001, -2.030607],
              [130.09390330000008, -2.02904],
              [130.10035730000004, -2.03469],
              [130.10206630000005, -2.041962],
              [130.09045430000003, -2.040361],
              [130.0907903000001, -2.046538],
              [130.09805330000006, -2.050627],
              [130.1098333000001, -2.051188],
              [130.1157233, -2.053894],
              [130.1157233, -2.059494],
              [130.12223830000005, -2.061737],
              [130.12728930000003, -2.056823],
              [130.1419373000001, -2.057611],
              [130.16117930000007, -2.053602],
              [130.1687783000001, -2.055093],
              [130.1864633, -2.062863],
              [130.1905223000001, -2.059971],
              [130.2006533000001, -2.06267],
              [130.2134863000001, -2.056707],
              [130.22572330000003, -2.054076],
              [130.23637430000008, -2.055637],
              [130.2445073, -2.053028],
              [130.25372330000005, -2.053535],
              [130.2635203000001, -2.043996],
              [130.26187130000005, -2.036146],
              [130.26944030000004, -2.027303],
              [130.2614903000001, -2.022175],
              [130.25160230000006, -2.009603],
              [130.2645883, -2.015647],
              [130.27592530000004, -2.010205],
              [130.2853093000001, -2.013252],
              [130.29304530000002, -2.009547],
              [130.2995763, -2.000721],
              [130.30482530000006, -2.000062],
              [130.30635130000007, -1.991604],
              [130.3197943, -1.973979],
              [130.3256533000001, -1.981711],
              [130.3355563, -1.979717],
              [130.33621330000005, -1.986691],
              [130.3450173000001, -1.986028],
              [130.37168930000007, -1.996928],
              [130.37313930000005, -2.004988],
              [130.37803730000007, -2.012839],
              [130.38617030000012, -2.008613],
              [130.3927463000001, -2.014782],
              [130.38253830000008, -2.023744],
              [130.37332230000004, -2.026932],
              [130.36616530000003, -2.02648],
              [130.36261030000003, -2.030122],
              [130.3633423000001, -2.038377],
              [130.3730323000001, -2.030916],
              [130.3818973000001, -2.032174],
              [130.38916030000007, -2.036148],
              [130.40232930000002, -2.028452],
              [130.4044043, -2.023888],
              [130.41555830000004, -2.025259],
              [130.4249423000001, -2.024265],
              [130.42718530000002, -2.020393],
              [130.4407503000001, -2.019336],
              [130.45364430000006, -2.009562],
              [130.45085230000007, -2.003619],
              [130.43772930000011, -2.011257],
              [130.42799430000002, -2.012367],
              [130.4283603, -2.004284],
              [130.41484130000003, -2.004649],
              [130.4135443, -1.998254],
              [130.40383930000007, -1.99414],
              [130.39712630000008, -1.985603],
              [130.39077830000008, -1.982014],
              [130.40277130000004, -1.971571],
              [130.4107213000001, -1.985761],
              [130.41847330000007, -1.982801],
              [130.42302030000008, -1.987403],
              [130.43383830000005, -1.980215],
              [130.43928630000005, -1.978759],
              [130.44232230000011, -1.963805],
              [130.43765330000008, -1.954988],
              [130.42555330000005, -1.948153],
              [130.4171603000001, -1.948129],
              [130.41244530000006, -1.952608],
              [130.40789830000006, -1.949899],
              [130.3878023000001, -1.950346],
              [130.38273630000003, -1.945952],
              [130.3771213000001, -1.947148],
              [130.3704533, -1.942238],
              [130.36331230000008, -1.950348],
              [130.3592993000001, -1.943988],
              [130.36566230000005, -1.940494],
              [130.36467030000006, -1.929956],
              [130.35464530000002, -1.932574],
              [130.34878630000003, -1.922942],
              [130.3379983000001, -1.919744],
              [130.34242330000006, -1.912334],
              [130.35054130000003, -1.91547],
              [130.3686073, -1.900493],
              [130.37493930000005, -1.903159],
              [130.3867653000001, -1.888745],
              [130.40420630000006, -1.892195],
              [130.41023330000007, -1.885942],
              [130.40785330000006, -1.879891],
              [130.41542130000005, -1.873521],
              [130.4075173000001, -1.861981],
              [130.4079293000001, -1.855477],
              [130.4133763000001, -1.851233],
              [130.4253093000001, -1.857832],
              [130.43335030000003, -1.846694],
              [130.43711930000006, -1.832536],
              [130.45423930000004, -1.827754],
              [130.43945330000008, -1.820975],
              [130.4224253000001, -1.799914],
              [130.4171603000001, -1.786374],
              [130.41816730000005, -1.775836],
              [130.39866730000006, -1.760817],
              [130.3794253000001, -1.751896],
              [130.3735203000001, -1.754756],
              [130.3473213000001, -1.726009],
              [130.34573430000012, -1.72146],
              [130.35188330000005, -1.715605],
              [130.36372430000006, -1.71466],
              [130.3667603, -1.709892],
              [130.3654183000001, -1.703958],
              [130.3716743000001, -1.698855],
              [130.37616030000004, -1.691436],
              [130.3782963000001, -1.682235],
              [130.37530530000004, -1.674801],
              [130.36308330000008, -1.668781],
              [130.3564153000001, -1.668331699999953],
              [130.3455973, -1.664002],
              [130.3354653, -1.670075],
              [130.32833930000004, -1.679494],
              [130.30091930000003, -1.686088],
              [130.29249630000004, -1.689293],
              [130.27700830000003, -1.689706],
              [130.26985230000003, -1.692105],
              [130.26403590000007, -1.700521]
            ]
          ],
          [
            [
              [129.85275330000002, -1.654999],
              [129.85661330000005, -1.661338],
              [129.86364830000002, -1.66177],
              [129.86241230000007, -1.655258],
              [129.85275330000002, -1.654999]
            ]
          ],
          [
            [
              [129.9090883, -1.63905],
              [129.91664230000003, -1.634235],
              [129.9176033000001, -1.624447],
              [129.91023330000007, -1.621664],
              [129.90596030000006, -1.616183],
              [129.90182530000004, -1.620608],
              [129.9020693000001, -1.631029],
              [129.9090883, -1.63905]
            ]
          ],
          [
            [
              [131.30004850000012, -1.533278799999948],
              [131.28928140000005, -1.532710499999951],
              [131.27825340000004, -1.534318699999972],
              [131.27027070000008, -1.541964],
              [131.27498550000007, -1.544941799999947],
              [131.29657440000005, -1.545438099999956],
              [131.30960230000005, -1.547051],
              [131.30985040000007, -1.540226899999936],
              [131.30004850000012, -1.533278799999948]
            ]
          ],
          [
            [
              [130.2342533000001, -1.475899],
              [130.2383883000001, -1.46952],
              [130.24469030000012, -1.466777],
              [130.25898730000006, -1.46509],
              [130.2674723, -1.459415],
              [130.26258930000006, -1.45048],
              [130.2426613, -1.456466],
              [130.2276763000001, -1.459121],
              [130.2062383000001, -1.471612],
              [130.2067413000001, -1.477943],
              [130.21467630000006, -1.480672],
              [130.2342533000001, -1.475899]
            ]
          ],
          [
            [
              [130.31393530000003, -1.422077],
              [130.31123430000002, -1.425235],
              [130.32458530000008, -1.430115],
              [130.32609630000002, -1.425284],
              [130.31393530000003, -1.422077]
            ]
          ],
          [
            [
              [131.0179717000001, -1.311113899999953],
              [131.02457720000007, -1.298223099999973],
              [131.0145629000001, -1.296025799999938],
              [131.01076290000003, -1.299658099999931],
              [131.0179717000001, -1.311113899999953]
            ]
          ],
          [
            [
              [129.75714130000006, -1.262105],
              [129.76417630000003, -1.259651],
              [129.76722730000006, -1.255055],
              [129.7577523000001, -1.251114],
              [129.7506413000001, -1.254889],
              [129.7515873000001, -1.261103],
              [129.75714130000006, -1.262105]
            ]
          ],
          [
            [
              [129.66516130000002, -1.251698],
              [129.6557163000001, -1.254659],
              [129.6580963, -1.261687],
              [129.6671913, -1.260336],
              [129.66516130000002, -1.251698]
            ]
          ],
          [
            [
              [129.65565530000003, -1.22404],
              [129.65727330000004, -1.219842],
              [129.65237530000002, -1.214353],
              [129.64863630000002, -1.222577],
              [129.65565530000003, -1.22404]
            ]
          ],
          [
            [
              [129.74083030000008, -1.215544],
              [129.7337043000001, -1.209015],
              [129.73059130000001, -1.216777],
              [129.74083030000008, -1.215544]
            ]
          ],
          [
            [
              [129.77708530000007, -1.217838],
              [129.7862553000001, -1.210553],
              [129.7845003000001, -1.205199],
              [129.77494830000012, -1.208965],
              [129.76861630000008, -1.216547],
              [129.77708530000007, -1.217838]
            ]
          ],
          [
            [
              [129.71292130000006, -1.199913],
              [129.71774330000005, -1.195325],
              [129.71507330000009, -1.190315],
              [129.70597930000008, -1.194253],
              [129.71292130000006, -1.199913]
            ]
          ],
          [
            [
              [129.65745630000004, -1.202284],
              [129.65834130000007, -1.193591],
              [129.65167330000008, -1.187759],
              [129.64920130000007, -1.192988],
              [129.6507113, -1.200251],
              [129.65745630000004, -1.202284]
            ]
          ],
          [
            [
              [129.39048830000002, -1.17788],
              [129.39640830000008, -1.187105],
              [129.4031533000001, -1.184941],
              [129.40083430000004, -1.179812],
              [129.39048830000002, -1.17788]
            ]
          ],
          [
            [
              [129.67733070000008, -1.177225799999974],
              [129.6732717000001, -1.178991799999949],
              [129.6784447000001, -1.187700799999959],
              [129.69135270000004, -1.190219799999966],
              [129.69149070000003, -1.182331799999929],
              [129.67733070000008, -1.177225799999974]
            ]
          ],
          [
            [
              [129.46383730000002, -1.184603],
              [129.48103430000003, -1.1732],
              [129.48053030000005, -1.16865],
              [129.46980330000008, -1.160621],
              [129.46708730000012, -1.155837],
              [129.45369030000006, -1.158094],
              [129.44720530000006, -1.16337],
              [129.4452973000001, -1.168716],
              [129.44807530000003, -1.177879],
              [129.45622330000003, -1.184579],
              [129.46383730000002, -1.184603]
            ]
          ],
          [
            [
              [131.0536813000001, -1.190325],
              [131.05744930000003, -1.18723],
              [131.06068430000005, -1.172756],
              [131.05784630000005, -1.15567],
              [131.05339130000004, -1.154278],
              [131.0476083000001, -1.165877],
              [131.04844730000002, -1.175194],
              [131.0536813000001, -1.190325]
            ]
          ],
          [
            [
              [129.35699660000012, -1.153642899999966],
              [129.35891730000003, -1.165182],
              [129.35432430000003, -1.170349],
              [129.3638463000001, -1.176125],
              [129.3799143000001, -1.17508],
              [129.37893730000008, -1.161729],
              [129.37661830000002, -1.156827],
              [129.3654183000001, -1.152244],
              [129.35699660000012, -1.153642899999966]
            ]
          ],
          [
            [
              [129.35699660000012, -1.153642899999966],
              [129.3526313000001, -1.147717],
              [129.34314030000007, -1.144636],
              [129.3176883000001, -1.147322],
              [129.30770930000006, -1.157076],
              [129.31913830000008, -1.158954],
              [129.33009430000004, -1.164685],
              [129.33342030000006, -1.160035],
              [129.34063830000002, -1.160403],
              [129.35037330000011, -1.153869],
              [129.35699660000012, -1.153642899999966]
            ]
          ],
          [
            [
              [129.7484743000001, -1.143266],
              [129.74017330000004, -1.144626],
              [129.72891330000004, -1.143553],
              [129.72175630000004, -1.146875],
              [129.71116630000006, -1.148109],
              [129.70004330000006, -1.156996],
              [129.68864530000008, -1.161677],
              [129.67594930000007, -1.163888],
              [129.67820830000005, -1.170861],
              [129.69239830000004, -1.16969],
              [129.6908883000001, -1.17614],
              [129.69549630000006, -1.183058],
              [129.70834430000002, -1.188852],
              [129.71606530000008, -1.185475],
              [129.72065830000008, -1.180254],
              [129.7331703000001, -1.170906],
              [129.73805330000005, -1.162347],
              [129.7481693000001, -1.149137],
              [129.7484743000001, -1.143266]
            ]
          ],
          [
            [
              [129.88772630000005, -1.141273],
              [129.8795173000001, -1.133877],
              [129.86367830000006, -1.131811],
              [129.85089130000006, -1.144271],
              [129.8370523000001, -1.143073],
              [129.82811030000005, -1.149498],
              [129.8106693000001, -1.14869],
              [129.7910313000001, -1.154332],
              [129.77825930000006, -1.159185],
              [129.7649993000001, -1.15938],
              [129.75164830000006, -1.164522],
              [129.74707030000002, -1.167898],
              [129.7342533000001, -1.183977],
              [129.73365830000012, -1.190824],
              [129.74121130000003, -1.206046],
              [129.74697930000002, -1.209173],
              [129.7559053000001, -1.208908],
              [129.76809730000002, -1.202382],
              [129.7944953000001, -1.192793],
              [129.80380330000003, -1.196164],
              [129.81079130000012, -1.196126],
              [129.8166503000001, -1.202248],
              [129.82815630000005, -1.198996],
              [129.84004230000005, -1.200358],
              [129.86958330000004, -1.190606],
              [129.88609330000008, -1.182649],
              [129.89552330000004, -1.18251],
              [129.89515730000005, -1.188209],
              [129.88253830000008, -1.199394],
              [129.87292530000002, -1.202355],
              [129.87381030000006, -1.210649],
              [129.86784430000012, -1.213392],
              [129.86566230000005, -1.201181],
              [129.86074930000007, -1.200071],
              [129.8522653000001, -1.204822],
              [129.85035730000004, -1.209825],
              [129.84417730000007, -1.210786],
              [129.8388523000001, -1.216007],
              [129.82837030000007, -1.219376],
              [129.81964130000006, -1.230116],
              [129.82054230000006, -1.23501],
              [129.8300173, -1.238145],
              [129.8388523000001, -1.228617],
              [129.8502353, -1.230612],
              [129.8719943000001, -1.222446],
              [129.88237030000005, -1.216318],
              [129.89717130000008, -1.202202],
              [129.91902230000005, -1.184537],
              [129.92671230000008, -1.176674],
              [129.9235083000001, -1.191573],
              [129.92324930000007, -1.200664],
              [129.93199230000005, -1.204035],
              [129.93884330000003, -1.187877],
              [129.94424530000003, -1.183117],
              [129.95987030000003, -1.17896],
              [129.9641113, -1.176444],
              [129.9665993000001, -1.166954],
              [129.97357230000011, -1.169105],
              [129.97816530000011, -1.16221],
              [129.97035230000006, -1.156252],
              [129.95057730000008, -1.155155],
              [129.92988630000002, -1.152105],
              [129.91731330000005, -1.148147],
              [129.90857030000006, -1.150312],
              [129.89450130000012, -1.148888],
              [129.88772630000005, -1.141273]
            ]
          ],
          [
            [
              [129.8514103000001, -1.139721],
              [129.85521030000007, -1.133514],
              [129.85316530000011, -1.12977],
              [129.84135530000003, -1.138313],
              [129.8514103000001, -1.139721]
            ]
          ],
          [
            [
              [131.06987030000005, -1.14548],
              [131.07325830000002, -1.134968],
              [131.0675513000001, -1.132654],
              [131.0652013, -1.138625],
              [131.06987030000005, -1.14548]
            ]
          ],
          [
            [
              [129.77045410000005, -1.146702799999957],
              [129.78077430000008, -1.136937],
              [129.77912240000012, -1.128180499999928],
              [129.7705724000001, -1.127168499999925],
              [129.7563933, -1.138114099999939],
              [129.7569969000001, -1.145483799999965],
              [129.76480460000005, -1.148782299999937],
              [129.77045410000005, -1.146702799999957]
            ]
          ],
          [
            [
              [131.08164880000004, -1.078925699999957],
              [131.08386330000008, -1.083612],
              [131.0910043, -1.083166],
              [131.0903323000001, -1.077477],
              [131.08164880000004, -1.078925699999957]
            ]
          ],
          [
            [
              [131.1644593000001, -1.079776],
              [131.16893030000006, -1.073642],
              [131.17231830000003, -1.062496],
              [131.16093530000012, -1.071781],
              [131.1644593000001, -1.079776]
            ]
          ],
          [
            [
              [131.2355371000001, -1.055635699999925],
              [131.227554, -1.056038899999976],
              [131.22504920000006, -1.066552],
              [131.21853770000007, -1.076510699999972],
              [131.21987830000012, -1.08149009999994],
              [131.22677280000005, -1.08263919999996],
              [131.234005, -1.06434459999997],
              [131.2355371000001, -1.055635699999925]
            ]
          ],
          [
            [
              [131.15870730000006, -1.035147],
              [131.1548623000001, -1.04376],
              [131.15336630000002, -1.055085],
              [131.15072730000009, -1.060477],
              [131.15533530000005, -1.07153],
              [131.16093530000012, -1.068905],
              [131.1662143000001, -1.061558],
              [131.16708430000006, -1.050006],
              [131.16401730000007, -1.036665],
              [131.15870730000006, -1.035147]
            ]
          ],
          [
            [
              [131.244214, -1.014537099999927],
              [131.22975540000004, -1.01445259999997],
              [131.22862480000003, -1.021765899999934],
              [131.2350901000001, -1.02296709999996],
              [131.2444018000001, -1.020356599999957],
              [131.244214, -1.014537099999927]
            ]
          ],
          [
            [
              [131.08164880000004, -1.078925699999957],
              [131.08508330000006, -1.075163],
              [131.09428430000003, -1.074273],
              [131.09478830000012, -1.079384],
              [131.10496630000011, -1.080855],
              [131.11264130000006, -1.065592],
              [131.12051430000008, -1.074355],
              [131.12973030000012, -1.068861],
              [131.1290593000001, -1.059146],
              [131.13896230000012, -1.053833],
              [131.1360023000001, -1.043648],
              [131.12907430000007, -1.05276],
              [131.12049930000012, -1.049054],
              [131.12977630000012, -1.044772],
              [131.13087530000007, -1.036558],
              [131.12893730000008, -1.026898],
              [131.1317603000001, -1.02213],
              [131.1373453000001, -1.023874],
              [131.14720230000012, -1.015911],
              [131.14395230000002, -1.007219],
              [131.14309730000002, -0.995559],
              [131.13891630000012, -0.993869],
              [131.11909530000003, -0.998589],
              [131.10844530000008, -0.997751],
              [131.0937043, -1.000117],
              [131.09034730000008, -1.004813],
              [131.09025630000008, -1.019928],
              [131.0823673000001, -1.028244],
              [131.0845193, -1.034403],
              [131.07563830000004, -1.050933],
              [131.07737830000008, -1.070191],
              [131.07626430000005, -1.076182],
              [131.08164880000004, -1.078925699999957]
            ]
          ],
          [
            [
              [130.66050730000006, -0.950732],
              [130.6571503, -0.942076],
              [130.64668330000006, -0.938118],
              [130.63601730000005, -0.939469],
              [130.6374363000001, -0.94714],
              [130.64825530000007, -0.951215],
              [130.66050730000006, -0.950732]
            ]
          ],
          [
            [
              [131.25644150000005, -0.924085899999966],
              [131.2526541000001, -0.933742],
              [131.2625226, -0.935409],
              [131.25644150000005, -0.924085899999966]
            ]
          ],
          [
            [
              [131.1162263000001, -0.930403],
              [131.12381030000006, -0.922585],
              [131.11711230000003, -0.919847],
              [131.1140143, -0.923529],
              [131.1162263000001, -0.930403]
            ]
          ],
          [
            [
              [130.92492730000004, -0.895698],
              [130.91853330000004, -0.890254],
              [130.91583330000003, -0.896126],
              [130.90838630000007, -0.894889],
              [130.9026493, -0.898564],
              [130.89550830000007, -0.890696],
              [130.88444530000004, -0.896951],
              [130.8666843000001, -0.894985],
              [130.86215230000005, -0.896587],
              [130.85795630000007, -0.905399],
              [130.85035730000004, -0.902553],
              [130.8437203000001, -0.897218],
              [130.8378603000001, -0.901408],
              [130.8342133000001, -0.897882],
              [130.8258823000001, -0.907717],
              [130.81860430000006, -0.910813],
              [130.81213430000003, -0.908653],
              [130.80342130000008, -0.914572],
              [130.79878330000008, -0.91461],
              [130.79051230000005, -0.930732],
              [130.77825930000006, -0.929027699999949],
              [130.7632453000001, -0.931854],
              [130.75109930000008, -0.929688],
              [130.7402803000001, -0.930288],
              [130.72009330000003, -0.943484],
              [130.70278930000006, -0.943834],
              [130.69143730000008, -0.951128],
              [130.68249530000003, -0.953456],
              [130.65374830000007, -0.954407],
              [130.64617930000009, -0.955992],
              [130.6433723, -0.960426],
              [130.6359873, -0.960175],
              [130.63578830000006, -0.975345],
              [130.63714630000004, -0.984173],
              [130.64485230000003, -0.994292],
              [130.6499023, -0.996615],
              [130.6620643000001, -1.008126],
              [130.67382830000008, -1.011614],
              [130.67942830000004, -1.02345],
              [130.6853033000001, -1.026535],
              [130.69133030000012, -1.042191],
              [130.6974953, -1.051407],
              [130.70549030000006, -1.053331],
              [130.70787130000008, -1.060349],
              [130.70158430000004, -1.064648],
              [130.7030033000001, -1.069939],
              [130.71464630000003, -1.075381],
              [130.71847630000002, -1.079929],
              [130.71543930000007, -1.085729],
              [130.71937630000002, -1.090341],
              [130.71724030000007, -1.096655],
              [130.7077372000001, -1.097947699999963],
              [130.69236390000003, -1.094411],
              [130.67884450000008, -1.110559199999955],
              [130.69762150000008, -1.12257649999998],
              [130.69461720000004, -1.131589399999939],
              [130.70363010000005, -1.135344799999928],
              [130.70776110000008, -1.140977899999939],
              [130.725787, -1.153370799999948],
              [130.7363021000001, -1.163510299999928],
              [130.73442440000008, -1.17364989999993],
              [130.7276647000001, -1.181911799999966],
              [130.73423760000003, -1.18845749999997],
              [130.7450169000001, -1.190993799999944],
              [130.7484138000001, -1.196516899999949],
              [130.74713050000003, -1.206845699999974],
              [130.742692, -1.219738599999971],
              [130.7498782, -1.243622199999947],
              [130.7605986000001, -1.250993199999925],
              [130.766313, -1.259386199999938],
              [130.7697952000001, -1.259654099999977],
              [130.78363480000007, -1.253225399999963],
              [130.79250150000007, -1.259198899999944],
              [130.79944710000007, -1.259647],
              [130.8104257000001, -1.267712899999935],
              [130.81333840000002, -1.272642099999928],
              [130.82342070000004, -1.277347199999951],
              [130.83193470000003, -1.28899789999997],
              [130.84210840000003, -1.29548989999995],
              [130.86234880000006, -1.303695399999924],
              [130.8735630000001, -1.311354],
              [130.88286270000003, -1.313815599999941],
              [130.8987267000001, -1.322841699999969],
              [130.90994100000012, -1.326397499999928],
              [130.92389040000012, -1.328859099999931],
              [130.93047080000008, -1.331603399999949],
              [130.9360167000001, -1.342191799999966],
              [130.94701970000006, -1.353496299999961],
              [130.95968070000004, -1.360731099999953],
              [130.96766920000005, -1.363142799999935],
              [130.97942580000006, -1.355305],
              [130.98681140000008, -1.342191799999966],
              [130.989223, -1.330284399999925],
              [130.99519110000006, -1.318283299999962],
              [130.99989630000005, -1.312569899999971],
              [131.0015767000001, -1.303831699999932],
              [131.00661800000012, -1.293749299999945],
              [131.01367570000002, -1.285347199999933],
              [131.0183809, -1.26451],
              [131.03451280000002, -1.262829599999975],
              [131.03955410000003, -1.257116199999928],
              [131.043251, -1.243336799999952],
              [131.03989020000006, -1.225188299999957],
              [131.03047990000005, -1.208048099999928],
              [131.0312963, -1.19548],
              [131.04019230000006, -1.189823],
              [131.04414430000008, -1.184838],
              [131.0417023000001, -1.169307],
              [131.04487630000006, -1.153965],
              [131.04969830000005, -1.141046],
              [131.05195630000003, -1.123288],
              [131.05654930000003, -1.114793],
              [131.06123430000002, -1.111195],
              [131.0670933, -1.097069],
              [131.06585730000006, -1.082858],
              [131.0716553000001, -1.070292],
              [131.07252530000005, -1.060631],
              [131.0717013000001, -1.041662],
              [131.0769203000001, -1.030471],
              [131.0770573000001, -1.022249],
              [131.08239830000002, -1.007376],
              [131.07875130000002, -1.003569],
              [131.0706183000001, -1.009234],
              [131.0621043000001, -1.007256],
              [131.05993730000012, -1.000111],
              [131.06330930000001, -0.997197],
              [131.06274430000008, -0.989933],
              [131.0655673000001, -0.980887],
              [131.07702730000005, -0.978847],
              [131.07870530000002, -0.968526],
              [131.07058730000006, -0.96748],
              [131.0555273000001, -0.959398],
              [131.05680930000005, -0.949999],
              [131.05050730000005, -0.943976],
              [131.05270430000007, -0.938331],
              [131.04766930000005, -0.928681],
              [131.04005530000006, -0.927499],
              [131.0390933000001, -0.922081],
              [131.0303503, -0.91236],
              [131.02433830000007, -0.912108],
              [131.01344330000006, -0.919683],
              [130.99395830000003, -0.92585],
              [130.9830783000001, -0.919242],
              [130.97638030000007, -0.924527],
              [130.9694373000001, -0.926239],
              [130.96702630000004, -0.934308],
              [130.96203730000002, -0.93706],
              [130.9548803, -0.932476],
              [130.94679330000008, -0.939082],
              [130.93589830000008, -0.942551],
              [130.9236763, -0.930298],
              [130.92425630000002, -0.920781],
              [130.91865530000007, -0.913384],
              [130.92492730000004, -0.895698]
            ]
          ],
          [
            [
              [134.0987709000001, -0.888513799999942],
              [134.0916946000001, -0.89157739999996],
              [134.0910745000001, -0.899304699999959],
              [134.0958141000001, -0.901889899999958],
              [134.10369890000004, -0.916955599999937],
              [134.1093496000001, -0.921706399999948],
              [134.11237060000008, -0.916124399999944],
              [134.10665570000003, -0.90893],
              [134.1067965000001, -0.90132669999997],
              [134.1035581000001, -0.890485],
              [134.0987709000001, -0.888513799999942]
            ]
          ],
          [
            [
              [131.20585060000008, -0.882568699999979],
              [131.19683780000003, -0.885399899999925],
              [131.18497810000008, -0.89729159999996],
              [131.192243, -0.898594799999955],
              [131.1993606000001, -0.89449639999998],
              [131.20446550000008, -0.895188299999973],
              [131.2101268, -0.887464199999954],
              [131.20585060000008, -0.882568699999979]
            ]
          ],
          [
            [
              [129.90257330000009, -0.846922],
              [129.89926230000003, -0.837262],
              [129.8933263, -0.835419],
              [129.8905793, -0.842195],
              [129.89395230000002, -0.846283],
              [129.90257330000009, -0.846922]
            ]
          ],
          [
            [
              [130.52606230000004, -0.80194],
              [130.52092030000006, -0.797709],
              [130.50836230000004, -0.803267],
              [130.5122993000001, -0.812275],
              [130.5089733000001, -0.817233],
              [130.52229330000011, -0.828346],
              [130.5370183000001, -0.82788],
              [130.5422373, -0.822812],
              [130.54443430000003, -0.810075],
              [130.54873730000008, -0.803452],
              [130.5471503000001, -0.796877],
              [130.53334130000007, -0.798211],
              [130.52606230000004, -0.80194]
            ]
          ],
          [
            [
              [130.7107393000001, -0.793483],
              [130.7066813, -0.785506],
              [130.70239330000004, -0.782613],
              [130.69967730000008, -0.795286],
              [130.7107393000001, -0.793483]
            ]
          ],
          [
            [
              [129.7907113000001, -0.774188],
              [129.78437830000007, -0.76907],
              [129.77677930000004, -0.770325],
              [129.77723730000002, -0.775034],
              [129.7907113000001, -0.774188]
            ]
          ],
          [
            [
              [130.74488830000007, -0.785113],
              [130.74272230000008, -0.776909],
              [130.7346963000001, -0.778902],
              [130.7238933000001, -0.772356],
              [130.7156993000001, -0.773888],
              [130.71864330000005, -0.785312],
              [130.7255563000001, -0.796815],
              [130.73013330000003, -0.798731],
              [130.74063130000002, -0.792658],
              [130.74488830000007, -0.785113]
            ]
          ],
          [
            [
              [130.78663730000005, -0.756116],
              [130.78118930000005, -0.757248],
              [130.77174430000002, -0.754339],
              [130.7693183, -0.760274],
              [130.77327030000004, -0.764144],
              [130.76397730000008, -0.774505],
              [130.7572183000001, -0.77021],
              [130.7564093000001, -0.777935],
              [130.76075830000002, -0.782565],
              [130.76588530000004, -0.799199],
              [130.77320930000008, -0.80153],
              [130.77906830000006, -0.792889],
              [130.78164730000003, -0.784937],
              [130.7715303000001, -0.777523],
              [130.77438430000007, -0.77179],
              [130.7821963, -0.769424],
              [130.78213530000005, -0.762892],
              [130.78663730000005, -0.756116]
            ]
          ],
          [
            [
              [130.81123430000002, -0.766809],
              [130.81370630000004, -0.76186],
              [130.8076023000001, -0.75486],
              [130.80009530000007, -0.753732],
              [130.79596030000005, -0.758745],
              [130.80059830000005, -0.763601],
              [130.79496830000005, -0.771771],
              [130.79840130000002, -0.775479],
              [130.8072823000001, -0.773368],
              [130.81280530000004, -0.785849],
              [130.81326330000002, -0.792886],
              [130.8060313000001, -0.796037],
              [130.8133553, -0.801769],
              [130.80085830000007, -0.80427],
              [130.79025330000002, -0.813636],
              [130.79147430000012, -0.801759],
              [130.78672830000005, -0.798974],
              [130.78230330000008, -0.805073],
              [130.77267530000006, -0.806605],
              [130.7665263, -0.818981],
              [130.7549133000001, -0.810347],
              [130.7452393000001, -0.811074],
              [130.73313930000006, -0.821236],
              [130.72953830000006, -0.816787],
              [130.71917830000007, -0.815596],
              [130.71983430000012, -0.826849],
              [130.7137613000001, -0.830985],
              [130.70298830000002, -0.829912],
              [130.70684830000005, -0.82433],
              [130.70691030000012, -0.818332],
              [130.71206730000006, -0.812867],
              [130.69862430000012, -0.807571],
              [130.69558830000005, -0.811136],
              [130.6777813000001, -0.80595],
              [130.67366130000005, -0.799646],
              [130.66992230000005, -0.810132],
              [130.6824653000001, -0.813981],
              [130.68484530000012, -0.822818],
              [130.6771093000001, -0.823888],
              [130.67036530000007, -0.819765],
              [130.64825530000007, -0.821889],
              [130.6460883000001, -0.815079],
              [130.6466223000001, -0.804577],
              [130.63751230000003, -0.804146],
              [130.63362130000007, -0.801478],
              [130.63575830000002, -0.794277],
              [130.63153130000012, -0.792298],
              [130.62407030000008, -0.797357],
              [130.62623630000007, -0.802612],
              [130.61912630000006, -0.808928],
              [130.61956830000008, -0.814762],
              [130.6121223, -0.818483],
              [130.60525530000007, -0.81569],
              [130.5974433, -0.82444],
              [130.58902030000002, -0.827871],
              [130.58505330000003, -0.832585],
              [130.57762230000003, -0.830317],
              [130.58387830000004, -0.823757],
              [130.58647230000008, -0.811137],
              [130.58316130000003, -0.808587],
              [130.57244930000002, -0.809071],
              [130.5744023000001, -0.799961],
              [130.56657430000007, -0.794111],
              [130.56216430000006, -0.795831],
              [130.55799930000012, -0.813173],
              [130.5652013, -0.819548],
              [130.5625153000001, -0.824153],
              [130.55288730000007, -0.821814],
              [130.54824930000007, -0.827044],
              [130.54960630000005, -0.83703],
              [130.54266430000007, -0.840931],
              [130.52960230000008, -0.838927],
              [130.51393230000008, -0.83213],
              [130.5044713000001, -0.833535],
              [130.50019930000008, -0.829738],
              [130.49157730000002, -0.829777],
              [130.48484830000007, -0.832701],
              [130.47923330000003, -0.820337],
              [130.4943703, -0.808347],
              [130.50018330000012, -0.798014],
              [130.4879003000001, -0.792646],
              [130.47590730000002, -0.792975],
              [130.47003230000007, -0.799174],
              [130.47494530000006, -0.802166],
              [130.47226030000002, -0.810959],
              [130.47615130000008, -0.819986],
              [130.46975730000008, -0.821987],
              [130.46142630000008, -0.819964],
              [130.4584963000001, -0.809616],
              [130.45341530000007, -0.804091],
              [130.44662530000005, -0.828915],
              [130.44612230000007, -0.837074],
              [130.45361430000003, -0.843177],
              [130.45498730000008, -0.854611],
              [130.44780030000004, -0.866789],
              [130.44230730000004, -0.865633],
              [130.4373323000001, -0.859302],
              [130.43248030000007, -0.864352],
              [130.43670730000008, -0.867923],
              [130.4370583000001, -0.87459],
              [130.44625930000007, -0.879028],
              [130.45573430000002, -0.880707],
              [130.4575043000001, -0.877378],
              [130.46608030000004, -0.882332],
              [130.4519203000001, -0.889845],
              [130.44512930000008, -0.896496],
              [130.42353830000002, -0.892966],
              [130.4231423000001, -0.900718],
              [130.4148563000001, -0.905651],
              [130.4080513, -0.8999],
              [130.4062963, -0.911272],
              [130.40258830000005, -0.915642],
              [130.38974030000008, -0.923037],
              [130.4012613000001, -0.924589],
              [130.40875330000006, -0.919421],
              [130.41502430000003, -0.922187],
              [130.42176830000005, -0.911393],
              [130.4297643000001, -0.913471],
              [130.43386930000008, -0.908937],
              [130.44780030000004, -0.902112],
              [130.45802330000004, -0.900978],
              [130.4640813000001, -0.907887],
              [130.45991530000003, -0.913967],
              [130.47088630000007, -0.920078],
              [130.4727633000001, -0.915708],
              [130.46614130000012, -0.903236],
              [130.47287030000007, -0.899562],
              [130.4769903, -0.905196],
              [130.48481830000003, -0.908196],
              [130.4957283000001, -0.905859],
              [130.51501530000007, -0.897087],
              [130.52348330000007, -0.903054],
              [130.5125283000001, -0.911552],
              [130.52821430000006, -0.912062],
              [130.53428730000007, -0.918374],
              [130.54293930000006, -0.914942],
              [130.54655530000002, -0.905832],
              [130.55200230000003, -0.907703],
              [130.55833530000007, -0.915679],
              [130.5683143000001, -0.910809],
              [130.57959030000006, -0.914541],
              [130.58418330000006, -0.909248],
              [130.59471230000008, -0.913088],
              [130.60354630000006, -0.909829],
              [130.6106423000001, -0.911527],
              [130.62507730000004, -0.908917],
              [130.6341863, -0.903866],
              [130.6466223000001, -0.905816],
              [130.6611183000001, -0.899226],
              [130.67555230000005, -0.899501],
              [130.69441330000006, -0.888088],
              [130.7103433000001, -0.888833],
              [130.72581530000002, -0.882071],
              [130.73350630000004, -0.875872],
              [130.74682630000007, -0.882027],
              [130.75503630000003, -0.872762],
              [130.7649543000001, -0.872333],
              [130.77525330000003, -0.875776],
              [130.79266430000007, -0.871908],
              [130.79817230000003, -0.868866],
              [130.8011633000001, -0.860914],
              [130.81730730000004, -0.868579],
              [130.82699630000002, -0.864922],
              [130.84779430000003, -0.859505],
              [130.8518683000001, -0.853353],
              [130.85862830000008, -0.85326],
              [130.86241230000007, -0.846465],
              [130.86410530000012, -0.834009],
              [130.87107930000002, -0.835761],
              [130.86814930000003, -0.843134],
              [130.87197930000002, -0.847647],
              [130.8812723000001, -0.838101],
              [130.88740630000007, -0.839681],
              [130.89663730000007, -0.834631],
              [130.8968053000001, -0.830307],
              [130.8882913000001, -0.827225],
              [130.88026530000002, -0.830249],
              [130.87046830000008, -0.829583],
              [130.8606883000001, -0.818479],
              [130.8546913, -0.813903],
              [130.8603673, -0.810229],
              [130.8682103000001, -0.809213],
              [130.87022430000002, -0.801379],
              [130.87751830000002, -0.797306],
              [130.88237030000005, -0.799403],
              [130.88908430000004, -0.791694],
              [130.8976603000001, -0.800836],
              [130.90512130000002, -0.797342],
              [130.9154823, -0.798641],
              [130.9200293, -0.789602],
              [130.9253543000001, -0.784653],
              [130.91838130000008, -0.774135],
              [130.91149930000006, -0.779013],
              [130.89901730000008, -0.777362],
              [130.8959963000001, -0.773889],
              [130.89721730000008, -0.766281],
              [130.89056430000005, -0.76395],
              [130.88363730000003, -0.765834],
              [130.8745123000001, -0.772495],
              [130.86891230000003, -0.772822],
              [130.8672643000001, -0.765604],
              [130.85813930000006, -0.77279],
              [130.84840430000008, -0.767565],
              [130.8442083000001, -0.780185],
              [130.85107430000005, -0.784995],
              [130.85369930000002, -0.792149],
              [130.86583030000008, -0.794035],
              [130.8564153000001, -0.805888],
              [130.85397430000012, -0.798906],
              [130.84184330000005, -0.790561],
              [130.84216330000004, -0.768987],
              [130.83924930000012, -0.766726],
              [130.83201630000008, -0.771089],
              [130.8290263, -0.77886],
              [130.82467730000008, -0.782082],
              [130.8174593000001, -0.780791],
              [130.80894530000012, -0.771305],
              [130.81123430000002, -0.766809]
            ]
          ],
          [
            [
              [130.12812830000007, -0.751358],
              [130.13818430000003, -0.745393],
              [130.1514893000001, -0.742132],
              [130.15353430000005, -0.738685],
              [130.15033030000006, -0.731043],
              [130.14524930000005, -0.729082],
              [130.13452230000007, -0.733373],
              [130.1237953000001, -0.748474],
              [130.12812830000007, -0.751358]
            ]
          ],
          [
            [
              [130.2338873000001, -0.725171],
              [130.21675130000006, -0.726524],
              [130.2127693000001, -0.730777],
              [130.21424930000012, -0.742789],
              [130.2202463000001, -0.741991],
              [130.22630330000004, -0.736019],
              [130.23452830000008, -0.733854],
              [130.2338873000001, -0.725171]
            ]
          ],
          [
            [
              [130.2615823000001, -0.701642],
              [130.26808230000006, -0.696131],
              [130.2613983000001, -0.691584],
              [130.25621030000002, -0.694851],
              [130.25575330000004, -0.701925],
              [130.2615823000001, -0.701642]
            ]
          ],
          [
            [
              [130.2324533000001, -0.700286],
              [130.2460943000001, -0.696627],
              [130.24758930000007, -0.691742],
              [130.24066230000005, -0.687828],
              [130.2320413000001, -0.689993],
              [130.22610530000009, -0.696137],
              [130.2324533000001, -0.700286]
            ]
          ],
          [
            [
              [130.25288430000012, -0.681917],
              [130.25494430000003, -0.674915],
              [130.2497413000001, -0.670195],
              [130.24409530000003, -0.677714],
              [130.25288430000012, -0.681917]
            ]
          ],
          [
            [
              [130.31324830000005, -0.654126],
              [130.30546630000003, -0.645038],
              [130.30055330000005, -0.64399],
              [130.2867433, -0.648916],
              [130.28543130000003, -0.653629],
              [130.2697763000001, -0.654358],
              [130.26556430000005, -0.666933],
              [130.27555930000005, -0.671199],
              [130.28938330000005, -0.666454],
              [130.28955130000008, -0.661913],
              [130.30033930000002, -0.660082],
              [130.31324830000005, -0.654126]
            ]
          ],
          [
            [
              [130.2777063000001, -0.588575],
              [130.27609330000007, -0.602406],
              [130.28237930000012, -0.607189],
              [130.28762830000005, -0.596387],
              [130.2777063000001, -0.588575]
            ]
          ],
          [
            [
              [130.6502233000001, -0.578442],
              [130.64260930000012, -0.578707],
              [130.63331630000005, -0.583631],
              [130.6184853000001, -0.585599],
              [130.59762630000012, -0.591703],
              [130.59401030000004, -0.597566],
              [130.56982430000005, -0.610863],
              [130.5615243000001, -0.608703],
              [130.55160630000012, -0.618069],
              [130.5548563000001, -0.626372],
              [130.56211930000006, -0.626795],
              [130.5675053000001, -0.623527],
              [130.58171130000005, -0.619307],
              [130.60305830000004, -0.599037],
              [130.61610430000007, -0.597900899999956],
              [130.63627630000008, -0.593608],
              [130.6423493000001, -0.589019],
              [130.6568453000001, -0.58612],
              [130.6502233000001, -0.578442]
            ]
          ],
          [
            [
              [130.6753083000001, -0.57506],
              [130.68447930000002, -0.570941],
              [130.68214430000012, -0.565921],
              [130.66270530000008, -0.573463],
              [130.67117330000008, -0.577404],
              [130.6753083000001, -0.57506]
            ]
          ],
          [
            [
              [130.2777063000001, -0.588575],
              [130.2743683000001, -0.583845],
              [130.27597130000004, -0.576717],
              [130.26890630000003, -0.568878],
              [130.2713933000001, -0.564181],
              [130.2600863, -0.556695],
              [130.26002530000005, -0.564284],
              [130.26791430000003, -0.576538],
              [130.26312330000007, -0.581533],
              [130.27060030000007, -0.586714],
              [130.2777063000001, -0.588575]
            ]
          ],
          [
            [
              [130.8060313000001, -0.486848],
              [130.81727630000012, -0.478585],
              [130.81398030000003, -0.472001],
              [130.80250630000012, -0.477341],
              [130.79919430000007, -0.482452],
              [130.8060313000001, -0.486848]
            ]
          ],
          [
            [
              [130.44162030000007, -0.447459],
              [130.4337623, -0.446233],
              [130.43109230000005, -0.453244],
              [130.43469330000005, -0.456753],
              [130.44162030000007, -0.447459]
            ]
          ],
          [
            [
              [129.89071730000012, -0.407423],
              [129.88249230000008, -0.406033],
              [129.87512230000004, -0.41585],
              [129.86033630000009, -0.424223],
              [129.85131830000012, -0.426795],
              [129.8443003000001, -0.436901],
              [129.84498630000007, -0.446327],
              [129.84184330000005, -0.45133],
              [129.84156830000006, -0.461036],
              [129.8489383000001, -0.474611],
              [129.8495183000001, -0.478636],
              [129.85688830000004, -0.485029],
              [129.8620913000001, -0.499799],
              [129.87156730000004, -0.506887],
              [129.87904430000003, -0.501602],
              [129.8876193000001, -0.498741],
              [129.88835230000007, -0.494091],
              [129.89715630000012, -0.486399],
              [129.89726330000008, -0.481171],
              [129.90286330000004, -0.477957],
              [129.90353430000005, -0.462498],
              [129.89953630000002, -0.44979],
              [129.90289330000007, -0.447265],
              [129.91507030000002, -0.453322],
              [129.91340730000002, -0.437284],
              [129.9172823, -0.433547],
              [129.92019730000004, -0.424817],
              [129.91922030000012, -0.419643],
              [129.91218630000003, -0.409922],
              [129.90196330000003, -0.406406],
              [129.89071730000012, -0.407423]
            ]
          ],
          [
            [
              [130.61706630000003, -0.414636],
              [130.61755430000005, -0.407459],
              [130.6074073000001, -0.404527],
              [130.6033943000001, -0.408834],
              [130.6125793000001, -0.427646],
              [130.6041573000001, -0.428191],
              [130.5903783000001, -0.420896],
              [130.5702523000001, -0.43316],
              [130.5618293000001, -0.434113],
              [130.55036930000006, -0.440819],
              [130.5490423000001, -0.44594],
              [130.55520730000012, -0.453798],
              [130.5464333000001, -0.465977],
              [130.5358893, -0.467392],
              [130.5193183, -0.478171],
              [130.50474630000008, -0.463205],
              [130.50573830000008, -0.451744],
              [130.48281930000007, -0.439866],
              [130.47557130000007, -0.447042],
              [130.46540930000003, -0.453115],
              [130.4752353, -0.457896],
              [130.48089630000004, -0.465267],
              [130.46782030000008, -0.472598],
              [130.46188430000007, -0.469091],
              [130.45999230000007, -0.480407],
              [130.46331830000008, -0.482939],
              [130.46086130000003, -0.490122],
              [130.4713753000001, -0.495591],
              [130.50286930000004, -0.492288],
              [130.5080723000001, -0.4968],
              [130.50273230000005, -0.504617],
              [130.48451330000012, -0.508658],
              [130.46778930000005, -0.516967],
              [130.45265230000007, -0.518212],
              [130.45779430000005, -0.530286],
              [130.46327230000009, -0.531559],
              [130.47676130000002, -0.526806],
              [130.48378030000003, -0.520897],
              [130.49633830000005, -0.518848],
              [130.50016830000004, -0.521352],
              [130.50723330000005, -0.516438],
              [130.52011130000005, -0.520332],
              [130.53003030000002, -0.513337],
              [130.53321930000004, -0.518012],
              [130.54095530000006, -0.520913],
              [130.54646330000003, -0.514652],
              [130.54664630000002, -0.508202],
              [130.5612493000001, -0.501566],
              [130.56477430000007, -0.488847],
              [130.55946430000006, -0.477542],
              [130.5641793000001, -0.473036599999944],
              [130.57183930000008, -0.471178],
              [130.57830830000012, -0.476332],
              [130.59262130000002, -0.474871],
              [130.5988923000001, -0.489867],
              [130.6112223, -0.492305],
              [130.6094213, -0.496347],
              [130.6010133000001, -0.495161],
              [130.60214330000008, -0.501625],
              [130.5919503, -0.499412],
              [130.59639030000005, -0.512961],
              [130.5906073000001, -0.515595],
              [130.59205730000008, -0.533225],
              [130.58325230000003, -0.531426],
              [130.57495230000006, -0.534585],
              [130.56744430000003, -0.529289],
              [130.56634530000008, -0.537123],
              [130.57377730000007, -0.547225],
              [130.58946230000004, -0.54456],
              [130.60545430000002, -0.536775],
              [130.62648030000003, -0.533385],
              [130.63714630000004, -0.525403],
              [130.65141330000006, -0.522567],
              [130.65914930000008, -0.517869],
              [130.66546630000005, -0.506135],
              [130.67384430000004, -0.499655],
              [130.66325430000006, -0.49282],
              [130.67248630000006, -0.488593],
              [130.6791693, -0.473715599999935],
              [130.6879123000001, -0.471184],
              [130.6893013, -0.46306],
              [130.69418430000007, -0.454782],
              [130.69122330000005, -0.448786],
              [130.68132030000004, -0.447776],
              [130.6723333000001, -0.441239],
              [130.66442930000005, -0.443865],
              [130.66581730000007, -0.435407],
              [130.6629643000001, -0.428714],
              [130.6450043000001, -0.417431],
              [130.63092130000007, -0.416468],
              [130.6278383, -0.413574],
              [130.61706630000003, -0.414636]
            ]
          ],
          [
            [
              [130.29470830000002, -0.37065],
              [130.28414930000008, -0.367939],
              [130.28250230000003, -0.373802],
              [130.29470830000002, -0.37065]
            ]
          ],
          [
            [
              [132.4630972000001, -0.349411099999941],
              [132.43047610000008, -0.343737899999951],
              [132.40920140000003, -0.344683399999951],
              [132.38225350000005, -0.363594199999966],
              [132.36253130000011, -0.359805],
              [132.35851, -0.368422199999941],
              [132.3441481000001, -0.377613699999927],
              [132.32088190000002, -0.381922299999928],
              [132.2892482000001, -0.381086],
              [132.2685897, -0.376715899999965],
              [132.25746590000006, -0.379496899999936],
              [132.25627410000004, -0.394593499999928],
              [132.2441904000001, -0.40050059999993],
              [132.19306270000004, -0.409227399999963],
              [132.1621788000001, -0.425032499999929],
              [132.1452869000001, -0.446001799999976],
              [132.10434380000004, -0.463903],
              [132.0786333000001, -0.48287879999998],
              [132.07108510000012, -0.492142499999943],
              [132.07382990000008, -0.506209599999977],
              [132.07280060000005, -0.51513019999993],
              [132.05907660000003, -0.536402399999929],
              [132.0212404, -0.549745399999949],
              [131.98346070000002, -0.564857199999949],
              [131.94905270000004, -0.601820499999974],
              [131.93471720000002, -0.608870699999954],
              [131.9234368000001, -0.619211099999973],
              [131.91356650000012, -0.624851299999932],
              [131.91006030000005, -0.634479699999929],
              [131.91121640000006, -0.646707],
              [131.8984385000001, -0.661244],
              [131.89314330000002, -0.662485099999969],
              [131.8846635000001, -0.67786179999996],
              [131.8623351000001, -0.685834099999965],
              [131.85094330000004, -0.694694299999981],
              [131.82731590000003, -0.711149099999943],
              [131.8176118, -0.715368299999966],
              [131.8020008000001, -0.714946399999974],
              [131.77710760000002, -0.720431299999973],
              [131.74672950000001, -0.72296289999997],
              [131.74000620000004, -0.726535699999943],
              [131.70968460000006, -0.731284799999969],
              [131.6866695000001, -0.74151379999995],
              [131.66475020000007, -0.743340399999965],
              [131.6479455000001, -0.741148499999952],
              [131.62445130000003, -0.739783799999941],
              [131.6024414000001, -0.744238199999927],
              [131.58872470000006, -0.749688799999944],
              [131.57944320000001, -0.750988199999938],
              [131.574988, -0.75952719999998],
              [131.56403590000002, -0.765467399999977],
              [131.54686750000008, -0.770895699999926],
              [131.5374018000001, -0.768502099999978],
              [131.52641290000008, -0.771875],
              [131.51696320000008, -0.768728199999941],
              [131.5324710000001, -0.76097429999993],
              [131.54188640000007, -0.750451099999964],
              [131.5402249000001, -0.742143399999975],
              [131.53302480000002, -0.735497199999941],
              [131.5219479000001, -0.735497199999941],
              [131.51197860000002, -0.738820299999929],
              [131.49868620000007, -0.739374099999964],
              [131.4779268000001, -0.75170559999998],
              [131.4678722000001, -0.755550099999937],
              [131.4517552000001, -0.755697899999973],
              [131.44332710000003, -0.760873099999969],
              [131.42883660000007, -0.765456799999924],
              [131.41257180000002, -0.765161099999943],
              [131.40946670000005, -0.77758149999994],
              [131.3998167000001, -0.782253599999933],
              [131.39668960000006, -0.776645199999962],
              [131.3839732, -0.779183099999955],
              [131.37569410000003, -0.783505],
              [131.36859470000002, -0.781722599999966],
              [131.35735560000012, -0.78217559999996],
              [131.34537910000006, -0.786201799999958],
              [131.32970440000008, -0.788890399999957],
              [131.32689870000002, -0.796335499999941],
              [131.31506810000008, -0.797384399999942],
              [131.30427510000004, -0.803047699999979],
              [131.294072, -0.805658799999946],
              [131.2825574000001, -0.805356],
              [131.2743104000001, -0.810027599999955],
              [131.2695155, -0.809395399999971],
              [131.2486954000001, -0.816825699999924],
              [131.24322940000002, -0.820019699999932],
              [131.23283320000007, -0.816487099999961],
              [131.2248515000001, -0.822072899999966],
              [131.2303587, -0.830408599999942],
              [131.23563780000006, -0.830632099999946],
              [131.2453147000001, -0.846515199999942],
              [131.24393080000004, -0.850617],
              [131.24695040000006, -0.863216099999931],
              [131.245309, -0.877975599999957],
              [131.25220550000006, -0.877613899999972],
              [131.26224990000003, -0.882429599999966],
              [131.26944490000005, -0.889331],
              [131.271135, -0.894613499999934],
              [131.288198, -0.900315399999954],
              [131.288488, -0.911138499999936],
              [131.2766666000001, -0.921691899999928],
              [131.2679081000001, -0.921890299999973],
              [131.26782040000012, -0.932096499999943],
              [131.27414370000008, -0.942772199999979],
              [131.27069530000006, -0.95146109999996],
              [131.26699750000012, -0.968617399999971],
              [131.27671550000002, -0.977645],
              [131.27321060000008, -0.981149899999934],
              [131.26492650000012, -0.980406399999936],
              [131.26630720000003, -0.987416099999962],
              [131.2727397000001, -0.987309099999948],
              [131.27665170000012, -0.993506499999967],
              [131.2749371000001, -0.998650299999952],
              [131.25821840000003, -1.002872499999967],
              [131.25364070000012, -1.014225199999942],
              [131.2412776000001, -1.038716499999964],
              [131.24136690000012, -1.056063399999971],
              [131.23663120000003, -1.066417799999954],
              [131.22987380000006, -1.075190599999928],
              [131.2289254000001, -1.084556099999929],
              [131.23520860000008, -1.088705399999981],
              [131.2322448000001, -1.101746],
              [131.22128880000002, -1.116134799999941],
              [131.2191865000001, -1.123964399999977],
              [131.21334050000007, -1.13273339999995],
              [131.2115089, -1.142486299999973],
              [131.2144412, -1.150877499999979],
              [131.2044456000001, -1.160959499999933],
              [131.1993533000001, -1.174996499999963],
              [131.1736912, -1.229457699999955],
              [131.14661850000005, -1.237333399999955],
              [131.14158020000002, -1.243184],
              [131.1254997000001, -1.248262],
              [131.10941910000008, -1.248826299999962],
              [131.09474920000002, -1.247133599999927],
              [131.08346460000007, -1.240080699999965],
              [131.054971, -1.256161199999951],
              [131.04566120000004, -1.265471],
              [131.0380441000001, -1.282115799999929],
              [131.0369157, -1.287476],
              [131.03099120000002, -1.292271899999946],
              [131.0273238000001, -1.299606899999958],
              [131.0295807000001, -1.314559],
              [131.01762140000005, -1.326384799999971],
              [131.01138560000004, -1.330282199999942],
              [131.007683, -1.353276899999969],
              [131.00437560000012, -1.362598599999956],
              [130.99819720000005, -1.37188649999996],
              [130.9955341000001, -1.386175199999968],
              [130.983041, -1.39464849999996],
              [130.96999570000003, -1.397716199999934],
              [130.9640587, -1.401332899999943],
              [130.96353620000002, -1.410216799999944],
              [130.9551748, -1.413874899999939],
              [130.95099420000008, -1.423281399999951],
              [130.9347941000001, -1.429029799999967],
              [130.933749, -1.440004],
              [130.94838130000005, -1.452546],
              [130.97346520000008, -1.454636299999947],
              [130.97816850000004, -1.458294399999943],
              [130.9933234, -1.464565399999969],
              [131.041401, -1.459862199999975],
              [131.0617817000001, -1.455681499999969],
              [131.0706656000001, -1.455681499999969],
              [131.0764140000001, -1.458817],
              [131.0837302000001, -1.457249299999944],
              [131.09313670000006, -1.448887899999932],
              [131.11404, -1.458817],
              [131.11978840000006, -1.464565399999969],
              [131.132853, -1.468746099999976],
              [131.1637336000001, -1.468664399999966],
              [131.1747031000001, -1.481558499999949],
              [131.18782040000008, -1.490932699999973],
              [131.20037720000005, -1.515261599999974],
              [131.1980228000001, -1.528603199999964],
              [131.2113644000001, -1.536451199999931],
              [131.23726290000002, -1.527818399999944],
              [131.2488926000001, -1.514561799999967],
              [131.26437610000005, -1.511796899999979],
              [131.29257810000001, -1.503502199999957],
              [131.31580320000012, -1.491889599999979],
              [131.32741580000004, -1.478618099999949],
              [131.33681650000005, -1.462581599999965],
              [131.33902840000007, -1.454286899999943],
              [131.33460460000003, -1.443227299999933],
              [131.31282850000002, -1.411894899999936],
              [131.31856820000007, -1.413366399999973],
              [131.32520390000002, -1.410048499999959],
              [131.3285218000001, -1.392906099999948],
              [131.3422101000001, -1.38414819999997],
              [131.3462409000001, -1.376842299999964],
              [131.3456642000001, -1.369127899999967],
              [131.3506410000001, -1.364151099999958],
              [131.3600417, -1.365810099999976],
              [131.36426970000002, -1.368909499999972],
              [131.36451650000004, -1.386146],
              [131.37204370000006, -1.408727499999941],
              [131.37783380000008, -1.413938599999938],
              [131.3856505000001, -1.409885499999973],
              [131.3917031000001, -1.412605699999972],
              [131.39284210000005, -1.422477399999934],
              [131.4019545000001, -1.431589799999927],
              [131.41182620000006, -1.431969399999957],
              [131.41979950000007, -1.429311699999971],
              [131.42473540000003, -1.420958699999971],
              [131.43043060000002, -1.430071],
              [131.42169790000003, -1.435006899999962],
              [131.40878880000002, -1.439183399999933],
              [131.40005610000003, -1.445258299999978],
              [131.381072, -1.450953499999969],
              [131.3795533000001, -1.459306499999968],
              [131.3852485000001, -1.466900099999975],
              [131.40081540000006, -1.480948299999966],
              [131.4266338000001, -1.494616899999926],
              [131.4395429000001, -1.508665099999973],
              [131.44371940000008, -1.515119699999957],
              [131.44865530000004, -1.515119699999957],
              [131.4665003, -1.509424399999943],
              [131.4729549000001, -1.505627599999968],
              [131.4869231, -1.486194],
              [131.580708, -1.533625399999949],
              [131.6608725000001, -1.560903599999961],
              [131.67952790000004, -1.547614899999928],
              [131.69751, -1.55507],
              [131.706345, -1.56982],
              [131.715363, -1.579279],
              [131.7279820000001, -1.585073],
              [131.746902, -1.580707],
              [131.760055, -1.575338],
              [131.771896, -1.576184],
              [131.77931200000012, -1.584259],
              [131.7913820000001, -1.607412],
              [131.802185, -1.614175],
              [131.811493, -1.614552],
              [131.819962, -1.609863],
              [131.827118, -1.600914],
              [131.832108, -1.598398],
              [131.837143, -1.588472],
              [131.854889, -1.580706],
              [131.862884, -1.579925],
              [131.879334, -1.58446],
              [131.891861, -1.580015],
              [131.906067, -1.568793],
              [131.924652, -1.55729],
              [131.936157, -1.55497],
              [131.957413, -1.559585],
              [131.9542540000001, -1.565032],
              [131.936172, -1.574581],
              [131.924103, -1.579081],
              [131.904694, -1.595126],
              [131.895004, -1.611248],
              [131.884445, -1.637041],
              [131.885056, -1.650211],
              [131.893143, -1.667721],
              [131.901413, -1.673553],
              [131.900376, -1.681486],
              [131.902359, -1.695235],
              [131.910202, -1.719122],
              [131.918762, -1.722203],
              [131.931763, -1.715107],
              [131.9488520000001, -1.714305],
              [131.953323, -1.706443],
              [131.966171, -1.708266],
              [131.98059100000012, -1.703937],
              [131.989807, -1.693785],
              [131.989654, -1.684875],
              [132.000351, -1.670366],
              [132.00444, -1.670768],
              [132.006424, -1.689755],
              [132.0102230000001, -1.698891],
              [132.016449, -1.698257],
              [132.02656600000012, -1.702561],
              [132.041229, -1.71715],
              [132.035156, -1.721655],
              [132.025696, -1.71964],
              [132.016128, -1.733399],
              [132.0139160000001, -1.740871],
              [132.006806, -1.749438],
              [132.000366, -1.750634],
              [131.995102, -1.744068],
              [131.990738, -1.750609],
              [131.98158300000011, -1.757768],
              [131.972183, -1.768779],
              [131.962341, -1.774156],
              [131.9579920000001, -1.778852],
              [131.960037, -1.789905],
              [131.94574000000011, -1.794514],
              [131.934006, -1.801438],
              [131.924271, -1.81388],
              [131.9469150000001, -1.843279],
              [131.953827, -1.841061],
              [131.956299, -1.830657],
              [131.962463, -1.835341],
              [131.9608, -1.840045],
              [131.952209, -1.848244],
              [131.926407, -1.86363],
              [131.926681, -1.871283],
              [131.930328, -1.874918],
              [131.932312, -1.88418],
              [131.941788, -1.895945],
              [131.956207, -1.918355],
              [131.959625, -1.922162],
              [131.96843, -1.925072],
              [131.983871, -1.922244],
              [131.991287, -1.927615],
              [131.984863, -1.938345],
              [131.985657, -1.948015],
              [131.9834750000001, -1.953642],
              [131.976547, -1.96023],
              [131.9689330000001, -1.964004],
              [131.979141, -1.976855],
              [132.002563, -1.990252],
              [132.010788, -1.988161],
              [132.022415, -2.000201],
              [132.025192, -2.009597],
              [132.03392, -2.018363],
              [132.041687, -2.023292],
              [132.061386, -2.015619],
              [132.070175, -2.016179],
              [132.077057, -2.012262],
              [132.083176, -2.01691],
              [132.096008, -2.019831],
              [132.081619, -2.031121],
              [132.077377, -2.038548],
              [132.056854, -2.052436],
              [132.050629, -2.049225],
              [132.042206, -2.054183],
              [132.03862, -2.069724],
              [132.04863000000012, -2.06539],
              [132.047302, -2.072934],
              [132.060455, -2.084131],
              [132.067703, -2.086537],
              [132.066071, -2.09229],
              [132.086868, -2.105612],
              [132.093399, -2.112965],
              [132.101608, -2.116058],
              [132.116073, -2.126052],
              [132.12306200000012, -2.132944],
              [132.133957, -2.134996],
              [132.144256, -2.147234],
              [132.181549, -2.146777],
              [132.1904300000001, -2.158445],
              [132.19899, -2.164477],
              [132.220535, -2.175086],
              [132.252899, -2.203974],
              [132.261276, -2.215072],
              [132.271362, -2.231796],
              [132.288101, -2.255702],
              [132.303848, -2.272941],
              [132.31601000000012, -2.280059],
              [132.326935, -2.272848],
              [132.344315, -2.271498],
              [132.349442, -2.269598],
              [132.367447, -2.269108],
              [132.378326, -2.264837],
              [132.394531, -2.254459],
              [132.4044950000001, -2.251057],
              [132.414108, -2.243449],
              [132.428208, -2.235659],
              [132.448639, -2.227986],
              [132.45393400000012, -2.223263],
              [132.4597930000001, -2.22291],
              [132.466171, -2.218522],
              [132.496613, -2.204045],
              [132.541596, -2.189893],
              [132.5671390000001, -2.189853],
              [132.577103, -2.188116],
              [132.59108, -2.189534],
              [132.6106410000001, -2.207515],
              [132.617874, -2.219816],
              [132.624786, -2.238214],
              [132.625427, -2.244944],
              [132.623016, -2.255474],
              [132.629059, -2.267657],
              [132.635422, -2.276503],
              [132.650681, -2.285981],
              [132.664245, -2.286649],
              [132.684281, -2.292436],
              [132.69899, -2.293448],
              [132.71257, -2.298666],
              [132.718857, -2.29862],
              [132.72435, -2.29387],
              [132.728546, -2.284661],
              [132.737503, -2.272683],
              [132.7529300000001, -2.264721],
              [132.7648620000001, -2.256651],
              [132.786667, -2.248517],
              [132.80928, -2.242155],
              [132.818619, -2.240879],
              [132.84266700000012, -2.240152],
              [132.85568200000012, -2.242493],
              [132.872894, -2.250066],
              [132.8826600000001, -2.26012],
              [132.887939, -2.269889],
              [132.901199, -2.278273],
              [132.907928, -2.279982],
              [132.9413760000001, -2.277698],
              [132.95393400000012, -2.281994],
              [132.96406600000012, -2.292395],
              [132.980347, -2.286043],
              [132.993012, -2.274291],
              [133.003372, -2.270129],
              [133.020737, -2.247848],
              [133.039337, -2.236547],
              [133.05682400000012, -2.231806],
              [133.068161, -2.230294],
              [133.09787, -2.233067],
              [133.100876, -2.229159],
              [133.118713, -2.225964],
              [133.126831, -2.217496],
              [133.1544040000001, -2.19951],
              [133.161804, -2.196452],
              [133.179809, -2.193953],
              [133.19470200000012, -2.194169],
              [133.224014, -2.199186],
              [133.222839, -2.205744],
              [133.2334750000001, -2.214761],
              [133.2501370000001, -2.203353],
              [133.273621, -2.199813],
              [133.284073, -2.192377],
              [133.288361, -2.193054],
              [133.290894, -2.201856],
              [133.30542, -2.212021],
              [133.314545, -2.214417],
              [133.33609, -2.214216],
              [133.347946, -2.211501],
              [133.370285, -2.209282],
              [133.381378, -2.204496],
              [133.395249, -2.209252],
              [133.40156500000012, -2.207279],
              [133.41358900000012, -2.207151],
              [133.418075, -2.214279],
              [133.4258420000001, -2.218412],
              [133.431793, -2.226344],
              [133.4405210000001, -2.227592],
              [133.469589, -2.226801],
              [133.48027, -2.230644],
              [133.490173, -2.227866],
              [133.50824000000011, -2.230487],
              [133.5167540000001, -2.227972],
              [133.522812, -2.235027],
              [133.527908, -2.237269],
              [133.543716, -2.234409],
              [133.5600280000001, -2.228908],
              [133.572784, -2.220584],
              [133.57782, -2.214152],
              [133.577072, -2.201741],
              [133.5856470000001, -2.184689],
              [133.595367, -2.176249],
              [133.60185300000012, -2.177288],
              [133.588745, -2.192097],
              [133.586807, -2.211229],
              [133.591507, -2.222319],
              [133.598496, -2.226804],
              [133.60554500000012, -2.223357],
              [133.609711, -2.217902],
              [133.6232910000001, -2.216815],
              [133.628494, -2.22027],
              [133.635605, -2.232454],
              [133.641907, -2.231422],
              [133.651855, -2.223614],
              [133.65741, -2.221723],
              [133.664612, -2.211094],
              [133.669769, -2.210125],
              [133.6739500000001, -2.202716],
              [133.683609, -2.19698],
              [133.706696, -2.199347],
              [133.7123570000001, -2.194354],
              [133.718063, -2.179075],
              [133.7244720000001, -2.170228],
              [133.732773, -2.16489],
              [133.7354580000001, -2.16024],
              [133.743988, -2.164961],
              [133.764435, -2.16996],
              [133.773651, -2.167671],
              [133.785492, -2.160098],
              [133.791672, -2.151504],
              [133.797806, -2.150572],
              [133.827515, -2.140474],
              [133.8376310000001, -2.131689],
              [133.841522, -2.118708],
              [133.8514100000001, -2.101366],
              [133.86348, -2.095458],
              [133.873856, -2.098912],
              [133.88829, -2.109205],
              [133.900192, -2.105531],
              [133.906922, -2.101279],
              [133.919769, -2.103529],
              [133.933334, -2.090041],
              [133.939453, -2.092401],
              [133.942169, -2.107109],
              [133.93650800000012, -2.115775],
              [133.915558, -2.13604],
              [133.9145360000001, -2.143449],
              [133.8934630000001, -2.144708],
              [133.888946, -2.148436],
              [133.884125, -2.169467],
              [133.880203, -2.171865],
              [133.876434, -2.18462],
              [133.866379, -2.20018],
              [133.83873, -2.211427],
              [133.817261, -2.218476],
              [133.8135380000001, -2.223261],
              [133.804153, -2.225533],
              [133.7849880000001, -2.233622],
              [133.7794950000001, -2.239764],
              [133.783111, -2.253215],
              [133.790054, -2.265046],
              [133.793716, -2.265498],
              [133.814056, -2.283216],
              [133.829056, -2.291898],
              [133.837128, -2.29282],
              [133.855621, -2.287607],
              [133.861511, -2.28037],
              [133.871246, -2.278252],
              [133.8807680000001, -2.285687],
              [133.887466, -2.296414],
              [133.894623, -2.298078],
              [133.905273, -2.296937],
              [133.91684, -2.298663],
              [133.9292150000001, -2.302352],
              [133.936707, -2.299077],
              [133.93985, -2.289542],
              [133.94114700000011, -2.277828],
              [133.937882, -2.271216],
              [133.945496, -2.263924],
              [133.948746, -2.250491],
              [133.948288, -2.240722],
              [133.945007, -2.235494],
              [133.943283, -2.216136],
              [133.94722, -2.209931],
              [133.947433, -2.187814],
              [133.955673, -2.183109],
              [133.952255, -2.196895],
              [133.954102, -2.220043],
              [133.961609, -2.22964],
              [133.964584, -2.237898],
              [133.960541, -2.244348],
              [133.961288, -2.251602],
              [133.95677200000011, -2.266691],
              [133.954727, -2.280649],
              [133.948502, -2.29828],
              [133.943115, -2.305055],
              [133.937225, -2.307119],
              [133.920456, -2.308088],
              [133.897888, -2.310832],
              [133.878479, -2.311223],
              [133.866409, -2.316959],
              [133.862869, -2.320985],
              [133.860748, -2.333097],
              [133.874115, -2.35372],
              [133.874634, -2.361201],
              [133.880249, -2.367062],
              [133.8898160000001, -2.367757],
              [133.889984, -2.372814],
              [133.898972, -2.377815],
              [133.911865, -2.382535],
              [133.933563, -2.386052],
              [133.9403840000001, -2.384848],
              [133.954636, -2.38566],
              [133.967682, -2.383425],
              [133.991226, -2.385502],
              [133.997406, -2.388152],
              [133.997009, -2.39424],
              [133.983261, -2.391419],
              [133.9751890000001, -2.392216],
              [133.966416, -2.397029],
              [133.95857300000011, -2.397636],
              [133.952347, -2.394905],
              [133.940842, -2.397249],
              [133.927521, -2.397016],
              [133.921722, -2.394257],
              [133.903, -2.391084],
              [133.891342, -2.391855],
              [133.88598600000012, -2.387459],
              [133.877518, -2.386076],
              [133.860168, -2.391234],
              [133.85554500000012, -2.399385],
              [133.848236, -2.406658],
              [133.835113, -2.41253],
              [133.826813, -2.412812],
              [133.821258, -2.415969],
              [133.8078, -2.4174],
              [133.796982, -2.42073],
              [133.78244, -2.42066],
              [133.77201800000012, -2.423293],
              [133.75679, -2.421965],
              [133.751816, -2.426217],
              [133.745972, -2.426553],
              [133.741974, -2.431953],
              [133.740784, -2.441379],
              [133.745651, -2.455336],
              [133.754257, -2.465167],
              [133.756027, -2.470911],
              [133.772415, -2.481375],
              [133.785004, -2.482586],
              [133.7922820000001, -2.477076],
              [133.7998500000001, -2.485995],
              [133.7979580000001, -2.491214],
              [133.801804, -2.498052],
              [133.801712, -2.510436],
              [133.797119, -2.516895],
              [133.78480500000012, -2.514698],
              [133.77092, -2.514347],
              [133.751389, -2.509727],
              [133.741226, -2.504102],
              [133.73024, -2.502031],
              [133.709732, -2.501672],
              [133.705444, -2.505517],
              [133.703964, -2.512925],
              [133.707748, -2.53392],
              [133.704681, -2.544757],
              [133.706345, -2.55733],
              [133.702484, -2.5672],
              [133.688873, -2.567871],
              [133.673538, -2.555308],
              [133.669464, -2.549791],
              [133.662659, -2.547032],
              [133.6565700000001, -2.538123],
              [133.6445460000001, -2.534434],
              [133.629959, -2.537819],
              [133.611633, -2.550385],
              [133.601746, -2.560834],
              [133.591782, -2.567954],
              [133.5800170000001, -2.566119],
              [133.573166, -2.552778],
              [133.56395, -2.557772],
              [133.554504, -2.555186],
              [133.5487670000001, -2.549949],
              [133.536423, -2.562235],
              [133.529221, -2.577921],
              [133.52005010000005, -2.578494699999965],
              [133.51367430000005, -2.564764599999933],
              [133.50857910000002, -2.557699499999956],
              [133.50276810000003, -2.543580399999939],
              [133.4966512000001, -2.538045299999965],
              [133.494546, -2.526204199999938],
              [133.4896037000001, -2.512455099999954],
              [133.48432650000007, -2.507002099999966],
              [133.47932320000007, -2.507925],
              [133.47284050000007, -2.513687],
              [133.46992650000004, -2.523303],
              [133.47218470000007, -2.538581099999931],
              [133.47624130000008, -2.551923199999976],
              [133.46805010000003, -2.557994199999939],
              [133.45918810000012, -2.55983109999994],
              [133.44632950000005, -2.554278],
              [133.43588110000007, -2.547277899999926],
              [133.43723860000011, -2.54450989999998],
              [133.44928850000008, -2.55284],
              [133.45619810000005, -2.555363099999965],
              [133.4596, -2.552595099999962],
              [133.45923410000012, -2.532522],
              [133.4498374000001, -2.503938799999958],
              [133.44175310000003, -2.503948699999967],
              [133.4310458000001, -2.506672699999967],
              [133.427446, -2.517319699999973],
              [133.42289950000009, -2.521408699999938],
              [133.41684450000002, -2.532056699999941],
              [133.41092550000008, -2.53615469999994],
              [133.4109565000001, -2.545417699999973],
              [133.40824140000007, -2.549388699999952],
              [133.38745040000003, -2.547563599999933],
              [133.3890219000001, -2.53094649999997],
              [133.38848810000002, -2.522724499999924],
              [133.38479640000003, -2.514095399999974],
              [133.3601006, -2.491981099999975],
              [133.346845, -2.484411],
              [133.3331776000001, -2.468401799999981],
              [133.320343, -2.451649],
              [133.310852, -2.444468],
              [133.296127, -2.440172],
              [133.2905270000001, -2.435524],
              [133.266892, -2.428887],
              [133.261185, -2.425495],
              [133.25274600000012, -2.424528],
              [133.2474820000001, -2.418496],
              [133.225571, -2.413351],
              [133.21611, -2.413823],
              [133.209183, -2.423674],
              [133.198273, -2.431165],
              [133.19046, -2.432613],
              [133.172272, -2.431602],
              [133.154907, -2.437547],
              [133.14971900000012, -2.43658],
              [133.129089, -2.438337],
              [133.116486, -2.450894],
              [133.104263, -2.461153],
              [133.094803, -2.461109],
              [133.07634, -2.478154],
              [133.060013, -2.486351],
              [133.046997, -2.498853],
              [133.04134420000003, -2.514294399999926],
              [133.024323, -2.533284],
              [133.011001, -2.550843],
              [133.008164, -2.557347],
              [132.983765, -2.587354],
              [132.97612, -2.598699],
              [132.956421, -2.6207],
              [132.943512, -2.631529],
              [132.927704, -2.654272],
              [132.905106, -2.678463],
              [132.889481, -2.693959],
              [132.876358, -2.705196],
              [132.859833, -2.722647],
              [132.845795, -2.734064],
              [132.82807900000012, -2.751805],
              [132.810135, -2.764168],
              [132.806305, -2.772341],
              [132.791977, -2.776874],
              [132.79223600000012, -2.786083],
              [132.778671, -2.786672],
              [132.772324, -2.783634],
              [132.74823, -2.804948],
              [132.734848, -2.807672],
              [132.726852, -2.802734],
              [132.716919, -2.800103],
              [132.706345, -2.793329],
              [132.705322, -2.784284],
              [132.693451, -2.789034],
              [132.687256, -2.777592],
              [132.667084, -2.767264],
              [132.669693, -2.763067],
              [132.66272, -2.75859],
              [132.663391, -2.75404],
              [132.639481, -2.734458],
              [132.6418000000001, -2.728361],
              [132.626618, -2.725794],
              [132.622269, -2.717572],
              [132.613693, -2.714706],
              [132.60524, -2.708963],
              [132.599136, -2.708683],
              [132.59259, -2.697015],
              [132.584152, -2.697335],
              [132.571304, -2.690378],
              [132.56430000000012, -2.689691],
              [132.546127, -2.693972],
              [132.53595, -2.68594],
              [132.524063, -2.681292],
              [132.516556, -2.682107],
              [132.513489, -2.686024],
              [132.501099, -2.680635],
              [132.493011, -2.684959],
              [132.4816740000001, -2.685956],
              [132.471268, -2.693501],
              [132.48053, -2.701713],
              [132.491958, -2.706877],
              [132.494034, -2.712974],
              [132.479553, -2.711727],
              [132.466675, -2.712895],
              [132.459076, -2.724637],
              [132.455627, -2.733331],
              [132.44110100000012, -2.739778],
              [132.436005, -2.735226],
              [132.44744900000012, -2.727416],
              [132.449936, -2.719247],
              [132.457382, -2.712507],
              [132.44841, -2.707226],
              [132.434143, -2.702587],
              [132.42981, -2.704433],
              [132.426071, -2.695867],
              [132.429184, -2.690683],
              [132.440643, -2.690212],
              [132.44015500000012, -2.679565],
              [132.426346, -2.67792],
              [132.41925, -2.685058],
              [132.411056, -2.682569],
              [132.407394, -2.686688],
              [132.395096, -2.689584],
              [132.389145, -2.685107],
              [132.378479, -2.683624],
              [132.364838, -2.676969],
              [132.356644, -2.668177],
              [132.349014, -2.670087],
              [132.343063, -2.665492],
              [132.332642, -2.666714],
              [132.329773, -2.662807],
              [132.31842, -2.661099],
              [132.304642, -2.663533],
              [132.297165, -2.661879],
              [132.2694550000001, -2.660019],
              [132.255615, -2.662969],
              [132.242828, -2.661198],
              [132.240372, -2.657643],
              [132.230209, -2.65541],
              [132.222412, -2.658242],
              [132.215896, -2.655601],
              [132.204514, -2.658724],
              [132.1979520000001, -2.655857],
              [132.185135, -2.6603],
              [132.182983, -2.664506],
              [132.1693570000001, -2.661704],
              [132.164566, -2.664536],
              [132.143036, -2.666401],
              [132.112564, -2.691751],
              [132.1133880000001, -2.697106],
              [132.1195070000001, -2.700262],
              [132.135193, -2.688329],
              [132.148422, -2.690616],
              [132.1617890000001, -2.696404],
              [132.195328, -2.701303],
              [132.19975130000012, -2.70719859999997],
              [132.177628, -2.708351],
              [132.165833, -2.711121],
              [132.15007, -2.717536],
              [132.149795, -2.72167],
              [132.141586, -2.723237],
              [132.140991, -2.717784],
              [132.127853, -2.712974],
              [132.125732, -2.725973],
              [132.122833, -2.727185],
              [132.116592, -2.719198],
              [132.11026, -2.718919],
              [132.106247, -2.711339],
              [132.096207, -2.710426],
              [132.089462, -2.721771],
              [132.0977630000001, -2.729757],
              [132.108719, -2.732216],
              [132.107453, -2.743379],
              [132.100769, -2.739761],
              [132.098236, -2.74868],
              [132.084534, -2.742947],
              [132.083649, -2.735412],
              [132.08721900000012, -2.730807],
              [132.082642, -2.7269],
              [132.071426, -2.728982],
              [132.06785600000012, -2.734745],
              [132.072098, -2.743138],
              [132.06979400000012, -2.750158],
              [132.05970800000011, -2.75138],
              [132.056091, -2.743963],
              [132.058609, -2.734067],
              [132.051361, -2.731716],
              [132.045746, -2.737135],
              [132.031403, -2.744798],
              [132.025116, -2.739227],
              [132.016128, -2.744882],
              [132.008637, -2.753639],
              [131.999619, -2.758435],
              [132.000259, -2.775774],
              [132.003845, -2.781428],
              [132.025436, -2.779842],
              [132.027924, -2.773338],
              [132.0398560000001, -2.772163],
              [132.051147, -2.778826],
              [132.051956, -2.783837],
              [132.0683140000001, -2.788992],
              [132.055984, -2.792231],
              [132.051102, -2.795913],
              [132.048523, -2.808053],
              [132.037201, -2.813771],
              [132.028076, -2.812225],
              [132.0174260000001, -2.815347],
              [132.017105, -2.822539],
              [132.0075230000001, -2.819157],
              [132.002197, -2.829061],
              [132.0122530000001, -2.837039],
              [132.015839, -2.835655],
              [132.031677, -2.845124],
              [132.022751, -2.854116],
              [132.0249480000001, -2.863144],
              [132.031677, -2.875789],
              [132.02102700000012, -2.881435],
              [132.004196, -2.877836],
              [131.999634, -2.888783],
              [132.005249, -2.892378],
              [132.01442, -2.893861],
              [132.019028, -2.905185],
              [132.03276, -2.903158],
              [132.033661, -2.916961],
              [132.038712, -2.924487],
              [132.0443110000001, -2.927353],
              [132.048737, -2.923554],
              [132.044846, -2.918208],
              [132.052017, -2.914119],
              [132.054382, -2.906692],
              [132.0535430000001, -2.898469],
              [132.05928, -2.890464],
              [132.064591, -2.910418],
              [132.068436, -2.914207],
              [132.080322, -2.919462],
              [132.07524100000012, -2.937264],
              [132.079407, -2.952044],
              [132.083862, -2.955544],
              [132.086578, -2.963079],
              [132.101105, -2.959604],
              [132.103989, -2.955235],
              [132.118805, -2.954003],
              [132.112945, -2.940607],
              [132.119873, -2.936399],
              [132.132507, -2.944955],
              [132.139923, -2.943558],
              [132.145798, -2.945967],
              [132.15097, -2.943949],
              [132.1491850000001, -2.937219],
              [132.1629180000001, -2.935707],
              [132.169906, -2.931382],
              [132.178696, -2.934077],
              [132.186569, -2.931761],
              [132.194519, -2.926233],
              [132.200363, -2.933242],
              [132.208206, -2.927877],
              [132.206131, -2.921211],
              [132.211426, -2.917981],
              [132.220169, -2.926483],
              [132.237274, -2.925423],
              [132.261734, -2.927635],
              [132.27091340000004, -2.933967299999949],
              [132.2858298000001, -2.931293],
              [132.3011748, -2.936348199999941],
              [132.31123860000002, -2.93472289999994],
              [132.32045, -2.940384],
              [132.323379, -2.944924],
              [132.333298, -2.947149],
              [132.335678, -2.94306],
              [132.34275800000012, -2.942467],
              [132.346863, -2.947192],
              [132.353516, -2.941022],
              [132.354981, -2.951036],
              [132.349182, -2.955469],
              [132.346985, -2.961222],
              [132.351852, -2.968865],
              [132.359543, -2.967942],
              [132.3722990000001, -2.976949],
              [132.38040200000012, -2.980504],
              [132.393021, -2.989051],
              [132.406891, -2.99169],
              [132.422455, -2.996901],
              [132.418961, -3.002489],
              [132.427383, -3.005075],
              [132.45217900000011, -3.023354],
              [132.456467, -3.024045],
              [132.47049, -3.034568],
              [132.478683, -3.038421],
              [132.489227, -3.050894],
              [132.498321, -3.055551],
              [132.501648, -3.063022],
              [132.509201, -3.065039],
              [132.5112150000001, -3.071986],
              [132.517166, -3.076535],
              [132.51712, -3.080895],
              [132.526169, -3.084006],
              [132.536758, -3.096243],
              [132.560303, -3.111483],
              [132.569, -3.124127],
              [132.572571, -3.132919],
              [132.5654760000001, -3.136195],
              [132.555573, -3.137001],
              [132.55421400000012, -3.145793],
              [132.558914, -3.157001],
              [132.577515, -3.166252],
              [132.593719, -3.170855],
              [132.602463, -3.1689],
              [132.6004180000001, -3.180171],
              [132.607773, -3.180389],
              [132.614166, -3.185],
              [132.621658, -3.185632],
              [132.625885, -3.181841],
              [132.6361240000001, -3.183161],
              [132.640655, -3.188335],
              [132.648849, -3.203051],
              [132.649491, -3.212531],
              [132.642227, -3.218619],
              [132.641556, -3.223848],
              [132.6268460000001, -3.226319],
              [132.617004, -3.223787],
              [132.5977170000001, -3.225047],
              [132.599915, -3.236715],
              [132.621033, -3.25172],
              [132.626663, -3.261778],
              [132.637543, -3.267638],
              [132.643661, -3.274078],
              [132.638855, -3.27672],
              [132.6474, -3.287013],
              [132.653244, -3.303674],
              [132.661209, -3.309716],
              [132.661728, -3.315686],
              [132.67218, -3.321647],
              [132.679138, -3.3288],
              [132.701401, -3.335582],
              [132.709717, -3.342537],
              [132.720123, -3.34553],
              [132.724991, -3.342083],
              [132.73323, -3.34163],
              [132.73788500000012, -3.317206],
              [132.735657, -3.313181],
              [132.735581, -3.300427],
              [132.7314, -3.28789],
              [132.741058, -3.286405],
              [132.7473450000001, -3.282379],
              [132.754395, -3.28388],
              [132.761826, -3.274508],
              [132.777206, -3.266926],
              [132.779785, -3.275953],
              [132.785278, -3.274749],
              [132.789352, -3.27986],
              [132.7980960000001, -3.279696],
              [132.797516, -3.268832],
              [132.810516, -3.272223],
              [132.808578, -3.279125],
              [132.810593, -3.295732],
              [132.813339, -3.298835],
              [132.824966, -3.301882],
              [132.82663, -3.306133],
              [132.840302, -3.308954],
              [132.833954, -3.316309],
              [132.835678, -3.322975],
              [132.834259, -3.331994],
              [132.823502, -3.33337],
              [132.820038, -3.350096],
              [132.810089, -3.354403],
              [132.803802, -3.365267],
              [132.806732, -3.378428],
              [132.804581, -3.381636],
              [132.815735, -3.394176],
              [132.817749, -3.407735],
              [132.822098, -3.414284],
              [132.8199310000001, -3.417848],
              [132.824646, -3.439973],
              [132.81974800000012, -3.451353],
              [132.825302, -3.460208],
              [132.839096, -3.468429],
              [132.8456880000001, -3.478252],
              [132.858505, -3.483144],
              [132.869034, -3.482682],
              [132.870911, -3.474296],
              [132.881729, -3.469528],
              [132.891739, -3.470857],
              [132.894486, -3.475054],
              [132.888275, -3.491545],
              [132.895828, -3.495397],
              [132.893387, -3.505791],
              [132.894989, -3.513669],
              [132.899979, -3.51735],
              [132.901184, -3.526251],
              [132.909546, -3.53677],
              [132.915283, -3.541085],
              [132.91259800000012, -3.553495],
              [132.915802, -3.557692],
              [132.922562, -3.557348],
              [132.925247, -3.553385],
              [132.945328, -3.558503],
              [132.945572, -3.561434],
              [132.932694, -3.568735],
              [132.924927, -3.581943],
              [132.909882, -3.584821],
              [132.906815, -3.59441],
              [132.899552, -3.606477],
              [132.901047, -3.610964],
              [132.898422, -3.621014],
              [132.905594, -3.640905],
              [132.896057, -3.650675],
              [132.889252, -3.651816],
              [132.880997, -3.647909],
              [132.865158, -3.65124],
              [132.853943, -3.65049],
              [132.832031, -3.643569],
              [132.821487, -3.64399],
              [132.805527, -3.641974],
              [132.7987210000001, -3.643983],
              [132.780472, -3.643859],
              [132.771362, -3.642422],
              [132.767136, -3.638632],
              [132.753845, -3.632709],
              [132.73513800000012, -3.638274],
              [132.7309570000001, -3.64249],
              [132.733856, -3.668794],
              [132.738052, -3.688043],
              [132.736282, -3.692584],
              [132.742477, -3.699422],
              [132.747132, -3.712411],
              [132.753937, -3.719312],
              [132.75853, -3.729253],
              [132.7630160000001, -3.753847],
              [132.768234, -3.759256],
              [132.768234, -3.764195],
              [132.779511, -3.77115],
              [132.778946, -3.778794],
              [132.782562, -3.788626],
              [132.78772, -3.796441],
              [132.790375, -3.820637],
              [132.788208, -3.825975],
              [132.79303, -3.834604],
              [132.7990420000001, -3.837932],
              [132.80397, -3.852015],
              [132.800766, -3.853734],
              [132.811432, -3.86874],
              [132.812073, -3.87661],
              [132.818603, -3.886731],
              [132.824906, -3.889661],
              [132.8273620000001, -3.89725],
              [132.834366, -3.908629],
              [132.834366, -3.91506],
              [132.839356, -3.924033],
              [132.849716, -3.932996],
              [132.845612, -3.935873],
              [132.8512270000001, -3.950527],
              [132.852783, -3.963398],
              [132.83537300000012, -3.946783],
              [132.829697, -3.932591],
              [132.821793, -3.927644],
              [132.817154, -3.929255],
              [132.812134, -3.93954],
              [132.814789, -3.952756],
              [132.822983, -3.964768],
              [132.831512, -3.968448],
              [132.831665, -3.961329],
              [132.8374480000001, -3.95989],
              [132.845077, -3.972997],
              [132.845276, -3.999777],
              [132.848007, -4.007956],
              [132.8493350000001, -4.019791],
              [132.852829, -4.024145],
              [132.8668060000001, -4.032573],
              [132.868347, -4.045893],
              [132.874878, -4.0497],
              [132.877136, -4.056806],
              [132.87294, -4.070646],
              [132.873779, -4.076817],
              [132.866745, -4.084352],
              [132.8772120000001, -4.09937],
              [132.88974, -4.101852],
              [132.89502, -4.111579],
              [132.908172, -4.110842],
              [132.91160600000012, -4.114331],
              [132.923264, -4.10926],
              [132.934631, -4.11438],
              [132.943527, -4.123508],
              [132.94635, -4.121635],
              [132.95123300000012, -4.108204],
              [132.94899, -4.099773],
              [132.963379, -4.091503],
              [132.9642030000001, -4.080395],
              [132.96843, -4.079341],
              [132.9735720000001, -4.072076],
              [132.984405, -4.070453],
              [133.001236, -4.060823],
              [133.016953, -4.061378],
              [133.029343, -4.064953],
              [133.044205, -4.07195],
              [133.0647120000001, -4.076064],
              [133.083023, -4.072324],
              [133.09825100000012, -4.075178],
              [133.109131, -4.075168],
              [133.125839, -4.078554],
              [133.13768, -4.079244],
              [133.145996, -4.076674],
              [133.156754, -4.068657],
              [133.164688, -4.055602],
              [133.170609, -4.042411],
              [133.1775050000001, -4.035912],
              [133.199951, -4.029217],
              [133.20842, -4.024172],
              [133.2189330000001, -4.014194],
              [133.2225340000001, -4.00317],
              [133.23143, -3.99836],
              [133.24736, -3.995993],
              [133.261566, -3.995993],
              [133.268677, -3.998363],
              [133.2758030000001, -4.005472],
              [133.299484, -4.010211],
              [133.320801, -4.010211],
              [133.333649, -4.00148],
              [133.338989, -3.989439],
              [133.354996, -3.975824],
              [133.362656, -3.966343],
              [133.3638, -3.960888],
              [133.406937, -3.912046],
              [133.410996, -3.906247],
              [133.408981, -3.893194],
              [133.4129180000001, -3.88688],
              [133.4090873, -3.88131489999995],
              [133.39425960000005, -3.886437199999932],
              [133.37943180000002, -3.882662799999935],
              [133.3708048000001, -3.875114199999928],
              [133.39075480000008, -3.880506099999934],
              [133.40369540000006, -3.876462199999935],
              [133.4174448000001, -3.876731799999959],
              [133.426758, -3.879353],
              [133.436432, -3.874069],
              [133.441742, -3.866425],
              [133.448776, -3.868323],
              [133.454849, -3.864768],
              [133.46307400000012, -3.86436],
              [133.4660490000001, -3.858389],
              [133.461746, -3.840461],
              [133.4589840000001, -3.836373],
              [133.459442, -3.827644],
              [133.442886, -3.8112],
              [133.428802, -3.807403],
              [133.417633, -3.798612],
              [133.415512, -3.787802],
              [133.4154360000001, -3.773212],
              [133.413711, -3.76821],
              [133.400833, -3.755737],
              [133.398987, -3.744928],
              [133.40057400000012, -3.735448],
              [133.412506, -3.714189],
              [133.412277, -3.706301],
              [133.416443, -3.697526],
              [133.436981, -3.689537],
              [133.453781, -3.679259],
              [133.470535, -3.663689],
              [133.476425, -3.655756],
              [133.4819490000001, -3.643344],
              [133.489273, -3.633348],
              [133.4954990000001, -3.627947],
              [133.47992810000005, -3.606660699999964],
              [133.5002982000001, -3.586940699999957],
              [133.4929303, -3.573071699999957],
              [133.48924640000007, -3.561369799999966],
              [133.47451060000003, -3.563970199999972],
              [133.45500730000003, -3.548367599999949],
              [133.4186807000001, -3.551158499999929],
              [133.39382740000008, -3.545682299999953],
              [133.351282, -3.552843399999972],
              [133.34243590000005, -3.542733599999963],
              [133.307894, -3.542733599999963],
              [133.31126390000009, -3.539363699999967],
              [133.33780220000006, -3.540206199999943],
              [133.34369960000004, -3.538521199999934],
              [133.35423070000002, -3.549052199999949],
              [133.38034770000002, -3.543997299999944],
              [133.39053610000008, -3.539951699999961],
              [133.4077284000001, -3.545261099999948],
              [133.44858890000012, -3.545261099999948],
              [133.433003, -3.534308799999963],
              [133.4220507000001, -3.529675099999963],
              [133.39846110000008, -3.507349199999965],
              [133.43510920000006, -3.511561699999959],
              [133.43932160000008, -3.519565299999954],
              [133.44564030000004, -3.523356499999977],
              [133.45111640000005, -3.535151199999973],
              [133.4589079000001, -3.546633899999961],
              [133.47321030000012, -3.552701599999978],
              [133.4781945000001, -3.559852799999931],
              [133.49162060000003, -3.557690899999955],
              [133.4953141000001, -3.564620299999945],
              [133.5091831000001, -3.56050289999996],
              [133.50094830000012, -3.550101199999972],
              [133.49921470000004, -3.541433099999949],
              [133.50289870000006, -3.534932],
              [133.5146006000001, -3.531464699999958],
              [133.515034, -3.535365399999932],
              [133.50528240000006, -3.539266],
              [133.50333210000008, -3.547934199999929],
              [133.51091670000005, -3.554868699999929],
              [133.51265030000002, -3.564620299999945],
              [133.4983479000001, -3.570254599999942],
              [133.5061492000001, -3.58065629999993],
              [133.50809950000007, -3.59452529999993],
              [133.5002982000001, -3.607744199999956],
              [133.49596420000012, -3.621829899999966],
              [133.508164, -3.617878],
              [133.525955, -3.607075],
              [133.53345380000007, -3.597125799999958],
              [133.539108, -3.596671],
              [133.55217, -3.588993],
              [133.55504660000008, -3.585625499999935],
              [133.56689040000003, -3.583787699999959],
              [133.58731080000007, -3.574394299999938],
              [133.5936411, -3.549685599999975],
              [133.60180920000005, -3.535187099999973],
              [133.5979294000001, -3.522118099999943],
              [133.5854729, -3.509253299999955],
              [133.57832580000002, -3.497001],
              [133.56155400000011, -3.475275],
              [133.55580280000004, -3.460253899999941],
              [133.54959810000003, -3.454785299999969],
              [133.52088820000006, -3.444163699999933],
              [133.4964900000001, -3.43017679999997],
              [133.49322990000007, -3.421343],
              [133.47840170000006, -3.422605],
              [133.4701989, -3.418819099999951],
              [133.4701989, -3.408512899999948],
              [133.47871720000012, -3.420186199999932],
              [133.49680550000005, -3.417346799999962],
              [133.5062703000001, -3.430071699999928],
              [133.52215020000006, -3.438905499999976],
              [133.54630780000002, -3.442741099999978],
              [133.55335430000002, -3.440797199999963],
              [133.55941770000004, -3.432397499999979],
              [133.54935160000002, -3.42158569999998],
              [133.54798460000006, -3.412513799999942],
              [133.5574471000001, -3.41616259999995],
              [133.566498, -3.432326],
              [133.57902500000012, -3.430235],
              [133.587204, -3.431057],
              [133.59001200000012, -3.425901],
              [133.600815, -3.42495],
              [133.609222, -3.421014],
              [133.613052, -3.414365],
              [133.6203, -3.417603],
              [133.6305390000001, -3.416416],
              [133.63591, -3.41827],
              [133.642715, -3.415076],
              [133.649414, -3.407233],
              [133.666016, -3.381107],
              [133.668823, -3.37131],
              [133.667175, -3.357254],
              [133.671189, -3.343666],
              [133.668686, -3.332929],
              [133.662293, -3.328091],
              [133.667603, -3.321451],
              [133.665222, -3.311347],
              [133.67157, -3.30695],
              [133.662842, -3.279163],
              [133.6610260000001, -3.257643],
              [133.661728, -3.252885],
              [133.655991, -3.235954],
              [133.66547460000004, -3.222784199999978],
              [133.67476640000007, -3.218958099999952],
              [133.6766794, -3.211032799999941],
              [133.6717602000001, -3.208573199999933],
              [133.67121370000007, -3.202014199999951],
              [133.66684110000006, -3.190536099999974],
              [133.66957390000005, -3.175778499999979],
              [133.65782250000007, -3.162660699999947],
              [133.659189, -3.154188699999963],
              [133.66274170000008, -3.149816099999953],
              [133.6600088, -3.14353049999994],
              [133.661102, -3.136425],
              [133.657276, -3.127953099999957],
              [133.65782250000007, -3.122760599999935],
              [133.66875410000011, -3.121120799999971],
              [133.6731267, -3.125493499999948],
              [133.677226, -3.120301],
              [133.682831, -3.102923],
              [133.691757, -3.097441],
              [133.698456, -3.087761],
              [133.705536, -3.08198],
              [133.711609, -3.072074],
              [133.72139, -3.060332],
              [133.730103, -3.043777],
              [133.74294580000003, -3.033849399999951],
              [133.75671440000008, -3.034383299999945],
              [133.76140880000003, -3.026158399999929],
              [133.7714188000001, -3.023135399999944],
              [133.78176380000002, -3.027811399999962],
              [133.78850780000005, -3.026164399999971],
              [133.80153880000012, -3.029120399999954],
              [133.79960180000012, -3.023376399999961],
              [133.8006563, -3.014199799999972],
              [133.811633, -3.00727969999997],
              [133.82451880000008, -3.004654799999969],
              [133.84551780000004, -2.98938279999993],
              [133.84862, -2.979360499999927],
              [133.85601730000008, -2.972679],
              [133.861145, -2.973486],
              [133.866593, -3.005055],
              [133.876068, -3.019247],
              [133.868225, -3.028519],
              [133.875244, -3.038858],
              [133.88324000000011, -3.047432],
              [133.889526, -3.062818],
              [133.870438, -3.058641],
              [133.863861, -3.047959],
              [133.8629, -3.036824],
              [133.8530270000001, -3.024984],
              [133.842163, -3.024949],
              [133.834442, -3.036692],
              [133.8357390000001, -3.050179],
              [133.834762, -3.064236],
              [133.8392030000001, -3.082661],
              [133.835297, -3.093372],
              [133.828323, -3.097488],
              [133.825684, -3.104083],
              [133.812988, -3.108924],
              [133.814422, -3.113808],
              [133.8106990000001, -3.120683],
              [133.802185, -3.123127],
              [133.796188, -3.116624],
              [133.779953, -3.116589],
              [133.77845800000011, -3.128231],
              [133.782791, -3.13535],
              [133.7840880000001, -3.146087],
              [133.78025800000012, -3.152446],
              [133.777969, -3.162533],
              [133.7794950000001, -3.172745],
              [133.77475, -3.17792],
              [133.762695, -3.178907],
              [133.745712, -3.17312],
              [133.730225, -3.176296],
              [133.723419, -3.181734],
              [133.712555, -3.178659],
              [133.7088470000001, -3.173893],
              [133.704147, -3.179963],
              [133.70958, -3.190356],
              [133.709915, -3.200053],
              [133.707779, -3.214562],
              [133.697937, -3.219873],
              [133.687134, -3.218988],
              [133.675415, -3.231292],
              [133.6719660000001, -3.244824],
              [133.67337, -3.270695],
              [133.677078, -3.288035],
              [133.678375, -3.3022],
              [133.682083, -3.322236],
              [133.677673, -3.326587],
              [133.684692, -3.347138],
              [133.68840000000012, -3.354438],
              [133.687927, -3.360399],
              [133.69243270000004, -3.379780399999959],
              [133.70334530000002, -3.405581099999949],
              [133.70124070000008, -3.413765599999977],
              [133.69069600000012, -3.423606699999937],
              [133.68391780000002, -3.438895899999977],
              [133.6717374000001, -3.445232399999952],
              [133.66913820000002, -3.449326499999927],
              [133.66105190000007, -3.45313189999996],
              [133.6459592000001, -3.451280699999927],
              [133.64219480000008, -3.452457099999947],
              [133.64056470000003, -3.473584799999969],
              [133.6283314000001, -3.482150799999943],
              [133.6311919000001, -3.489534899999967],
              [133.64384780000012, -3.502106099999935],
              [133.64859190000004, -3.499713],
              [133.65384820000008, -3.504426099999932],
              [133.65586570000005, -3.518506199999933],
              [133.6626354000001, -3.51602719999994],
              [133.66102560000002, -3.527436499999965],
              [133.668609, -3.535169],
              [133.676346, -3.539071],
              [133.672852, -3.545828],
              [133.67822300000012, -3.548625],
              [133.6901521000001, -3.562574199999972],
              [133.692154, -3.577603],
              [133.696488, -3.579674],
              [133.697342, -3.587372],
              [133.706421, -3.597312],
              [133.712753, -3.61041],
              [133.71138, -3.620161],
              [133.699234, -3.639692],
              [133.698593, -3.662406],
              [133.695557, -3.674736],
              [133.706528, -3.675964],
              [133.715622, -3.668012],
              [133.730484, -3.662257],
              [133.735977, -3.655328],
              [133.75085400000012, -3.649401],
              [133.758911, -3.653317],
              [133.764557, -3.662805],
              [133.765976, -3.673018],
              [133.771408, -3.673026],
              [133.77597, -3.685265],
              [133.7849880000001, -3.694409],
              [133.786759, -3.699112],
              [133.795441, -3.706646],
              [133.802078, -3.708672],
              [133.803604, -3.713665],
              [133.814056, -3.724826],
              [133.8209230000001, -3.729719],
              [133.823151, -3.718538],
              [133.817627, -3.698503],
              [133.81146300000012, -3.691945],
              [133.800339, -3.667596],
              [133.796234, -3.65192],
              [133.7896730000001, -3.643363],
              [133.790649, -3.632807],
              [133.78923, -3.618171],
              [133.792099, -3.606249],
              [133.79592900000011, -3.602901],
              [133.812164, -3.58097],
              [133.822434, -3.568805],
              [133.836792, -3.560237],
              [133.841354, -3.561964],
              [133.843292, -3.569662],
              [133.842423, -3.577975],
              [133.844589, -3.586016],
              [133.846298, -3.6038],
              [133.841705, -3.6148],
              [133.846832, -3.6384],
              [133.849808, -3.643338],
              [133.86174, -3.653007],
              [133.866135, -3.660415],
              [133.8870700000001, -3.672111],
              [133.886871, -3.677337],
              [133.891952, -3.686011],
              [133.9044040000001, -3.693843],
              [133.910523, -3.703612],
              [133.912216, -3.72031],
              [133.916489, -3.733055],
              [133.919098, -3.747871],
              [133.929779, -3.757585],
              [133.936066, -3.760352],
              [133.946152, -3.776263],
              [133.933701, -3.769751],
              [133.92897, -3.761945],
              [133.921265, -3.755677],
              [133.91748, -3.767021],
              [133.916534, -3.78457],
              [133.933014, -3.822687],
              [133.937058, -3.834048],
              [133.948639, -3.850591],
              [133.9587550000001, -3.856008],
              [133.964523, -3.863938],
              [133.968643, -3.855862],
              [133.967743, -3.849666],
              [133.97792, -3.844753],
              [133.987198, -3.827692],
              [133.994629, -3.829482],
              [134.0073400000001, -3.809906],
              [134.021179, -3.802821],
              [134.029709, -3.793042],
              [134.037842, -3.790309],
              [134.043213, -3.785152],
              [134.048065, -3.787178],
              [134.0526880000001, -3.795563],
              [134.052735, -3.81047],
              [134.062897, -3.822192],
              [134.073013, -3.822616],
              [134.09018, -3.808548],
              [134.092636, -3.803799],
              [134.09996, -3.801862],
              [134.108368, -3.796208],
              [134.120773, -3.791647],
              [134.11731, -3.779255],
              [134.103317, -3.769931],
              [134.113907, -3.752354],
              [134.122833, -3.750878],
              [134.127503, -3.763913],
              [134.133621, -3.763007],
              [134.135239, -3.750452],
              [134.1400671, -3.746083899999974],
              [134.145019, -3.747267],
              [134.14517200000012, -3.757072],
              [134.15431200000012, -3.767935],
              [134.162475, -3.773171],
              [134.16629000000012, -3.781203],
              [134.171448, -3.774563],
              [134.174988, -3.777159],
              [134.172287, -3.790285],
              [134.175018, -3.803654],
              [134.183975, -3.820876],
              [134.194595, -3.831105],
              [134.183212, -3.838017],
              [134.165863, -3.855007],
              [134.154083, -3.862327],
              [134.14978, -3.870685],
              [134.142624, -3.875661],
              [134.135605, -3.875472],
              [134.127136, -3.882275],
              [134.1186070000001, -3.885062],
              [134.111465, -3.895862],
              [134.1159060000001, -3.901571],
              [134.1260830000001, -3.903886],
              [134.13237, -3.900981],
              [134.1360320000001, -3.894793],
              [134.153977, -3.896501],
              [134.161056, -3.904605],
              [134.1701660000001, -3.895794],
              [134.170776, -3.907435],
              [134.173691, -3.910945],
              [134.168823, -3.91678],
              [134.175095, -3.926775],
              [134.17543000000012, -3.932338],
              [134.181717, -3.93809],
              [134.182968, -3.943363],
              [134.193817, -3.954054],
              [134.198379, -3.960892],
              [134.218994, -3.972586],
              [134.243179, -3.980616],
              [134.2447360000001, -3.968341],
              [134.254669, -3.979665],
              [134.261856, -3.985589],
              [134.262604, -3.989894],
              [134.275482, -3.998153],
              [134.292129, -4.024002],
              [134.289429, -4.032084],
              [134.290817, -4.04146],
              [134.3026890000001, -4.046485],
              [134.317642, -4.0428],
              [134.325119, -4.02958],
              [134.329559, -4.025302],
              [134.327255, -4.020575],
              [134.335205, -4.01839],
              [134.326019, -4.006431],
              [134.323639, -3.995143],
              [134.3116450000001, -3.9841],
              [134.30954, -3.978989],
              [134.300415, -3.968823],
              [134.30098, -3.96515],
              [134.29408200000012, -3.95814],
              [134.289276, -3.957671],
              [134.278992, -3.948183],
              [134.274887, -3.949432],
              [134.270599, -3.938812],
              [134.27153, -3.93239],
              [134.283936, -3.933628],
              [134.297531, -3.941577],
              [134.305573, -3.948243],
              [134.309738, -3.956339],
              [134.323456, -3.963257],
              [134.3272250000001, -3.961773],
              [134.336426, -3.965236],
              [134.344315, -3.964449],
              [134.3577570000001, -3.954623],
              [134.360519, -3.944537],
              [134.351669, -3.933331],
              [134.34964, -3.915719],
              [134.362274, -3.915066],
              [134.340622, -3.899239],
              [134.329422, -3.892447],
              [134.326813, -3.884071],
              [134.335159, -3.877494],
              [134.343735, -3.880839],
              [134.350006, -3.89135],
              [134.355438, -3.895203],
              [134.372238, -3.90127],
              [134.3825680000001, -3.90903],
              [134.389206, -3.910197],
              [134.403427, -3.918255],
              [134.41806, -3.91439],
              [134.427612, -3.918089],
              [134.43367000000012, -3.915176],
              [134.4404750000001, -3.916685],
              [134.446396, -3.929828],
              [134.4549710000001, -3.931283],
              [134.453995, -3.938511],
              [134.4561000000001, -3.94591],
              [134.466385, -3.949717],
              [134.465805, -3.955335],
              [134.4771730000001, -3.965691],
              [134.478134, -3.974293],
              [134.4753270000001, -3.982145],
              [134.469208, -3.982354],
              [134.45195, -3.97606],
              [134.452683, -3.983749],
              [134.467529, -3.991988],
              [134.473999, -4.002141],
              [134.4835210000001, -4.004479],
              [134.48698400000012, -4.00893],
              [134.5038760000001, -4.013605],
              [134.5037390000001, -4.022117],
              [134.496048, -4.024962],
              [134.505508, -4.036729],
              [134.511093, -4.041412],
              [134.51918000000012, -4.036085],
              [134.5322420000001, -4.036261],
              [134.537232, -4.030152],
              [134.54744, -4.03],
              [134.548203, -4.02107],
              [134.5569, -4.008196],
              [134.56665, -4.005757],
              [134.5736240000001, -3.997059],
              [134.581344, -3.995068],
              [134.587112, -3.999609],
              [134.60553, -3.99616],
              [134.612503, -3.987059],
              [134.614578, -3.97473],
              [134.611099, -3.969221],
              [134.6196900000001, -3.961613],
              [134.626617, -3.94798],
              [134.637665, -3.943076],
              [134.64537, -3.944414],
              [134.657043, -3.936869],
              [134.659454, -3.929541],
              [134.651337, -3.927398],
              [134.655457, -3.921211],
              [134.675537, -3.917364],
              [134.681991, -3.920131],
              [134.692169, -3.915969],
              [134.7049710000001, -3.916918],
              [134.7273100000001, -3.932283],
              [134.736099, -3.936082],
              [134.7476190000001, -3.918505],
              [134.75975, -3.912687],
              [134.768143, -3.913916],
              [134.789566, -3.922733],
              [134.7979130000001, -3.929698],
              [134.80511500000011, -3.932754],
              [134.826309, -3.936705],
              [134.840607, -3.936332],
              [134.8476260000001, -3.939162],
              [134.8573, -3.937289],
              [134.873749, -3.947482],
              [134.8823850000001, -3.94028],
              [134.883026, -3.930411],
              [134.899491, -3.935792],
              [134.90834, -3.936442],
              [134.914108, -3.940584],
              [134.9073790000001, -3.944612],
              [134.904785, -3.952263],
              [134.909866, -3.959671],
              [134.922439, -3.958439],
              [134.93016000000011, -3.952839],
              [134.941773, -3.949943],
              [134.950455, -3.95053],
              [134.957321, -3.944243],
              [134.9687500000001, -3.950573],
              [134.9679410000001, -3.957511],
              [134.96411100000012, -3.960778],
              [134.944946, -3.966008],
              [134.94751, -3.974556],
              [134.9422, -3.977695],
              [134.929382, -3.977896],
              [134.920639, -3.982574],
              [134.912979, -3.97797],
              [134.903671, -3.977944],
              [134.890244, -3.972238],
              [134.883835, -3.97383],
              [134.867142, -3.972702],
              [134.858231, -3.964589],
              [134.859619, -3.959722],
              [134.85791, -3.949627],
              [134.835907, -3.949575],
              [134.812759, -3.944639],
              [134.805267, -3.939348],
              [134.7861180000001, -3.936664],
              [134.761444, -3.930416],
              [134.756241, -3.932063],
              [134.7500920000001, -3.939934],
              [134.7419890000001, -3.942467],
              [134.733917, -3.950591],
              [134.724228, -3.956508],
              [134.7197880000001, -3.953714],
              [134.694916, -3.962364],
              [134.6903380000001, -3.9662],
              [134.682739, -3.965721],
              [134.677872, -3.975889],
              [134.672547, -3.979119],
              [134.657562, -3.979944],
              [134.666595, -4.001422],
              [134.6684110000001, -4.009998],
              [134.667481, -4.018932],
              [134.664017, -4.024312],
              [134.66333, -4.033814],
              [134.673324, -4.039532],
              [134.68186900000012, -4.05345],
              [134.6957860000001, -4.055444],
              [134.70726, -4.053247],
              [134.718857, -4.056394],
              [134.701385, -4.067772],
              [134.68602, -4.066679],
              [134.679504, -4.073402],
              [134.648392, -4.082033],
              [134.6352240000001, -4.092207],
              [134.634109, -4.102526],
              [134.623673, -4.11947],
              [134.63049300000011, -4.120614],
              [134.646546, -4.134106],
              [134.661392, -4.134408],
              [134.672623, -4.139001],
              [134.682465, -4.140296],
              [134.699799, -4.140304],
              [134.706253, -4.137835],
              [134.716202, -4.160283],
              [134.715134, -4.163932],
              [134.7206880000001, -4.173905],
              [134.722412, -4.186622],
              [134.719437, -4.198254],
              [134.7343750000001, -4.205337],
              [134.743271, -4.207634],
              [134.748398, -4.204741],
              [134.759445, -4.206464],
              [134.768005, -4.215956],
              [134.773132, -4.228066],
              [134.786911, -4.236387],
              [134.799454, -4.236402],
              [134.80961600000012, -4.245682],
              [134.809647, -4.251832],
              [134.817429, -4.250422],
              [134.830948, -4.255357],
              [134.847702, -4.249976],
              [134.8570453000001, -4.253748899999948],
              [134.8702538000001, -4.251240399999972],
              [134.87833280000007, -4.240780099999938],
              [134.889938, -4.221521],
              [134.923175, -4.183060199999943],
              [134.95098010000004, -4.156550299999935],
              [134.979447, -4.130203],
              [135.002106, -4.105765],
              [135.0331119000001, -4.065578699999946],
              [135.049927, -4.041526],
              [135.066772, -4.015964],
              [135.086609, -3.984202],
              [135.11412, -3.941729],
              [135.1388250000001, -3.905458],
              [135.1771357, -3.851534899999933],
              [135.225434, -3.791374],
              [135.25798, -3.75193],
              [135.24142240000003, -3.748085699999933],
              [135.163559, -3.726844],
              [135.11734, -3.703116],
              [135.04451, -3.675407],
              [135.00058, -3.660682],
              [134.888657, -3.631025],
              [134.8207860000001, -3.606875],
              [134.748306, -3.579676],
              [134.6358801, -3.54263949999995],
              [134.7268014000001, -3.408591299999955],
              [134.72592940000004, -3.363769199999979],
              [134.7035059000001, -3.345510099999956],
              [134.68586660000005, -3.197340199999928],
              [134.6692402000001, -3.062209099999961],
              [134.65721470000005, -2.961710699999969],
              [134.6426193000001, -2.844780299999968],
              [134.63288580000005, -2.765633499999979],
              [134.624391, -2.699685099999954],
              [134.6216512000001, -2.673897299999965],
              [134.613888, -2.615657],
              [134.5987934000001, -2.489128899999969],
              [134.574951, -2.479402],
              [134.57048080000004, -2.476417099999935],
              [134.571854, -2.464585],
              [134.5666351000001, -2.459122399999956],
              [134.5565491000001, -2.458427],
              [134.53067040000008, -2.452035499999965],
              [134.5338901, -2.462780499999951],
              [134.5416107000001, -2.465602199999978],
              [134.550324, -2.472322],
              [134.545639, -2.475081399999965],
              [134.53784180000002, -2.470993499999963],
              [134.525589, -2.469901],
              [134.5210571, -2.467250599999943],
              [134.51287800000011, -2.473737],
              [134.51586910000003, -2.479254199999957],
              [134.52255250000007, -2.475988599999937],
              [134.5353851000001, -2.481106699999941],
              [134.5454559000001, -2.477722599999936],
              [134.556439, -2.47818929999994],
              [134.569458, -2.482677],
              [134.56527710000012, -2.488991499999941],
              [134.551025, -2.485076],
              [134.54736330000003, -2.489671899999962],
              [134.517014, -2.48546],
              [134.511856, -2.486148],
              [134.49635310000008, -2.499872299999936],
              [134.48571890000005, -2.51261889999995],
              [134.489609, -2.51687],
              [134.47525020000012, -2.52461459999995],
              [134.470108, -2.53225],
              [134.46422110000003, -2.549062399999968],
              [134.45942690000004, -2.568496899999957],
              [134.458633, -2.576937],
              [134.4596100000001, -2.593997],
              [134.463745, -2.599686],
              [134.47015380000005, -2.602218499999935],
              [134.47389160000012, -2.614113],
              [134.46879580000007, -2.618989],
              [134.469253, -2.62732],
              [134.47259520000011, -2.635134899999969],
              [134.472137, -2.641973],
              [134.4755249000001, -2.648350499999935],
              [134.484634, -2.657088],
              [134.493454, -2.662604],
              [134.49569540000005, -2.677929799999959],
              [134.49043270000004, -2.685987699999941],
              [134.4980011, -2.700812799999937],
              [134.4946440000001, -2.715395],
              [134.498138, -2.724874],
              [134.507126, -2.733331],
              [134.508225, -2.73884],
              [134.50233460000004, -2.753829],
              [134.50590520000003, -2.766872399999954],
              [134.513748, -2.772453],
              [134.51358, -2.785261],
              [134.521042, -2.797445],
              [134.5170449000001, -2.813637],
              [134.5175018000001, -2.820303899999942],
              [134.50892620000002, -2.837420499999951],
              [134.49806300000012, -2.846945499999947],
              [134.49411010000006, -2.853895399999942],
              [134.48764, -2.854529],
              [134.49624630000005, -2.863782199999946],
              [134.484787, -2.862969],
              [134.478775, -2.866908],
              [134.46373, -2.868641],
              [134.455597, -2.867141],
              [134.452332, -2.857607],
              [134.44390870000007, -2.849557199999936],
              [134.449219, -2.837725],
              [134.44755140000007, -2.833545899999933],
              [134.44926450000003, -2.823133899999959],
              [134.44668710000008, -2.817852099999925],
              [134.45205690000012, -2.811021599999947],
              [134.440719, -2.809114],
              [134.434418, -2.798658],
              [134.4245605000001, -2.794172499999945],
              [134.42289730000005, -2.779003099999954],
              [134.40742550000004, -2.770438899999931],
              [134.411255, -2.759873],
              [134.4108430000001, -2.749642],
              [134.40431200000012, -2.737748],
              [134.4084170000001, -2.722994],
              [134.4000092000001, -2.723673299999973],
              [134.396622, -2.713561],
              [134.391113, -2.706153],
              [134.382126, -2.698501],
              [134.388413, -2.696383],
              [134.3797, -2.676448],
              [134.372376, -2.677245],
              [134.3686996, -2.672056299999952],
              [134.36743730000012, -2.661032599999942],
              [134.37440450000008, -2.654385899999966],
              [134.3754874000001, -2.647494],
              [134.3669586000001, -2.648516299999926],
              [134.361114, -2.643117],
              [134.3508, -2.626972299999977],
              [134.360306, -2.625424],
              [134.354172, -2.618007],
              [134.34980740000003, -2.608700599999963],
              [134.34167480000008, -2.607488399999966],
              [134.341736, -2.600713],
              [134.335083, -2.588303],
              [134.327347, -2.587028],
              [134.34172100000012, -2.578714],
              [134.3329470000001, -2.570094],
              [134.33311460000004, -2.561301899999933],
              [134.34076430000005, -2.555094099999963],
              [134.33322140000007, -2.549985899999967],
              [134.32044980000012, -2.547391199999936],
              [134.31729130000008, -2.538490499999966],
              [134.32163960000003, -2.532339],
              [134.3164220000001, -2.522751],
              [134.30982970000002, -2.518780899999967],
              [134.31498720000002, -2.509653299999968],
              [134.29866, -2.507168],
              [134.296707, -2.496706],
              [134.290695, -2.495961],
              [134.29057310000007, -2.489583699999969],
              [134.28044180000006, -2.484699399999954],
              [134.28038020000008, -2.480276799999956],
              [134.27224730000012, -2.478378299999974],
              [134.276581, -2.468961],
              [134.268677, -2.459817],
              [134.2680964000001, -2.452011499999969],
              [134.26054450000004, -2.455222],
              [134.2585907, -2.445969099999957],
              [134.2624207, -2.435629399999925],
              [134.25376890000007, -2.43033859999997],
              [134.25010680000003, -2.433441899999934],
              [134.24851990000002, -2.442804299999978],
              [134.24484230000007, -2.442921399999932],
              [134.2403107, -2.434012899999971],
              [134.23073550000004, -2.42604579999994],
              [134.215637, -2.423776],
              [134.20794650000005, -2.407340799999929],
              [134.202957, -2.403027],
              [134.202438, -2.394243],
              [134.205414, -2.384763],
              [134.19647220000002, -2.381887699999936],
              [134.1934357, -2.37694],
              [134.1930850000001, -2.36879],
              [134.17642200000012, -2.355395],
              [134.1743775000001, -2.340217],
              [134.17421, -2.323337],
              [134.16333, -2.312475],
              [134.1571960000001, -2.296555],
              [134.1586761000001, -2.289381699999979],
              [134.154831, -2.278057],
              [134.15768430000003, -2.266803699999969],
              [134.1525269000001, -2.258988899999963],
              [134.15130610000006, -2.244913399999973],
              [134.139572, -2.24146],
              [134.13774150000006, -2.229219399999977],
              [134.14671260000011, -2.228306],
              [134.1368112, -2.217432199999962],
              [134.142182, -2.215897],
              [134.1485901000001, -2.206253099999969],
              [134.14961240000002, -2.199477699999932],
              [134.14398190000009, -2.180744899999979],
              [134.1477661, -2.175923099999977],
              [134.144653, -2.162934],
              [134.141281, -2.15662],
              [134.132065, -2.157191],
              [134.1279449000001, -2.160113299999978],
              [134.13121030000002, -2.167413],
              [134.12353560000008, -2.169303699999944],
              [134.1196900000001, -2.15816],
              [134.11842350000006, -2.143678399999942],
              [134.1145782000001, -2.128744599999948],
              [134.116745, -2.114271],
              [134.1150206000001, -2.109558899999968],
              [134.107969, -2.103573099999949],
              [134.1146665000001, -2.098806],
              [134.10427860000004, -2.074164199999927],
              [134.1071928, -2.061473],
              [134.10420290000002, -2.05290679999996],
              [134.10981750000008, -2.044186099999934],
              [134.121369, -2.039825],
              [134.1212, -2.028563],
              [134.12513730000012, -2.026436599999954],
              [134.13886980000007, -2.010306699999944],
              [134.14241030000005, -2.002726099999961],
              [134.140122, -1.982989],
              [134.136276, -1.976377],
              [134.138382, -1.966435199999978],
              [134.14295960000004, -1.966036699999961],
              [134.14627080000002, -1.97196109999993],
              [134.151764, -1.970459],
              [134.1528320000001, -1.963448],
              [134.14163210000004, -1.960636499999964],
              [134.134537, -1.956784],
              [134.136185, -1.948272],
              [134.1329651000001, -1.927638899999977],
              [134.1277920000001, -1.913501],
              [134.121018, -1.885279],
              [134.1226044000001, -1.871538799999939],
              [134.121567, -1.864302],
              [134.1140289000001, -1.868436199999962],
              [134.10739200000012, -1.867804099999944],
              [134.1017303000001, -1.862106399999959],
              [134.10679630000004, -1.849812899999961],
              [134.0997622000001, -1.836190899999963],
              [134.102707, -1.815612],
              [134.099091, -1.804576],
              [134.101257, -1.79923],
              [134.096832, -1.788539],
              [134.099686, -1.778081],
              [134.100128, -1.766874],
              [134.09292410000012, -1.768191799999954],
              [134.08912710000004, -1.706681099999969],
              [134.08067190000008, -1.663559499999963],
              [134.08078000000012, -1.659793],
              [134.070724, -1.658636],
              [134.070495, -1.653291],
              [134.075394, -1.649156],
              [134.08807400000012, -1.646513],
              [134.0940700000001, -1.640954699999952],
              [134.106186, -1.638415],
              [134.1169890000001, -1.638704],
              [134.1234280000001, -1.63342],
              [134.132324, -1.620484],
              [134.138306, -1.607331],
              [134.1445920000001, -1.597497],
              [134.165878, -1.581982],
              [134.176544, -1.568312],
              [134.190582, -1.5559],
              [134.188522, -1.551875],
              [134.190277, -1.54377],
              [134.195709, -1.54348],
              [134.199127, -1.534289],
              [134.201004, -1.522276],
              [134.211151, -1.504111],
              [134.211304, -1.495382],
              [134.2042080000001, -1.480494],
              [134.198242, -1.464167],
              [134.200668, -1.448879],
              [134.211289, -1.433419],
              [134.21411100000012, -1.409393],
              [134.222595, -1.392558],
              [134.229096, -1.375887],
              [134.240891, -1.358418],
              [134.246674, -1.352618],
              [134.252945, -1.350375],
              [134.264191, -1.340722],
              [134.2695010000001, -1.341644],
              [134.273971, -1.349803],
              [134.279343, -1.347622],
              [134.277268, -1.333312],
              [134.255875, -1.318073],
              [134.246719, -1.305482],
              [134.2463530000001, -1.291859],
              [134.224548, -1.263694],
              [134.214584, -1.244608],
              [134.207703, -1.234785],
              [134.203461, -1.224502],
              [134.2034450000001, -1.212823],
              [134.200592, -1.207016],
              [134.189941, -1.194597],
              [134.176452, -1.188738],
              [134.169525, -1.183048],
              [134.15980500000012, -1.178843],
              [134.14505, -1.164471],
              [134.133026, -1.148435],
              [134.128571, -1.147287],
              [134.10568200000012, -1.126421],
              [134.103912, -1.123427],
              [134.10463, -1.10785],
              [134.099136, -1.097738],
              [134.082031, -1.082444],
              [134.071945, -1.064218],
              [134.074997, -1.038871],
              [134.073379, -1.027148],
              [134.06520100000012, -1.014386],
              [134.04592900000011, -1.002719],
              [134.038849, -0.993113],
              [134.036682, -0.98518],
              [134.0250850000001, -0.978805],
              [134.014923, -0.96587],
              [134.010346, -0.957241],
              [134.0119780000001, -0.941936],
              [134.020752, -0.929099],
              [134.038895, -0.928229],
              [134.042999, -0.926844],
              [134.045547, -0.918676],
              [134.039916, -0.910760399999958],
              [134.0403384000001, -0.904142699999966],
              [134.0496313000001, -0.902171499999952],
              [134.0549386, -0.891100699999924],
              [134.0502613000001, -0.88089309999998],
              [134.05011720000005, -0.869253899999933],
              [134.05474960000004, -0.86747859999997],
              [134.064374, -0.876270399999953],
              [134.06966110000008, -0.875479599999949],
              [134.06928720000008, -0.868382299999951],
              [134.07581930000003, -0.86928469999998],
              [134.078792, -0.873723299999938],
              [134.08933720000005, -0.878798499999959],
              [134.09919330000002, -0.878094499999975],
              [134.1006013000001, -0.873588899999959],
              [134.10879020000004, -0.870029399999964],
              [134.113555, -0.871617699999945],
              [134.116371, -0.878798499999959],
              [134.12467830000003, -0.879924899999935],
              [134.1356608000001, -0.869364899999937],
              [134.1263679000001, -0.862184],
              [134.1258474000001, -0.855877699999951],
              [134.1200318000001, -0.853172699999959],
              [134.0955967000001, -0.833319199999949],
              [134.07919950000007, -0.814170799999943],
              [134.05948740000008, -0.809383599999933],
              [134.041324, -0.803188399999954],
              [134.03215850000004, -0.796991899999966],
              [134.02766630000008, -0.78967149999994],
              [134.0146076000001, -0.781682299999943],
              [134.00387100000012, -0.772634499999924],
              [133.989624, -0.755516],
              [133.98333700000012, -0.744825],
              [133.9827136, -0.738401499999952],
              [133.98570870000003, -0.732020699999964],
              [133.977635, -0.725900299999978],
              [133.97342590000005, -0.719785199999933],
              [133.964951, -0.723153],
              [133.95015850000004, -0.713789799999972],
              [133.94120310000005, -0.716579799999977],
              [133.93369330000007, -0.73264329999995],
              [133.92854180000006, -0.732802],
              [133.9222370000001, -0.721541599999966],
              [133.9143478000001, -0.72017059999996],
              [133.9040897000001, -0.727429499999971],
              [133.89605210000002, -0.724976],
              [133.89238000000012, -0.718431],
              [133.88146070000005, -0.714146399999947],
              [133.87098430000003, -0.715743099999941],
              [133.8651244, -0.719910099999936],
              [133.8665568, -0.724858499999925],
              [133.8577018000001, -0.731109099999969],
              [133.83842920000006, -0.732932199999937],
              [133.83256920000008, -0.735146],
              [133.81802760000005, -0.747044899999935],
              [133.807312, -0.746111],
              [133.79866970000012, -0.737838699999941],
              [133.7953261, -0.724858499999925],
              [133.77292820000002, -0.73019759999994],
              [133.7590871000001, -0.727281399999924],
              [133.7614688000001, -0.72225409999993],
              [133.7544368, -0.717826599999967],
              [133.74401920000003, -0.721603],
              [133.73998230000007, -0.718347499999936],
              [133.730972, -0.723443],
              [133.738281, -0.729195],
              [133.7355500000001, -0.737074],
              [133.72779900000012, -0.746916],
              [133.7168031000001, -0.74920979999996],
              [133.681534, -0.741431],
              [133.641022, -0.73514],
              [133.628143, -0.737566],
              [133.60546440000007, -0.73462509999996],
              [133.58293630000003, -0.726681599999949],
              [133.57772740000007, -0.72069149999993],
              [133.5653565, -0.721603],
              [133.56900270000006, -0.729806899999971],
              [133.56825930000002, -0.73785],
              [133.55025090000004, -0.745563599999969],
              [133.5285040000001, -0.747777399999961],
              [133.49686040000006, -0.74126629999995],
              [133.45245670000008, -0.740427699999941],
              [133.432892, -0.737877],
              [133.413269, -0.737816],
              [133.3952640000001, -0.740686],
              [133.389481, -0.739691],
              [133.37851000000012, -0.728603],
              [133.371033, -0.723538],
              [133.34993, -0.714875],
              [133.3311920000001, -0.705017],
              [133.321533, -0.698632],
              [133.30423, -0.676454],
              [133.28996340000003, -0.660557799999935],
              [133.27222410000002, -0.650568499999963],
              [133.24969080000005, -0.627465],
              [133.24705240000003, -0.618870799999968],
              [133.237045, -0.608619],
              [133.22587350000003, -0.600274899999931],
              [133.190183, -0.589503799999932],
              [133.179151, -0.584842399999957],
              [133.1721394000001, -0.577527699999962],
              [133.16280930000005, -0.572184199999924],
              [133.15833, -0.566973599999926],
              [133.15926220000006, -0.553610799999944],
              [133.15164860000004, -0.546152599999971],
              [133.14468620000002, -0.543637],
              [133.12729350000006, -0.544979799999965],
              [133.12279390000003, -0.553214899999944],
              [133.1121128000001, -0.55143309999994],
              [133.10777970000004, -0.548309299999971],
              [133.0960689000001, -0.545549799999947],
              [133.0870192000001, -0.538196899999946],
              [133.08156630000008, -0.528355199999964],
              [133.08150410000007, -0.517239599999925],
              [133.06986430000006, -0.516003199999943],
              [133.0591680000001, -0.519445599999926],
              [133.0514469000001, -0.518894099999955],
              [133.0470471000001, -0.515485],
              [133.04151980000006, -0.520548599999927],
              [133.03076540000006, -0.519445599999926],
              [133.02415210000004, -0.515006299999925],
              [133.01616080000008, -0.527370399999938],
              [133.00700360000008, -0.50198],
              [132.9995113000001, -0.49157409999998],
              [133.002425, -0.481584399999974],
              [132.9850692000001, -0.480715499999974],
              [132.9617588000001, -0.473893399999952],
              [132.96974390000003, -0.452310599999976],
              [132.96195610000007, -0.440535299999965],
              [132.9561973000001, -0.439283399999965],
              [132.9457314000001, -0.442888899999957],
              [132.92550030000007, -0.452154199999939],
              [132.90505030000008, -0.447433499999931],
              [132.89427100000012, -0.448631199999966],
              [132.886965, -0.446235799999954],
              [132.8801380000001, -0.447792799999945],
              [132.86169330000007, -0.437492499999962],
              [132.8292355000001, -0.424677],
              [132.81115010000008, -0.415454699999941],
              [132.80168820000006, -0.413538399999936],
              [132.78827390000004, -0.406591699999979],
              [132.77509910000003, -0.396770499999946],
              [132.7693511000001, -0.397365099999945],
              [132.7564383, -0.383079799999962],
              [132.7485455000001, -0.387722599999961],
              [132.7137245, -0.373794199999963],
              [132.709546, -0.362187199999937],
              [132.70188080000003, -0.358925199999931],
              [132.69143910000003, -0.36636569999996],
              [132.67565360000003, -0.365437199999974],
              [132.6584752000001, -0.361258699999951],
              [132.64733250000006, -0.357080099999962],
              [132.63990400000012, -0.360330099999942],
              [132.61993990000008, -0.357080099999962],
              [132.60090450000007, -0.355223],
              [132.581869, -0.356615899999952],
              [132.52597570000012, -0.353666],
              [132.5032827000001, -0.358393699999965],
              [132.4630972000001, -0.349411099999941]
            ]
          ],
          [
            [
              [130.20617730000004, -0.333005],
              [130.1945803000001, -0.329083],
              [130.1818853000001, -0.320566],
              [130.1733253000001, -0.319004],
              [130.17047130000003, -0.323311],
              [130.17950530000007, -0.333493],
              [130.18760730000008, -0.337181],
              [130.19686930000012, -0.335758],
              [130.1957863, -0.342244],
              [130.20149330000004, -0.348122],
              [130.2132573, -0.350605],
              [130.2175453000001, -0.343657],
              [130.21913230000007, -0.333371],
              [130.21548530000007, -0.329863],
              [130.20617730000004, -0.333005]
            ]
          ],
          [
            [
              [130.37989830000004, -0.318324],
              [130.37231530000008, -0.317479],
              [130.36940030000005, -0.325812],
              [130.37265030000003, -0.329782],
              [130.3804173000001, -0.323637],
              [130.37989830000004, -0.318324]
            ]
          ],
          [
            [
              [130.35449230000006, -0.315152],
              [130.34495630000004, -0.314513],
              [130.34957930000007, -0.32182],
              [130.35449230000006, -0.315152]
            ]
          ],
          [
            [
              [130.1313483, -0.311248],
              [130.12466530000006, -0.313132],
              [130.12403930000005, -0.31922],
              [130.13066130000004, -0.323424],
              [130.1360323, -0.330333],
              [130.14265530000011, -0.331606],
              [130.1428843000001, -0.321774],
              [130.1386573000001, -0.312286],
              [130.1313483, -0.311248]
            ]
          ],
          [
            [
              [130.66284230000008, -0.309709],
              [130.66488730000003, -0.316284],
              [130.65213130000006, -0.324699],
              [130.65541130000008, -0.330535],
              [130.66352930000005, -0.334883],
              [130.66606230000002, -0.327229],
              [130.67213530000004, -0.326494],
              [130.66975430000002, -0.318481],
              [130.6725623000001, -0.311062],
              [130.66284230000008, -0.309709]
            ]
          ],
          [
            [
              [130.48869330000002, -0.306078],
              [130.48606930000005, -0.302687],
              [130.47930930000007, -0.306439],
              [130.48207130000003, -0.311472],
              [130.47665430000006, -0.31577],
              [130.47842430000003, -0.325258],
              [130.48516930000005, -0.328829],
              [130.48642030000008, -0.333315],
              [130.49864230000003, -0.333673],
              [130.50990330000002, -0.33224],
              [130.51620530000002, -0.32805],
              [130.51426730000003, -0.321999],
              [130.50935430000004, -0.321521],
              [130.51182630000005, -0.309435],
              [130.50222830000007, -0.311899],
              [130.49560630000008, -0.305623],
              [130.48869330000002, -0.306078]
            ]
          ],
          [
            [
              [130.85192930000005, -0.301919],
              [130.8455663000001, -0.305006],
              [130.84892330000002, -0.31642],
              [130.8607793000001, -0.319338],
              [130.86679130000005, -0.316821],
              [130.8788591000001, -0.317792199999928],
              [130.88387180000007, -0.322839599999952],
              [130.8932963000001, -0.319607],
              [130.8859873, -0.311116],
              [130.8777473, -0.305564],
              [130.86688230000004, -0.302999],
              [130.85192930000005, -0.301919]
            ]
          ],
          [
            [
              [130.22328230000005, -0.291533],
              [130.22099330000003, -0.280616],
              [130.21653830000002, -0.279406],
              [130.1957553000001, -0.284089],
              [130.1925053000001, -0.28874],
              [130.1957553000001, -0.300353],
              [130.19010930000002, -0.305285],
              [130.19862430000012, -0.315812],
              [130.2029123000001, -0.313639],
              [130.21319630000005, -0.322274],
              [130.22210730000006, -0.322172],
              [130.23300230000007, -0.305039],
              [130.22392330000002, -0.29602],
              [130.22328230000005, -0.291533]
            ]
          ],
          [
            [
              [130.33500730000003, -0.198116],
              [130.3293003000001, -0.198453],
              [130.3197633000001, -0.208497],
              [130.30799930000012, -0.210726],
              [130.31514030000005, -0.217228],
              [130.32725530000005, -0.214031],
              [130.33346630000005, -0.206394],
              [130.34054630000003, -0.20318],
              [130.33500730000003, -0.198116]
            ]
          ],
          [
            [
              [130.35133430000008, -0.182679],
              [130.34523030000003, -0.18601],
              [130.34843530000012, -0.192214],
              [130.3608253000001, -0.183843],
              [130.35133430000008, -0.182679]
            ]
          ],
          [
            [
              [130.2614443000001, -0.182393],
              [130.25659230000008, -0.18405],
              [130.24476630000004, -0.183285],
              [130.24620130000005, -0.190766],
              [130.25853030000007, -0.197383],
              [130.2605903000001, -0.193484],
              [130.27429230000007, -0.196319],
              [130.2846383000001, -0.195529],
              [130.28257830000007, -0.190292],
              [130.27114930000005, -0.182399],
              [130.2614443000001, -0.182393]
            ]
          ],
          [
            [
              [130.22648630000003, -0.16947],
              [130.22203130000003, -0.169752],
              [130.19964630000004, -0.179836],
              [130.20649730000002, -0.186917],
              [130.22638030000007, -0.189009],
              [130.2350623000001, -0.184537],
              [130.23082030000012, -0.174136],
              [130.22648630000003, -0.16947]
            ]
          ],
          [
            [
              [130.26075830000002, -0.17371],
              [130.25801130000002, -0.162213],
              [130.24845930000004, -0.159729],
              [130.24687230000006, -0.164036],
              [130.2372133, -0.165007],
              [130.23837330000003, -0.179778],
              [130.2545933, -0.180776],
              [130.26075830000002, -0.17371]
            ]
          ],
          [
            [
              [130.68788230000007, -0.133518],
              [130.68513530000007, -0.132189],
              [130.6681833, -0.136229],
              [130.66101130000004, -0.142084],
              [130.66203330000008, -0.146344],
              [130.66902230000005, -0.148214],
              [130.6781923000001, -0.141617],
              [130.68952930000012, -0.138881],
              [130.68788230000007, -0.133518]
            ]
          ],
          [
            [
              [130.641021, -0.114600099999961],
              [130.64450130000012, -0.122216],
              [130.65182530000004, -0.123046],
              [130.6541903000001, -0.114931],
              [130.64560030000007, -0.116463],
              [130.641021, -0.114600099999961]
            ]
          ],
          [
            [
              [130.25563130000012, -0.110889],
              [130.2443853000001, -0.111906],
              [130.24484330000007, -0.119785],
              [130.25067130000002, -0.124676],
              [130.25924730000008, -0.121128],
              [130.25563130000012, -0.110889]
            ]
          ],
          [
            [
              [130.1813823000001, -0.105677],
              [130.18338030000007, -0.09901],
              [130.17344730000002, -0.091931],
              [130.17681930000003, -0.10642],
              [130.1813823000001, -0.105677]
            ]
          ],
          [
            [
              [130.05510030000005, -0.070913],
              [130.05058330000008, -0.067459],
              [130.0426943000001, -0.072799],
              [130.01876830000003, -0.071957],
              [130.01226830000007, -0.075921],
              [130.04806530000008, -0.076189],
              [130.05297930000006, -0.079824],
              [130.05510030000005, -0.070913]
            ]
          ],
          [
            [
              [131.07850730000007, -0.050579],
              [131.07612630000006, -0.039915],
              [131.07125930000007, -0.038352],
              [131.06185930000004, -0.045465],
              [131.06442330000004, -0.050249],
              [131.07775930000003, -0.053347],
              [131.07850730000007, -0.050579]
            ]
          ],
          [
            [
              [130.5104983000001, -0.031905],
              [130.50190830000008, -0.031239],
              [130.50010730000008, -0.037996],
              [130.50572230000012, -0.040238],
              [130.5132913000001, -0.03905],
              [130.5104983000001, -0.031905]
            ]
          ],
          [
            [
              [130.9391333000001, -0.01941],
              [130.93476930000008, -0.021357],
              [130.94540430000006, -0.035501],
              [130.94654930000002, -0.029277],
              [130.95149230000004, -0.024517],
              [130.9391333000001, -0.01941]
            ]
          ],
          [
            [
              [130.641021, -0.114600099999961],
              [130.6429293000001, -0.107527],
              [130.63188230000003, -0.102827],
              [130.62242230000004, -0.106421],
              [130.60296630000005, -0.097789],
              [130.5998383000001, -0.091759],
              [130.60984830000007, -0.084692],
              [130.61085530000003, -0.079605],
              [130.62286430000006, -0.081835],
              [130.62548830000003, -0.08737],
              [130.63790930000005, -0.09443],
              [130.6392373000001, -0.087637],
              [130.6523443000001, -0.086926],
              [130.65768530000003, -0.084184],
              [130.6598673000001, -0.075951],
              [130.66914430000008, -0.07915],
              [130.69158930000003, -0.07633],
              [130.6961063000001, -0.081991],
              [130.6929483, -0.087392],
              [130.6828163, -0.090417],
              [130.68217530000004, -0.095835],
              [130.69773930000008, -0.097377],
              [130.70198130000006, -0.094282],
              [130.7067263, -0.098559],
              [130.70991630000003, -0.108472],
              [130.70950330000005, -0.117798],
              [130.71737730000007, -0.136429],
              [130.72526630000004, -0.142849],
              [130.7274943000001, -0.148095],
              [130.73498630000006, -0.15077],
              [130.7399603, -0.155969],
              [130.7452853000001, -0.154601],
              [130.75563130000012, -0.161138],
              [130.7626193000001, -0.162085],
              [130.77224730000012, -0.159287],
              [130.7778783000001, -0.170581599999934],
              [130.78321930000004, -0.175972],
              [130.77925130000006, -0.182297],
              [130.78566030000002, -0.185189],
              [130.78964330000008, -0.195165],
              [130.78785730000004, -0.207314],
              [130.78971930000012, -0.215328],
              [130.78433330000007, -0.225045],
              [130.78398230000005, -0.229884],
              [130.79191630000003, -0.236304],
              [130.7939613000001, -0.247945],
              [130.81532330000005, -0.248472],
              [130.8216863, -0.247049],
              [130.83512930000006, -0.252155],
              [130.84796230000006, -0.245113],
              [130.86131330000012, -0.239908],
              [130.87789930000008, -0.254474],
              [130.8814853, -0.267155],
              [130.88845930000002, -0.270744],
              [130.8944093, -0.278485],
              [130.89718630000004, -0.289954],
              [130.89471530000003, -0.297381],
              [130.90095530000008, -0.302237],
              [130.90185630000008, -0.3095],
              [130.91656530000012, -0.307695],
              [130.9212503000001, -0.31389],
              [130.92210430000011, -0.322637],
              [130.93222130000004, -0.329571],
              [130.93582230000004, -0.33886],
              [130.93483030000004, -0.345364],
              [130.94030830000008, -0.352237],
              [130.9554753000001, -0.3585],
              [130.96543930000007, -0.359564],
              [130.97094830000003, -0.357509],
              [130.98545830000012, -0.36948],
              [130.99440030000005, -0.363978],
              [130.9889223, -0.353586],
              [130.99317930000007, -0.345308],
              [131.00880430000007, -0.347139],
              [131.0205843000001, -0.357836],
              [131.01908930000002, -0.364856],
              [131.02894630000003, -0.362347],
              [131.02671830000008, -0.35474],
              [131.0331423, -0.350441],
              [131.04156530000012, -0.348792],
              [131.0420693000001, -0.354092],
              [131.0550693, -0.348489],
              [131.05996730000004, -0.335117],
              [131.0646673000001, -0.334347],
              [131.06909230000008, -0.327163],
              [131.0785373000001, -0.327069],
              [131.08226030000003, -0.32184],
              [131.10139530000004, -0.317925],
              [131.09782430000007, -0.328338],
              [131.11409030000004, -0.329364],
              [131.12358130000007, -0.334869],
              [131.1314853, -0.333908],
              [131.15290930000003, -0.335303],
              [131.1652683000001, -0.340807],
              [131.1764223, -0.342278],
              [131.19136130000004, -0.350622],
              [131.1989443000001, -0.364414],
              [131.20460530000003, -0.369441],
              [131.2214973, -0.37375],
              [131.23733530000004, -0.386011],
              [131.24907030000008, -0.385238],
              [131.25418130000003, -0.379031],
              [131.2490543, -0.362597],
              [131.2435153, -0.35538],
              [131.24295130000007, -0.34818],
              [131.26019330000008, -0.349504],
              [131.26785330000007, -0.352522],
              [131.27600130000008, -0.349724],
              [131.28386030000001, -0.342196],
              [131.28019730000005, -0.334987],
              [131.2909853000001, -0.324762],
              [131.2985993000001, -0.325944],
              [131.2999883000001, -0.319205],
              [131.3048563000001, -0.316625],
              [131.30510030000005, -0.307064],
              [131.31123430000002, -0.303163],
              [131.32171630000005, -0.306597],
              [131.32556230000012, -0.30125],
              [131.3335733, -0.301274],
              [131.33689930000003, -0.298116],
              [131.3366853000001, -0.28907],
              [131.32823230000008, -0.280923],
              [131.31015030000003, -0.271314],
              [131.2987213, -0.260155],
              [131.2929693000001, -0.247195],
              [131.29058930000008, -0.225052],
              [131.2996373000001, -0.223529],
              [131.30383330000006, -0.214844],
              [131.31071530000008, -0.213132],
              [131.3121033000001, -0.204384],
              [131.30255230000012, -0.19422],
              [131.30743430000007, -0.189569],
              [131.2950293, -0.174033],
              [131.29258730000004, -0.165096],
              [131.28318830000012, -0.166393],
              [131.27529930000003, -0.158246],
              [131.25956830000007, -0.15475],
              [131.25517330000002, -0.142929],
              [131.2484283000001, -0.140317],
              [131.23477230000003, -0.151041],
              [131.2295693000001, -0.147859],
              [131.22311430000002, -0.138743],
              [131.20832830000006, -0.142782],
              [131.2003333, -0.13487],
              [131.19043030000012, -0.134494],
              [131.1881413000001, -0.128732],
              [131.1889043000001, -0.11681],
              [131.17770430000007, -0.108365],
              [131.17564430000004, -0.102767],
              [131.16470430000004, -0.10429],
              [131.15356530000008, -0.095619],
              [131.15094030000012, -0.08576],
              [131.13845830000002, -0.083195],
              [131.13458330000003, -0.079895],
              [131.12443630000007, -0.077737],
              [131.11900330000003, -0.079846],
              [131.10467530000005, -0.080557],
              [131.09472730000005, -0.074707],
              [131.0908363000001, -0.075731],
              [131.08386330000008, -0.068225],
              [131.07618730000002, -0.068319],
              [131.0693063000001, -0.072844],
              [131.05821330000003, -0.068958],
              [131.0523683, -0.070606],
              [131.04321330000005, -0.065625],
              [131.0330203000001, -0.066172],
              [131.03131130000008, -0.060926],
              [131.04448030000003, -0.060387],
              [131.05032430000006, -0.056143],
              [131.05223130000002, -0.049982],
              [131.0512093000001, -0.040476],
              [131.0449993000001, -0.026122],
              [131.04042130000005, -0.026911],
              [131.0357523, -0.036863],
              [131.02768030000004, -0.040755],
              [131.01921130000005, -0.037791],
              [131.01522750000004, -0.04405],
              [131.0031593000001, -0.049149],
              [130.98895330000005, -0.047779],
              [130.97792130000005, -0.040718],
              [130.97413630000005, -0.044862],
              [130.98082030000012, -0.052196],
              [130.9798283, -0.061929],
              [130.9674533000001, -0.061771],
              [130.96220430000005, -0.056001],
              [130.9635323000001, -0.050935],
              [130.95568930000002, -0.045556],
              [130.95243930000004, -0.040066],
              [130.94641130000002, -0.045179],
              [130.9384003, -0.04406],
              [130.92163130000006, -0.035138],
              [130.9135143000001, -0.033729],
              [130.9096843000001, -0.029633],
              [130.90344330000005, -0.030133],
              [130.87448130000007, -0.017198],
              [130.84036330000004, -0.013755],
              [130.8305673000001, -0.011542],
              [130.8229983000001, -0.01699],
              [130.81866530000002, -0.010867],
              [130.80961630000002, -0.008021],
              [130.79730230000007, -0.011671],
              [130.7938243000001, -0.008144],
              [130.78631630000007, -0.008816],
              [130.77186630000006, -0.015397],
              [130.7695923000001, -0.011418],
              [130.75033630000007, -0.018616],
              [130.7494663000001, -0.025871],
              [130.7433933000001, -0.024181],
              [130.73611530000005, -0.026527],
              [130.73701530000005, -0.036323],
              [130.7512673000001, -0.039186],
              [130.7646793, -0.037209],
              [130.77269030000002, -0.038445],
              [130.78109830000005, -0.044458],
              [130.7775273000001, -0.051072],
              [130.76791430000003, -0.049546],
              [130.7637333, -0.054604],
              [130.75422730000003, -0.054056],
              [130.7472993, -0.050982],
              [130.7454073, -0.055985],
              [130.73571830000003, -0.048929],
              [130.72724930000004, -0.055874],
              [130.72233630000005, -0.051018],
              [130.7142033, -0.052902],
              [130.7108313000001, -0.04782],
              [130.7249303000001, -0.040778],
              [130.7223063, -0.031326],
              [130.70954930000005, -0.028345],
              [130.7021493000001, -0.037827],
              [130.6956183000001, -0.035451],
              [130.6882783000001, -0.043377],
              [130.6827853000001, -0.039444],
              [130.66748130000008, -0.045212],
              [130.64852930000006, -0.050229],
              [130.6506813000001, -0.056796],
              [130.64541730000008, -0.060696],
              [130.64227330000006, -0.053949],
              [130.6354523000001, -0.056295],
              [130.62927330000002, -0.052995],
              [130.62519930000008, -0.06225],
              [130.61746330000005, -0.061366],
              [130.61706630000003, -0.056229],
              [130.60981830000003, -0.050396],
              [130.6033943000001, -0.049169],
              [130.5937203000001, -0.052256],
              [130.59361330000002, -0.046666],
              [130.58618230000002, -0.041685],
              [130.57472330000007, -0.047125],
              [130.57882730000006, -0.056178],
              [130.5753333, -0.058423],
              [130.56463630000007, -0.044623],
              [130.5687713000001, -0.039682],
              [130.5568703, -0.034232],
              [130.55897530000004, -0.043051],
              [130.55754130000003, -0.048461],
              [130.56366030000004, -0.052855],
              [130.56375230000003, -0.059766],
              [130.5679173000001, -0.068882],
              [130.56076130000008, -0.072657],
              [130.5556183000001, -0.064581],
              [130.53541630000007, -0.055243],
              [130.5353553000001, -0.057957],
              [130.54753130000006, -0.069278],
              [130.5401313000001, -0.074382],
              [130.52497930000004, -0.061724],
              [130.52642930000002, -0.054414],
              [130.51754830000004, -0.055195],
              [130.5137333, -0.048847],
              [130.5081183000001, -0.045773],
              [130.4981083, -0.04517],
              [130.50010730000008, -0.051321],
              [130.5124363000001, -0.063935],
              [130.50010730000008, -0.062465],
              [130.48954830000002, -0.065219],
              [130.49058630000002, -0.071369],
              [130.48779330000002, -0.078091],
              [130.48265130000004, -0.077966],
              [130.47219930000006, -0.070941],
              [130.4602053000001, -0.073912],
              [130.46472230000006, -0.082658],
              [130.4608313000001, -0.085699],
              [130.4499823000001, -0.083043],
              [130.44346630000007, -0.086709],
              [130.4358833000001, -0.096644],
              [130.4318853000001, -0.089282],
              [130.4223333000001, -0.077028],
              [130.41433830000005, -0.086438],
              [130.42343230000006, -0.087087],
              [130.42628530000002, -0.092142],
              [130.42205830000012, -0.103288],
              [130.41618430000005, -0.100866],
              [130.40995830000008, -0.103102],
              [130.40094030000012, -0.114069],
              [130.3886573000001, -0.111178],
              [130.38728430000003, -0.107099],
              [130.3937383000001, -0.098485],
              [130.39480630000003, -0.088833],
              [130.3887033000001, -0.083761],
              [130.39241130000005, -0.075781],
              [130.3841863, -0.074445],
              [130.3791053000001, -0.07944],
              [130.36947650000002, -0.083298],
              [130.35197530000005, -0.080725],
              [130.3510593000001, -0.090033],
              [130.3591163000001, -0.097692],
              [130.3691103000001, -0.099887],
              [130.36575330000005, -0.106953],
              [130.37089630000003, -0.116268],
              [130.36781330000008, -0.120918],
              [130.35975730000007, -0.123608],
              [130.34936530000004, -0.134285],
              [130.34445230000006, -0.128588],
              [130.3443453000001, -0.118475],
              [130.33679230000007, -0.110364],
              [130.34513930000003, -0.103649],
              [130.34066830000006, -0.09456],
              [130.3435823000001, -0.087783],
              [130.33558730000004, -0.078351],
              [130.33261130000005, -0.087082],
              [130.3352973000001, -0.093575],
              [130.32936130000007, -0.096843],
              [130.33512930000006, -0.102314],
              [130.32954430000007, -0.108222],
              [130.32095430000004, -0.104992],
              [130.3156133000001, -0.109358],
              [130.30828930000007, -0.108374],
              [130.30468830000007, -0.102451],
              [130.29579230000002, -0.103358],
              [130.28744530000006, -0.094325],
              [130.28602630000012, -0.105587],
              [130.28134230000012, -0.110464],
              [130.2720343000001, -0.112575],
              [130.26866230000007, -0.118664],
              [130.27505530000008, -0.12114],
              [130.27929730000005, -0.126729],
              [130.2793583, -0.134716],
              [130.27055430000007, -0.13206],
              [130.2693643, -0.142002],
              [130.2721103, -0.149074],
              [130.2821053, -0.150925],
              [130.28443930000003, -0.154262],
              [130.29374730000006, -0.151337],
              [130.29347330000007, -0.161568],
              [130.2881013000001, -0.172199],
              [130.29284730000006, -0.177715],
              [130.31604030000005, -0.180565],
              [130.33346630000005, -0.19145],
              [130.34112630000004, -0.193411],
              [130.3437963, -0.189104],
              [130.33792130000006, -0.182204],
              [130.33882230000006, -0.170824],
              [130.3353433000001, -0.161916],
              [130.32031330000007, -0.153273],
              [130.3207093000001, -0.142699],
              [130.32762230000003, -0.142072],
              [130.33030730000007, -0.145698],
              [130.33944730000007, -0.148644],
              [130.34944230000008, -0.159287],
              [130.35485930000004, -0.1528],
              [130.35138030000007, -0.144922],
              [130.3532563000001, -0.138716],
              [130.36193930000002, -0.142232],
              [130.36485330000005, -0.15494],
              [130.3647463000001, -0.162123],
              [130.37234530000012, -0.172188],
              [130.37846430000002, -0.171742],
              [130.37336730000004, -0.162364],
              [130.3802803000001, -0.16266],
              [130.38240130000008, -0.166974],
              [130.38983230000008, -0.169568],
              [130.40078830000004, -0.160274],
              [130.40872230000002, -0.162813],
              [130.4071203000001, -0.153732],
              [130.40210030000003, -0.150504],
              [130.4002693000001, -0.141649],
              [130.39250230000005, -0.140666],
              [130.38884030000008, -0.129342],
              [130.39531030000012, -0.127670699999953],
              [130.39994830000012, -0.130236],
              [130.41336130000002, -0.132848799999977],
              [130.41659630000004, -0.13656],
              [130.40905830000008, -0.151551],
              [130.41507030000002, -0.160296],
              [130.41398730000003, -0.167361],
              [130.40907330000005, -0.16919],
              [130.3684703, -0.177816],
              [130.36653230000002, -0.183732],
              [130.38172930000007, -0.188069],
              [130.3902283000001, -0.182439],
              [130.40394630000003, -0.181539],
              [130.4172523000001, -0.17817],
              [130.41975430000002, -0.182999],
              [130.42964230000007, -0.187265],
              [130.43095430000005, -0.197269],
              [130.42428630000006, -0.203296],
              [130.4163363, -0.203968],
              [130.4131473000001, -0.208736],
              [130.40560930000004, -0.208214],
              [130.39737030000003, -0.215612],
              [130.40002530000004, -0.227574],
              [130.39259430000004, -0.225496],
              [130.3901373000001, -0.231349],
              [130.37489330000005, -0.234892],
              [130.37170430000003, -0.239895],
              [130.36102330000006, -0.241373],
              [130.35955830000012, -0.233114],
              [130.37120130000005, -0.237003],
              [130.37197930000002, -0.231329],
              [130.36563130000002, -0.224764],
              [130.36312930000008, -0.213835],
              [130.36363230000006, -0.207125],
              [130.35678130000008, -0.204124],
              [130.34301830000004, -0.20497],
              [130.33233730000006, -0.211505],
              [130.32919330000004, -0.221619],
              [130.3254243, -0.219603],
              [130.31491130000006, -0.220683],
              [130.30641230000003, -0.218542],
              [130.3029183000001, -0.221755],
              [130.29361030000007, -0.218529],
              [130.28590430000008, -0.221155],
              [130.27385030000005, -0.218617],
              [130.2654573000001, -0.220556],
              [130.25488330000007, -0.213069],
              [130.2418063, -0.207538],
              [130.21507330000009, -0.205376],
              [130.20851230000005, -0.211403],
              [130.2109683000001, -0.218593],
              [130.2265023000001, -0.224649],
              [130.2419893000001, -0.228913],
              [130.2545023, -0.229913],
              [130.2616273000001, -0.228481],
              [130.27957230000004, -0.23023],
              [130.28151030000004, -0.236724],
              [130.30339130000004, -0.248883],
              [130.3097993, -0.258315],
              [130.30894530000012, -0.26538],
              [130.30432230000008, -0.276526],
              [130.31294330000003, -0.273954],
              [130.3134003, -0.266826],
              [130.32022130000007, -0.266099],
              [130.31951930000002, -0.27511],
              [130.32995630000005, -0.26455],
              [130.33310030000007, -0.258687],
              [130.34343030000002, -0.261062],
              [130.35138030000007, -0.259802],
              [130.35315030000004, -0.26958],
              [130.3651433000001, -0.269594],
              [130.36886630000004, -0.28213],
              [130.3777163000001, -0.282661],
              [130.38262930000008, -0.279276],
              [130.37657230000002, -0.273398],
              [130.36914130000002, -0.273799],
              [130.36988830000007, -0.264771],
              [130.38404930000002, -0.266693],
              [130.38244730000008, -0.271515],
              [130.39096130000007, -0.273764],
              [130.40124530000003, -0.271074],
              [130.4107213000001, -0.263445],
              [130.42259230000002, -0.263803],
              [130.43443330000002, -0.283302],
              [130.43118330000004, -0.290431],
              [130.4398043000001, -0.292047],
              [130.4453893000001, -0.277174],
              [130.45640630000003, -0.263276],
              [130.4658823000001, -0.261853],
              [130.47976730000005, -0.263078],
              [130.4894723000001, -0.259701],
              [130.50947630000007, -0.248079],
              [130.51754830000004, -0.251269],
              [130.5233773000001, -0.258402499999931],
              [130.5192873000001, -0.273449],
              [130.5119323, -0.285012],
              [130.51156630000003, -0.296183],
              [130.50938430000008, -0.301069],
              [130.51634230000002, -0.315322],
              [130.53347830000007, -0.311454],
              [130.54065030000004, -0.332555],
              [130.53884930000004, -0.347183],
              [130.54190130000006, -0.366267],
              [130.54205330000002, -0.382903],
              [130.5484623000001, -0.386563],
              [130.55117830000006, -0.401425],
              [130.54415930000005, -0.423291],
              [130.53595030000008, -0.431507],
              [130.54843230000006, -0.438857],
              [130.55777030000002, -0.433933],
              [130.55928130000007, -0.418266],
              [130.5661623000001, -0.411027],
              [130.5716863, -0.40074],
              [130.5851143000001, -0.386832],
              [130.5811013000001, -0.382329],
              [130.5831303000001, -0.369085],
              [130.5752873, -0.367333],
              [130.5702523000001, -0.372509],
              [130.5656133000001, -0.371805],
              [130.55461230000003, -0.376322],
              [130.55703830000004, -0.36354],
              [130.56712430000005, -0.359077],
              [130.56857330000003, -0.347561],
              [130.57643230000008, -0.336469],
              [130.567979, -0.328149],
              [130.57577530000003, -0.32379519999995],
              [130.58339030000002, -0.324508],
              [130.60017430000005, -0.316714],
              [130.60682730000008, -0.310868],
              [130.61203030000001, -0.315489],
              [130.61805830000003, -0.311823],
              [130.62652630000002, -0.312598],
              [130.63264530000004, -0.31682],
              [130.63987830000008, -0.310395],
              [130.6568913000001, -0.307793],
              [130.6602183000001, -0.304111],
              [130.66789330000006, -0.302923],
              [130.6694953000001, -0.297974],
              [130.67952030000004, -0.296162],
              [130.6810613, -0.303081],
              [130.6908423000001, -0.304489],
              [130.69564830000002, -0.309689],
              [130.6921393, -0.315615],
              [130.69659430000002, -0.330867],
              [130.69360430000006, -0.335551],
              [130.68306030000008, -0.338974],
              [130.67509530000007, -0.338778],
              [130.66919030000008, -0.342217],
              [130.67680430000007, -0.346963],
              [130.68109130000005, -0.345533],
              [130.6849833000001, -0.353555],
              [130.6905223000001, -0.357542],
              [130.70060730000012, -0.386023],
              [130.6962433000001, -0.396382],
              [130.70332430000008, -0.400839],
              [130.70330830000012, -0.411323],
              [130.70735230000003, -0.421987],
              [130.71649230000003, -0.43832],
              [130.72357230000011, -0.446287],
              [130.73634430000004, -0.446382],
              [130.74778830000002, -0.449761],
              [130.76561030000005, -0.449809],
              [130.7663583000001, -0.44354],
              [130.78993230000003, -0.451321],
              [130.7983703000001, -0.440409],
              [130.80941830000006, -0.446891],
              [130.8196263000001, -0.438918],
              [130.83221530000003, -0.438199],
              [130.84712230000002, -0.428913],
              [130.8639683, -0.428156],
              [130.8731233000001, -0.430605],
              [130.88031030000002, -0.415071],
              [130.90023830000007, -0.409953],
              [130.9051823000001, -0.403456],
              [130.9135443, -0.404403],
              [130.9236913000001, -0.393835],
              [130.93113730000005, -0.389246],
              [130.92387430000008, -0.385078],
              [130.92211930000008, -0.375219],
              [130.9161693000001, -0.371169],
              [130.92321830000003, -0.3663],
              [130.9347233000001, -0.374165],
              [130.9409793000001, -0.365199],
              [130.93972830000007, -0.359437],
              [130.93028330000004, -0.355777],
              [130.92680430000007, -0.347818],
              [130.92636130000005, -0.338601],
              [130.91653530000008, -0.327623],
              [130.91546730000005, -0.314317],
              [130.90246630000001, -0.314448],
              [130.8936013000001, -0.324219],
              [130.88415580000003, -0.325167299999976],
              [130.87889560000008, -0.320719799999949],
              [130.86111530000005, -0.321418],
              [130.84721430000002, -0.318266],
              [130.84315530000003, -0.312831],
              [130.8425453000001, -0.302239],
              [130.82136530000002, -0.296122],
              [130.80635130000007, -0.302296],
              [130.80668730000002, -0.306792],
              [130.8133243000001, -0.308888],
              [130.81463630000007, -0.313438],
              [130.8106693000001, -0.319247],
              [130.80094930000007, -0.312538],
              [130.7909853000001, -0.312333],
              [130.78361530000006, -0.302178],
              [130.77502530000004, -0.298113399999977],
              [130.76551830000005, -0.300908],
              [130.76016330000004, -0.29374],
              [130.74864230000003, -0.297115],
              [130.73930430000007, -0.295879],
              [130.73191830000007, -0.300178],
              [130.72580030000006, -0.293767],
              [130.73869430000002, -0.283894],
              [130.7266393000001, -0.27061],
              [130.7202003000001, -0.252566],
              [130.71608030000004, -0.249962],
              [130.7167363000001, -0.239885],
              [130.70437730000003, -0.229134],
              [130.6936343000001, -0.213778],
              [130.6911933, -0.202598],
              [130.6765143, -0.188555],
              [130.67028830000004, -0.174826],
              [130.65565530000003, -0.161941],
              [130.6525123, -0.155077],
              [130.64730930000007, -0.151894],
              [130.6257333000001, -0.148021],
              [130.6266793000001, -0.13305],
              [130.62004130000003, -0.126639],
              [130.61259530000007, -0.124199],
              [130.6228033000001, -0.116796],
              [130.641021, -0.114600099999961]
            ]
          ],
          [
            [
              [130.11012330000005, -0.004146],
              [130.0962833000001, 0.008309],
              [130.0867313000001, 0.01022],
              [130.08207730000004, -0.002735],
              [130.09436130000006, -0.01298],
              [130.09504730000003, -0.021193],
              [130.1033943000001, -0.027359],
              [130.10504230000004, -0.0319],
              [130.10243230000003, -0.039083],
              [130.0901493, -0.040272],
              [130.09381130000008, -0.052229],
              [130.08958430000007, -0.068486],
              [130.0978093000001, -0.072807],
              [130.1041573000001, -0.071611],
              [130.10771230000012, -0.095182],
              [130.11354130000007, -0.097831],
              [130.1181643000001, -0.091461],
              [130.12335230000008, -0.101292],
              [130.13072230000012, -0.101018],
              [130.14219730000002, -0.091145],
              [130.1465303000001, -0.075513],
              [130.15550330000008, -0.07105],
              [130.14646930000004, -0.060687],
              [130.14772030000006, -0.046666],
              [130.13601730000005, -0.048551],
              [130.12509230000012, -0.037555],
              [130.12344430000007, -0.043933],
              [130.1087043000001, -0.037308],
              [130.12132330000009, -0.027326],
              [130.13291930000003, -0.030841],
              [130.13610930000004, -0.02022],
              [130.1219483000001, -0.019447],
              [130.11418230000004, -0.016456],
              [130.10566730000005, -0.010353],
              [130.11012330000005, -0.004146]
            ]
          ],
          [
            [
              [130.92973330000007, -0.008711],
              [130.94976830000007, -0.000219],
              [130.96125830000005, 0.007557],
              [130.95895430000007, 0.017184],
              [130.9472353000001, 0.018057],
              [130.93991130000006, 0.020993],
              [130.93319730000007, 0.020639],
              [130.92536930000006, 0.016633],
              [130.92060930000002, 0.009332],
              [130.92059330000006, -0.000169],
              [130.91740430000004, -0.009299],
              [130.92460730000005, -0.011748],
              [130.92973330000007, -0.008711]
            ]
          ],
          [
            [
              [130.8759463, 0.028167],
              [130.8675243, 0.032567],
              [130.86335830000007, 0.024656],
              [130.8671273000001, 0.015514],
              [130.8823853, 0.010128],
              [130.88934430000006, 0.010847],
              [130.8968053000001, 0.020579],
              [130.89682030000006, 0.026426],
              [130.89096130000007, 0.03143],
              [130.8759463, 0.028167]
            ]
          ],
          [
            [
              [131.07335310000008, 0.37749580000002],
              [131.0643351000001, 0.389814800000067],
              [131.04872610000007, 0.399707800000044],
              [131.03845610000008, 0.394852800000024],
              [131.03148310000006, 0.382926800000064],
              [131.03598410000006, 0.376463800000067],
              [131.05162510000002, 0.376680800000031],
              [131.05539310000006, 0.373750800000039],
              [131.04611610000006, 0.364995800000031],
              [131.05061710000007, 0.358288800000025],
              [131.0647781, 0.352539800000045],
              [131.07796110000004, 0.354465800000071],
              [131.08017410000002, 0.363232800000048],
              [131.07335310000008, 0.37749580000002]
            ]
          ],
          [
            [
              [131.14215480000007, 0.386844900000028],
              [131.14740380000012, 0.391829900000062],
              [131.14364980000005, 0.406940900000052],
              [131.1353488000001, 0.41365490000004],
              [131.13154980000002, 0.399896900000044],
              [131.13666180000007, 0.387463900000057],
              [131.14215480000007, 0.386844900000028]
            ]
          ],
          [
            [
              [131.1806421000001, 0.482026700000063],
              [131.18345010000007, 0.485066700000061],
              [131.17858210000009, 0.494575700000041],
              [131.16698610000003, 0.503852700000039],
              [131.15992110000002, 0.515924700000028],
              [131.1475921000001, 0.520208700000069],
              [131.14501310000003, 0.50985970000005],
              [131.1490421000001, 0.501447700000028],
              [131.1569611000001, 0.491445700000043],
              [131.17171610000003, 0.482893700000034],
              [131.1806421000001, 0.482026700000063]
            ]
          ],
          [
            [
              [131.2225251000001, 0.556229200000075],
              [131.22205210000004, 0.568167200000062],
              [131.2013151000001, 0.578191200000049],
              [131.1870331, 0.582235200000071],
              [131.1792051000001, 0.576279200000045],
              [131.18237910000005, 0.569209200000046],
              [131.19139710000002, 0.564808200000073],
              [131.2065341000001, 0.561006200000065],
              [131.2166661000001, 0.554777200000046],
              [131.2225251000001, 0.556229200000075]
            ]
          ]
        ]
      },
      "properties": {
        "nama": "Provinsi Papua Barat",
        "nid": 32,
        "type": 1
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [119.37115410000001, -3.467318199999966],
              [119.36265320000007, -3.471223699999939],
              [119.36381830000005, -3.483879099999967],
              [119.37189440000009, -3.484121099999925],
              [119.3696718000001, -3.476581],
              [119.37115410000001, -3.467318199999966]
            ]
          ],
          [
            [
              [119.4185331000001, -3.469146599999931],
              [119.41473960000008, -3.467203099999949],
              [119.40814680000005, -3.47428229999997],
              [119.41669390000004, -3.475683899999979],
              [119.4185331000001, -3.469146599999931]
            ]
          ],
          [
            [
              [118.89061430000004, -2.651508299999932],
              [118.89453120000007, -2.642636],
              [118.89692760000003, -2.629565099999979],
              [118.89250070000003, -2.613534799999968],
              [118.87941150000006, -2.622508599999946],
              [118.879119, -2.641147599999954],
              [118.88537380000002, -2.651516299999969],
              [118.89061430000004, -2.651508299999932]
            ]
          ],
          [
            [
              [117.3806545000001, -2.272107799999958],
              [117.37976580000009, -2.266567799999962],
              [117.37434960000007, -2.264814799999954],
              [117.37077090000002, -2.269371099999944],
              [117.37346510000009, -2.275649299999941],
              [117.3806545000001, -2.272107799999958]
            ]
          ],
          [
            [
              [117.31767090000005, -2.268661],
              [117.320356, -2.25697219999995],
              [117.31498020000004, -2.255101599999932],
              [117.30683660000011, -2.265086099999962],
              [117.31229610000003, -2.27140739999993],
              [117.31767090000005, -2.268661]
            ]
          ],
          [
            [
              [117.394196, -2.101452799999947],
              [117.388773, -2.096925099999964],
              [117.38161060000004, -2.101456],
              [117.38516080000011, -2.106843699999956],
              [117.39064850000011, -2.106875399999979],
              [117.394196, -2.101452799999947]
            ]
          ],
          [
            [
              [117.62665140000001, -2.098170099999948],
              [117.62845920000007, -2.089079799999979],
              [117.62119220000011, -2.091805099999931],
              [117.62665140000001, -2.098170099999948]
            ]
          ],
          [
            [
              [117.08118900000011, -2.050868799999932],
              [117.069472, -2.053342],
              [117.0710385000001, -2.07023479999998],
              [117.07951530000003, -2.077187199999969],
              [117.08691170000009, -2.073167199999943],
              [117.08755880000001, -2.058881499999927],
              [117.08118900000011, -2.050868799999932]
            ]
          ],
          [
            [
              [117.27173230000005, -2.057494599999927],
              [117.2699196000001, -2.050306099999943],
              [117.265424, -2.047539399999948],
              [117.26089620000005, -2.052973899999927],
              [117.26361470000006, -2.061107199999981],
              [117.27173230000005, -2.057494599999927]
            ]
          ],
          [
            [
              [117.3525393000001, -2.064010199999927],
              [117.3561482, -2.057749499999943],
              [117.35345610000002, -2.04144359999998],
              [117.3463124000001, -2.041445199999941],
              [117.34266510000009, -2.047744499999965],
              [117.343512, -2.056824899999981],
              [117.34892920000004, -2.064899699999955],
              [117.3525393000001, -2.064010199999927]
            ]
          ],
          [
            [
              [117.3075348000001, -2.04449029999995],
              [117.3093517000001, -2.037226399999952],
              [117.304859, -2.034537],
              [117.29854890000001, -2.036340699999926],
              [117.2976675000001, -2.041721199999927],
              [117.30125160000011, -2.047181699999953],
              [117.3075348000001, -2.04449029999995]
            ]
          ],
          [
            [
              [117.33884320000004, -2.005010699999957],
              [117.33499620000009, -1.995164399999965],
              [117.32590830000004, -1.996221299999945],
              [117.33080390000009, -2.00641909999996],
              [117.33884320000004, -2.005010699999957]
            ]
          ],
          [
            [
              [117.26324350000004, -1.993720899999971],
              [117.25998530000004, -1.985578699999962],
              [117.24710560000005, -1.986772299999927],
              [117.24518220000004, -1.994418799999949],
              [117.24784740000007, -1.997596],
              [117.26087570000004, -1.999182799999971],
              [117.26324350000004, -1.993720899999971]
            ]
          ],
          [
            [
              [117.14536570000007, -1.977113899999949],
              [117.14625740000008, -1.969854899999973],
              [117.13817580000011, -1.968060099999946],
              [117.13628990000007, -1.975242299999934],
              [117.13968100000011, -1.981321],
              [117.14536570000007, -1.977113899999949]
            ]
          ],
          [
            [
              [117.30481440000005, -1.94626489999996],
              [117.30838240000003, -1.940857899999969],
              [117.3030314, -1.934579],
              [117.29942010000002, -1.939941799999929],
              [117.30481440000005, -1.94626489999996]
            ]
          ],
          [
            [
              [119.327323, -1.935132499999952],
              [119.32430980000004, -1.945167599999934],
              [119.33427640000002, -1.942930099999955],
              [119.327323, -1.935132499999952]
            ]
          ],
          [
            [
              [117.25324950000004, -1.906613399999969],
              [117.25505570000007, -1.901189599999952],
              [117.25147090000007, -1.890312399999971],
              [117.24605030000009, -1.892100399999947],
              [117.24335610000003, -1.898387099999979],
              [117.248717, -1.907446099999959],
              [117.25324950000004, -1.906613399999969]
            ]
          ],
          [
            [
              [119.5658843000001, -0.847382799999934],
              [119.55977060000009, -0.848822799999937],
              [119.55552240000009, -0.857575899999972],
              [119.54784170000005, -0.864263899999969],
              [119.54363250000006, -0.86460569999997],
              [119.54193020000002, -0.873448199999928],
              [119.53647150000006, -0.878393399999936],
              [119.53595670000004, -0.885545499999978],
              [119.53026970000008, -0.895144599999981],
              [119.52660850000007, -0.896117199999935],
              [119.52263370000003, -0.912085099999956],
              [119.52314540000009, -0.921853399999975],
              [119.52159170000004, -0.93419229999995],
              [119.51703930000008, -0.944860699999936],
              [119.5086361000001, -0.952003899999966],
              [119.50549750000005, -0.957886799999926],
              [119.50387980000005, -0.972208599999931],
              [119.49801880000007, -0.984977199999946],
              [119.48986560000003, -0.993245],
              [119.48088450000012, -0.998463499999957],
              [119.47379020000005, -1.020605],
              [119.47147550000011, -1.036572499999977],
              [119.46885390000011, -1.04253239999997],
              [119.46730150000008, -1.059938299999942],
              [119.46055020000006, -1.073751099999924],
              [119.45432330000006, -1.080482],
              [119.44534940000005, -1.096875699999941],
              [119.439988, -1.103376799999978],
              [119.4336393000001, -1.106448799999953],
              [119.42213880000008, -1.107315299999925],
              [119.42234440000004, -1.113143799999932],
              [119.40980280000008, -1.127746199999933],
              [119.40230850000012, -1.126936],
              [119.39632530000006, -1.137240399999939],
              [119.39107520000005, -1.140543199999968],
              [119.38544360000003, -1.139084599999933],
              [119.37911620000011, -1.159596799999974],
              [119.3752373000001, -1.15875],
              [119.36850130000005, -1.165030899999977],
              [119.36780130000011, -1.170399799999927],
              [119.35906620000003, -1.173887899999954],
              [119.35130020000008, -1.17236459999998],
              [119.3417581000001, -1.166351599999928],
              [119.3393850000001, -1.168931099999952],
              [119.33797630000004, -1.185040299999969],
              [119.33013480000011, -1.204043699999943],
              [119.32579370000008, -1.219123499999966],
              [119.32262020000007, -1.219665599999928],
              [119.31964340000002, -1.233349299999929],
              [119.31424780000009, -1.244353899999965],
              [119.31154820000006, -1.256574899999976],
              [119.3028035000001, -1.273500199999944],
              [119.31715330000009, -1.295647699999961],
              [119.32131360000005, -1.297827199999972],
              [119.32587910000007, -1.31486979999994],
              [119.33181560000003, -1.342812199999969],
              [119.3324242000001, -1.349509099999977],
              [119.32495570000003, -1.35967],
              [119.29709450000007, -1.381691599999954],
              [119.2905058, -1.392310099999975],
              [119.28775170000006, -1.410913099999959],
              [119.2926768000001, -1.42070549999994],
              [119.2912215, -1.435975199999973],
              [119.28809030000002, -1.450025],
              [119.30049610000003, -1.494798099999969],
              [119.30645880000009, -1.510223099999962],
              [119.31066190000001, -1.53435479999996],
              [119.319453, -1.551482699999951],
              [119.32638170000007, -1.572964899999931],
              [119.32404870000005, -1.596361499999944],
              [119.31595260000006, -1.608043],
              [119.30271010000001, -1.610555799999929],
              [119.3025530000001, -1.623786399999972],
              [119.30909180000003, -1.625608899999975],
              [119.3099324000001, -1.630059199999948],
              [119.30416280000009, -1.634789299999966],
              [119.30599040000004, -1.642750099999944],
              [119.2928217000001, -1.645535099999961],
              [119.2945525, -1.654782099999977],
              [119.28651830000001, -1.656349399999954],
              [119.28416730000004, -1.663425],
              [119.28727860000004, -1.676280899999938],
              [119.2866467, -1.682315799999969],
              [119.28193060000001, -1.684349199999929],
              [119.28184020000003, -1.691125399999976],
              [119.29543880000006, -1.698355099999958],
              [119.30094710000003, -1.70680729999998],
              [119.30241870000009, -1.721717399999932],
              [119.3067678000001, -1.741121499999963],
              [119.31249260000004, -1.757635799999946],
              [119.3154479000001, -1.77244479999996],
              [119.31118030000005, -1.796264899999926],
              [119.3220583000001, -1.802038099999947],
              [119.33106, -1.814805099999944],
              [119.33754350000004, -1.819429],
              [119.34530230000007, -1.814464499999929],
              [119.35208320000004, -1.817585299999962],
              [119.3591272000001, -1.83102759999997],
              [119.35898250000002, -1.836652],
              [119.36419950000004, -1.856443099999979],
              [119.35121650000008, -1.870737099999928],
              [119.35065110000005, -1.883813699999962],
              [119.3534757000001, -1.897014799999965],
              [119.34485160000008, -1.908562699999948],
              [119.33550240000011, -1.91398],
              [119.33871120000003, -1.919198899999969],
              [119.33432330000005, -1.926247899999964],
              [119.34052830000007, -1.930538499999955],
              [119.33089210000003, -1.963654499999961],
              [119.3233636000001, -1.964050499999928],
              [119.31838610000011, -1.953430499999968],
              [119.30468790000009, -1.959869599999934],
              [119.2965266000001, -1.959811299999956],
              [119.2938153, -1.952516899999978],
              [119.28570020000006, -1.954338399999926],
              [119.27965430000006, -1.959535799999969],
              [119.26216880000004, -1.970619599999964],
              [119.25321840000004, -1.972313399999962],
              [119.241595, -1.980776399999968],
              [119.22721660000002, -1.986713699999939],
              [119.22286950000012, -1.992708099999959],
              [119.2127081000001, -1.997774899999968],
              [119.21370940000008, -2.001947599999937],
              [119.22328420000008, -2.013115],
              [119.20980630000008, -2.028195],
              [119.20992090000004, -2.036587899999972],
              [119.205167, -2.05400579999997],
              [119.2044701000001, -2.063407699999971],
              [119.19719390000012, -2.077927399999965],
              [119.19561610000005, -2.089985399999932],
              [119.200434, -2.106871699999942],
              [119.1980565, -2.111973099999943],
              [119.1896183, -2.120000699999935],
              [119.1917611, -2.129220399999951],
              [119.18587230000003, -2.136472399999946],
              [119.18874, -2.148232399999927],
              [119.16585380000004, -2.161099],
              [119.15968140000007, -2.170247099999926],
              [119.15973940000003, -2.177052899999978],
              [119.15463360000001, -2.188533],
              [119.14792520000003, -2.194570699999929],
              [119.1453752000001, -2.200468399999977],
              [119.1519929000001, -2.229996099999937],
              [119.152214, -2.23938469999996],
              [119.13754930000005, -2.261741799999925],
              [119.13024180000002, -2.276806199999953],
              [119.12860220000005, -2.295859699999937],
              [119.13366280000002, -2.307813299999964],
              [119.1226415000001, -2.314146299999948],
              [119.12312610000004, -2.330277399999943],
              [119.13169430000005, -2.348434599999962],
              [119.134706, -2.362920899999949],
              [119.1389, -2.373019],
              [119.14210790000004, -2.390538599999957],
              [119.13870830000008, -2.414778899999931],
              [119.13517050000007, -2.420503099999962],
              [119.13677730000006, -2.441932599999973],
              [119.13612460000002, -2.449137299999961],
              [119.12919970000007, -2.463838599999974],
              [119.13458890000004, -2.464317199999925],
              [119.14323, -2.458871699999975],
              [119.14637930000004, -2.462681799999928],
              [119.14039460000004, -2.473994199999936],
              [119.1276822000001, -2.475595599999963],
              [119.12197530000003, -2.481340099999954],
              [119.10476520000009, -2.485130499999968],
              [119.10210840000002, -2.48865109999997],
              [119.07562220000011, -2.494618],
              [119.05503490000001, -2.503896399999974],
              [119.04075460000001, -2.508357099999955],
              [119.0339848000001, -2.524384299999952],
              [119.0322973000001, -2.539619699999946],
              [119.03458780000005, -2.569024699999943],
              [119.03099080000004, -2.581358499999965],
              [119.0222447000001, -2.587498299999936],
              [119.0113579, -2.588128299999937],
              [119.0079363000001, -2.591120099999955],
              [119.01050020000002, -2.599462499999959],
              [118.99817460000008, -2.60522519999995],
              [118.99652750000007, -2.617382899999939],
              [118.9884872, -2.620506899999953],
              [118.9805735000001, -2.62916279999996],
              [118.9669937000001, -2.627751199999977],
              [118.9669, -2.632053599999949],
              [118.9595369000001, -2.640808599999957],
              [118.94703770000001, -2.638431199999957],
              [118.94237270000008, -2.639738],
              [118.93266630000005, -2.651145199999974],
              [118.92566230000011, -2.65073979999994],
              [118.92443360000004, -2.65947749999998],
              [118.903946, -2.668389],
              [118.894301, -2.667244499999924],
              [118.88389060000009, -2.676625399999978],
              [118.87378290000004, -2.680822099999943],
              [118.86798930000009, -2.679176699999971],
              [118.867042, -2.673080499999969],
              [118.85949290000008, -2.669225499999925],
              [118.85533070000008, -2.660768099999927],
              [118.8479622000001, -2.658000499999957],
              [118.8437, -2.652681],
              [118.84006640000007, -2.642969099999959],
              [118.83031840000001, -2.629372299999943],
              [118.8218323000001, -2.620072899999968],
              [118.81591520000006, -2.622055899999964],
              [118.81165310000006, -2.633669699999928],
              [118.81111580000004, -2.641999599999963],
              [118.80016970000008, -2.653458499999942],
              [118.7931688000001, -2.665790899999934],
              [118.7879683000001, -2.668624799999975],
              [118.77758030000007, -2.684452],
              [118.7784415000001, -2.702684],
              [118.7735037000001, -2.714350399999944],
              [118.7742, -2.721429],
              [118.7798, -2.736851],
              [118.78417620000005, -2.74279],
              [118.7813155, -2.748397099999977],
              [118.7746237, -2.750288699999942],
              [118.7625, -2.748896],
              [118.7578, -2.763686],
              [118.7589, -2.766989],
              [118.7558, -2.779788],
              [118.7582000000001, -2.79072],
              [118.758, -2.800513],
              [118.77253970000004, -2.811384],
              [118.77304230000004, -2.820629499999939],
              [118.76885220000008, -2.835001599999941],
              [118.76965810000002, -2.84457679999997],
              [118.76736170000004, -2.853558599999928],
              [118.76995950000003, -2.86731229999998],
              [118.79016310000009, -2.861027299999932],
              [118.80973920000008, -2.85143759999994],
              [118.8227422000001, -2.852975299999969],
              [118.83467680000001, -2.856794099999945],
              [118.84151910000003, -2.850782399999957],
              [118.8512445, -2.852217499999938],
              [118.86310560000004, -2.861024699999973],
              [118.87990780000007, -2.878950499999974],
              [118.886011, -2.887312299999962],
              [118.8877103000001, -2.90224319999993],
              [118.8861955000001, -2.910777399999972],
              [118.8760228000001, -2.923058],
              [118.875081, -2.933862499999975],
              [118.86880450000001, -2.946182199999953],
              [118.86966330000007, -2.95578329999995],
              [118.85701310000002, -2.978493199999946],
              [118.84811590000004, -2.991759],
              [118.84769740000002, -3.007458199999974],
              [118.8549, -3.011405],
              [118.86055090000002, -3.021746],
              [118.86068510000007, -3.03179549999993],
              [118.85179640000001, -3.049957],
              [118.848492, -3.053275],
              [118.83955870000011, -3.081351599999948],
              [118.8245, -3.089005],
              [118.80460030000006, -3.079693599999928],
              [118.80189430000007, -3.071672199999966],
              [118.79627560000006, -3.071917099999951],
              [118.79694630000006, -3.08100109999998],
              [118.79231620000007, -3.084398199999953],
              [118.78593930000011, -3.079372099999944],
              [118.77758770000003, -3.083599199999981],
              [118.77628760000005, -3.104290299999946],
              [118.7815802, -3.118850599999973],
              [118.78036380000003, -3.121183499999972],
              [118.78895970000008, -3.134984199999963],
              [118.79904320000003, -3.143910699999935],
              [118.80797060000009, -3.147637499999973],
              [118.82024420000005, -3.16359719999997],
              [118.82666250000011, -3.165092199999947],
              [118.82980960000009, -3.170499899999925],
              [118.82938230000002, -3.180503499999929],
              [118.840182, -3.209833599999968],
              [118.838045, -3.219440099999929],
              [118.8288447000001, -3.233063399999935],
              [118.8402678000001, -3.251324499999953],
              [118.8392861000001, -3.25410409999995],
              [118.84671340000011, -3.28268769999994],
              [118.85308810000004, -3.288610299999959],
              [118.85657070000002, -3.29657949999995],
              [118.85731420000002, -3.305612099999962],
              [118.85347500000012, -3.31216029999996],
              [118.84735750000004, -3.313231299999927],
              [118.84348190000003, -3.319704],
              [118.8416089000001, -3.332917399999928],
              [118.84430570000006, -3.33961039999997],
              [118.85126550000007, -3.342513599999961],
              [118.84541370000011, -3.364291199999968],
              [118.8490385, -3.371617399999934],
              [118.84525520000011, -3.37728809999993],
              [118.85161960000005, -3.385324199999957],
              [118.85095460000002, -3.389494099999979],
              [118.85994950000008, -3.400547199999949],
              [118.86832160000006, -3.417281699999933],
              [118.86998190000008, -3.427162899999928],
              [118.87834850000002, -3.440778099999932],
              [118.87944520000008, -3.447765599999968],
              [118.8773943000001, -3.472205599999938],
              [118.88733530000002, -3.485491899999943],
              [118.89510940000002, -3.487024499999961],
              [118.89314350000006, -3.497754199999974],
              [118.8997551000001, -3.509723],
              [118.906229, -3.525181099999941],
              [118.915186, -3.537189599999977],
              [118.92174630000011, -3.540149],
              [118.92680020000012, -3.548923099999968],
              [118.92782510000006, -3.558546899999953],
              [118.93510480000009, -3.569032599999957],
              [118.9425854000001, -3.568814799999927],
              [118.9447838000001, -3.557663399999967],
              [118.95002620000002, -3.549542],
              [118.95776980000005, -3.550732399999958],
              [118.96841040000004, -3.544851],
              [118.97812440000007, -3.549301399999933],
              [118.98052270000005, -3.556565],
              [118.99354710000011, -3.564793599999973],
              [119.00124760000006, -3.561831899999959],
              [118.99534330000006, -3.555214799999931],
              [118.99667590000001, -3.546578599999975],
              [119.00701570000001, -3.531291],
              [119.020728, -3.516449499999965],
              [119.03002760000004, -3.513718399999959],
              [119.03752660000009, -3.516276599999969],
              [119.05712640000002, -3.50514069999997],
              [119.06466340000009, -3.502473],
              [119.07864960000006, -3.504906499999947],
              [119.10178690000009, -3.51283589999997],
              [119.10687220000011, -3.516505399999971],
              [119.11912030000008, -3.512560699999938],
              [119.12496180000005, -3.501505899999927],
              [119.13555010000005, -3.494069099999933],
              [119.15431090000004, -3.491345199999955],
              [119.1642753000001, -3.488305499999967],
              [119.17358640000009, -3.488752199999965],
              [119.19699770000011, -3.493805299999963],
              [119.222915, -3.485388799999953],
              [119.23543250000012, -3.486042899999973],
              [119.24031480000008, -3.48078739999994],
              [119.2420945, -3.472429099999943],
              [119.24603880000006, -3.466271799999959],
              [119.26356220000002, -3.45700839999995],
              [119.28226740000002, -3.453272],
              [119.27811170000007, -3.448685199999943],
              [119.27871730000004, -3.441050099999927],
              [119.28359120000005, -3.431222799999944],
              [119.292904, -3.431112599999949],
              [119.30029150000007, -3.425828699999954],
              [119.30712380000011, -3.425213199999973],
              [119.31704790000003, -3.427749499999948],
              [119.33036410000011, -3.434649099999945],
              [119.33455870000012, -3.43227829999995],
              [119.34405150000009, -3.434399],
              [119.35563780000007, -3.442074199999979],
              [119.36711490000005, -3.45325859999997],
              [119.37404310000011, -3.463423299999931],
              [119.37749830000007, -3.453803599999958],
              [119.38721240000007, -3.45003689999993],
              [119.38955550000003, -3.45697169999994],
              [119.40117780000003, -3.458575399999972],
              [119.40458480000007, -3.464029],
              [119.41139680000003, -3.466654399999925],
              [119.42239070000005, -3.457461899999942],
              [119.42694160000008, -3.459282],
              [119.42731790000005, -3.471947099999966],
              [119.4534198, -3.478859199999931],
              [119.46149380000008, -3.486102699999947],
              [119.47414320000007, -3.502913799999931],
              [119.48437590000003, -3.492593],
              [119.48998570000003, -3.473498],
              [119.47668010000007, -3.459179899999981],
              [119.47628570000006, -3.449545799999953],
              [119.46881610000003, -3.433323],
              [119.47267290000002, -3.412217],
              [119.4671082000001, -3.406464699999958],
              [119.46489420000012, -3.389287899999943],
              [119.45917710000003, -3.383391299999971],
              [119.46516870000005, -3.378675899999962],
              [119.46315860000004, -3.373140299999932],
              [119.45494990000009, -3.366174299999955],
              [119.45001740000009, -3.355421599999943],
              [119.43820690000007, -3.351844799999981],
              [119.43088130000001, -3.346828399999936],
              [119.43124710000006, -3.339234899999951],
              [119.44286960000011, -3.337533699999938],
              [119.44420850000006, -3.329287],
              [119.43458310000005, -3.316972899999939],
              [119.4397672, -3.302922799999976],
              [119.43483150000009, -3.294583099999954],
              [119.43680620000009, -3.288603599999931],
              [119.43608990000007, -3.277623199999937],
              [119.43061910000006, -3.263156199999969],
              [119.4212040000001, -3.249010299999952],
              [119.40040490000001, -3.238805599999978],
              [119.39082340000004, -3.232392399999981],
              [119.37319800000012, -3.212565699999971],
              [119.37027850000004, -3.199339],
              [119.37226520000002, -3.196532199999979],
              [119.38305360000004, -3.195720699999924],
              [119.39204570000004, -3.198321],
              [119.40931660000001, -3.184720299999981],
              [119.41633410000009, -3.182454699999937],
              [119.4308866, -3.172821],
              [119.4386032000001, -3.183595099999934],
              [119.44764680000003, -3.184359899999947],
              [119.4585052000001, -3.182291199999952],
              [119.46389760000011, -3.18864149999996],
              [119.47815, -3.19037869999994],
              [119.4895696000001, -3.182731499999932],
              [119.50351100000012, -3.177807799999925],
              [119.51561890000005, -3.171686399999942],
              [119.5157574000001, -3.167573699999934],
              [119.5254576000001, -3.151290099999926],
              [119.53694020000012, -3.13914189999997],
              [119.54358720000005, -3.13879129999998],
              [119.55919490000008, -3.134134599999925],
              [119.56826750000005, -3.129340199999945],
              [119.57352460000004, -3.131460199999935],
              [119.58197470000005, -3.129722099999981],
              [119.58631240000011, -3.125445499999955],
              [119.59630670000001, -3.128123],
              [119.60549730000002, -3.119812599999932],
              [119.61437510000007, -3.124643599999956],
              [119.63073270000007, -3.128389299999981],
              [119.63214290000008, -3.109985399999971],
              [119.6273979, -3.096712099999934],
              [119.62105080000003, -3.088042799999926],
              [119.6190514000001, -3.07924509999998],
              [119.61112330000003, -3.074004799999955],
              [119.6069424000001, -3.06664029999996],
              [119.59476060000009, -3.06329519999997],
              [119.5938814000001, -3.058104399999934],
              [119.58671820000006, -3.047457099999974],
              [119.58520830000009, -3.035545299999967],
              [119.5802450000001, -3.03492179999995],
              [119.5766119000001, -3.02652489999997],
              [119.57698690000007, -3.018306199999927],
              [119.57133990000011, -3.004618199999925],
              [119.56509270000004, -2.99531479999996],
              [119.56919430000005, -2.974047799999937],
              [119.57228950000001, -2.971618399999954],
              [119.57673180000006, -2.951805899999954],
              [119.56921240000008, -2.936739],
              [119.5691174000001, -2.927375899999959],
              [119.55686350000008, -2.92125649999997],
              [119.55430940000008, -2.913355699999954],
              [119.55524630000002, -2.906294899999978],
              [119.55088840000008, -2.897085899999979],
              [119.55602490000001, -2.885433199999966],
              [119.56338590000007, -2.879686499999934],
              [119.56909990000008, -2.867029399999979],
              [119.56786920000002, -2.857960499999933],
              [119.56138, -2.850796399999979],
              [119.56322750000004, -2.830565199999967],
              [119.56673580000006, -2.821564599999931],
              [119.56153780000011, -2.813170699999944],
              [119.56506, -2.806402499999933],
              [119.57526120000011, -2.771294699999942],
              [119.58462750000001, -2.762832799999956],
              [119.57843090000006, -2.757649499999957],
              [119.57842950000008, -2.752009899999962],
              [119.58719480000002, -2.743350599999928],
              [119.60611950000009, -2.740182199999936],
              [119.611348, -2.742538599999932],
              [119.6139108000001, -2.749098599999968],
              [119.62391040000011, -2.75078039999994],
              [119.63023360000011, -2.754517],
              [119.6358742000001, -2.751648599999953],
              [119.64959620000002, -2.75410329999994],
              [119.664842, -2.753499799999929],
              [119.6809429000001, -2.756156499999975],
              [119.6884318000001, -2.753007599999933],
              [119.69969780000008, -2.742847499999925],
              [119.7078226000001, -2.732355199999972],
              [119.72343460000002, -2.726997],
              [119.72958260000007, -2.718524499999944],
              [119.745513, -2.715343399999938],
              [119.74848380000003, -2.711468899999943],
              [119.74799590000009, -2.688553],
              [119.75558400000011, -2.685219399999937],
              [119.7573473000001, -2.680877799999962],
              [119.752488, -2.67099369999994],
              [119.76622730000008, -2.663625],
              [119.77610420000008, -2.662265199999979],
              [119.78418940000006, -2.65232489999994],
              [119.79696120000006, -2.640458299999977],
              [119.7824601000001, -2.627791599999966],
              [119.76063980000004, -2.617130499999973],
              [119.740018, -2.591072299999951],
              [119.7160689000001, -2.575854],
              [119.7095, -2.566533799999945],
              [119.71101090000002, -2.554543],
              [119.7139939000001, -2.547604899999953],
              [119.72647590000008, -2.526763099999926],
              [119.7310529, -2.507671199999947],
              [119.73132, -2.500274399999967],
              [119.72656010000003, -2.492268799999977],
              [119.70054280000011, -2.47376],
              [119.69434120000005, -2.460611799999981],
              [119.696927, -2.44539919999994],
              [119.71256010000002, -2.424510199999929],
              [119.71902210000007, -2.409469199999933],
              [119.719792, -2.404277499999978],
              [119.71435290000011, -2.392377899999929],
              [119.6998718000001, -2.371045199999969],
              [119.690224, -2.352103299999953],
              [119.69447240000011, -2.342145099999925],
              [119.68838760000006, -2.333526399999926],
              [119.68143740000005, -2.338636699999938],
              [119.6736012, -2.339410899999962],
              [119.67030120000004, -2.332946499999935],
              [119.66347950000011, -2.327085299999965],
              [119.65514960000007, -2.326341899999932],
              [119.650033, -2.315832],
              [119.64291130000004, -2.30984449999994],
              [119.62859330000003, -2.294154799999944],
              [119.63617240000008, -2.279816199999971],
              [119.62927760000002, -2.252779299999929],
              [119.63257870000007, -2.242452399999934],
              [119.64449050000007, -2.239692399999967],
              [119.65622320000011, -2.234230599999933],
              [119.66817050000009, -2.216134099999977],
              [119.67623, -2.209287299999971],
              [119.69074190000003, -2.20908019999996],
              [119.70664150000005, -2.197700599999962],
              [119.70746190000011, -2.194337299999972],
              [119.7270344000001, -2.177447699999959],
              [119.7293472, -2.16632809999993],
              [119.73834350000004, -2.161612699999978],
              [119.74316360000012, -2.15427509999995],
              [119.75163140000006, -2.154892299999972],
              [119.7618764, -2.145358],
              [119.762561, -2.136699699999951],
              [119.76987880000001, -2.120998899999961],
              [119.78199030000007, -2.116603399999974],
              [119.79310310000005, -2.108513799999969],
              [119.80366980000008, -2.112208599999974],
              [119.81002860000001, -2.103522199999929],
              [119.81421680000005, -2.101897199999939],
              [119.81740390000004, -2.09356],
              [119.81610390000003, -2.083857399999943],
              [119.82082860000003, -2.073565699999961],
              [119.81766, -2.060026099999959],
              [119.82538080000006, -2.04889309999993],
              [119.839175, -2.044561599999952],
              [119.8422862000001, -2.045923899999934],
              [119.85147630000006, -2.041797599999938],
              [119.85473470000011, -2.035353499999928],
              [119.86151260000008, -2.032724899999948],
              [119.86582280000005, -2.020796699999948],
              [119.87027130000001, -2.018059699999981],
              [119.86820560000001, -2.008205299999929],
              [119.87043720000008, -1.999807099999941],
              [119.8676355, -1.984695099999954],
              [119.86764180000011, -1.967472799999939],
              [119.86469840000007, -1.964206899999965],
              [119.86580050000009, -1.94916939999996],
              [119.87082630000009, -1.938218399999926],
              [119.86229120000007, -1.933796299999926],
              [119.86046750000003, -1.928034499999967],
              [119.86567840000009, -1.911833399999978],
              [119.869124, -1.890376599999968],
              [119.86473420000004, -1.88068839999994],
              [119.8572266000001, -1.875812399999973],
              [119.87331240000003, -1.865439899999956],
              [119.87522090000004, -1.860421899999949],
              [119.86722510000004, -1.849863099999936],
              [119.868348, -1.841385599999967],
              [119.86402440000006, -1.838383699999952],
              [119.85798330000011, -1.822598699999958],
              [119.86268860000007, -1.816790099999935],
              [119.859053, -1.806865399999936],
              [119.86437750000005, -1.796077199999957],
              [119.86603620000005, -1.787906199999952],
              [119.87136970000006, -1.780714299999943],
              [119.86765780000007, -1.771814399999926],
              [119.85660030000008, -1.769961199999955],
              [119.843496, -1.775466399999971],
              [119.83601220000003, -1.770155699999975],
              [119.82694140000001, -1.767393399999946],
              [119.82008340000004, -1.758122599999979],
              [119.81460820000007, -1.755014699999947],
              [119.81459380000001, -1.749298599999975],
              [119.80900930000007, -1.741287399999976],
              [119.79998940000007, -1.74147279999994],
              [119.79245450000008, -1.730300599999964],
              [119.79428070000006, -1.724564499999929],
              [119.7764658000001, -1.709053499999925],
              [119.77961510000011, -1.693033899999932],
              [119.7766342000001, -1.687159699999938],
              [119.76472680000006, -1.679166099999975],
              [119.75949760000003, -1.66795],
              [119.75933410000005, -1.658497899999929],
              [119.7510195000001, -1.652617599999928],
              [119.75020150000012, -1.643094],
              [119.72912910000002, -1.644939699999952],
              [119.72624580000002, -1.634984899999949],
              [119.728997, -1.621977],
              [119.73563230000002, -1.613355899999931],
              [119.73292960000003, -1.604313599999955],
              [119.72068420000005, -1.597146899999927],
              [119.71530890000008, -1.592182499999979],
              [119.7097017000001, -1.582429899999966],
              [119.709984, -1.57681759999997],
              [119.70414910000011, -1.573653199999967],
              [119.70126470000002, -1.566065799999933],
              [119.69200020000005, -1.564839699999936],
              [119.684871, -1.553263199999947],
              [119.67816120000009, -1.550614799999948],
              [119.671011, -1.543132],
              [119.67309630000011, -1.534815],
              [119.66663540000002, -1.53048519999993],
              [119.66931440000008, -1.514187499999935],
              [119.68476910000004, -1.485285199999964],
              [119.68657960000007, -1.477366399999937],
              [119.69334920000006, -1.472722],
              [119.69259540000007, -1.467011399999933],
              [119.70057450000002, -1.457813499999929],
              [119.69545670000002, -1.45089669999993],
              [119.68501770000012, -1.449009399999966],
              [119.68407140000011, -1.442323],
              [119.67086590000008, -1.442564599999969],
              [119.6715488000001, -1.43330949999995],
              [119.66311660000008, -1.422275699999943],
              [119.6551803000001, -1.422890399999972],
              [119.64679870000009, -1.413483099999951],
              [119.64368410000009, -1.406202399999927],
              [119.63266520000002, -1.400602599999957],
              [119.62833060000003, -1.405948699999954],
              [119.62327790000006, -1.39710649999995],
              [119.61444760000006, -1.401915499999973],
              [119.60155810000003, -1.402689199999941],
              [119.59999460000006, -1.397693],
              [119.59218610000005, -1.396472599999925],
              [119.58803110000008, -1.410196799999937],
              [119.58201960000008, -1.410402399999953],
              [119.58451880000007, -1.421344599999941],
              [119.582929, -1.425621299999932],
              [119.56869270000004, -1.42134429999993],
              [119.56892080000011, -1.428036299999974],
              [119.5619306000001, -1.427624399999956],
              [119.55688, -1.431525499999964],
              [119.549113, -1.433097799999928],
              [119.54525880000006, -1.422491199999968],
              [119.54095450000011, -1.429817],
              [119.53201660000002, -1.429438299999958],
              [119.52919290000011, -1.436681199999953],
              [119.51937390000012, -1.446137299999975],
              [119.5117378000001, -1.451117],
              [119.50810750000005, -1.445790299999942],
              [119.51068790000011, -1.438175099999967],
              [119.51719930000002, -1.438927099999944],
              [119.52161920000003, -1.423746599999959],
              [119.51865470000007, -1.412008899999933],
              [119.513054, -1.411436599999945],
              [119.50896740000007, -1.40377639999997],
              [119.5033585000001, -1.401269799999966],
              [119.50216540000008, -1.392946499999937],
              [119.49052970000002, -1.390889099999924],
              [119.47844820000012, -1.398331799999937],
              [119.4519977000001, -1.366904399999953],
              [119.44702370000005, -1.355041399999948],
              [119.4487865000001, -1.339573899999948],
              [119.45584940000003, -1.335002199999963],
              [119.45608260000006, -1.329036299999927],
              [119.46430040000007, -1.318633399999953],
              [119.47129860000007, -1.316936099999964],
              [119.48196980000012, -1.322005099999956],
              [119.50020310000002, -1.320242299999961],
              [119.51056540000002, -1.312973799999952],
              [119.5194993, -1.315359899999976],
              [119.52659430000006, -1.300746599999968],
              [119.52149210000005, -1.294831599999952],
              [119.51594650000004, -1.302574099999958],
              [119.50968990000001, -1.298937899999942],
              [119.50121840000008, -1.29929889999994],
              [119.49880980000012, -1.30249189999995],
              [119.48174970000002, -1.30942349999998],
              [119.47451760000001, -1.303727299999935],
              [119.47330000000011, -1.29779],
              [119.47910740000009, -1.291258299999924],
              [119.4934674000001, -1.28337149999993],
              [119.50724260000004, -1.273076399999979],
              [119.51863890000004, -1.256529099999966],
              [119.523986, -1.251484699999935],
              [119.53061800000012, -1.238850199999945],
              [119.5421976, -1.222974299999976],
              [119.54210480000006, -1.200516299999947],
              [119.55695240000011, -1.204884299999947],
              [119.56421480000006, -1.201952499999948],
              [119.56614130000003, -1.19032059999995],
              [119.57049170000005, -1.181054199999949],
              [119.57216190000008, -1.14899029999998],
              [119.58767720000003, -1.152134399999966],
              [119.60314610000012, -1.15913269999993],
              [119.60725820000005, -1.154105299999969],
              [119.60766160000003, -1.139872199999957],
              [119.610421, -1.129879799999969],
              [119.609129, -1.120443099999932],
              [119.60372910000001, -1.117864899999972],
              [119.593038, -1.124265799999932],
              [119.5905120000001, -1.11485209999995],
              [119.5906662000001, -1.102996499999961],
              [119.58786530000009, -1.098908899999969],
              [119.58920710000007, -1.089999599999942],
              [119.5836392000001, -1.086608799999965],
              [119.57812620000004, -1.068021499999929],
              [119.58024980000005, -1.055545099999961],
              [119.57386250000002, -1.052901099999929],
              [119.5773124000001, -1.045645699999966],
              [119.57401460000005, -1.038445799999977],
              [119.57782640000005, -1.030925199999956],
              [119.57544690000009, -1.018624599999953],
              [119.56586940000011, -1.018698499999971],
              [119.55685950000009, -1.009547299999952],
              [119.55905630000007, -0.998536099999967],
              [119.55961060000004, -0.977653499999974],
              [119.55503230000011, -0.964163299999939],
              [119.55552970000008, -0.944720299999972],
              [119.55315580000001, -0.938173699999936],
              [119.55591650000008, -0.926884299999926],
              [119.55868490000012, -0.926365299999929],
              [119.5633094000001, -0.913012399999957],
              [119.56194670000002, -0.895265599999959],
              [119.56457010000008, -0.895532199999934],
              [119.5701428000001, -0.881844499999943],
              [119.57185090000007, -0.867920799999979],
              [119.56203460000006, -0.854810499999928],
              [119.5658843000001, -0.847382799999934]
            ]
          ]
        ]
      },
      "properties": {
        "nama": "Provinsi Sulawesi Barat",
        "nid": 29,
        "type": 1
      }
    }
    ]
  };

  public static mapWALIKOTA = {};

  public static mapBUPATI = {};
}
